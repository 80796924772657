import React from "react";
import _ from "lodash";
import {Badge} from "reactstrap";

export const OverUnder = ({code, children, className}) => {
    return (
        <Badge className={className} color={code === "OVER" ? "danger" : "primary"} pill>{children}</Badge>
    )
};

export const OddEven = ({code, children, className}) => {
    return (
        <Badge className={className} color={code === "ODD" ? "success" : "info"} pill>{children}</Badge>
    )
};

export const Range = ({children}) => {
    const color = ranges[children - 1]?.color;
    return (
        <span style={{
            color: "white",
            fontWeight: "bold",
            paddingLeft: 5,
            paddingRight: 5,
            borderRadius: 5,
            fontSize: "14px",
            background: color
        }}>{children}</span>
    )
};

export const BetType = ({code, name}) => {
    const bet = _.find(betTypes, type => _.includes(type.code, code));
    if (bet)
        return <bet.component code={code}>{name}</bet.component>;
    return null;
};

const betTypes = [
    {
        "code": "OVER|UNDER",
        "component": OverUnder
    },
    {
        "code": "odd|even",
        "component": OddEven
    },
    {
        "code": "ONE|TWO|THREE|FOUR|FIVE|SIX",
        "component": Range
    }
];

const ranges = [
    {
        code: "ONE",
        color: "blue"
    },
    {
        code: "TWO",
        color: "darkgreen"
    },
    {
        code: "THREE",
        color: "darkblue"
    },
    {
        code: "FOUR",
        color: "brown"
    },
    {
        code: "FIVE",
        color: "deeppink"
    },
    {
        code: "SIX",
        color: "red"
    }
];
