import React, {useEffect} from "react";
import {posts} from "../../constants/lotto90";
import {playAudio} from "../../store/audio/action";
import ResultHistory from "./ResultHistory";
import BallNumbers from "./BallNumber";
import LastDraw from "./LastDraw";
import Header from "./Header";
import {inIframe} from "../../constants/general";

const ResultDashboard = (props) => {

    const {
        drawing: {number, isDrawing, detail, total}, drawingNumber,
        fetchLotto90ResultReport,
        resultReport: {items},
        oldResult,
        newResult,
        countDownTimer,
        t,
        config,
        audio
    } = props;
    const playSound = (src) => {
        if (audio && !audio.isMute) playAudio(src);
    }


    useEffect(() => {
        fetchLotto90ResultReport();
    }, [drawingNumber, fetchLotto90ResultReport])


    const getCodeByConfig = (code) => {
        if (config) {
            let conf = config.lotto90Config.filter(e => e.code === code)[0];
            return conf && conf.rangeNumber;
        }
        return "";
    }


    const SpecialX = ({rkey, value, isTrKey = true, isTrVal = true, rkeyP = ""}) => {
        let val;
        let key;
        if (isDrawing) {
            val = "?";
            key = "?";
        } else {
            if (isTrVal)
                val = t("labels:drawing.lotto90.label.".concat(value))
            else
                val = value
            if (isTrKey) key = rkeyP + t("labels:drawing.lotto90.".concat(rkey));
            else key = rkey;

        }
        return <div className="post-spacial-sec">
            <div className="post-spacial d-flex">
                <div className="post-spacial-value">
                    {key}
                </div>
                <div className="post-spacial-label">
                    {val}
                </div>
            </div>
        </div>
    }


    const SpecialXList = () => {
        return <div className="d-flex align-items-center justify-content-start mt-2">
            <div className="f-res label-spe">{t("labels:drawing.lotto90.special_x")}</div>
            <SpecialX rkey={oldResult?.underOverCode} value={oldResult?.underOverCode}/>
            <SpecialX rkey={oldResult?.oddEvenCode} value={oldResult?.oddEvenCode}/>
            <SpecialX
                isTrKey={false}
                rkey={t("labels:drawing.lotto90.".concat(oldResult?.underOverCode)) + t("labels:drawing.lotto90.".concat(oldResult?.oddEvenCode))}
                isTrVal={false}
                value={t("labels:drawing.lotto90.label.".concat(oldResult?.underOverCode)) + t("labels:drawing.lotto90.label.".concat(oldResult?.oddEvenCode))}
            />
            <SpecialX rkey={oldResult?.rangeCode} rkeyP="R" value={oldResult?.rangeCode}/>
            <SpecialX isTrKey={false} isTrVal={false} rkey={oldResult?.smallCode}
                      value={getCodeByConfig(oldResult?.smallCode)}/>
            <SpecialX isTrKey={false} isTrVal={false} rkey={oldResult?.bigCode}
                      value={getCodeByConfig(oldResult?.bigCode)}/>
        </div>
    }


    const SpecialList = () => {
        return <div className="d-flex align-items-center justify-content-start mt-2">
            <div className="f-res label-spe">{t("labels:drawing.lotto90.special")}</div>
            {
                posts.map((post, i) => {
                    let show;
                    if (oldResult?.posts) {
                        let number = oldResult.posts[i].number;
                        if (isDrawing && !detail.includes(parseInt(number))) {
                            show = "?"
                        } else show = number;
                    }

                    return <div key={i} className="post-sec">
                        <div className="post-a d-flex justify-content-end" key={i}>
                            <div className="post-value">{post}</div>
                            < div className="post-label">{show}</div>
                        </div>
                    </div>
                })
            }
        </div>
    }


    const isInIframe = inIframe();

    return <div id="lotto90" className={`col no-padding-div none-select mt-2 ${isInIframe ? "" : "ml-5 mr-5"}`}>
        <div className="row col no-padding-div justify-content-center">
            <div className={`no-padding-div pad-r-3 ${isInIframe ? "col-8" : "col-7"}`}>
                <div className="left-side">
                    <div className="lo90-drawing">
                        <div className="p-body ml-auto mr-auto">
                            <Header oldResult={oldResult} isDrawing={isDrawing}
                                    newResult={newResult} countDownTimer={countDownTimer}
                                    playSound={playSound}/>
                            <LastDraw isDrawing={isDrawing}
                                      t={t}
                                      oldResult={oldResult}
                                      total={total}/>
                            <SpecialList/>
                            {oldResult?.result && <SpecialXList/>}
                            <BallNumbers/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`no-padding-div pad-l-3 ${isInIframe ? "col-4" : "col-3"}`}>
                <ResultHistory isDrawing={isDrawing} items={items} number={number}/>
            </div>
        </div>
    </div>
}

export default ResultDashboard;
