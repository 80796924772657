import {
    FETCH_LOTTO5_CONFIGS
} from "./constant";

const initialState = {
    lotto5Config: {
        overUnder: [],
        ranges: []
    },
    lotto6Config: {
        slots: [],
        rangeLine: []
    },
    lotto12Config: {
        underOverOddEven: [],
        colors: [],
        numbers: [],
    },
    lotto90Config: [],
    limit: {},
    packagePrices: [],
    lotto90PackagePrices: [],
    lotto99PackagePrices: [],
    yukiBetTypes: {
        underOverOddEven: [],
        colors: [],
        numbers: [],
    }
};

const reducer = (state = initialState, action) => {
    if (action.type === FETCH_LOTTO5_CONFIGS) {
        return {
            ...action.payload
        }
    }
    return state;
};

export default reducer;
