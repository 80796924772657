import {UncontrolledTooltip} from "reactstrap";
import React from "react";
import _ from "lodash";
import {moneyFormatter} from "../../../utils/MoneyUtils";

const TicketPrintBody = (props) => {
    const {item, bodyClass, t, is58} = props;

    const returnMargin = (length) => {
        switch (length) {
            case 1:
                return "ml-1";
            case 2:
                return "ml-2";
            case 3:
                return "ml-3";
            case 4:
                return "ml-3";
            case 5:
                return "ml-4";
            case 6:
                return "ml-4";
            case 7:
                return "ml-5";
            case 8:
                return "ml-5";
            case 9:
                return "ml-5";
            default:
                return "";
        }

    };

    const returnColHeight = (obj) => {
        let arr = [obj.numberFrom.length, obj.numberTo.length];
        let a = Math.max(...arr);
        switch (a) {
            case 1:
                return "h-t-1";
            case 2:
                return "h-t-2";
            case 3:
                return "h-t-3";
            case 4:
                return "h-t-4";
            case 5:
                return "h-t-5";
            case 6:
                return "h-t-6";
            case 7:
                return "h-t-7";
            case 8:
                return "h-t-8";
            case 9:
                return "h-t-9";
            default:
                return "h-t-10";
        }
    };

    const returnExpanded = (obj) => {
        let arr = [obj.numberFrom.length, obj.numberTo.length];
        let ex = Math.max(...arr);
        switch (ex) {
            case 1:
                return <div className="expanded-ticket"/>;
            case 2:
                return <div className="expanded-ticket"/>;
            case 3:
                return <div style={{height: '30px'}}/>;
            case 4:
                return <div style={{height: '65px'}}/>;
            case 5:
                return <div style={{height: '100px'}}/>;
            case 6:
                return <div style={{height: '130px'}}/>;
            case 7:
                return <div style={{height: '160px'}}/>;
            case 8:
                return <div style={{height: '190px'}}/>;
            case 9:
                return <div style={{height: '215px'}}/>;
            default:
                return <div style={{height: '245px'}}/>;
        }
    };

    const vertLine = (obj) => {
        let isDigitOne = false;
        let isDigitTwo = false;
        let isDigitThree = false;
        let isDigitFour = false;
        let titles = obj?.betTitle?.split("_");
        titles && titles.forEach((e) => {
            switch (true) {
                case e === "J1":
                    isDigitOne = true;
                    break;
                case e === "J2":
                    isDigitTwo = true;
                    break;
                case e === "J3":
                    isDigitThree = true;
                    break;
                case e === "J4":
                    isDigitFour = true;
                    break;
                default:
                    break;
            }
        });
        return <div className="d-inline-flex ml-hr-ticket">
            <div className={`hr-vert ${isDigitOne ? "" : "border-0"}`}/>
            <div className={`hr-vert ${isDigitTwo ? "" : "border-0"}`}/>
            {obj.numberFrom.length > 2 && <div className={`hr-vert ${isDigitThree ? "" : "border-0"}`}/>}
            {obj.numberFrom.length > 3 && <div className={`hr-vert ${isDigitFour ? "" : "border-0"}`}/>}
            {obj.multiDigit != null && <div
                className={`hr-right ${obj.numberFrom.length === obj.multiDigit ? 'ml-hr-ticket' : 'ml-3'}`}/>}
        </div>
    };

    const getPairLabel = (length) => {
        if (length === 2) return "NOT_PAIR";
        if (length === 3) return "NOT_TRIPLE";
        if (length === 4) return "NOT_SQUARE";
    };

    const renderNormal = (obj, i) => {
        let num = _.isNaN(parseInt(obj.numberFrom)) ? t("labels:drawing.lotto90.label." + obj.numberFrom) : obj.numberFrom;
        let isEmPost = _.isEmpty(item.postGroup);
        return <div style={{lineHeight: "28px"}} key={i}
                    className={`row p-0 m-0 align-items-center ${obj.winQty > 0 ? " bg-win" : ""}`}>
            <div className="row p-0 m-0 align-content-center">
                <div className={`f-23 ${isEmPost ? "f-bayon" : "font-weight-bold"}`}>{num}</div>
                &nbsp;:&nbsp;
                <div>{moneyFormatter(obj.betAmount, obj.currencyCode, `f-23 ${isEmPost ? "f-bayon" : "font-weight-bold"}`)}</div>
            </div>
            {obj?.winQty > 0 && (
                <div className="win-animate p-0 m-0 text-center ml-1 mb-1">
                    <div className="f-18 font-weight-bold">{obj?.winQty}</div>
                </div>
            )}
        </div>
    };

    const renderRange = (obj, i) => {
        return <div key={i} className="row p-0 m-0 font-weight-bold">
            <div>
                <div style={{lineHeight: "25px"}}
                     className="f-23 d-flex">{`${obj.numberFrom} ${obj.multiDigit == null ? "" : obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}`}&nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode, "f-23")}
                    {obj?.winQty > 0 && (
                        <div style={{lineHeight: "20px"}} className="win-animate p-0 m-0 text-center ml-1 mt-1 f-16 font-weight-bold">
                            {obj?.winQty}
                        </div>
                    )}</div>
                <div className="row justify-content-between m-0 ">
                    <div className={`hr-vert ${returnMargin(obj.numberFrom.length)}`}/>
                    {obj.multiDigit != null && <div
                        className={`hr-right ${obj.numberFrom.length === obj.multiDigit ? 'mr-1' : 'mr-3'}`}/>}
                    <div className="row p-0 m-0">
                        <div style={{lineHeight: "16px"}}
                             className="black-c f-16 pt-1">&nbsp;{`(${obj.numberQty}លេខ)`}{obj.pairStatus === 2 && (
                            <span className="text-danger">
                                        {t(`messages:sale.bet_title.`.concat(getPairLabel(obj.numberFrom?.length)))}
                                    </span>
                        )}</div>
                    </div>
                </div>
                <div style={{lineHeight: "25px"}}
                     className='f-23'>{`${obj.numberTo} ${obj.multiDigit == null ? "" : obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}`}&nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode, "f-23")}</div>
            </div>
            {/*<div>*/}
            {/*    <div className="d-flex">*/}
            {/*        <div> &nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode, "f-20")}</div>*/}
            {/*        {obj?.winQty > 0 && (*/}
            {/*            <div className="win-animate p-0 m-0 text-center ml-1 mt-win f-18 font-weight-bold">*/}
            {/*                {obj?.winQty}*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*    <div className="row p-0 m-0">*/}
            {/*        <div*/}
            {/*            className="black-c f-16">&nbsp;{`(${obj.numberQty}លេខ)`}{obj.pairStatus === 2 && (*/}
            {/*            <span className="text-danger">*/}
            {/*                            {t(`messages:sale.bet_title.`.concat(getPairLabel(obj.numberFrom?.length)))}*/}
            {/*                        </span>*/}
            {/*        )}</div>*/}
            {/*    </div>*/}
            {/*    <div className="mt-1"> &nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode, "f-23")}</div>*/}
            {/*</div>*/}
        </div>
    };

    const renderMultiple = (obj, i) => {
        return <div key={i} className="row p-0 m-0">
            <div className={`w-left-side align-items-center row p-0 m-0 w-100 ${obj.winQty > 0 ? " bg-win" : ""}`}>
                <div className="f-25 f-bayon">{obj.numberFrom}</div>
                &nbsp;
                <button className={`text-black-50 border-0 p-0 m-0 ${
                    obj.winQty > 0 ? " bg-win" : " bg-white"
                }`}>
                    <div id={`multiply${i}`}
                         className="black-c">{obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}</div>
                    <UncontrolledTooltip placement="bottom" target={`multiply${i}`}>
                        {`លោកអ្នកចាក់ គុណ${obj.numberFrom.length === obj.multiDigit ? "" : `${obj.multiDigit}D`} = ${obj.numberQty}លេខ`}
                    </UncontrolledTooltip>
                </button>
                &nbsp;:&nbsp;
                <div>{moneyFormatter(obj.betAmount, obj.currencyCode)}</div>
                {obj?.winQty > 0 && (
                    <div className="win-animate p-0 m-0 text-center ml-1 f-18 font-weight-bold">
                        {obj?.winQty}
                    </div>
                )}
            </div>

        </div>
    };

    const renderSpeRange = (obj, i) => {
        return <div key={i} className="row p-0 m-0">
            <div>
                <div key={i} className="row p-0 m-0">
                    <div>
                        <div>{`${obj.numberFrom} ${obj.multiDigit == null ? "" : obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}`}</div>
                        <div className="d-inline-flex">
                            {vertLine(obj)}
                        </div>
                        <div>{`${obj.numberTo} ${obj.multiDigit == null ? "" : obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}`}</div>
                    </div>
                    <div>
                        <div>&nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode)}</div>
                        <div className="row p-0 m-0 pb-1 pt-1">
                            {obj?.winQty > 0 && (
                                <div className="win-animate p-0 m-0 text-center ml-1 f-18 font-weight-bold">
                                    {obj?.winQty}
                                </div>
                            )}
                            <div
                                className={`black-c pb-0 ${bodyClass}`}>&nbsp;{`(${obj.numberQty}លេខ)`} {obj.pairStatus === 2 && (
                                <span className="text-danger">
                                        {t(`messages:sale.bet_title.`.concat(getPairLabel(obj.numberFrom?.length)))}
                                    </span>
                            )}</div>
                        </div>

                        <div>&nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode)}</div>
                    </div>
                </div>
                <div className="m-0 row mb-1">
                    {obj.betTitle &&
                    <div
                        className="font-weight-badge text-white badge-danger">{t(`messages:sale.bet_title.${obj.betTitle}`)}</div>}
                </div>
            </div>
        </div>
    };

    const renderMatrix = (obj, i) => {
        return <div key={i} className="row p-0 m-0 pb-1">
            <div className="row align-items-center p-0 m-0">
                {obj.numberFrom.length > 0 && <div
                    className={`pr-1  ${returnColHeight(obj)}`}>
                    {obj.numberFrom?.split("").map((c, i) => {
                        return <div key={i}>{c}</div>
                    })}
                </div>}
                {obj.numberTo.length > 0 && <div
                    className={`border-left-matrix pl-1 pr-1 ${returnColHeight(obj)}`}>
                    {obj.numberTo?.split("").map((c, i) => {
                        return <div key={i}>{c}</div>
                    })}
                </div>}

            </div>
            <div className="justify-content-between">
                <div className="row p-0 m-0">
                    &nbsp;:&nbsp;
                    <div
                        className="black-c">{`(${obj.numberQty}លេខ)`}{obj.pairStatus === 1 && (
                        <span>{t(`messages:sale.bet_title.PAIR`)}</span>)}{obj.pairStatus === 2 && (
                        <span className="text-danger">
                                        {t(`messages:sale.bet_title.`.concat(getPairLabel(obj.numberFrom?.length)))}
                                    </span>
                    )}</div>
                </div>
                {is58 ? returnExpanded(obj) : <div className="expanded-ticket">
                    {obj?.winQty > 0 && (
                        <div className="win-animate p-0 m-0 text-center ml-1 f-18 font-weight-bold">
                            {obj?.winQty}
                        </div>
                    )}
                </div>}
                <div className="row p-0 m-0">
                    {obj.multiDigit != null && <div
                        className="black-c">{obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}</div>}
                    <div className="mb-bet-1">&nbsp;:&nbsp;</div>
                    <div className="mb-bet-2">{moneyFormatter(obj.betAmount, obj.currencyCode)}</div>
                </div>

            </div>
        </div>
    };


    return item.items.map((obj, i) => {
        if (obj.betTitle === "MULTIPLE") return renderMultiple(obj, i);
        obj.posts = item.posts;
        switch (obj.betType) {
            // case "NORMAL":
            //     return renderNormal(obj, i);
            case "RANGE":
                return renderRange(obj, i);
            case "MULTIPLE":
                return renderMultiple(obj, i);
            case "SPECIAL_RANGE":
                return renderSpeRange(obj, i);
            case "MATRIX":
                return renderMatrix(obj, i);
            default :
                return renderNormal(obj, i);
        }
    });
};

export default TicketPrintBody;
