import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {env} from "../../env"
import Dashboard from "../../components/lotto5/Dashboard";
import {connect} from "react-redux";
import {fetchUserBalance, setIsAutoPrint} from "../../store/user/action";
import {
    deleteLotto5Betting,
    fetchLotto5RecentBetReport,
    fetchLotto5RecentBetReportCache,
    lotto5ConfirmBettingNow
} from "../../store/lotto5/action";
import Lotto5Nav from "../../components/lotto5/Lotto5Nav";

class Lotto5Dashboard extends Component {

    render() {

        let platform = env.PLATFORM_FOR;

        return (
            <div className="main-w">
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-new-lotto5 lotto5">
                            <Lotto5Nav/>
                            <Dashboard
                                {...this.props}
                                platform={platform}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    user: state.user,
    audio: state.audio,
})


export default withTranslation()(connect(
    mapStateToProps,
    {
        setIsAutoPrint,
        lotto5ConfirmBettingNow,
        fetchLotto5RecentBetReport,
        fetchLotto5RecentBetReportCache,
        deleteLotto5Betting,
        fetchUserBalance,
    }
)(Lotto5Dashboard))
