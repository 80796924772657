import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    setBetStatus,
    setRenderResultStatus,
    setResultTotalNumberKeno,
    fetchLotto12RecentDraw,
    fetchLotto12DrawingResult,
    playCountDownAudio,
    playGoodLuckAudio,
    fetchStatistic12,
    fetchLotto12ListBetting,
    addLotto12Betting,
    deleteLotto12Betting,
    lotto12ConfirmBettingNow,
    fetchUserBalance,
    fetchLotto12RecentBetReport,
    fetchUserBalanceValidate,
    setIsAutoPrint,
    clearCartLotto12, setIsAddToCart, fetchLotto12RecentBetReportCache
} from "../../store/rootAction";
import {clientAp} from "../../constants/general";
import {SUBSCRIPTION_LOTTO12} from "../../constants/lotto12";
import {env} from "../../env";
import DashboardMix from "../../components/lotto12/mix/DashboardMix";
import {getMute12, setLotto12, toggleMute12} from "../../utils/cached";
import moment from "moment/moment";
import swal from "sweetalert";
import BettingDialog from "../../components/lotto5/BettingDialog";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";


class Lotto12DashboardMix extends Component {

    constructor(props) {
        super(props)
        this.state = {
            resultItem: null,
            resultItemRandom: null,
            indexResultItem: 0,
            allNumbers: [],
            timeCount: null,
            pendingAmount: 0,
            pendingLotto12: 0,
            winUnderOver: "",
            winOddEven: "",
            winNumber: "",
            winColor: "",
            imgLotto12: "",
            status: null,
            isStopped: true,
            isStartFlip: false,
            isStoppedJackpot: true,
            showJackpotValue: false,
            isEnableBetNow: true,
            isMute: "ON",
            counter: 1,
            bettingId: null,
            showDeleteBetting: false,
            showBetting: false,
            betCode: "",
            betAmount: 0,
            betGameType: "",
            betGameOdds: "",
            betGameRange: "",
            betMessage: "",
            isShowRecentBet: false,
            recentBetDetail: null,
            isShowValidateBalance: false,
            messageValidateBalance: null,
        };
    }

    setWinLose() {
        let data = this.props.oldResult;
        if (data) {
            const win = {
                winUnderOver: data.underOverCode,
                winOddEven: data.oddEvenCode,
                winNumber: data.numberCode,
                winColor: data.colorCode
            };

            this.setState({
                ...win
            });
            setLotto12({...win});
        }
    }

    preSetNewGame() {
        this.setState({
            winUnderOver: "",
            winOddEven: "",
            winNumber: "",
            winColor: "",
            resultItem: null,
            indexResultItem: 0,
            totalResult: null,
        });

    }


    checkDrawingResult() {
        this.props.fetchLotto12DrawingResult()
            .then(() => {
                let countDownTimer = this.props.countDownTimer;
                if (countDownTimer <= 0) {
                    this.props.setRenderResultStatus({isStopRender: false});
                    this.getReleaseNum();
                }
            });
    }

    subscription = () => {
        if (socketLottoConfig.l12) {
            socket.on(socketEvent.l12, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO12,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto12_drawing) {
                        let currentDraw = event.data.v_lotto12_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    onDrawStatusChange(currentDraw) {
        if (currentDraw.status !== this.state.status) {
            if (this.state.status && currentDraw.status === "OPEN") {
                this.refreshData();
            }
            this.setState({status: currentDraw.status});
            this.verifyCurrentDraw(currentDraw.drawCode);
        }
    }

    verifyCurrentDraw = currentDrawCode => {
        let betList = JSON.parse(sessionStorage.getItem("betting_lotto12") || "[]");
        if (betList.length) {
            let firstItem = betList[0];
            if (firstItem.drawCode !== currentDrawCode) {
                this.props.clearCart();
            }
        }

    }


    refreshData = () => {
        this.setState({isStopped: false, showJackpotValue: false});
        this.refresh = setTimeout(() => {
            this.setState({isStopped: true}, () => clearTimeout(this.refresh));
        }, 13000);
        this.checkDrawingResult();
        this.props.fetchStatistic12({row: 6, column: 7});
        this.props.fetchLotto12RecentDraw();
        this.props.fetchUserBalance();
        this.props.fetchLotto12RecentBetReportCache();
        this.preSetNewGame();
    }

    toggleMute = () => {
        this.setState((preState) => ({
            isMute: preState.isMute === "OFF" ? "ON" : "OFF"
        }), () => toggleMute12(this.state.isMute));
    }


    componentDidMount() {
        this.subscription();
        this.checkDrawingResult();
        this.props.fetchStatistic12({row: 6, column: 7});
        this.props.fetchLotto12RecentDraw();
        this.props.fetchUserBalance();
        this.props.fetchLotto12RecentBetReport();
        this.props.fetchLotto12ListBetting({isClear: true});
        this.listenLocalStorage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isStopBet && this.state.showBetting) {
            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betGameRange: "",
                betGameTime: ""
            })
        }
    }


    listenLocalStorage = () => {
        window.onstorage = () => {
            let mute = getMute12();
            if (mute !== this.state.isMute) {
                this.setState({isMute: mute});
            }
        }
    }

    componentWillUnmount() {
        if (this.drawingTime) clearTimeout(this.drawingTime);
        removeSocketListener(socketEvent.l12);
    }


    getReleaseNum = () => {
        this.setIsStartFlip(true);
    }

    countingSecond(count) {
        if (this.props.isStopRender) {
            if (this.state.countDownTimer < 0) {
                this.props.setRenderResultStatus({isStopRender: true})
            } else {
                let audio = new Audio();
                if (count >= 10) {
                } else if (count < 10 && count > 0) {
                    this.props.playCountDownAudio(count - 1);
                    this.props.setBetStatus({isStopBet: true})
                } else if (count === 0) {
                    this.props.playGoodLuckAduio();
                    this.props.setRenderResultStatus({isStopRender: false})
                    this.props.fetchLotto12ListBetting({isClear: true})
                    this.props.fetchLotto12DrawingResult().then(() => this.getReleaseNum(true));
                }
                // when the sound has been loaded, execute your code
                audio.oncanplaythrough = (event) => {
                    let playedPromise = audio.play();
                    if (playedPromise) {
                        playedPromise.catch((e) => {
                            if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                console.log(e.name);
                            }
                        }).then(() => {
                            console.log("playing sound !!!");
                        });
                    }
                }


            }
        }
    }


    setIsStartFlip = (value) => this.setState({isStartFlip: value});


    onBetting(item) {
        if (!this.props.isStopBet) {
            this.props.fetchUserBalanceValidate()
                .then(() => {
                    this.setState({
                        showBetting: true,
                        betGameType: item.name,
                        betGameOdds: item.value,
                        betGameRange: item.resultRange,
                        betCode: item.code,
                        betGameTime: moment(new Date()).format('HH:mm:ss')
                    });
                }).catch(error => {
                const {t} = this.props;
                this.setState({
                    isShowValidateBalance: true,
                    messageValidateBalance: t("labels:common." + error.response.data.message)
                });
            })
        }
    }

    clickAmountBetBtn = (amount) => {
        let betAmount = this.state.betAmount || 0

        this.setState({
            betAmount: parseInt(betAmount) + amount
        })
    }

    resetDefaultAmount = () => {
        this.setState({betAmount: 0})
    }

    changeBetAmount = (e) => {
        if (e.target.value >= 0 || !isNaN(e.target.value) || e.target.value === undefined) {
            this.setState({betAmount: e.target.value})
        }
    }

    isValidBetAmount = (betAmount = 0) => {
        betAmount = parseInt(betAmount);
        if (typeof betAmount !== "number") {
            return false;
        }
        const {maxBet, minBet} = this.props.config.limit;

        if (betAmount < minBet || betAmount > maxBet)
            return false;

        return true
    }

    onCloseValidateBalance() {
        this.setState({
            isShowValidateBalance: false,
            messageValidateBalance: null
        });
    }


    onConfirmBetting = async (logs) => {
        const {t, user: {userBalance, isAddToCart, isAutoPrint}, fetchLotto12ListBetting} = this.props
        let totalCartAmount = fetchLotto12ListBetting({isClear: false});
        const betAmount = this.state.betAmount;
        if (!this.isValidBetAmount(betAmount)) {
            this.setState({betMessage: t("labels:keno.requireAmount")})
        } else if ((totalCartAmount + betAmount) > userBalance.balance) {
            this.setState({betMessage: t("labels:common.NOT_ENOUGH_BALANCE")})
        } else {
            const {
                betCode,
                betAmount,
                betGameOdds
            } = this.state;
            const data = {
                drawCode: this.props.newResult.drawCode,
                betTypeCode: betCode,
                amount: betAmount,
                betLog: logs,
                rebateRate: betGameOdds
            }

            await this.props.addLotto12Betting(data);
            !isAddToCart && this.submitBetNow(isAutoPrint);

            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betCode: "",
                betGameRange: "",
                betGameTime: "",
                betMessage: null
            })
        }
    }


    onCancelBetting = () => {
        this.setState({
            showBetting: false,
            betAmount: 0,
            betGameType: '',
            betGameOdds: '',
            betMessage: "",
            betGameRange: "",
            betGameTime: ""
        })
    }

    showConfirmDelete = (id) => {
        this.setState({showDeleteBetting: false, bettingId: null})
        this.props.deleteLotto12Betting({id: id})
    }

    submitBetNow(isPrint) {
        const {isEnableBetNow} = this.state;
        const {user} = this.props;
        isEnableBetNow && this.props.lotto12ConfirmBettingNow(() => this.setState({isEnableBetNow: false}))
            .then(() => {
                this.setState({isEnableBetNow: true})
                this.props.fetchLotto12RecentBetReport().then(() => isPrint && document.getElementById("print-button").click());
                this.props.fetchUserBalance()
                    .then(() => {
                        this.setState({pendingLotto12: user.userBalance.pendingLotto12})
                    })
            }).catch((error) => {
                const {response} = error;
                response && swal(response.data.message, {icon: "error", button: "បិទ"});
            })
    }

    onShowDetail(item) {
        this.setState({
            isShowRecentBet: true,
            recentBetDetail: item
        });
    }


    onCloseDetail() {
        this.setState({
            isShowRecentBet: false,
            recentBetDetail: null
        });
    }

    afterPrint() {
        this.props.fetchLotto12DrawingResult();
    }


    render() {
        const betFunctions = {
            setIsStartFlip: this.setIsStartFlip,
            setWinLose: this.setWinLose.bind(this),
            tenSecondCallback: this.countingSecond.bind(this),
            platform: env.PLATFORM_FOR,
            toggleMute: this.toggleMute,
            showConfirmDelete: this.showConfirmDelete.bind(this),
            onBetting: this.onBetting.bind(this),
            onCloseValidateBalance: this.onCloseValidateBalance.bind(this),
            submitBetNow: this.submitBetNow.bind(this),
            afterPrint: this.afterPrint.bind(this),
        }
        return (
            <div className="lotto12-mix m-lotto-1 card-border">
                <DashboardMix
                    {...betFunctions}
                    {...this.props}
                    {...this.state}/>

                <BettingDialog changeBetAmount={this.changeBetAmount}
                               clickAmountBetBtn={this.clickAmountBetBtn}
                               onConfirmBettingLotto5={this.onConfirmBetting}
                               resetDefaultAmount={this.resetDefaultAmount}
                               onCancelBetting={this.onCancelBetting}
                               {...this.state}
                               classDialog="bet-mix-dialog"
                               {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.lotto12,
    config: state.config,
    user: state.user,
    audio: state.audio
})

const mapDispatchToProps = dispatch => ({
    setBetStatus: payload => dispatch(setBetStatus(payload)),
    setRenderResultStatus: payload => dispatch(setRenderResultStatus(payload)),
    setResultTotalNumber: payload => dispatch(setResultTotalNumberKeno(payload)),
    fetchLotto12DrawingResult: () => dispatch(fetchLotto12DrawingResult()),
    fetchLotto12RecentDraw: payload => dispatch(fetchLotto12RecentDraw(payload)),
    playCountDownAudio: (index, isMute) => dispatch(playCountDownAudio(index, isMute)),
    playGoodLuckAudio: (isMute) => dispatch(playGoodLuckAudio(isMute)),
    fetchStatistic12: (payload) => dispatch(fetchStatistic12(payload)),
    playGoodLuckAduio: () => dispatch(playGoodLuckAudio()),
    fetchLotto12ListBetting: payload => dispatch(fetchLotto12ListBetting(payload)),
    addLotto12Betting: payload => dispatch(addLotto12Betting(payload)),
    deleteLotto12Betting: payload => dispatch(deleteLotto12Betting(payload)),
    lotto12ConfirmBettingNow: callback => dispatch(lotto12ConfirmBettingNow(() => callback())),
    fetchUserBalance: () => dispatch(fetchUserBalance()),
    fetchLotto12RecentBetReport: () => dispatch(fetchLotto12RecentBetReport()),
    fetchUserBalanceValidate: () => dispatch(fetchUserBalanceValidate()),
    setIsAutoPrint: payload => dispatch(setIsAutoPrint(payload)),
    clearCart: () => dispatch(clearCartLotto12()),
    setIsAddToCart: payload => dispatch(setIsAddToCart(payload)),
    fetchLotto12RecentBetReportCache: payload => dispatch(fetchLotto12RecentBetReportCache(payload)),
})

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lotto12DashboardMix))
