export const getResult = (index, oldResult) => {
    if (!oldResult) {
        return {
            totalResult: 0,
            allNumbersDrawing: [],
            resultItem: 0
        }
    }
    let totalResult = 0;
    let resultDetail = oldResult.detail.split(",").map(e => parseInt(e));
    let allNumbersDrawings = resultDetail.slice(0, index);
    if (allNumbersDrawings.length > 0) {
        allNumbersDrawings.forEach(item => {
            totalResult += item;
        });
    }
    return {
        totalResult: totalResult,
        allNumbersDrawing: allNumbersDrawings,
        resultItem: resultDetail[index - 1]
    }
}

