import React from "react";

export default (props) => {
    const rows = [];
    const {t, numberItems} = props;

    for (let i = 0; i < numberItems.length; i++) {
        let cols = [];
        let rowColor = numberItems[i];
        for (let j = 0; j < rowColor?.length; j++) {
            const col = rowColor[j];
            cols.push(
                <td
                    key={i + "" + j}
                    id={"keno-history-key" + i + "" + j}
                    width="5%"
                >
                    {
                        (col.length > 0 ?
                            <span
                                className={"d-flex justify-content-center align-items-center  statistic-ball-" + col.split('_')[0]}>
                                {col.length > 0 ? t("labels:drawing.drawPrefix.S_" + col.split('_')[1]) : ''}
                            </span>
                            : <span/>)
                    }
                </td>);
        }
        rows.push(<tr key={i}>{cols}</tr>);
    }

    return rows;
}
