import React, {Component} from "react";
import _ from "lodash";
import Images from "../../../constants/lotto5Images";
import {getDateTime} from "../../../utils/dateUtils";
import {Badge} from "reactstrap";
import Printer from "../print/Printer";
import {moneyFormatter} from "../../../utils/MoneyUtils";
import {currencyCode} from "../../../constants/general";

class OldRecentTicketRow extends Component {

    render() {
        const {t, tickets, onShowDetail} = this.props;

        return <div className="table-container pt-0 recent-ticket">
            <table className="table-custom border-0">
                <tbody>
                {
                    _.isEmpty(tickets) && <tr>
                        <td colSpan={8}><img className="data-not-found-img"
                                             src={Images.dataNotFound} alt="..."/></td>
                    </tr>
                }
                {
                    !_.isEmpty(tickets) && tickets.map((object, index) => {
                        const isRelease = object.status === 2;
                        if (!isRelease) object.detail = Array(5).fill("?").join(",");
                        return <React.Fragment key={index}>
                            <tr className="bg-tr-his">
                                <td>
                                    <div className="d-flex justify-content-between p-1 pl-2 pr-2">
                                        <div
                                            className="mr-3 f-bold">{object.drawLabel}</div>
                                        <div className="d-flex pl-5">

                                            {
                                                object?.detail?.split(",").map((row, j) => {
                                                    return <span
                                                        className="font-weight-bold result-number-cycle"
                                                        key={j}>{row}</span>
                                                })}
                                            <span
                                                className="ml-3 f-result font-weight-bold">{isRelease ? object.result : "??"}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {
                                object?.items.map((obj, i) => {
                                    obj.drawCode = object.drawCode;
                                    obj.drawAt = object.drawAt;
                                    obj.drawStatus = object.status;
                                    obj.drawLabel = object.drawLabel;
                                    return <tr key={i} className="animate-slide-up">
                                        <td>
                                            <div className="card m-2 rounded p-2">
                                                <div
                                                    className="d-flex justify-content-between">
                                                    <div className="f-bold btn p-0"
                                                         onClick={() => onShowDetail(obj)}>#{obj.ticketNumber}</div>
                                                    <div>{getDateTime(obj?.createdAt)}</div>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-between align-items-center border-bottom">
                                                    <div className="d-flex">
                                                        {
                                                            obj?.groupByPosts?.map((p, g) => {
                                                                if (_.isEmpty(p.postGroup)) {
                                                                    return <Badge
                                                                        className="mr-2 bg-orange f-15"
                                                                        key={g}>
                                                                        {t("labels:drawing.lotto90.special_x")}
                                                                    </Badge>

                                                                }
                                                                return <Badge
                                                                    className="mr-2 bg-orange f-15"
                                                                    key={g}>
                                                                    {p.postGroup}
                                                                </Badge>
                                                            })
                                                        }
                                                    </div>
                                                    <div>
                                                        {t("buttons:print")} <Printer
                                                        object={obj}
                                                        drawLabel={object.drawLabel} {...this.props}/>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="text-left w-50">
                                                        {
                                                            obj?.groupByPosts.map(g => {
                                                                return g.items?.map((p, n) => {
                                                                    if (!_.isEmpty(g.postGroup)) {
                                                                        const numberDetail = p.numberDetail?.split(",");
                                                                        return numberDetail.map((o, y) => {
                                                                            return <div key={y}
                                                                                        className="f-16 font-weight-bold result-history"
                                                                            >{o}</div>
                                                                        })
                                                                    } else {
                                                                        return <Badge
                                                                            key={n}
                                                                            className="mr-2 num-color f-16">
                                                                            {p.numberFrom.split("_").map((n, i) => {
                                                                                return <span key={i}
                                                                                             className="text-white">{t("labels:drawing.lotto90.label.".concat(n))}</span>
                                                                            })}
                                                                        </Badge>
                                                                    }

                                                                })
                                                            })
                                                        }
                                                    </div>
                                                    <div className="w-25 text-left">
                                                        {
                                                            obj.resultItems.map((r, i) => {
                                                                if (r.type !== "SPECIAL") {
                                                                    return <div key={i}><Badge
                                                                        className="font-weight-bold bg-orange mr-1 f-14">{t("labels:drawing.lotto90.special_x")} > {!isRelease ? "??" : t("labels:drawing.lotto90.label.".concat(r.number))}</Badge>
                                                                    </div>
                                                                }
                                                                return <div key={i}><Badge
                                                                    className="font-weight-bold bg-orange mr-1 f-14">{r.postCode} > {isRelease ? r.number : "??"}</Badge>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <span
                                                        className="w-25 text-right f-bold">{!isRelease ? "??" : moneyFormatter(obj.winAmount, currencyCode())}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </React.Fragment>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}

export default OldRecentTicketRow;