import DatetimeContainer from "../../date/DatetimeContainer";
import React from "react";
import ResultContentList from "../ResultContentList";
import CountDownTimer from "../../timer/CountDownTimer";
import {getShortBetType} from "../../../utils/tickets/printUtils";
import StatisticTableMix from "../mix/StatisticTableMix";
import {env} from "../../../env";
import {getOECode, isOE, jackpotNum} from "../../../constants/general";
import TabView from "../../lotto/TabView";
import RecentOrdering from "../RecentOrdering";
import RecentBetting from "../RecentBetting";
import LoadingGif from "../../../utils/LoadingGif";


export default (props) => {
    const {
        t, oldResult, toggleMute, isMute, config: {
            lotto6Config
        },
        lastResult: {
            result,
            underOverCode,
            oddEvenCode,
            number,
            drawLabel
        }, winRange, winUnderOver, winOddEven, jackpot, winRangeLine, winRangeLineOne
    } = props;
    let platformType = env.PLATFORM_TYPE;
    const jp = oldResult?.jackpot;

    const tabs = [
        t("labels:drawing.betting"),
        t("labels:drawing.result")
    ];

    const children = [
        <>
            <RecentOrdering {...props}/>
            <RecentBetting {...props}/>
        </>,
        <StatisticTableMix/>
    ]


    return <div>
        <div className="row no-margin-div">
            <div className="col-12 no-padding-div header-lotto6 header-div-title-6-mix">
                <div className="head-flex-lo6-mix-label">{t("labels:menus.lotto")} 6</div>
                <div className="text-white head-flex-lo6-mix-label">
                    <LoadingGif className="w-load-lo6-mix"/>
                     {props.newResult !== null ? props.newResult.drawLabel : t("labels:upcoming_draw")}
                </div>
                <div className="text-white head-flex-lo6-mix-label"
                     id="timer-display">
                    <DatetimeContainer/>
                    <img
                        onClick={toggleMute}
                        alt="audio"
                        className="pr-2 w-load-lo6-mix"
                        style={{cursor: "pointer"}}
                        src={isMute === "ON" ? require("../../../assets/images/audio/mute_blue.svg") : require("../../../assets/images/audio/volume_blue.svg")}/>
                </div>

            </div>
        </div>
        <div className="row no-padding-div no-margin-div">
            <div className="col-8 no-padding-div">
                <div
                    className="col div-body-white no-padding-div-left no-padding-div-right lotto6-content-result lotto6-v2-result fade-in">
                    <table
                        id="keno_result_post"
                        border="1"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                    >
                        <tbody>
                        <ResultContentList allNumbers={props.allNumbers}/>
                        </tbody>
                    </table>
                </div>


            </div>
            <div className="col-4 div-body-white no-padding-div">

                <div className="row m-0 lotto6-v2-card-count-down-logo bg-count-down card-border"
                     style={{height: "50%"}}>
                    <CountDownTimer countClass="fade-in total-result-render " {...props}/>
                </div>

                <div className="d-flex justify-content-center align-items-center card-border position-relative"
                     style={{height: "49%"}}>
                    <div className="row">
                        {
                            props.indexResultItem > 6 || props.indexResultItem === 0
                                ? <div
                                    className="result-time-countup-render-lo6-mix bg-transparent fade-in"/>
                                : <div
                                    className="result-time-countup-render-lo6-mix fade-in">{props.indexResultItem}</div>
                        }
                        <div className={"fade-in " + props.classNameNumberDraw}>{props.resultItem}</div>
                        <div className={"fade-in " + props.classNameLogoRender}>

                            <img src={require(`../../../assets/images/logo/${platformType}.png`)}
                                 alt="..."/>
                        </div>

                    </div>
                </div>

            </div>

            <div className="col-12">
                <div className="div-body-white row fade-in border-r">

                    {
                        lotto6Config.slots.map((item, i) => {
                                const isActive = jackpot === jackpotNum[item.code];
                                const isWin = item.code === winRange;
                                const showJackpot = isWin && isActive;
                                let classActive = isActive && oldResult ? " bg-win " : "";
                                const winClass = isWin ? " win-" + item.code : "";
                                let winCl = isWin ?
                                    isActive ? " win-bg-animate" : winClass
                                    : "";
                                const subItem = item.sub;


                                const RenderSlot = ({onClick, item}) => {
                                    let OUWinClass = item.code === winUnderOver ? ` OU-${item.code}` : "";
                                    let OEWinClass = item.code === winOddEven ? ` OE-${item.code}` : "";

                                    return <div
                                        className=" no-padding-div lotto6-box-bet lotto6-v2-bet-pricing-card pointer"
                                        onClick={onClick}>

                                        <div
                                            className={"lotto6-v2-slot-prefix-card lotto6-v2-card-bg-color-" + item.code + classActive + winCl + OUWinClass + OEWinClass}>
                                            <h1 className={`lotto6-v2-card-type-${item.code} lotto6-v2-card-bg-color-` + item.code + winClass + OUWinClass + OEWinClass}>{item.type === "underOver" ? t("labels:betPrefix.S_" + getShortBetType(item.code)) : t("labels:betPrefix." + item.code)}</h1>
                                        </div>


                                        <div className="lotto6-v2-slot-value-card position-relative">
                                            {showJackpot && < >
                                                <img className="lotto6-jackpot-v2-img position-absolute"
                                                     src={require(`../../../assets/images/jackpot/v2/${jp}.png`)}
                                                     alt="__"/>
                                                <img
                                                    className="lotto6-jackpot-v2-img position-absolute win-animate-border"
                                                    src={require('../../../assets/images/jackpot/v2/border-jackpot.png')}
                                                    alt="___"/>
                                            </>}
                                            <div
                                                className="d-flex justify-content-center align-items-center h-100">
                                                <div>
                                                    <div
                                                        className="lotto6-v2-range-number">{isOE(item.code) ? getOECode(item.code) : item.rangeNumber}</div>
                                                    <div
                                                        className={`lotto6-v2-bet-value`}>{item.value}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                return <div key={i}
                                            className="col-3 no-padding-div lotto6-box-bet lotto6-v2-bet-pricing-card">
                                    <RenderSlot onClick={() => props.onBetting(item)} item={item}/>
                                    {subItem &&
                                        <RenderSlot onClick={() => props.onBetting(subItem)} item={subItem}/>}
                                </div>


                            }
                        )

                    }
                    {lotto6Config.rangeLine.map((item, i) => {
                        return <div
                            className={"col-4 no-padding-div lotto6-box-bet lotto6-v2-bet-pricing-card pointer"}
                            key={i}
                            onClick={() => props.onBetting(item)}>


                            <div
                                className={"lotto6-v2-slot-prefix-card lotto6-v2-card-bg-color-" + item.code + " win-" + (item.code === winRangeLine ? item.code : "") + " win36-" + (item.code === winRangeLineOne ? item.code : "")}>
                                <h1>{t("labels:betPrefix." + item.code)}</h1>
                            </div>

                            <div className="lotto6-v2-slot-value-card">

                                <p className="lotto6-mix-range-number">{item.rangeNumber}</p>
                                <p className="lotto6-mix-bet-value">{item.value}</p>
                            </div>
                        </div>
                    })}


                </div>

            </div>

        </div>
        <div className="col-12 head-lo6-mix fade-in border-t border-b pl-3 pr-3">
            <div
                className="text-white last-result-betting-lo6-mix">{drawLabel}</div>
            {
                underOverCode ?
                    <div
                        className="text-white last-result-betting-lo6-mix fade-in">{t("labels:betPrefix." + underOverCode) + "  /   " + t("labels:betPrefix." + oddEvenCode) + "  /   " + t("labels:betPrefix." + number) + "   /   " + result}</div>
                    : <div className="text-white last-result-betting-lo6-mix fade-in"/>
            }

        </div>
        <div className="tab-view border-all">
            <TabView children={children} tabs={tabs}/>
        </div>
    </div>


}
