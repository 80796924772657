import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import _ from "lodash";
import Lotto5DNav from "../../../components/lo5d/Lotto5DNav";
import Images from "../../../constants/lotto5Images";
import MainTable from "../../../components/lo5d/result/MainTable";
import {fetchResults} from "../../../store/lotto5d/action";
import DatePicker from "react-datepicker";
import moment from "moment";
import {currentDate, getDateDef} from "../../../utils/dateUtils";


const ResultPage = ({fetchResults, ...props}) => {
    const {results: {data}, t} = props;
    const [date, setDate] = useState(currentDate());

    useEffect(() => {
        fetchResults({filterDate: getDateDef(date)})
    }, [fetchResults, date])


    const onFilter = (params = {}) => {
        fetchResults({filterDate: getDateDef(date), ...params})
    }

    return <div className="main-w">
        <div className="main">
            <div className="w-1200">
                <div className="wrapper-new-lotto5 lotto5">
                    <Lotto5DNav/>
                    <div className="row align-items-center ml-3 mt-2 pb-0">
                        <div className="form-date-range">
                            <label>{t("labels:drawing.date")}:</label>
                        </div>
                        <div className="pl-2">
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                selected={date}
                                maxDate={moment().toDate()}
                                onChange={e => setDate(e)}
                                className="form-control bg-white"/>
                        </div>
                        <div className="wrap-btn-filter">
                            <button className="btn-game"
                                    onClick={() => onFilter()}>{t("labels:common.getResult")}</button>
                        </div>
                    </div>

                    {
                        _.isEmpty(data) ? <img className="data-not-found-img"
                                               src={Images.dataNotFound} alt="..."/>
                            : <MainTable fetchResults={fetchResults} {...props}/>
                    }
                </div>
            </div>
        </div>
    </div>
};


const mapStateToProps = state => ({
    results: state.lotto5d.result,
});

export default connect(mapStateToProps,
    {
        fetchResults
    }
)(withTranslation()(ResultPage));
