import React, {useEffect, useRef, useState} from "react";
import bg99select1 from "../../assets/images/lotto99/bg99select1.png";
import DrawingResult from "./DrawingResult";
import {imgUrl} from "../../utils/asset";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {inIframe} from "../../constants/general";

const LeftSide = ({list, status, oldResult}) => {

    const [isFull, setFull] = useState(false);
    const isInIframe = inIframe();
    const fullscreenElementRef = useRef(null);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                setFull(false);
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('keydown', handleKeyPress);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const toggleFull = () => {
        const element = fullscreenElementRef.current;
        if (element && !document.fullscreenElement) {
            element.requestFullscreen().then(() => {
                setFull(true)
            });
        } else {
            document.exitFullscreen().then(() => {
                setFull(false)
            });
        }
    };

    return <div className="lotto99">
        <div
            id="fullscreen-element"
            ref={fullscreenElementRef}
            style={{
                backgroundImage: `url(${bg99select1})`,
            }} className={`${isFull ? "d-board-Fullscreen" : "d-board"}`}>
            {!isInIframe && <img
                onClick={toggleFull}
                className="l199-fullscreen pointer"
                src={imgUrl(`lotto12v2/${isFull ? 'exit_fullscreen' : 'fullscreen'}.png`)}
                alt=""/>}
            <DrawingResult list={list} status={status} oldResult={oldResult} isFull={isFull}/>
        </div>
    </div>
}

const mapStateToProps = state => ({
    list: state.lotto99.recentDraw.list,
    drawing: state.lotto99.drawing
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(LeftSide));
