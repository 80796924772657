import React, {useState} from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input, InputGroup, InputGroupAddon,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row
} from "reactstrap";
import {withTranslation} from "react-i18next";

const Paginated = props => {
    const {
        size,
        total,
        searchable,
        customFilter,
        children,
        onChange,
        onSearchChange,
        onSearch,
        onSizeChange,
        t
    } = props;
    const [search, setSearch] = useState("");
    const page = props.page;
    const totalPage = Math.ceil(total / size);
    const from = total === 0 ? 0 : ((page - 1) * size + 1);
    const to = page * size > total ? total : page * size;
    const pages = () => {
        const list = [];
        if (page > 3)
            list.push(
                <PaginationItem key={1} disabled>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            );
        for (let i = 1; i <= totalPage; i++) {
            if (i === page ||
                i - 1 === page ||
                i - 2 === page ||
                i + 1 === page ||
                i + 2 === page)
                list.push(
                    <PaginationItem key={i} active={i === page}>
                        <PaginationLink onClick={() => onChange(i)}>{i}</PaginationLink>
                    </PaginationItem>
                );
        }
        if (page < totalPage - 2)
            list.push(
                <PaginationItem key={totalPage} disabled>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            );
        return list;
    };

    return (
        <React.Fragment>
            {((!customFilter && searchable) || customFilter) && <Row className="p-3 justify-content-between">
                {!customFilter && searchable && <Col sm={12}>
                    <Form inline
                          className="float-left"
                          onSubmit={e => {
                              e.preventDefault();
                              onSearch(search);
                          }}>
                        <FormGroup>
                            <InputGroup>
                                <Input
                                    placeholder={t("labels:search")}
                                    id="search"
                                    onChange={({target: {value}}) => {
                                        setSearch(value);
                                        onSearchChange(value);
                                    }}
                                    type="text"/>
                                <InputGroupAddon addonType="append">
                                    <Button color="primary">{t("labels:search")}</Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </Col>}
                {customFilter && <Col sm={12}>{customFilter}</Col>}
            </Row>}
            {children}
            <Row className="align-items-center p-3">
                <Col sm={12} md={4}>
                    <Form inline className="float-left">
                        <FormGroup>
                            <Label for="inputLimit">{t("labels:show")}</Label>
                            <Input
                                onChange={e => onSizeChange(e.target.value)}
                                className="ml-2 mr-2"
                                value={size}
                                type="select"
                                id="inputLimit">
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Input>
                            <Label for="inputLimit">{t("labels:entries", {count: size})}</Label>
                        </FormGroup>
                    </Form>
                </Col>
                <Col sm={12} md={8}>
                    {(total > 0 && totalPage > 1) && <Pagination listClassName="justify-content-end">
                        <PaginationItem disabled={page === 1}>
                            <PaginationLink first onClick={() => onChange(1)}/>
                        </PaginationItem>
                        <PaginationItem disabled={page === 1}>
                            <PaginationLink previous onClick={() => onChange(page - 1)}/>
                        </PaginationItem>
                        {pages()}
                        <PaginationItem disabled={totalPage === page}>
                            <PaginationLink next onClick={() => onChange(page + 1)}/>
                        </PaginationItem>
                        <PaginationItem disabled={totalPage === page}>
                            <PaginationLink last onClick={() => onChange(totalPage)}/>
                        </PaginationItem>
                    </Pagination>}
                    <div className={"text-right" + ((total > 0 && totalPage > 1) ? "" : " pt-3")}>
                        {total > 0 ? <span>{t("labels:showing_entries", {from, to, total})}</span> :
                            <span>{t("labels:show_entry", {total})}</span>}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

Paginated.propTypes = {
    page: PropTypes.number.isRequired,
    size: PropTypes.any,
    total: PropTypes.number.isRequired,
    searchable: PropTypes.bool,
    customFilter: PropTypes.any,
    onSearch: function (props, propName, componentName) {
        if (props["searchable"]) {
            if (!props[propName]) {
                return new Error(
                    'Invalid prop `' + propName + '` supplied to' +
                    ' `' + componentName + '`. Validation failed.'
                );
            }
        }
    },
    onSizeChange: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
};

Paginated.defaultProps = {
    size: 10,
    searchable: false,
    onSearchChange: (text) => {
    },
    onSizeChange: (text) => {
    },
    onSearch: (text) => {
    }
};

export default withTranslation()(Paginated);