import {combineReducers} from "redux";
import drawing from "./drawing";
import statistic from "./statistic";
import recentDraw from "./recentDraw";
import winReport from "./winReport";
import resultReport from "./resultReport";
import tickets from "./tickets";


const lo99CombineReducer = combineReducers({
    drawing,
    statistic,
    recentDraw,
    winReport,
    resultReport,
    tickets
});
export default lo99CombineReducer;
