import {ADD_BET, CLEAR_BET} from "./types";

const init = {
    listBetting: [],
    rangeBetting: [],
    postBetting: []
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case ADD_BET:
            return {
                ...state,
                ...payload
            };
        case CLEAR_BET:
            return {
                listBetting: [],
                rangeBetting: [],
                postBetting: []
            };
        default:
            return state;
    }
}