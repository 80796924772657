import UnderOverResultHistory from "./UnderOverResultHistory";
import OddEvenColorResultHistory from "./OddEvenColorResultHistory";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

const TableStatistic = (props) => {
    const {t} = props;
    return <div className="row">
        <div className="col-6 no-padding-div">
            <div className="col no-padding-div">
                <div
                    className="col header-div-title header-history-light card-border">

                    <span className="">{t("labels:drawing.numberUnderOver")}</span>
                </div>
                <div
                    className="col no-padding-div div-bg-gray lotto6-history-result lotto12-history-result fade-in">

                    <div className="bet-grid-container">

                        <table
                            id="keno_result_post"
                            border="1"
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%"
                            className="fade-in"
                        >
                            <tbody>
                            <UnderOverResultHistory {...props}/>
                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>


        <div className="col-6 no-padding-div">

            <div
                className="col header-div-title header-history-light card-border">
                <span className="">{t("labels:drawing.oddEvenColor")}</span>
            </div>
            <div
                className="col no-padding-div-left div-bg-gray lotto6-history-result lotto12-history-result fade-in">


                <div className="bet-grid-container">

                    <table
                        id="keno_result_post"
                        border="1"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"

                    >
                        <tbody>
                        <OddEvenColorResultHistory {...props}/>
                        </tbody>

                    </table>

                </div>

            </div>

        </div>

    </div>
}
const mapStateToProps = state => ({
    ...state.lotto12.statistic
})

export default connect(mapStateToProps)(withTranslation()(TableStatistic));
