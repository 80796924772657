import {gql} from "@apollo/client";

const posts = ["A", "B", "C", "D", "E"];


const ballNumbers = Array(90).fill(0).map((e, i) => i + 1);
const SUBSCRIPTION_LOTTO90 = gql`
        subscription Lotto90Sub {
            v_lotto90_drawing {
                 drawCode
                 status
            }
           }`;

export {posts, ballNumbers, SUBSCRIPTION_LOTTO90}
