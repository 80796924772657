import {moneyFormatter} from "../../../utils/MoneyUtils";
import {UncontrolledTooltip} from "reactstrap";
import React from "react";
import _ from "lodash";

const TicketPrintBody = (props) => {
    const {item, t, is58} = props;
    const returnMargin = (length) => {
        switch (length) {
            case 1:
                return "ml-1";
            case 2:
                return "ml-2";
            case 3:
                return "ml-3";
            case 4:
                return "ml-3";
            case 5:
                return "ml-4";
            case 6:
                return "ml-4";
            case 7:
                return "ml-5";
            case 8:
                return "ml-5";
            case 9:
                return "ml-5";
            default:
                return "";
        }

    };

    const vertLine = (obj) => {
        let isDigitOne = false;
        let isDigitTwo = false;
        let isDigitThree = false;
        let isDigitFour = false;
        let titles = obj?.betTitle?.split("_");
        titles && titles.forEach((e) => {
            switch (true) {
                case e === "J1":
                    isDigitOne = true;
                    break;
                case e === "J2":
                    isDigitTwo = true;
                    break;
                case e === "J3":
                    isDigitThree = true;
                    break;
                case e === "J4":
                    isDigitFour = true;
                    break;
                default:
                    break;
            }
        });
        return <div className={`d-inline-flex ${is58 ? "ml-hr-ticket" : "ml-sim"}`}>
            <div className={`${is58 ? "" : "p-hr-show"} hr-vert ${isDigitOne ? "" : "border-0"}`}/>
            <div className={`${is58 ? "" : "p-hr-show"} hr-vert ${isDigitTwo ? "" : "border-0"}`}/>
            {obj.numberFrom.length > 2 &&
            <div className={`${is58 ? "" : "p-hr-show"} hr-vert ${isDigitThree ? "" : "border-0"}`}/>}
            {obj.numberFrom.length > 3 &&
            <div className={`${is58 ? "" : "p-hr-show"} hr-vert ${isDigitFour ? "" : "border-0"}`}/>}
            {obj.multiDigit != null && <div
                className={`hr-right ${obj.numberFrom.length === obj.multiDigit ? (is58 ? "ml-hr-ticket" : "ml-sim") : 'ml-3'}`}/>}
        </div>
    };

    const getPairLabel = (length) => {
        if (length === 2) return "NOT_PAIR";
        if (length === 3) return "NOT_TRIPLE";
        if (length === 4) return "NOT_SQUARE";
    };

    const renderNormal = (obj, i) => {
        let num = _.isNaN(parseInt(obj.numberFrom)) ? t("labels:drawing.lotto90.label." + obj.numberFrom) : obj.numberFrom;
        let isEmPost = _.isEmpty(item.postGroup);
        return <div style={{lineHeight: "28px"}} key={i}
                    className={`row p-0 m-0 align-items-center ${obj.winQty > 0 ? " bg-win" : ""}`}>
            <div className="row p-0 m-0 align-content-center">
                <div className={`f-23 ${isEmPost ? "f-bayon" : "font-weight-bold"}`}>{num}</div>
                &nbsp;:&nbsp;
                <div>{moneyFormatter(obj.betAmount, obj.currencyCode, `f-23 text-black ${isEmPost ? "f-bayon" : "font-weight-bold"}`)}</div>
            </div>
            {obj?.winQty > 0 && (
                <div className="win-animate p-0 m-0 text-center ml-1 mb-1">
                    <div className="f-18 font-weight-bold">{obj?.winQty}</div>
                </div>
            )}
        </div>
    };

    const renderRange = (obj, i) => {
        return <div key={i} className="row p-0 m-0">
            <div>
                <div
                    className="f-23 f-bayon">{`${obj.numberFrom} ${obj.multiDigit == null ? "" : obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}`}</div>
                <div className="row justify-content-between m-0 ">
                    <div className={`f-18 hr-vert ${returnMargin(obj.numberFrom.length)}`}/>
                    {obj.multiDigit != null && <div
                        className={`hr-right f-23 f-bayon ${obj.numberFrom.length === obj.multiDigit ? 'mr-1' : 'mr-3'}`}/>}
                </div>
                <div
                    className='mt-1 f-23 f-bayon'>{`${obj.numberTo} ${obj.multiDigit == null ? "" : obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}`}</div>
            </div>
            <div>
                <div> &nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode, "f-23 f-bayon text-black")}</div>
                <div style={is58 ? {lineHeight: "25px"} : {lineHeight: "23px"}} className="row p-0 m-0">
                    {obj?.winQty > 0 && (
                        <div className="win-animate p-0 m-0 text-center ml-1 f-15">
                            {obj?.winQty}
                        </div>
                    )}
                    <div
                        className="black-c f-18 font-weight-bold">&nbsp;{`(${obj.numberQuantity}${t('labels:numbers')})`}{obj.pairStatus === 2 && (
                        <span className="text-danger">&nbsp;
                            {t(`messages:sale.bet_title.`.concat(getPairLabel(obj.numberFrom?.length)))}
                                    </span>
                    )}</div>
                </div>
                <div> &nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode, 'f-23 f-bayon text-black')}</div>
            </div>
        </div>;
    };

    const renderMultiple = (obj, i) => {
        return <div style={{lineHeight: "28px"}} key={i} className="row p-0 m-0">
            <div
                className={`w-left-side align-items-center row p-0 m-0 w-100 f-bayon ${obj.winQty > 0 ? " bg-win" : ""}`}>
                <div className="f-23 f-bayon">{obj.numberFrom}</div>
                &nbsp;
                <button className={`text-black-50 border-0 p-0 m-0 ${
                    obj.winQty > 0 ? " bg-win" : " bg-white"
                }`}>
                    <div id={`multiply${i}`}
                         className="f-23 f-bayon">{obj.numberFrom.length === obj.multiDigit ? 'X' : `(X${obj.multiDigit}D)`}</div>
                    <UncontrolledTooltip placement="bottom" target={`multiply${i}`}>
                        {`លោកអ្នកចាក់ គុណ${obj.numberFrom.length === obj.multiDigit ? "" : `${obj.multiDigit}D`} = ${obj.numberQuantity}លេខ`}
                    </UncontrolledTooltip>
                </button>
                &nbsp;:&nbsp;
                <div>{moneyFormatter(obj.betAmount, obj.currencyCode, 'f-23 f-bayon text-black')}</div>
                {obj?.winQty > 0 && (
                    <div className="win-animate p-0 m-0 text-center ml-1 f-15">
                        {obj?.winQty}
                    </div>
                )}
            </div>

        </div>;
    };


    const renderSpeRange = (obj, i) => {
        return <div key={i} className="row p-0 m-0">
            <div>
                <div key={i} className="row p-0 m-0">
                    <div>
                        <div
                            className="f-23 f-bayon">{`${obj.numberFrom} ${obj.multiDigit == null ? "" : obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}`}</div>
                        <div className="row justify-content-between m-0 ">
                            {vertLine(obj)}
                        </div>
                        <div
                            className="f-23 f-bayon mt-1">{`${obj.numberTo} ${obj.multiDigit == null ? "" : obj.numberFrom.length === obj.multiDigit ? 'x' : `(x${obj.multiDigit}D)`}`}</div>
                    </div>
                    <div>
                        <div>&nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode, "f-23 f-bayon text-black")}</div>
                        <div style={is58 ? {lineHeight: "25px"} : {lineHeight: "23px"}} className="row p-0 m-0 p-0">
                            {obj?.winQty > 0 && (
                                <div className="win-animate p-0 m-0 text-center ml-1 f-15">
                                    {obj?.winQty}
                                </div>
                            )}
                            <div
                                className="black-c f-18 font-weight-bold">&nbsp;{`(${obj.numberQuantity}${t('labels:numbers')})`}{obj.pairStatus === 2 && (
                                <span className="text-danger">&nbsp;
                                    {t(`messages:sale.bet_title.`.concat(getPairLabel(obj.numberFrom?.length)))}
                                    </span>
                            )}</div>
                        </div>
                        <div>&nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode, "f-23 f-bayon text-black")}</div>
                    </div>
                </div>
                <div style={{lineHeight: "18px"}} className="m-0 row mb-2">
                    {obj.betTitle &&
                    <div
                        className="font-weight-bold text-black f-18">({t(`messages:sale.bet_title.${obj.betTitle}`)})</div>}
                </div>
            </div>
        </div>;
    };


    return item.items.map((obj, i) => {
        if (obj.betTitle === "MULTIPLE") return renderMultiple(obj, i);
        obj.posts = item.posts;
        switch (obj.betType) {
            case "NORMAL":
                return renderNormal(obj, i);
            case "RANGE":
                return renderRange(obj, i);
            case "MULTIPLE":
                return renderMultiple(obj, i);
            case "SPECIAL_RANGE":
                return renderSpeRange(obj, i);
            default :
                return null;
        }
    });
};

export default TicketPrintBody;
