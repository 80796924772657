import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    setBetStatusLotto5,
    setRenderResultStatus,
    setResultTotalNumberKeno,
    fetchLotto5RecentDraw,
    fetchLotto5DrawingResult,
    fetchLotto5RecentBetReport,
    playAudio,
    setIsAutoPrint,
    fetchStatistic5,
    fetchListLotto5Betting,
    addLotto5Betting,
    deleteLotto5Betting,
    lotto5ConfirmBettingNow,
    fetchUserBalance,
    fetchUserBalanceValidate,
    clearCartLotto5,
    setIsAddToCart, fetchLotto5RecentBetReportCache
} from "../../store/rootAction";
import {clientAp, jackpotNum} from "../../constants/general";
import _ from "lodash";
import {env} from "../../env"
import betDownAudio from "../../assets/audios/betPlace.mp3";
import stopDrawAudio from "../../assets/audios/drawStop.mp3";
import countdownAudio from "../../assets/audios/countdown.mp3";
import goodLuckAudio from "../../assets/audios/goodluck.mp3";
import {SUBSCRIPTION_LOTTO5} from "../../constants/lotto5";
import DashboardMix from "../../components/lotto5/mix/DashboardMix";
import {getResult} from "../../utils/general";
import {getMute5, setLotto5, toggleMute5} from "../../utils/cached";
import winSound from "../../assets/audios/lotto5_win.mp3";
import BettingDialog from "../../components/lotto5/BettingDialog";
import swal from "sweetalert";
import {getDateHHmmss} from "../../utils/dateUtils";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";

class Lotto5DashboardMix extends Component {

    constructor(props) {
        super(props)
        this.state = {
            resultItem: null,
            resultItemRandom: null,
            indexResultItem: 0,
            totalResult: null,
            allNumbersDrawing: [],
            timeCountLotto5: null,
            classNameNumberDraw: "hide-number-draw",
            classNameLogoRender: "hide-logo-render",
            classCountDown: "total-result-render-count",
            winUO: "",
            winRange: "",
            isShowRecentBet: false,
            recentBetDetail: null,
            imageRotate: "",
            imageBalanceRotate: "",
            imageResultRotate: "",
            isStoppedJackpot: true,
            showJackpotValue: false,
            jackpot: 0,
            counter: 1,
            bettingId: null,
            showBetting: false,
            betCode: "",
            betAmount: 0,
            betGameType: "",
            betGameOdds: "",
            betGameRange: "",
            betGameTime: "",
            betMessage: "",
            isShowValidateBalance: false,
            messageValidateBalance: null,
            status: null,
            isEnableBetNow: true,
            isMute: "ON",

        };
        this.onDrawStatusChange = this.onDrawStatusChange.bind(this);

    }

    playAudio = (src) => {
        const {audio} = this.props;
        if (audio && this.state.isMute === "OFF") playAudio(src);
    };


    onCloseValidateBalance() {
        this.setState({
            isShowValidateBalance: false,
            messageValidateBalance: null
        });
    }


    setWinLose(indexResultItem) {
        const {oldResult} = this.props;
        let jp = _.toUpper(oldResult?.rangeCode) === oldResult.jackpotType ? jackpotNum[oldResult.jackpotType] : 0;
        this.jackpotInv && clearInterval(this.jackpotInv);
        jp > 0 && this.playAudio(winSound);

        let winUO = _.toUpper(oldResult?.underOverCode);
        let winRange = _.toUpper(oldResult?.rangeCode);
        setLotto5({
            winUO: winUO,
            winRange: winRange,
        });
        this.setState({
            winUO: winUO,
            winRange: winRange,
            resultItem: null,
            indexResultItem: indexResultItem,
            totalResult: null,
            jackpot: jp,
            classNameNumberDraw: "hide-number-draw",
            classNameLogoRender: "show-logo-render-" + env.PLATFORM_FOR,
            classCountDown: "total-result-render-count"
        })
    }

    refreshData = () => {
        this.preSetNewGame();
        this.props.fetchStatistic5({row: 6, column: 7});
        this.props.fetchLotto5RecentBetReportCache();
        this.props.fetchListLotto5Betting({isClear: true});
        this.props.fetchUserBalance();
        this.getRecentDraw();
        this.setState({showJackpotValue: false});
    }

    preSetNewGame() {
        if (this.drawingTime) {
            clearInterval(this.drawingTime);
        }
        this.setState({
            winUO: "",
            winRange: "",
            winOE: "",
            resultItem: null,
            indexResultItem: 0,
            totalResult: null,
            classNameNumberDraw: "hide-number-draw",
            classNameLogoRender: "show-logo-render-" + env.PLATFORM_FOR,
            classCountDown: "total-result-render-count"
        });
        this.props.setRenderResultStatus({isStopRender: true});
        this.setNewGame();
    }

    setNewGame() {
        this.props.fetchLotto5DrawingResult()
            .then(() => {
                if (this.props.countDownTimer > 0) {
                    this.setState({
                        timeCountLotto5: this.props.countDownTimer,
                        classNameNumberDraw: "hide-number-draw",
                        classNameLogoRender: "show-logo-render-" + env.PLATFORM_FOR,
                        classCountDown: "total-result-render-count"
                    })
                    this.countingSecond(this.props.countDownTimer)
                }
            })
    }

    checkDrawingResult() {
        this.props.fetchLotto5DrawingResult()
            .then(() => {
                const {countDownTimer} = this.props;
                this.setBettingStatusByTimer(countDownTimer)
                if (countDownTimer > 0) {
                    this.setState({
                        // timeCountLotto5: countDownTimer,
                        classNameNumberDraw: "hide-number-draw",
                        classNameLogoRender: "show-logo-render-" + env.PLATFORM_FOR,
                        classCountDown: "total-result-render-count"
                    })
                }
                if (countDownTimer > 10) {
                    this.playAudio(betDownAudio)
                } else if (countDownTimer <= 0) {
                    this.setState({
                        classNameNumberDraw: "show-number-draw",
                        classNameLogoRender: "hide-logo-render",
                        classCountDown: "total-result-render-count-total"
                    })
                    this.props.setRenderResultStatus({isStopRender: false});
                    return this.getReleaseNum();
                }
            }).catch(() => {
            this.setState({allNumbersDrawing: []})
        })
    }

    toggleMute = () => {
        this.setState((preState) => ({
            isMute: preState.isMute === "OFF" ? "ON" : "OFF"
        }), () => toggleMute5(this.state.isMute));
    }

    subscription = () => {
        if (socketLottoConfig.l5) {
            socket.on(socketEvent.l5, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO5,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto5_drawing) {
                        let currentDraw = event.data.v_lotto5_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    componentDidUpdate() {
        if (this.props.isStopBet && this.state.showBetting) {
            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betGameRange: "",
                betGameTime: ""
            })
        }
    }


    componentDidMount() {
        this.subscription();
        this.checkDrawingResult();
        this.props.fetchStatistic5({row: 6, column: 7});
        this.props.fetchLotto5RecentBetReport();
        this.props.fetchListLotto5Betting({isClear: true});
        this.props.fetchUserBalance();
        this.getRecentDraw();
        this.listenLocalStorage();
    }

    getRecentDraw = () => {
        this.props.fetchLotto5RecentDraw()
            .then(() => {
                if (this.props.isStopRender && this.props.lastResult) {
                    const details = this.props.lastResult.details
                    this.setState({allNumbersDrawing: details})
                }
            });
    }

    listenLocalStorage = () => {
        window.onstorage = () => {
            let mute = getMute5();
            if (mute !== this.state.isMute) {
                this.setState({isMute: mute});
            }
        }
    }


    componentWillUnmount() {
        clearTimeout(this.drawingTime);
        removeSocketListener(socketEvent.l5);
    }

    setBettingStatusByTimer(timerSecond) {
        if (timerSecond <= 9 && timerSecond > 0) {
            this.setState({classCountDown: "total-result-render-count-warning"})
            this.props.setBetStatus({isStopBet: true})
        }
    }

    countingSecond(count) {
        if (this.props.isStopRender) {
            if (this.props.countDownTimer < 0) {
                this.props.setRenderResultStatus({isStopRender: true})
            } else {
                if (count >= 10) {
                    this.setState({classCountDown: "total-result-render-count"})
                } else if (count < 10 && count > 0) {
                    this.playAudio(countdownAudio);
                    if (count === 9) {
                        this.setBettingStatusByTimer(count);
                    }
                } else if (count === 0) {
                    if (this.drawingTime) {
                        clearInterval(this.drawingTime);
                    }
                    this.playAudio(goodLuckAudio)
                    this.setState({classCountDown: "total-result-render-count-total"})
                    this.props.setRenderResultStatus({isStopRender: false});
                }

            }
        }
    }

    getResultDrawing() {
        this.props.fetchLotto5DrawingResult()
            .then(() => this.getReleaseNum()).catch(() => {
            this.setState({allNumbersDrawing: []});
        })
    }

    getReleaseNum = async () => {
        let {indexNumber, countDownTimer} = this.props;
        if (countDownTimer > 0) countDownTimer = 0;
        let count = Math.abs(countDownTimer);
        if (count <= 3) {
            this.showLoading = setTimeout(() => {
                this.drawResult(0);
                clearTimeout(this.showLoading)
            }, 3000);
            return false;
        } else if (indexNumber <= 20 && indexNumber >= 0) {
            this.drawResult(indexNumber);
        }
    }

    drawResult = (indexNumber) => {
        let {oldResult} = this.props;
        setLotto5({
            showJackpotValue: oldResult.jackpot > 1
        })
        this.setState({allNumbersDrawing: []});
        let {totalResult, allNumbersDrawing} = getResult(indexNumber, this.props.oldResult)

        this.setState({
                allNumbersDrawing: allNumbersDrawing,
                indexResultItem: indexNumber,
                totalResult: totalResult,
                classNameNumberDraw: "show-number-draw",
                classNameLogoRender: "hide-logo-render"
            }, () => this.showResultRender()
        );

    }

    showResultRender = () => {
        this.random && clearInterval(this.random);
        this.jackpotInv && clearInterval(this.jackpotInv);
        let num = 0;
        if (this.props.oldResult?.jackpot > 1) {
            this.jackpotInv = setInterval(() => {
                if (num >= 5) num = 0;
                num += 1;
                this.setState({
                    jackpot: num,
                });
            }, 800);
        } else {
            this.setState({
                jackpot: 0,
            });
        }

        this.setState({
            classNameNumberDraw: "show-number-draw",
            classNameLogoRender: "hide-logo-render"
        })
        let countSound = 1;
        this.drawingTime &&
        clearInterval(this.drawingTime);
        this.drawingTime = setInterval(() => {
                let indexResultItem = this.state.indexResultItem + 1;
                if (indexResultItem === 21) {
                    this.drawingTime && clearInterval(this.drawingTime);
                    this.setWinLose(indexResultItem);
                } else if (indexResultItem === 22) {
                } else {
                    countSound++;
                    if (countSound % 2 === 0) {
                        let resultItem = 1
                        this.random && clearInterval(this.random);
                        this.random = setInterval(() => {
                            if (resultItem === 81) {
                                resultItem = 1
                            }
                            this.setState({resultItem: resultItem++})
                        }, 10);

                        this.startDrawing = setTimeout(() => {
                            this.random && clearInterval(this.random);
                            let {
                                totalResult,
                                allNumbersDrawing,
                                resultItem
                            } = getResult(indexResultItem, this.props.oldResult);
                            this.playAudio(stopDrawAudio);
                            this.setState({
                                timeCountLotto5: -1,
                                allNumbersDrawing: allNumbersDrawing,
                                resultItem: resultItem,
                                indexResultItem: indexResultItem,
                                totalResult: totalResult,
                                classNameNumberDraw: "show-number-draw"
                            }, () => clearTimeout(this.startDrawing))
                        }, 1000);
                    }
                }
            }, 1000
        );
    }


    onShowDetail(item) {
        this.setState({
            isShowRecentBet: true,
            recentBetDetail: item
        });
    }

    onCloseDetail() {
        this.setState({
            isShowRecentBet: false,
            recentBetDetail: null
        });
    }


    onDrawStatusChange(currentDraw) {
        if (currentDraw.status !== this.state.status) {
            if (currentDraw.status === "OPEN" && this.state.status) {
                this.refreshData();
            }
            if (currentDraw.status === "RELEASE") this.getResultDrawing();
            this.setState({status: currentDraw.status});
        }
        this.verifyCurrentDraw(currentDraw.drawCode);
    }

    verifyCurrentDraw = currentDrawCode => {
        let betList = JSON.parse(sessionStorage.getItem("betting_lotto5") || "[]");
        if (betList.length) {
            let firstItem = betList[0];
            if (firstItem.drawCode !== currentDrawCode) {
                this.props.clearCart();
            }
        }

    }


    clickAmountBetBtn = (amount) => {
        let betAmount = this.state.betAmount || 0;

        this.setState({
            betAmount: parseInt(betAmount.toString()) + amount
        })
    }

    resetDefaultAmount = () => {
        this.setState({betAmount: 0})
    }

    changeBetAmount = (e) => {
        const value = e.target.value;
        if (value >= 0 || !isNaN(value) || value === undefined) {
            this.setState({betAmount: value})
        }
    }

    isValidBetAmount = (betAmount = 0) => {
        betAmount = parseInt(betAmount);
        if (typeof betAmount !== "number") {
            return false;
        }
        const {maxBet, minBet} = this.props.config.limit;

        if (betAmount < minBet || betAmount > maxBet)
            return false;

        return true
    }

    onConfirmBettingLotto5 = async (logs) => {
        const {t, user: {userBalance, isAddToCart}, fetchListLotto5Betting} = this.props;
        const betAmount = this.state.betAmount;
        let totalCartAmount = fetchListLotto5Betting({isClear: false});
        if (!this.isValidBetAmount(betAmount)) {
            this.setState({betMessage: t("labels:keno.requireAmount")})
        } else if ((totalCartAmount + betAmount) > userBalance.balance) {
            console.log(userBalance)
            console.log("---->>uukk")
            this.setState({betMessage: t("labels:common.NOT_ENOUGH_BALANCE")})
        } else {
            const data = {
                drawCode: this.props.newResult.drawCode,
                betTypeCode: this.state.betCode,
                amount: this.state.betAmount,
                betLog: logs,
                rebateRate: this.state.betGameOdds,
            }
            await this.props.addLotto5Betting(data);
            !isAddToCart && this.submitBetNow();

            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betCode: "",
                betGameRange: "",
                betGameTime: ""
            })
        }
    }

    onCancelBetting = () => {
        this.setState({
            showBetting: false,
            betAmount: 0,
            betGameType: '',
            betGameOdds: '',
            betMessage: "",
            betGameRange: "",
            betGameTime: ""
        })
    }

    showConfirmDelete(index) {
        this.setState({showDeleteBetting: false, bettingId: null})
        this.props.deleteLotto5Betting(index)
    }

    submitBetNow() {
        const {isEnableBetNow} = this.state;
        const {user} = this.props;
        isEnableBetNow && this.props.lotto5ConfirmBettingNow(() => {
            this.setState({isEnableBetNow: false});
        }).then(() => {
            this.setState({isEnableBetNow: true});
            this.props.fetchLotto5RecentBetReport().then(() => user.isAutoPrint && document.getElementById("print-button").click());
            this.props.fetchUserBalance();
        }).catch((error) => {
            const {response} = error;
            this.setState({isEnableBetNow: true});
            response && swal(response.data.message, {icon: "error", button: "បិទ"});
        })
    }


    afterPrint() {
        this.props.fetchLotto5DrawingResult();
    }

    onBetting(item) {
        const {isStopBet, fetchUserBalanceValidate, t} = this.props;
        const {name, value, resultRange, code} = item;
        if (!isStopBet) {
            fetchUserBalanceValidate()
                .then(() => {
                    this.setState({
                        showBetting: true,
                        betGameType: name,
                        betGameOdds: value,
                        betGameRange: resultRange,
                        betCode: code,
                        betGameTime: getDateHHmmss(new Date())
                    });
                }).catch(error => {
                this.setState({
                    isShowValidateBalance: true,
                    messageValidateBalance: t("labels:common." + error.response.data.message)
                });
            })
        }
    }


    render() {
        let platform = env.PLATFORM_FOR;
        const betFunctions = {
            tenSecondCallback: this.countingSecond.bind(this),
            toggleMute: this.toggleMute,
            showConfirmDelete: this.showConfirmDelete.bind(this),
            submitBetNow: this.submitBetNow.bind(this),
            onBetting: this.onBetting.bind(this),
            onShowDetail: this.onShowDetail.bind(this),
            onCloseDetail: this.onCloseDetail.bind(this),
            afterPrint: this.afterPrint.bind(this),
            onCloseValidateBalance: this.onCloseValidateBalance.bind(this),
        }

        return (
            <div className="lotto5-mix m-lotto-1">
                <DashboardMix
                    {...betFunctions}
                    {...this.props}
                    {...this.state}
                    platform={platform}
                />
                <BettingDialog changeBetAmount={this.changeBetAmount}
                               clickAmountBetBtn={this.clickAmountBetBtn}
                               onConfirmBettingLotto5={this.onConfirmBettingLotto5}
                               resetDefaultAmount={this.resetDefaultAmount}
                               onCancelBetting={this.onCancelBetting}
                               {...this.state}
                               classDialog="bet-mix-dialog"
                               {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.lotto5,
    ...state.lotto5.draw,
    config: state.config,
    user: state.user,
    audio: state.audio,
})

const mapDispatchToProps = dispatch => ({
    setBetStatus: payload => dispatch(setBetStatusLotto5(payload)),
    setRenderResultStatus: payload => dispatch(setRenderResultStatus(payload)),
    setResultTotalNumber: payload => dispatch(setResultTotalNumberKeno(payload)),
    fetchLotto5RecentDraw: payload => dispatch(fetchLotto5RecentDraw(payload)),
    fetchLotto5DrawingResult: () => dispatch(fetchLotto5DrawingResult()),
    fetchLotto5RecentBetReport: () => dispatch(fetchLotto5RecentBetReport()),
    fetchLotto5RecentBetReportCache: () => dispatch(fetchLotto5RecentBetReportCache()),
    setIsAutoPrint: payload => dispatch(setIsAutoPrint(payload)),
    fetchStatistic5: (payload) => dispatch(fetchStatistic5(payload)),
    fetchListLotto5Betting: payload => dispatch(fetchListLotto5Betting(payload)),
    addLotto5Betting: payload => dispatch(addLotto5Betting(payload)),
    deleteLotto5Betting: payload => dispatch(deleteLotto5Betting(payload)),
    lotto5ConfirmBettingNow: (callback) => dispatch(lotto5ConfirmBettingNow(() => callback())),
    fetchUserBalance: () => dispatch(fetchUserBalance()),
    fetchUserBalanceValidate: () => dispatch(fetchUserBalanceValidate()),
    clearCart: () => dispatch(clearCartLotto5()),
    setIsAddToCart: payload => dispatch(setIsAddToCart(payload)),
})

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lotto5DashboardMix))
