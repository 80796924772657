import axios from "../axios";
import API_LOTTO90 from "../../endpoints/lotto90";
import {getDateDef} from "../../utils/dateUtils";
import {getResult} from "../../utils/general";
import {
    LOTTO90_REQUEST_FETCH_DRAWING_RESULT,
    LOTTO90_REQUEST_FETCH_RESULT_REPORT,
    LOTTO90_SET_DRAWING,
    LOTTO90_REQUEST_FETCH_WIN_LOSS_REPORT, LOTTO90_RECENT_TICKET
} from "./constant";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {playAudio} from "../audio/action";
import resultSound from "../../assets/audios/lotto90/bling_sound.mp3";
import openSound from "../../assets/audios/lotto90/result_sound.mp3";
import {ballNumbers} from "../../constants/lotto90";
import {getRandomArbitrary, shuffle} from "../../utils/util";
import {delay} from "../../utils/interval";

function getIndexNumber(time) {
    if (time <= 0) {
        time *= -1;
        if (time > 24) return 5;
        if (time >= 8) {
            time -= 8;
            return Math.ceil(time / 3);
        }
        return 0;
    } else
        return 5;
}

const resetBg = () => {
    let numbers = shuffle(ballNumbers);
    numbers.forEach((p) => {
        let rd = getRandomArbitrary(0, 90);
        setTimeout(() => {
            setActiveResult([p], true);
        }, rd * 50)
    })
}

const getBalls = () => {
    let pdoc = document.getElementById("tb-number");
    let docs = pdoc && pdoc.getElementsByClassName("num-circle");
    if (docs) {
        const arr = Array.prototype.slice.call(docs)
        return arr;
    }
    return [];
}

const setDrawingBg = () => {
    getBalls().forEach(e => {
        e.classList.add("drawing")
    });
}

const setActiveResult = (numbers = [], isRemove = false) => {
    numbers.forEach(p => {
        let number = padZero(p);
        let doc = document.getElementById("drawing-".concat(number));
        if (doc) {
            if (!isRemove)
                doc.classList.add("active");
            else doc.classList.remove("drawing", "active")
        }
    });
}

export const fetchLotto90DrawingResult = () => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LOTTO90.FETCH_LOTTO90_DRAWING_RESULT);
        let data = response.data.data;
        // let data = drawing90;
        if (data !== null) {
            if (!data.oldResult)
                delete data.oldResult;
            dispatch({
                type: LOTTO90_REQUEST_FETCH_DRAWING_RESULT,
                payload: data
            });
        }
        return response;
    } catch (error) {
        return await Promise.reject(error);
    }
};

export const fetchLotto90WinLossReport = (payload) => (dispatch) => {
    dispatch(showLoading("sectionBar"));

    return axios.get(API_LOTTO90.FETCH_LOTTO90_WIN_LOSS_REPORT, {
        params: {filterByLotto: "LOTTO90", size: 10, ...payload}
    })
        .then((response) => {
            const paging = response.data.paging;
            const data = response.data.data;
            dispatch({
                type: LOTTO90_REQUEST_FETCH_WIN_LOSS_REPORT,
                payload: {
                    tickets: data,
                    filter: payload,
                    ...paging
                },
            });

            dispatch(hideLoading("sectionBar"));

        })
        .catch((error) => {
            dispatch(hideLoading("sectionBar"));
            return Promise.reject(error);
        });
};

export const fetchLotto90RecentTicket = (payload = {}) => (dispatch) => {
    return axios.get(API_LOTTO90.FETCH_LOTTO90_RECENT_TICKET, {
        params: {
            page: 1,
            size: 3,
            filterByLotto: "LOTTO90",
            groupByDraw: "YES",
            ...payload
        }
    })
        .then((response) => {
            dispatch({
                type: LOTTO90_RECENT_TICKET,
                payload: response.data.data
            });
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const fetchLotto90ResultReport = (payload = {}) => async (dispatch) => {

    try {
        const response = await axios
            .get(API_LOTTO90.FETCH_LOTTO90_RESULT_REPORT, {
                params: {
                    page: 1,
                    size: 11,
                    filterDate: getDateDef(),
                    ...payload
                }
            });
        const data = response.data.data;
        dispatch({
            type: LOTTO90_REQUEST_FETCH_RESULT_REPORT,
            payload: {
                items: data,
                filter: payload,
                paging: response.data.paging
            },
        });

    } catch (error) {
        return await Promise.reject(error);
    }

};

const padZero = (resultItem) => String(resultItem).padStart(2, "0");


let random;
let startDrawing;
let drawingTime;
let timeSound;
let mute = false;

export const clearAllInv = () => {
    random && clearInterval(random)
    startDrawing && clearTimeout(startDrawing)
    drawingTime && clearInterval(drawingTime)
    timeSound && clearTimeout(timeSound)
}

export const setMute90 = (val) => {
    mute = val;
}


export const drawingNumber = () => async (dispatch, getState) => {
    let {drawing: {index}, oldResult, countDownTimer} = getState().lotto90;
    let audio = getState().audio;
    let tempIndex;
    clearAllInv();


    const playSound = (src) => {
        if ((audio && !audio.isMute) && !mute) playAudio(src);
    }

    let indexR = getIndexNumber(countDownTimer);
    if (oldResult && countDownTimer <= 0 && indexR < 5) {
        if (countDownTimer < 0) countDownTimer *= -1;

        setDrawingBg();
        if (indexR > 0) index = indexR - 1;
        let {totalResult, allNumbersDrawing} = getResult(index, oldResult);
        tempIndex = index;

        dispatch({
            type: LOTTO90_SET_DRAWING,
            payload: {
                isDrawing: index < 5,
                total: totalResult,
                tempIndex: tempIndex,
                number: "?",
                detail: allNumbersDrawing
            }
        });

        await delay((8 - countDownTimer) * 1000);

        setActiveResult(allNumbersDrawing);
        const randomNumber = () => {
            index++;
            let resultItem = 1

            random && clearInterval(random);
            random = setInterval(() => {
                if (resultItem === 91) {
                    resultItem = 1
                }
                dispatch({
                    type: LOTTO90_SET_DRAWING,
                    payload: {
                        number: resultItem++
                    }
                });
            }, 10)
            startDrawing && clearTimeout(startDrawing);
            startDrawing = setTimeout(() => {
                random && clearInterval(random)

                let {resultItem, totalResult, allNumbersDrawing} = getResult(index, oldResult);
                let number = padZero(resultItem);
                dispatch({
                    type: LOTTO90_SET_DRAWING,
                    payload: {
                        number: number,
                        index: index,
                        // tempIndex: tempIndex,
                        total: totalResult,
                        detail: allNumbersDrawing
                    }
                });
                setDrawingBg();
                setActiveResult(allNumbersDrawing);
                playSound(resultSound);
                startDrawing && clearTimeout(startDrawing);
            }, 1000);
        }

        randomNumber()
        drawingTime = setInterval(() => {
            if (index < 5) {
                randomNumber()
                tempIndex++;
                dispatch({
                    type: LOTTO90_SET_DRAWING,
                    payload: {
                        tempIndex: tempIndex,
                    }
                });
            } else {
                clearInterval(drawingTime);
                timeSound = setTimeout(() => {
                    dispatch({
                        type: LOTTO90_SET_DRAWING,
                        payload: {
                            isDrawing: false,
                            index: 0,
                            total: 0,
                            detail: [],
                        }
                    });
                    playSound(openSound);
                    timeSound && clearTimeout(timeSound);
                    resetBg();
                }, 2000);
            }
        }, 3500)
    } else {
        clearAllInv();
        dispatch({
            type: LOTTO90_SET_DRAWING,
            payload: {
                isDrawing: false
            }
        });

    }
}


export const createBetting = (payload) => (dispatch) => {
    return axios
        .post(API_LOTTO90.BETTING_NOW, payload)
        .then(async (response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};


