import {Button} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import React from "react";

export default ({submitBetNow, isEnableBetNow, t}) => {
    return <div className="pl-3 pr-3 d-flex">
        <Button onClick={() => submitBetNow()}
                disabled={!isEnableBetNow}
                className="btn-confirm-bet-now mr-3 d-flex align-items-center justify-content-center border-0">
            {isEnableBetNow ? t("labels:common.betNow") :
                <ClipLoader color="white" loading={!isEnableBetNow} size={18}/>}
        </Button>
        <Button onClick={() => submitBetNow(true)}
                disabled={!isEnableBetNow}
                className="btn-confirm-bet-now bg-primary d-flex align-items-center justify-content-center border-0">
            {isEnableBetNow ? t("labels:common.print") :
                <ClipLoader color="white" loading={!isEnableBetNow} size={18}/>}
        </Button>
    </div>
}