import {
    LOTTO6_SET_BET_STATUS,
    LOTTO6_SET_RENDER_RESULT_STATUS,
    LOTTO6_REQUEST_LIST_BETTING,
    LOTTO6_REQUEST_FETCH_RESULT_REPORT,
    LOTTO6_REQUEST_FETCH_RECENT_BET_REPORT,
    LOTTO6_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO6_REQUEST_FETCH_DRAWING_RESULT,
    LOTTO6_STATISTIC, LOTTO6_REQUEST_FETCH_RECENT_DRAW
} from "./constant";

const initialState = {
    draw: {
        isStopBet: false,
        isStopRender: true,
        timeCountWarning: 10,
        countDownTimer: 180,
        allNumbers: [],
        indexNumber: null,
        newResult: {},
        oldResult: null,
        lastResult: {
            detail: ""
        },
    },
    bettingLotto6: {
        betList: [],
        summary: {
            total: 0,
            currency: "",
            currencySignal: ""
        }
    },
    winReport: {
        tickets: [],
        summary: {
            totalBetAmount: 0,
            totalWinAmount: "",
            totalWinLoseAmount: "",
            currencyCode: ""
        },
        page: 0,
        size: 0,
        totals: 0
    },
    lastDraws: [],
    resultReport: {
        items: [],
        filter: {},
        page: 0,
        size: 0,
        totals: 0
    },
    recentBetting: [],
    statistic: {
        underOverItems: [],
        numberItems: []
    }
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOTTO6_SET_BET_STATUS:
            return {
                ...state,
                draw: {
                    ...state.draw,
                    isStopBet: action.payload.isStopBet
                }
            }
        case LOTTO6_SET_RENDER_RESULT_STATUS:
            return {
                ...state,
                draw: {
                    ...state.draw,
                    isStopRender: action.payload.isStopRender
                }
            }

        case LOTTO6_REQUEST_LIST_BETTING:
            return {
                ...state,
                bettingLotto6: {
                    ...state.bettingLotto6,
                    betList: action.payload.betList,
                    summary: {
                        ...state.bettingLotto6.summary,
                        ...action.payload.summary
                    }
                }
            }
        case LOTTO6_REQUEST_FETCH_RESULT_REPORT:
            return {
                ...state,
                resultReport: {
                    ...state.resultReport,
                    ...action.payload
                }
            }
        case LOTTO6_REQUEST_FETCH_RECENT_DRAW:
            return {
                ...state,
                draw: {
                    ...state.draw,
                    lastResult: action.payload[0]
                },
                lastDraws: action.payload,
            }
        case LOTTO6_REQUEST_FETCH_RECENT_BET_REPORT:
            return {
                ...state,
                recentBetting: action.payload
            }
        case LOTTO6_REQUEST_FETCH_WIN_LOSS_REPORT:
            return {
                ...state,
                winReport: {
                    tickets: action.payload.tickets,
                    summary: action.payload.summary,
                    ...action.payload
                }
            }
        case LOTTO6_REQUEST_FETCH_DRAWING_RESULT:
            return {
                ...state,
                draw: {
                    ...state.draw,
                    ...action.payload
                }
            }
        case LOTTO6_STATISTIC:
            return {
                ...state,
                statistic: {
                    ...action.payload
                }
            };
        default:
            return state
    }
}

export default reducer;