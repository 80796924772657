import React from "react";
import {env} from "../../env";

export default (props) => {
    let rows = [];
    const {underOverItems} = props;
    console.log(underOverItems, "underOverItems")
    for (let i = 0; i < underOverItems?.length; i++) {
        let cols = [];
        let rowNum = underOverItems[i];
        for (let j = 0; j < rowNum?.length; j++) {
            const col = rowNum[j];
            cols.push(<td
                key={j}
                id={"keno-history-key" + j}
                width="5%"
            >
                {(col.length > 0 ?
                    <span className={"text-white statistic-ball-" + col.split('_')[0] + " statistic-ball-" + env.PLATFORM_FOR + "-" + col.split('_')[0]}>
                        {col.length > 0 ? props.t("labels:drawing.drawPrefix.S_" + col.split('_')[1]) : ''}
                    </span>
                    : <span/>)}
            </td>);
        }
        rows.push(<tr key={i}>{cols}</tr>)
    }

    return rows;
}