import {getDate} from "../../utils/dateUtils";
import CountdownTimer from "./CountdownTimer";
import React from "react";
import {env} from "../../env";

const Header = ({oldResult, isDrawing, playSound, countDownTimer, newResult}) => {
    let platformType = env.PLATFORM_TYPE;

    return <div className="lo90-body-card d-flex">
        <div className="col header-text p-0">
            <div className="d-flex align-items-center logo">
                <span><img src={require(`../../assets/images/logo/${platformType}.png`)} alt="logo"/></span><span
                className="lotto-name kh-font-battambang">៩០សំណាង</span>
            </div>
            <span className="text-white last-result-betting">
                <span
                    className="p-2 date-sec">{getDate(isDrawing ? oldResult.drawAt : newResult?.drawAt)}</span>
                <span
                    className="p-2 ticket-sec">{isDrawing ? oldResult.drawCode : newResult?.drawCode}</span>
               <CountdownTimer playSound={playSound} isDrawing={isDrawing} time={countDownTimer}/>
            </span>
        </div>
    </div>
}


export default Header;
