import React from "react";

export const JackpotValue = item => {
    return <b>{item.isJackpot ? (<span className="jackpot-value left-bet-type-THREE">x{item.jackpot}</span>) : "---"}</b>;
}

export const Jackpot = (props) => {
    const {t, item, className} = props;
    return <b>{item.isJackpot ? (<span
            className="jackpot-value">{t("labels:drawing.jp." + item.jackpotType)} x{item.jackpot}</span>) :
        <span className={className}>---</span>}</b>;
}
