import React from 'react';
import {useEffect, useState} from "react";


const RandomNumber = ({className, digit = 100}) => {
    const [random, setRandom] = useState(0);

    useEffect(() => {
        const interval = setInterval(
            () => setRandom(Math.floor(Math.random() * digit)),
            50
        );
        return () => {
            clearInterval(interval);
        };
    }, [digit]);


    return <>{random}</>

};

export default RandomNumber;
