import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {padNumber2} from "../../constants/general";
import {Link} from "react-router-dom";
import {IoIosArrowForward} from "react-icons/io";
import {JackpotValue} from "../jackpot/JackpotValue";


const ResultHistory = ({list, t}) => {

    return <div className="results">
        <div className="list">
            <div className="item border">
                {list.map((p, i) => {
                    return <div key={i} className="item">
                        <span
                            style={{color: "rgb(90, 68, 168)"}}>{p.drawLabel}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={`item-code s-${p.numberCode}`}>{padNumber2(p.result)}</span>
                        <span
                            className="item-zodiac">&nbsp;&nbsp;&nbsp;&nbsp;{t("labels:drawing.drawPrefix.lotto12v2_sign." + p.numberCode)}</span>
                        <span className="ml-3">{p.isJackpot && <JackpotValue {...p}/>}</span>
                    </div>

                })}
                <div className="p-1-vw d-flex justify-content-center align-items-center ">
                    <span>
                        <Link to="/lottery1/12/result">{t("labels:ticket.see_more")}
                            <IoIosArrowForward/>
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    </div>
}
const mapStateToProps = state => ({
    list: state.lotto12v2.recentDraw.list
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(ResultHistory));
