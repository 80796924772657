import Images from "../constants/lotto5Images";
import React from "react";
import {pfType999} from "../constants/general";

const RefreshButton = ({...props}) => {
    const {onClick, className} = props;
    const refreshImg = pfType999 ? Images.refeshIcon999 : Images.refeshIcon

    return <span className="btn-refresh-dashboard">
						<img
                            src={refreshImg}
                            alt=""
                            onClick={onClick}
                            className={className}
                        />
					</span>
}

export default RefreshButton;