import {LOTTO12V2_REQUEST_FETCH_RESULT_REPORT} from "./types";

const init = {
    data: {
        items: [],
        filter: {},
        page: 0,
        size: 0,
        totals: 0
    },
}


export default (state = init, {type, payload}) => {
    switch (type) {
        case LOTTO12V2_REQUEST_FETCH_RESULT_REPORT:
            return {
                ...state,
                data: payload
            }
        default:
            return state;
    }
}