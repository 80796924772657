import moment from "moment";
import NumberFormat from "react-number-format";
import React from "react";

export default (props) => {
    const {t} = props;
    return <div className="wrap-alert">
        <div className="mb-2">
            <div className="text-left game-bet-info-left">
                <div>
                                                            <span
                                                                className="label-bet-info">{t("labels:common.drawCode")}: </span>
                    <span
                        className="value-bet-info">{props.recentBetDetail.drawCode + " " + moment(props.recentBetDetail.createdAt).format('HH:mm')}</span>
                </div>
            </div>
        </div>

        <div className="table-responsive">
            <table className="table detail-report">
                <thead>
                <tr>
                    <th className="text-center">{t("labels:common.no")}</th>
                    <th className="text-center">{t("labels:common.betType")}</th>
                    <th className="text-center">{t("labels:common.betPoint")}</th>
                    <th className="text-center">{t("labels:common.win")}</th>
                </tr>
                </thead>

                <tbody>
                {
                    props.recentBetDetail.items.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td className="text-center">{i + 1}</td>
                                <td className="text-center">{item.betCode === "UNDER" || item.betCode === "OVER" ? t("labels:betPrefix." + item.betCode) : item.betCode}</td>
                                <td className="text-center">
                                    <NumberFormat value={item.betAmount}
                                                  displayType={'text'}
                                                  thousandSeparator={true}/> x {item.betValue}
                                </td>
                                <td className="text-center">
                                    <NumberFormat value={item.isWin ? item.winAmount : 0}
                                                  displayType={'text'}
                                                  thousandSeparator={true}
                                                  suffix={" " + props.recentBetDetail.currencyCode}/>
                                </td>
                            </tr>
                        )
                    })
                }
                <tr className="datail-footer">
                    <td colSpan={2} className="text-center">
                        <p>{t("labels:common.total")}</p>
                    </td>
                    <td className="text-center">
                        <NumberFormat
                            value={props.recentBetDetail.totalBetAmount}
                            displayType={'text'} thousandSeparator={true}
                            suffix={" " + props.recentBetDetail.currency}/>
                    </td>
                    <td className="text-center">
                        <NumberFormat value={props.recentBetDetail.totalWin}
                                      displayType={'text'} thousandSeparator={true}
                                      suffix={" " + props.recentBetDetail.currency}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>;
}