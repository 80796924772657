import React from 'react'
import Lottie from 'react-lottie';


export default class LottieControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isStopped: props.isStopped, isPaused: false};
    }

    render() {
        let {
            loop,
        } = this.props;

        const defaultOptions = {
            loop: loop,
            autoplay: true,
            animationData: this.props.src,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return <Lottie options={defaultOptions}
                       isClickToPauseDisabled={true}
                       isStopped={this.state.isStopped}
                       isPaused={this.state.isPaused}/>
    }
}

