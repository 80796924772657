import {getDate} from "../../utils/dateUtils";
import React from "react";

const LastDraw = ({oldResult, isDrawing, total, t}) => {
    return <div className="row ml-0 mt-3 align-items-center mb-3">
        <span className="f-res">{getDate(oldResult?.drawAt)}</span>
        <span className="f-res">{oldResult?.drawCode}</span>
        <span className="f-res">{t("labels:drawing.lotto90.total")} ៖</span>
        <span className="total-sec">{isDrawing ? total : oldResult?.result}</span>
    </div>
}

export default LastDraw;
