import {env} from "../env";

export const features = env.FEATURES.split(",");

export const hasFeature = (value) => {
    let features = env.FEATURES;
    if (features.includes(value)) {
        return true;
    } else
        return false;
}


const FeatureWrapper = ({value, children, hide}) => {
    if (hide) return null;
    if (hasFeature(value)) {
        return children;
    } else
        return null;
}

export default FeatureWrapper;
