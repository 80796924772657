import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "../assets/css/custom/main-nav.css";
import {env} from "../env"
import FeatureWrapper from "../routes/FeatureWrapper";
import {inIframe, pfType999Color} from "../constants/general";

class MainNav extends Component {


    render() {
        let platform = env.PLATFORM_FOR;
        const {t} = this.props;
        let isIframe = inIframe();


        return (
            <div className={`w-100 nav d-flex nav-${platform} ${pfType999Color}`}>
                <div className={"w-1200 menu menu-" + platform}>
                    <ul>
                        <FeatureWrapper value="5,6,12" hide={isIframe}>
                            <li>
                                <NavLink
                                    to="/"
                                    exact
                                >{t("labels:menus.home")}</NavLink>
                            </li>
                        </FeatureWrapper>
                        <FeatureWrapper value="5,6,12" hide={isIframe}>
                            <li>
                                <NavLink
                                    target="_blank"
                                    to="/lottery-mix"
                                    activeClassName="active"
                                >{t("labels:menus.two_screen")}</NavLink>
                            </li>
                        </FeatureWrapper>
                        <FeatureWrapper value="5">
                            <li>
                                <NavLink
                                    to="/lottery5"
                                    activeClassName="active"
                                >{platform === 'KPW' ? 'LUCKY 5D' : `${t("labels:menus.lotto")} 5`}</NavLink>
                            </li>
                        </FeatureWrapper>
                        <FeatureWrapper value="6">
                            <li>
                                <NavLink
                                    to="/lottery6"
                                    activeClassName="active"
                                >{platform === 'KPW' ? 'LUCKY 6D' : `${t("labels:menus.lotto")} 6/36`}</NavLink>
                            </li>
                        </FeatureWrapper>

                        <FeatureWrapper value="12">
                            <li>
                                <NavLink
                                    to="/lottery12"
                                    activeClassName="active"
                                >{t("labels:menus.lotto")} 12</NavLink>
                            </li>
                        </FeatureWrapper>

                        <FeatureWrapper value="90">
                            <li>
                                <NavLink
                                    to="/lottery90"
                                    activeClassName="active"
                                >{t("labels:menus.lotto")} 90</NavLink>
                            </li>
                        </FeatureWrapper>
                        <FeatureWrapper value="5D">
                            <li>
                                <NavLink
                                    to="/lottery5D"
                                    activeClassName="active"
                                >{t("labels:menus.lottery_5d")}</NavLink>
                            </li>
                        </FeatureWrapper>
                        <FeatureWrapper value="yuki">
                            <li>
                                <NavLink
                                    to="/lottery1/12"
                                    activeClassName="active"
                                >{t("labels:menus.lotto")} 1/12</NavLink>
                            </li>
                        </FeatureWrapper>
                        <FeatureWrapper value="99">
                            <li>
                                <NavLink
                                    to="/lottery1/99"
                                    activeClassName="active"
                                >{t("labels:menus.lotto")} 1/99</NavLink>
                            </li>
                        </FeatureWrapper>

                        {!isIframe && <>
                            <li>
                                <NavLink
                                    to="/statement"
                                    activeClassName="active"
                                >{t("labels:menus.statement")}</NavLink>
                            </li>

                            <li>
                                <NavLink
                                    to="/password"
                                    activeClassName="active"
                                >{t("labels:menus.password")}</NavLink>
                            </li>
                        </>}
                        <FeatureWrapper value="5,6,12" hide={isIframe}>
                            <li>
                                <NavLink
                                    target="_blank"
                                    to="/lottery"
                                    exact
                                >{t("labels:menus.lottos")}</NavLink>
                            </li>
                        </FeatureWrapper>
                    </ul>
                </div>
                {/*{getUpdateWeb() && <div className="web-version"><span >{t("labels:menus.lottoNav.web_updated")}</span></div>}*/}
            </div>
        );
    }
}

export default withTranslation()(MainNav);
