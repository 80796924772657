import React, {Component} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Home from "../pages/home/Home";

import Lotto5Dashboard from "../pages/lotto5";
import Lotto5DashboardMix from "../pages/lotto/lotto5";
import Lotto6DashboardMix from "../pages/lotto/lotto6";
import Lotto12DashboardMix from "../pages/lotto/lotto12";
import Lotto5WinReport from "../components/lotto5/Lotto5WinReport";
import Lotto5Result from "../components/lotto5/Lotto5Result";

import Lotto6Dashboard2 from "../pages/lotto6";
import Lotto6WinReport from "../components/lotto6/Lotto6WinReport";
import Lotto6Result from "../components/lotto6/Lotto6Result";

import Lotto12Dashboard from "../pages/lotto12";
import Lotto90Dashboard from "../pages/lotto90";
import Lotto12WinReport from "../components/lotto12/Lotto12WinReport";
import LottoWinReport from "../components/lotto/mix/LottoWinReportMix";
import Lotto12Result from "../components/lotto12/Lotto12Result";
import Lotto from "../pages/lotto";

import Statement from "../pages/account/Statement";
import ResetPassword from "../pages/account/ResetPassword";
import withAuth from "./withAuth";
import Lotto90WinReport from "../components/lotto90/Lotto90WinReport";
import Lotto90Result from "../components/lotto90/Lotto90Result";
import FeatureWrapper, {features} from "./FeatureWrapper";
import Lotto5DDashboard from "../pages/lotto5d";
import ResultPage from "../pages/lotto5d/result";
import TicketPage from "../pages/lotto5d/ticket";

import Lotto12v2Dashboard from "../pages/lotto12v3";
import Lotto12v2WinReport from "../components/lotto12v2/Lotto12v2WinReport";
import Lotto12v2Result from "../components/lotto12v2/Lotto12v2Result";

import Lotto199Dashboard from "../pages/lotto99";
import Lotto99WinReport from "../components/lotto99/Lotto99WinReport";
import Lotto99Result from "../components/lotto99/Lotto99Result";
import lottoMixResult from "../pages/lotto/lottoMixResult";

const Lotto5 = () => {
    return <FeatureWrapper value="5">
        <Switch>
            <Route exact path="/lottery5" component={withAuth(Lotto5Dashboard)}/>
            <Route
                exact
                path="/lottery5/win-report"
                component={withAuth(Lotto5WinReport)}
            />
            <Route exact path="/lottery5/result" component={withAuth(Lotto5Result)}/>
        </Switch>
    </FeatureWrapper>
}

const Lotto12 = () => {
    return <FeatureWrapper value="12">
        <Switch>
            <Route exact path="/lottery12/result" component={withAuth(Lotto12Result)}/>
            <Route exact path="/lottery12" component={withAuth(Lotto12Dashboard)}/>
            <Route
                exact
                path="/lottery12/win-report"
                component={withAuth(Lotto12WinReport)}
            />
        </Switch>
    </FeatureWrapper>
}

const Lotto6 = () => <FeatureWrapper value="6">
    <Switch>
        <Route exact path="/lottery6" component={withAuth(Lotto6Dashboard2)}/>
        <Route
            exact
            path="/lottery6/win-report"
            component={withAuth(Lotto6WinReport)}
        />
        <Route exact path="/lottery6/result" component={withAuth(Lotto6Result)}/>
    </Switch>
</FeatureWrapper>

const Lotto5D = () => <FeatureWrapper value="5D">
    <Switch>
        <Route exact path="/lottery5D" component={withAuth(Lotto5DDashboard)}/>
        {/*<Route*/}
        {/*    exact*/}
        {/*    path="/lottery5D/win-report"*/}
        {/*    component={withAuth(Lotto6WinReport)}*/}
        {/*/>*/}
        <Route exact path="/lottery5D/result" component={withAuth(ResultPage)}/>
        <Route exact path="/lottery5D/ticket" component={withAuth(TicketPage)}/>
    </Switch>
</FeatureWrapper>

const LottoMix = () => {
    return <FeatureWrapper value="5">
        <Switch>
            <Route
                exact
                path="/lottery"
                component={withAuth(Lotto)}
            />
            <Route
                exact
                path="/lottery/lottery5-mix"
                component={withAuth(Lotto5DashboardMix)}
            />
            <Route
                exact
                path="/lottery/lottery6-mix"
                component={withAuth(Lotto6DashboardMix)}
            />

            <Route
                exact
                path="/lottery/lottery12-mix"
                component={withAuth(Lotto12DashboardMix)}
            />
            <Route
                exact
                path="/lottery/win-report"
                component={withAuth(LottoWinReport)}
            />
            <Route
                exact
                path="/lottery/result-lottery-mix"
                component={withAuth(lottoMixResult)}
            />
            <Route exact path="/lottery/statement-mix" component={withAuth(Statement)}/>

        </Switch>
    </FeatureWrapper>
}

const Lotto90 = () => <FeatureWrapper value="90">
    <Switch>
        <Route
            exact
            path="/lottery90"
            component={withAuth(Lotto90Dashboard)}
        />
        <Route
            exact
            path="/lottery90/bet"
            component={withAuth(Lotto90Dashboard)}
        />
        <Route
            exact
            path="/lottery90/win-report"
            component={withAuth(Lotto90WinReport)}
        />
        <Route
            exact path="/lottery90/result"
            component={withAuth(Lotto90Result)}
        />
    </Switch>
</FeatureWrapper>


const Lotto12V2 = () => <FeatureWrapper value="yuki">
    <Switch>
        <Route
            exact
            path="/lottery1/12"
            component={withAuth(Lotto12v2Dashboard)}
        />
        <Route
            exact
            path="/lottery1/12/win-report"
            component={withAuth(Lotto12v2WinReport)}
        />
        <Route
            exact
            path="/lottery1/12/result"
            component={withAuth(Lotto12v2Result)}
        />
    </Switch>
</FeatureWrapper>

const Lotto199 = () => <FeatureWrapper value="99">
    <Switch>
        <Route
            exact
            path="/lottery1/99"
            component={withAuth(Lotto199Dashboard)}
        />
        <Route
            exact
            path="/lottery1/99/win-report"
            component={withAuth(Lotto99WinReport)}
        />
        <Route
            exact
            path="/lottery1/99/result"
            component={withAuth(Lotto99Result)}
        />
    </Switch>
</FeatureWrapper>

class App extends Component {

    render() {

        const MainRout = () =>
            features.length > 1
                ? <Route exact path="/" component={withAuth(Home)}/>
                : <Redirect from="/" to={`/lottery${features[0]}`}/>

        return (
            <Switch>
                <Route path="/lottery5" component={Lotto5}/>
                <Route path="/lottery6" component={Lotto6}/>
                <Route path="/lottery12" component={Lotto12}/>
                <Route path="/lottery90" component={Lotto90}/>
                <Route path="/lottery5D" component={Lotto5D}/>
                <Route path="/lottery1/12" component={Lotto12V2}/>
                <Route path="/lottery1/99" component={Lotto199}/>
                <Route path="/lottery" component={LottoMix}/>
                <Route exact path="/statement" component={withAuth(Statement)}/>
                <Route exact path="/password" component={withAuth(ResetPassword)}/>
                <MainRout/>
            </Switch>
        );
    }
}

export default App;
