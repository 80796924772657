import _ from "lodash";
import {default as NumberFormat} from "react-number-format";
import React from "react";

export const currencies = [
    {
        "label": "KHR (៛)",
        "value": "KHR",
        "code": "KHR",
        "symbol": "៛",
        "suffix": "៛"
    },
    {
        "label": "USD ($)",
        "value": "USD",
        "code": "USD",
        "symbol": "$",
        "prefix": "$"
    },
    {
        "label": "THB (฿)",
        "value": "THB",
        "code": "THB",
        "symbol": "฿",
        "suffix": "฿"
    }
];

export const getCurrency = code => {
    return _.find(currencies, obj => obj.code === code);
};

export const moneyFormatter = (amount, currency, className = "", isDanger = false) => {
    const symbol = getCurrency(currency);
    return <NumberFormat
        value={amount}
        className={isDanger ? "text-danger" : (amount < 0 ? "text-danger " : className)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={symbol?.prefix}
        suffix={symbol?.suffix}/>;
};
