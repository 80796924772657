import React, {Component} from "react";
import "../assets/css/custom/footer.css";
import {withTranslation} from "react-i18next";
import {getUpdateWeb, pfType999, pfType999Color} from "../constants/general";
import {env} from "../env";


class Footer extends Component {
    componentDidMount() {
        getUpdateWeb();
    }

    render() {

        return (
            <div>
                <div className="w-100 main-footer">
                    <div className="w-1200 footer">
                        {pfType999 ? <p className={`copyright bg-footer-999 ${pfType999Color}`}>© រក្សាសិទ្ធិ
                                2021ដោយ: {env.PLATFORM_TYPE} (v{env.VERSION})</p> :
                            <p className="copyright">{env.VERSION}</p>}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Footer);
