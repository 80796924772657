import {withTranslation} from "react-i18next";
import React from "react";
import {connect} from "react-redux";
import Lotto5DNav from "../../../components/lo5d/Lotto5DNav";
import {clearTicketReport, fetchTicket5D} from "../../../store/lotto5d/action";
import MainTable from "../../../components/lo5d/ticket/MainTable";
import Filter from "../../../components/lo5d/ticket/Filter";

const TicketPage = props => {
    const {
        ticket: {
            filter
        },
    } = props;

    const onFilter = (params = {}) => {
        props.fetchTicket5D(params);
    };

    return (
        <div className="main-w">
            <div className="main">
                <div className="w-1200">
                    <div className="wrapper-new-lotto5 lotto5">
                        <Lotto5DNav/>
                        <Filter
                            {...filter}
                            onIsWin={e => onFilter({...filter, isWin: e})}
                            onChangeDate={e => onFilter({...filter, filterDate: e})}
                            onReset={() => onFilter({})}
                            onFilter={e => onFilter({...filter, ...e})}/>
                        <MainTable filter={filter} {...props}/>
                    </div>
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = state => ({
    ticket: state.lotto5d.ticket,
});
export default connect(mapStateToProps,
    {
        fetchTicket5D,
        clearTicketReport
    }
)(withTranslation()(TicketPage));
