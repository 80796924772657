const LIST_DRAWING_LO99 = 'LIST_DRAWING_LO99';
const LIST_STATISTIC_LO99 = 'LIST_STATISTIC_LO99';
const LO99_LIST_RECENT_DRAW = 'LO99_LIST_RECENT_DRAW';
const LOTTO99_REQUEST_FETCH_WIN_LOSS_REPORT = 'LOTTO99_REQUEST_FETCH_WIN_LOSS_REPORT';
const LOTTO99_REQUEST_FETCH_RESULT_REPORT = 'LOTTO99_REQUEST_FETCH_RESULT_REPORT';

const LOTTO99_RECENT_TICKET = "LOTTO99_RECENT_TICKET";


export {
    LIST_DRAWING_LO99,
    LIST_STATISTIC_LO99,
    LO99_LIST_RECENT_DRAW,
    LOTTO99_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO99_REQUEST_FETCH_RESULT_REPORT,
    LOTTO99_RECENT_TICKET
}





