import React from "react";
import UnderOverResultHistory from "../UnderOverResultHistory";
import OddEvenColorResultHistory from "../OddEvenColorResultHistory";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

const StatisticTableMix = (props) => {
    const {t} = props;
    return <div className="row no-margin-div border-top border-dark">
        <div className="col-6 no-padding-div">
            <div className="col no-padding-div bg-yellow-l">
                <div
                    className="col justify-content-center header-div-title header-text card-border">

                    <span className="last-result-betting">{t("labels:drawing.numberUnderOver")}</span>
                </div>
                <div
                    className="col border-t no-padding-div div-bg-gray lotto6-history-result lotto12-history-result fade-in">

                    <div className="bet-grid-container">

                        <table
                            id="keno_result_post"
                            border="1"
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%"
                            className="fade-in"
                        >
                            <tbody>
                            <UnderOverResultHistory {...props}/>
                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>


        <div className="col-6 no-padding-div bg-yellow-l">

            <div
                className="col justify-content-center header-div-title header-text card-border">
                <span className="last-result-betting">{t("labels:drawing.oddEvenColor")}</span>
            </div>
            <div
                className="col border-t border-left-0 div-bg-gray no-padding-div lotto6-history-result lotto12-history-result fade-in">

                <div className="bet-grid-container">

                    <table
                        id="keno_result_post"
                        border="1"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"

                    >
                        <tbody>
                        <OddEvenColorResultHistory {...props}/>
                        </tbody>

                    </table>

                </div>

            </div>

        </div>

    </div>
}
const mapStateToProps = state => ({
    ...state.lotto12.statistic
})

export default connect(mapStateToProps)(withTranslation()(StatisticTableMix));