const betNumberTypes = {
    "ONE" : "1",
    "TWO" : "2",
    "THREE" : "3",
    "FOUR" : "4",
    "FIVE" : "5",
    "SIX" : "6",
    "SEVEN" : "7",
    "EIGHT": "8",
    "NINE": "9",
    "TEN": "10",
    "ELEVEN": "11",
    "TWELVE": "12",
    "TWO_THREE": "2-3",
    "THREE_FOUR": "3-4",
    "FOUR_FIVE": "4-5",
    "RED": "RED",
    "BLUE": "BLUE",
    "GREEN": "GREEN",
    "UNDER": "UNDER",
    "OVER": "OVER",
    "ODD": "ODD",
    "EVEN": "EVEN",
};

const betShortTypes = {
    ...betNumberTypes,
    "UNDER": "U",
    "OVER": "O",
    "RED": "R",
    "BLUE": "B",
    "GREEN": "G",
    "ODD": "OD",
    "EVEN": "E",
}

export const getShortBetType = betTypeCode => betShortTypes[betTypeCode];

export const getBetType = betTypeCode => betNumberTypes[betTypeCode];