import React from "react";
import "../../assets/css/lotto12v2.css";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    clearAllInv, clearCartLotto12v2,
    fetchLotto12v2DrawingResult,
    fetchLotto12v2RecentBetReport,
    fetchLotto12v2RecentDraw,
    fetchStatistic12v2
} from "../../store/lotto12v2/action";
import {clientAp, lotto12DrawStyle} from "../../constants/general";
import {SUBSCRIPTION_LOTTO12V2} from "../../constants/lotto12";
import {fetchUserBalance} from "../../store/user/action";
import PropTypes from "prop-types";
import {removeSocketListener, socket} from "../../connections/socketServices";
import {socketEvent, socketLottoConfig} from "../../constants/socket";


class Dashboard extends React.Component {

    state = {
        status: undefined,
    }

    componentDidMount() {
        this.subscription();
        this.refreshData();
    }

    refreshData = () => {
        const {
            fetchStatistic12v2,
            fetchLotto12v2RecentDraw,
            fetchLotto12v2RecentBetReport,
            fetchUserBalance
        } = this.props;

        this.getDrawing();
        fetchStatistic12v2();
        fetchLotto12v2RecentDraw();
        fetchUserBalance();
        fetchLotto12v2RecentBetReport();
    }

    getDrawing = () => {
        const {
            fetchLotto12v2DrawingResult,
            drawStyle
        } = this.props;
        return fetchLotto12v2DrawingResult(drawStyle === lotto12DrawStyle.circle);
    }

    onDrawStatusChange = (currentDraw) => {
        const {status} = this.state;
        if (currentDraw.status !== status) {
            if (status && currentDraw.status === "OPEN") {
                this.refreshData();
            }
            if (status && currentDraw.status === "RELEASE") {
                this.getDrawing();
            }
            this.verifyCurrentDraw(currentDraw);
        }
        this.setState({status: currentDraw.status});
    }

    verifyCurrentDraw = currentDrawCode => {
        let betList = JSON.parse(sessionStorage.getItem("betting_lotto12v2") || "[]");
        if (betList.length) {
            let firstItem = betList[0];
            if (firstItem.drawCode !== currentDrawCode) {
                this.props.clearCartLotto12v2();
            }
        }

    }

    subscription = () => {
        if (socketLottoConfig.yuki) {
            socket.on(socketEvent.yuki, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            this.sub = clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO12V2,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_yuki_drawing) {
                        let currentDraw = event.data.v_yuki_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    componentWillUnmount() {
        this.sub && this.sub.unsubscribe();
        removeSocketListener(socketEvent.yuki);
        clearAllInv();
    }


    render() {
        return <div className="dash-wrapper lotto12v2">
            <LeftSide drawStyle={this.props.drawStyle}/>
            <RightSide t={this.props.t}/>
        </div>
    }
}

const mapStateToProps = state => ({})

Dashboard.propTypes = {
    drawStyle: PropTypes.string
}

export default withTranslation()(connect(
    mapStateToProps,
    {
        fetchLotto12v2DrawingResult,
        fetchStatistic12v2,
        fetchLotto12v2RecentDraw,
        fetchUserBalance,
        fetchLotto12v2RecentBetReport,
        clearAllInv,
        clearCartLotto12v2
    }
)(Dashboard));
