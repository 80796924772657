import {permutations} from "@combinatorics/n-permutations";


export const getNormalRange = (min, max) => [...Array(parseInt(max) - parseInt(min) + 1).keys()].map(i => i + parseInt(min)).map(i => i.toString().padStart(min.length, "0"));

export const getSpecialRangeEven = (min, max) => getNormalRange(min, max).filter(v => v % 2 === 0);

export const getSpecialRangeEvenEven = (min, max) => getNormalRange(min, max).filter(v => {
    const number = v.toString().split("");
    if (number[0] % 2 === 0 && number[1] % 2 === 0)
        return v;
    return null;
});

export const getSpecialRangeEvenOdd = (min, max) => getNormalRange(min, max).filter(v => {
    const number = v.toString().split("");
    if (number[0] % 2 === 0 && number[1] % 2 !== 0)
        return v;
    return null;
});

export const getSpecialRangeOdd = (min, max) => getNormalRange(min, max).filter(v => v % 2 !== 0);

export const getSpecialRangeOddOdd = (min, max) => getNormalRange(min, max).filter(v => {
    const number = v.toString().split("");
    if (number[0] % 2 !== 0 && number[1] % 2 !== 0)
        return v;
    return null;
});

export const getSpecialRangeOddEven = (min, max) => getNormalRange(min, max).filter(v => {
    const number = v.toString().split("");
    if (number[0] % 2 !== 0 && number[1] % 2 === 0)
        return v;
    return null;
});

export const getMulti = (word, length) => {
    let masterList = [];
    const possibles = permutations(word, length);
    while (true) {
        const result = possibles.next();
        if (typeof result.value !== "undefined") {
            masterList.push(result.value.join(""));
        }
        if (result.done) {
            return [...new Set(masterList)];
        }
    }
}

export const getMatrix = (arr) => {

    const result = [];
    // Number of arrays
    let n = arr.length;

    // To keep track of next element in
    // each of the n arrays
    let indices = new Array(n);

    // Initialize with first element's index
    for (let i = 0; i < n; i++)
        indices[i] = 0;

    while (true) {
        let num = "";
        // concat string current combination
        for (let i = 0; i < n; i++)
            num += arr[i][indices[i]];

        // add string array
        result.push(num);

        // Find the rightmost array that has more
        // elements left after the current element
        // in that array
        let next = n - 1;
        while (next >= 0 && (indices[next] + 1 >= arr[next].length))
            next--;

        // No such array is found so no more
        // combinations left
        if (next < 0)
            return result;

        // If found move to next element in that
        // array
        indices[next]++;

        // For all arrays to the right of this
        // array current index again points to
        // first element
        for (let i = next + 1; i < n; i++)
            indices[i] = 0;
    }

}

export const getSpecialRange = (numberFrom, numberTo) => {
    const from = numberFrom.split("");
    const to = numberTo.split("");
    let diffIndex = [];
    let master = [];

    if (parseInt(numberFrom) > parseInt(numberTo)) {
        console.log("method require number To greater than number From");
        return master;
    }

    const check = from.length === to.length;
    // make sure they have the same length
    if (check) {
        from.forEach((item, i) => {
            if (item !== to[i]) {
                diffIndex.push(i);
            }
        });
        if (diffIndex.length > 0) {
            switch (diffIndex.length) {
                case 1:
                    let start = from[diffIndex[0]];
                    let end = to[diffIndex[0]];
                    for (let i = start; i <= end; i++) {
                        diffIndex.forEach((changeIn) => {
                            from[changeIn] = i;
                        });
                        master.push(from.join(""));
                    }
                    break;
                default :
                    let itemChange = [];
                    diffIndex.forEach((changeIn) => {
                        itemChange.push(to[changeIn] - from[changeIn]);
                    });
                    itemChange = [...new Set(itemChange)];
                    if (itemChange.length === 1) {
                        let start = from[diffIndex[0]];
                        let end = to[diffIndex[0]];
                        for (let i = start; i <= end; i++) {
                            diffIndex.forEach((changeIn) => {
                                from[changeIn] = i;
                            });
                            master.push(from.join(""));
                        }
                    } else {
                        console.log("out of specail range");
                    }
                    break;
            }
        }
    } else {
        console.log("method require number are the same length");
    }

    return master;
}
export const specialTripleX = (start, end) => {
    let ranges = getNormalRange(start, end);
    let lastDigit = end.substring(0, 1);
    let firstDigit = start.substring(0, 1);
    let pairs = [];
    ranges.forEach((e) => {
        let list = e.split("");
        if ([...new Set(e.split(""))].length === 1) {
            pairs.push(e);
        } else {
            if (lessOrEqual(list, firstDigit, lastDigit) && [...new Set(e.split(""))].length <= 2) {
                pairs.push(e);
            }
        }
    })
    return pairs;
}
export const lessOrEqual = (list, firstDigit, lastDigit) => {
    for (let e of list) {
        if (parseInt(e) > parseInt(lastDigit) || parseInt(e) < parseInt(firstDigit)) {
            return false;
        }
    }

    if (list.length > 3) {
        let list1 = list.sort();
        let first = [list1[0], list1[1]];
        let last = [list1[2], list1[3]];
        if ([...new Set(first)].length === 1 && [...new Set(last)].length === 1) {
            return false;
        }
    }
    return true;
}

export const getNotPair = (list) => list.filter(v => [...new Set(v.split(""))].length !== 1);
export const getPair = (list) => list.filter(v => [...new Set(v.split(""))].length === 1);