import React, {useState} from 'react';
import background from "../../assets/images/lotto12v2/zodiac_bg@1x.a104956b.png";
import {imgUrl} from "../../utils/asset";
import StatisticTable from "../lotto12v3/StatisticTable";
import DrawingTable from "../lotto12v3/DrawingTable";
import {inIframe} from "../../constants/general";

const DrawingResult = () => {
    const [isFull, setFull] = useState(false);

    const toggleFull = () => setFull(!isFull);
    const isInIframe = inIframe();


    return (
        <div style={{
            backgroundImage: `url(${background})`,
        }} className={`${isFull ? "d-board-Fullscreen" : "d-board"}`}>
            {!isInIframe && <img onClick={toggleFull}
                  className="i-fullscreen pointer"
                  src={imgUrl(`lotto12v2/${isFull ? 'exit_fullscreen' : 'fullscreen'}.png`)}
                  alt=""/>}
            <div className="d-lotto12v3 d-flex justify-content-center align-items-center">
                <DrawingTable/>
                <StatisticTable/>
            </div>
        </div>
    );
};

export default DrawingResult;
