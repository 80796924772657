import Balance from "../../pages/components/Balance";
import DrawResult from "./DrawResult";
import RecentBetting from "./RecentBetting";
import SweetAlert from "react-bootstrap-sweetalert";
import React from "react";
import RecentOrdering from "./RecentOrdering";
import _ from "lodash";
import BettingDashboard from "./BettingDashboard";
import BettingDialog from "../lotto5/BettingDialog";

export default (props) => {
    const {t, user: {userBalance, isAddToCart}} = props;
    return <div className="wrap-keno-content">

        <div className="row recent-betting-table">

            {/* betting history left side */}
            <div className="col-3 border">

                {/* user balance */}
                <Balance pendingAmount={userBalance.pendingLotto12}/>
                {/* End of user balance */}

                {/* Last drawing */}
                <DrawResult {...props}/>
                {/* End of last drawing */}

                {/* Recent Ordering */}
                {(!_.isEmpty(props.bettingLotto12.betList) && isAddToCart) && <RecentOrdering {...props}/>}
                {/* End of recent ordering */}


                {/* Recent betting */}
                <RecentBetting {...props}/>
                {/* End of recent betting */}

            </div>

            {/* betting dashboard and pricing */}
            <BettingDashboard {...props}/>


        </div>

        {/*Show Betting Dialog*/}
        <BettingDialog changeBetAmount={props.changeBetAmount}
                       clickAmountBetBtn={props.clickAmountBetBtn}
                       onConfirmBettingLotto5={props.onConfirmBetting}
                       resetDefaultAmount={props.resetDefaultAmount}
                       onCancelBetting={props.onCancelBetting}
                       {...props}/>

        <SweetAlert
            title={props.messageValidateBalance !== null ? props.messageValidateBalance : "message"}
            show={props.isShowValidateBalance}
            onConfirm={props.onCloseValidateBalance}
            confirmBtnText={t("labels:common.okay")}
            showCancel={false}
            btnSize="lg"
            confirmBtnBsStyle="warning"
        />

    </div>
}