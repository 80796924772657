import React, {Component} from "react";
import _ from "lodash";
import Lotto12v2Nav from "./Lotto12v2Nav";
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import moment from "moment";
import 'moment-timezone';
import {withTranslation} from "react-i18next";
import Paginate from "../table/Paginate";
// import Printer from "../print/Printer";
import SweetAlert from "react-bootstrap-sweetalert";
import Images from "../../constants/lotto5Images";
import {currentDate, getDateDef} from "../../utils/dateUtils";
import TicketDetail from "./TicketDetail";
import {moneyFormatter} from "../../utils/MoneyUtils";
import {fetchLotto12v2WinLossReport} from "../../store/lotto12v2/action";

class Lotto12WinReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            date: currentDate(),
            isShow: false,
            dataDetail: null
        };
    }

    componentDidMount() {
        this.onFilter();
    }

    onChange(page) {
        this.onFilter({page: page})
        this.setState({page: page});
    }

    onFilter = (params = {}) => {
        this.props.fetchLotto12v2WinLossReport({
            ...this.props.filter,
            filterDate: getDateDef(this.state.date), ...params
        })
    }

    onShowDetail(item) {
        this.setState({
            isShow: true,
            dataDetail: item
        });
    }

    onCloseDetail() {
        this.setState({
            isShow: false,
            dataDetail: null
        });
    }

    render() {
        const {t, data: {tickets, summary, totals, size}} = this.props;
        const {page} = this.state;
        const dataDetail = this.state.dataDetail;

        return (
            <div className="main-w ">
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-game-content">
                            <Lotto12v2Nav/>
                            <div className="fade-in">
                                <div className="row align-items-center ml-3 mt-2 pb-0">
                                    <div className="form-date-range">
                                        <label>{t("labels:drawing.date")}:</label>
                                    </div>
                                    <div className="pl-2">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={this.state.date}
                                            maxDate={moment().toDate()}
                                            onChange={date => this.setState({date: date})}
                                            className="form-control bg-white"/>
                                    </div>
                                    <div className="wrap-btn-filter">
                                        <button className="btn-game"
                                                onClick={() => this.onFilter()}>{t("labels:common.getResult")}</button>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="table-container pt-0">
                                        <table className="table-custom">
                                            <thead>
                                            <tr>
                                                <th className="text-center">{t("labels:report.no")}</th>
                                                <th className="text-center">{t("labels:report.ticketCode")}</th>
                                                <th className="text-center">{t("labels:report.drawCodeTime")}</th>
                                                <th className="text-center">{t("labels:report.result")}</th>
                                                <th className="text-center">{t("labels:report.qty")}</th>
                                                <th className="text-center">{t("labels:report.betAmount")}</th>
                                                <th className="text-center">{t("labels:report.win")}</th>
                                                <th className="text-center">{t("labels:report.winLose")}</th>
                                                {/*<th className="text-center">{t("labels:report.print")}</th>*/}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                _.isEmpty(tickets)
                                                    ? <tr>
                                                        <td colSpan={10}><img className="data-not-found-img"
                                                                              src={Images.dataNotFound} alt="..."/></td>
                                                    </tr>
                                                    : tickets.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center f-bold">{item.ticketCode}</td>
                                                                <td className="text-center"><span
                                                                    className="title-ticket-id-btn"
                                                                    onClick={this.onShowDetail.bind(this, item)}>{`${item.drawLabel}`}</span>
                                                                </td>
                                                                <td className="text-center">{item.result} / {t("labels:betPrefix." + item.colorCode)}</td>
                                                                <td className="text-center">{item.quantity}</td>
                                                                <td className="text-right pr-2">
                                                                    {moneyFormatter(item.betAmount, item.currencyCode)}
                                                                </td>
                                                                <td className="text-right pr-2">
                                                                    {moneyFormatter(item.winAmount, item.currencyCode, "text-danger")}
                                                                </td>
                                                                <td className="text-right pr-2">
                                                                    {moneyFormatter(item.winLoseAmount, item.currencyCode, "text-primary")}

                                                                </td>
                                                                {/*<td>{item.winAmount > 0 ?*/}
                                                                {/*    <Printer data={item}*/}
                                                                {/*             lottoType="LOTTO12" {...this.props}/> : null}</td>*/}
                                                            </tr>
                                                        );
                                                    })
                                            }
                                            <tr className="win-footer">
                                                <td className="text-center" colSpan={5}><span
                                                    className="pl-2">{t("labels:common.total")}</span></td>
                                                <td className="text-right pr-2">
                                                    {moneyFormatter(summary.totalBetAmount, summary.currencyCode)}
                                                </td>
                                                <td className="text-right pr-2">
                                                    {moneyFormatter(summary.totalWinAmount, summary.currencyCode, "text-danger")}
                                                </td>
                                                <td className="text-right pr-2">
                                                    {moneyFormatter(summary.totalWinLoseAmount, summary.currencyCode, "text-primary")}
                                                </td>
                                                {/*<td className="text-right pr-2"/>*/}
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {
                                        totals > 0
                                            ? <div className="mt-2">
                                                <Paginate
                                                    page={page}
                                                    size={size}
                                                    total={totals}
                                                    onChange={page => this.onChange(page)}
                                                />
                                            </div>
                                            : null
                                    }

                                    <SweetAlert
                                        title=""
                                        show={this.state.isShow}
                                        onConfirm={this.onCloseDetail.bind(this)}
                                        confirmBtnText={t("labels:common.close")}
                                        showCancel={false}
                                        btnSize="lg"
                                    >
                                        <TicketDetail dataDetail={dataDetail}/>
                                    </SweetAlert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state.lotto12v2.winReport
};

const mapDispatchToProps = dispatch => ({
    fetchLotto12v2WinLossReport: payload => dispatch(fetchLotto12v2WinLossReport(payload))
});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lotto12WinReport));
