import React, {Component} from "react";
import Lotto90Nav from "./Lotto90Nav";
import {connect} from "react-redux";
import {fetchLotto90ResultReport} from "../../store/rootAction";
import moment from "moment";
import 'moment-timezone';
import {withTranslation} from "react-i18next";
import LoadingBar from "react-redux-loading-bar";
import DatePicker from "react-datepicker";
import {currentDate, getDateDef} from "../../utils/dateUtils";
import LottoMixNav from "../lotto/mix/LottoMixNav";
import {lottoPaths} from "../../constants/general";
import HistoryTableRow from "./HistoryResultRow"
import Paginate from "../table/Paginate";

class Lotto90Result extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            date: currentDate(),
            showResult: false,
            data: null
        }
    }

    componentDidMount() {
        this.onFilter();
    }

    onChange(page) {
        this.onFilter({page: page})
        this.setState({page: page});
    }


    onFilter = (params = {}) => {
        this.props.fetchLotto90ResultReport({
            ...this.props.filter,
            size: 20,
            filterDate: getDateDef(this.state.date),
            ...params
        })
    }


    render() {
        const {t} = this.props;
        let isFrame = lottoPaths.includes(this.props.location?.pathname);
        const list = this.props.bItems;

        return (
            <div className="main-w">

                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-game-content">
                            {isFrame ? <LottoMixNav {...this.props}/> : <Lotto90Nav/>}
                            <div>
                                <div className="row align-items-center ml-3 mt-2 pb-0">
                                    <div className="form-date-range">
                                        <label>{t("labels:drawing.date")}:</label>
                                    </div>
                                    <div className="pl-2">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={this.state.date}
                                            maxDate={moment().toDate()}
                                            onChange={date => this.setState({date: date})}
                                            className="form-control bg-white"/>
                                    </div>
                                    <div className="wrap-btn-filter">
                                        <button className="btn-game"
                                                onClick={() => this.onFilter()}>{t("labels:common.getResult")}</button>
                                    </div>
                                </div>

                                <LoadingBar scope="sectionBar" style={{backgroundColor: '#448', height: '5px'}}/>

                                <div id="history-5" className="table-responsive fade-in">
                                    <table className="table table-hover table-striped table-bordered">
                                        <thead>
                                        <tr>
                                            <th className="text-center py-2">{t("labels:reports.table.no")}</th>
                                            <th className="text-center py-2">{t("labels:reports.table.ticket_no")}</th>
                                            <th className="text-center py-2">{t("labels:reports.table.time")}</th>
                                            <th className="text-center py-2">{t("labels:reports.table.result_detail")}</th>
                                            <th className="text-center py-2">{t("labels:reports.table.result")}</th>
                                            <th className="text-center py-2">{t("labels:drawing.lotto90.label.OVER")} / {t("labels:drawing.lotto90.label.UNDER")}</th>
                                            <th className="text-center py-2">{t("labels:reports.table.odd_even")}</th>
                                            <th className="text-center py-2">{t("labels:reports.table.big_small")}</th>
                                            <th className="text-center py-2">{t("labels:reports.table.range")}</th>
                                            <th className="text-center py-2">{t("labels:reports.table.jackpot")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {list.map((item, index) => {
                                            return <HistoryTableRow key={index} index={index + 1} object={item}/>
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    this.props.totals > 0
                                        ? <div className="mt-2">
                                            <Paginate
                                                page={this.state.page}
                                                size={this.props.size}
                                                total={this.props.totals}
                                                onChange={page => this.onChange(page)}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state.lotto90.resultReport
};

const mapDispatchToProps = dispatch => ({
    fetchLotto90ResultReport: payload => dispatch(fetchLotto90ResultReport(payload)),
});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lotto90Result));
