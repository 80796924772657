import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {imgUrl} from "../../utils/asset";
import arrow from "../../assets/images/lotto12v3/arrow.png";
import {env} from "../../env";

const DrawingTable = ({drawing}) => {
    const {active} = drawing;
    let resDeg = (active[0] ?? 1) * 30;
    let platformType = env.PLATFORM_TYPE;
    return <div className="turntable">
        <img id="img-border-outline" src={imgUrl("lotto12v3/lucky_border1.png")} alt=""
             className="deng-img"/>
        <div className="lucky-wheel">
            <img id="lv3-result-img" src={imgUrl(`lotto12v3/lucky-wheel-img-${active[0] ?? "0"}.png`)} alt="___"/>
            <img
                className="arrow-logo1" src={imgUrl(`logo/${platformType}.png`)}
                alt="---"/>
            <div id="arrow-l12" className="arrow"
                 style={{transform: `rotate(${resDeg}deg)`, backgroundImage: `url(${arrow})`,}}/>
        </div>
    </div>
}
const mapStateToProps = state => ({
    drawing: state.lotto12v2.drawing
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(DrawingTable));
