import React, {Component} from "react";
import "../assets/css/bootstrap.min.css";
import "../assets/css/main.css";
import "../assets/css/mreset.css";
import "../assets/css/mreset-origin.css";
import "../assets/css/animate.css";
import "../assets/css/games/game.css";
import "../assets/css/six36.css";
import "../assets/css/lotto5.css";
import "../assets/css/lotto5d.css";
import "../assets/css/games/history-info-left.css";
import "../assets/css/games/game-dashboard.css";
import "../assets/css/games/betting-pricing.css";
import "../assets/css/games/betting-history.css";
import "../assets/css/games/game-media-screen.css";
import "../assets/css/custom/style.css";
import "../assets/css/custom/validation.css";
import "../assets/css/custom/footer.css";
import "../assets/css/different-style.css";
import "pnotify/dist/es/PNotifyButtons";
import "pnotify/dist/es/PNotifyConfirm";
import "pnotify/dist/es/PNotifyCallbacks";
import "pnotify/dist/PNotifyBrightTheme.css";
import "../assets/css/lotto90.css";
import "../assets/css/mixtwoscreens.css";

import HeaderWrapper from "./HeaderWrapper";
import MainNav from "./MainNav";
import Footer from "./Footer";
import RootRoute from "../routes";
import {Router, Switch, Route} from "react-router-dom";
import history from "../routes/history"
import Login from "../pages/auth/Login"
import withoutAuth from "./../routes/withoutAuth";
import {getIframePathName, inIframe, lottoPaths, pfType999Color} from "../constants/general";
import {env} from "../env";
import MixTwoScreensPage from "../pages/mixtwoscreens";


const Main = props => {
    let isFrame = lottoPaths.includes(props.location?.pathname);
    let isFullScreen = props.location?.search.includes("fullscreen");
    const isInIframe = inIframe();
    let getPathName = getIframePathName();
    let isMix2 = getPathName?.includes("/lottery-mix");
    return (
        <React.Fragment>
            <div id="main-screen"
                 className={`platform-${env.PLATFORM_FOR} ${pfType999Color} platform-type-${env.PLATFORM_TYPE} ${(isInIframe && isMix2) ? "main-screen-iframe" : ""}`}>
                {(!isFrame && !isFullScreen && !isInIframe) && < HeaderWrapper {...props} />}
                {(!isFrame && !isFullScreen) && <MainNav {...props} />}
                <RootRoute {...props}/>
                {!isFrame && <Footer {...props} />}
            </div>
        </React.Fragment>
    )
}

class App extends Component {
    render() {
        return (
            <React.Fragment>
                <Router history={history}>
                    <Switch>
                        <Route exact path="/login" component={withoutAuth(Login)}/>
                        <Route path="/lottery-mix" component={(MixTwoScreensPage)}/>
                        <Route path="/" component={Main}/>
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

export default App;
