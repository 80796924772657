import {AUDIO} from "./constant";

export const setAudio = (payload) => (dispatch) => {
    dispatch({
        type: AUDIO,
        payload: payload,
    });
};

export const playAudio = (src) => {
    let audio = new Audio(src);
    const playPromise = audio.play();
    if (playPromise !== undefined) {
        playPromise
            .then((_) => {
            })
            .catch((_) => {
            })
            .finally(() => {
                audio = null;
            });
    }
};

export const setPlayAudio = (src, isMute) => (dispatch, getState) => {
    if (isMute) {
        if (isMute === "OFF") {
            let audio = new Audio(src);
            const playPromise = audio.play();
            if (playPromise !== undefined) {
                playPromise.then((_) => {
                }).catch((_) => {
                });
            }
        }
    } else {
        if (!getState().audio.isMute) {
            let audio = new Audio(src);
            const playPromise = audio.play();
            if (playPromise !== undefined) {
                playPromise.then((_) => {
                }).catch((_) => {
                });
            }
        }

    }


};

export const playCountDownAudio = (index, isMute) => (dispatch, getState) => {
    const {
        audio: {COUNDOWN_AUDIO},
    } = getState();
    dispatch(setPlayAudio(COUNDOWN_AUDIO[index], isMute));
};

export const playGoodLuckAudio = (isMute) => (dispatch, getState) => {
    const {
        audio: {goodLuckAudio},
    } = getState();
    dispatch(setPlayAudio(goodLuckAudio, isMute));
};
