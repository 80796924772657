import axios from "../axios";
import API_USER from "../../endpoints/user";
import history from "../../routes/history";
import {showLoading, hideLoading} from "react-redux-loading-bar";

import {
    USER_FETCH_BALANCE,
    USER_LOGOUT,
    USER_FETCH_STATEMENT,
    IS_AUTO_PRINT, IS_ADD_TO_CART, USER_FETCH_PROFILE_USER
} from "./constant";
import {PAGINATION} from "../rootConstant";
import {getLocal, setLocal} from "../../utils/cached";

const clearAuth = () => {
    return {
        type: USER_LOGOUT
    }
}

export const logout = () => {
    localStorage.setItem("isLoggedIn", "NO");
    localStorage.setItem("accessToken", "");
    localStorage.removeItem("allConfig-v5");
    return (dispatch) => {
        dispatch(clearAuth());
        history.push("/");
    }
}


export const fetchUserBalance = (data) => dispatch => {
    if (data) {
        dispatch({
            type: USER_FETCH_BALANCE,
            payload: data
        });
        return false;
    }
    return axios.get(API_USER.FETCH_BALANCE)
        .then(response => {
            const data = response.data.data
            dispatch({
                type: USER_FETCH_BALANCE,
                payload: data
            });
            localStorage.setItem("balance", JSON.stringify(data));
            return Promise.resolve(response)
        }).catch(error => {
            dispatch({
                type: USER_FETCH_BALANCE,
                payload: {
                    balance: 0,
                    fullPending: 0,
                    pendingLotto5: 0,
                    pendingLotto6: 0,
                    pendingLotto90: 0,
                    pendingLotto5d: 0,
                    pendingLotto99: 0,
                    pendingYuki: 0,
                    currencyCode: "",
                    currencySignal: ""
                }
            })
            return Promise.reject(error)
        })
}


export const fetchUserProfile = (data) => dispatch => {
    return axios.get(API_USER.FETCH_PROFILE_USER)
        .then(response => {
            const user = response.data.data.user
            dispatch({
                type: USER_FETCH_PROFILE_USER,
                payload: data
            });
            localStorage.setItem("user", JSON.stringify(user))
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

export const resetPassword = payload => dispatch => {
    return axios.post(API_USER.RESET_PASSWORD_USER, payload)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}


export const changeLanguage = payload => dispatch => {
    return axios.post(API_USER.CHANGE_LANGAUGE_USER, payload)
        .then(response => {
            return Promise.resolve(response)
        }).catch(error => {
            return Promise.reject(error)
        })
}

export const fetchStatement = payload => dispatch => {
    dispatch(showLoading("sectionBar"))
    return axios.get(API_USER.FETCH_STATEMENT, {
        params: {page: 1, size: PAGINATION.SIZE, ...payload}
    })
        .then(response => {
            const data = response.data.data
            const paging = response.data.paging
            dispatch({
                type: USER_FETCH_STATEMENT,
                payload: {
                    items: data.items,
                    summary: data.summary,
                    filter: payload,
                    ...paging
                }

            })
            dispatch(hideLoading("sectionBar"))
            return Promise.resolve(response)
        }).catch(error => {
            dispatch({
                type: USER_FETCH_STATEMENT,
                payload: {
                    items: [],
                    summary: {
                        betAmount: 0,
                        currencyCode: "KHR",
                        winAmount: 0,
                        winLoseAmount: 0
                    },
                    page: 1,
                    size: 0,
                    totals: 0
                }
            })
            dispatch(hideLoading("sectionBar"))
            return Promise.reject(error)
        })
}

export const fetchUserBalanceValidate = () => dispatch => {
    return axios.get(API_USER.FETCH_BALANCE_VALIDATE);
}

export const setIsAutoPrint = isPrint => dispatch => {
    dispatch({
        type: IS_AUTO_PRINT,
        isAutoPrint: isPrint
    })
};

export const setIsAddToCart = isAddToCart => dispatch => {
    dispatch({
        type: IS_ADD_TO_CART,
        isAddToCart: isAddToCart
    })
};


export const setRecentSize = (value) => {
    let rec = getLocal("recentSize");
    if (!rec) rec = {
        lo5: 3,
        lo6: 3,
        lo12: 3,
        lo90: 3,
        lo5d: 3,
        yuki: 3,
        lo99: 3
    };
    setLocal("recentSize", {...rec, ...value});
}

export const getRecentSize = () => {
    let rec = getLocal("recentSize");
    if (!rec) return {
        lo5: 3,
        lo6: 3,
        lo12: 3,
        lo90: 3,
        lo5d: 3,
        yuki: 3,
        lo99: 3
    }
    return rec;
}
