import React, {Component} from "react";
import MainTableRow from "./MainTableRow";
import TicketModal from "./ticketModal";
import Paginated from "../../tables/Paginated";
import Images from "../../../constants/lotto5Images";

const initState = {
    ticket: {},
    isShowTicket: false,
};

class MainTable extends Component {
    state = {
        ...initState
    };

    render() {
        const {
            t, ticket: {
                data,
                filter,
                page,
                totals,
                size
            }
        } = this.props;

        return (
            <React.Fragment>
                <Paginated
                    size={size ?? 0}
                    total={totals ?? 0}
                    page={page + 1}
                    onSizeChange={size => this.props.fetchTicket5D({...filter, size, page: 1})}
                    onChange={page => this.props.fetchTicket5D({...filter, page})}>

                    {data.tickets.length === 0 ? <div className='text-center bg-white border-0'>
                            <img className="data-not-found-img"
                                 src={Images.dataNotFound} alt="..."/>
                        </div> :
                        <div className="table-responsive fade-in" id="lotto5d">
                            <table className="table table-hover table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th className="text-center">{t("labels:lmhsb.no")}</th>
                                    <th className="text-center">{t("labels:lmhsb.ticket_no")}</th>
                                    <th className="text-center">{t("labels:lmhsb.draw_at")}</th>
                                    <th className="text-center">{t("labels:lmhsb.ticket.print_at")}</th>
                                    <th className="text-center">{t("labels:lmhsb.bet")}</th>
                                    <th className="text-center">{t("labels:lmhsb.win")}</th>
                                    <th className="text-center">{t("labels:lmhsb.win_lose")}</th>
                                    <th className="text-center">{t("labels:action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (data && data.tickets?.map((object, index) => {
                                        return (
                                            object.postGroup?.length > 0 && <MainTableRow
                                                key={index}
                                                filter={filter}
                                                onTicketToggle={(data) => this.setState(data)}
                                                index={index + 1}
                                                object={object}
                                            />
                                        )
                                    }))
                                }
                                </tbody>
                            </table>
                        </div>}
                </Paginated>
                <TicketModal isOpen={this.state.isShowTicket}
                             toggle={() => this.setState({...initState})}
                             {...this.props}
                             ticket={this.state.ticket}
                />
            </React.Fragment>
        );
    }
}

export default MainTable;
