import {LOTTO12V2_LIST_RECENT_DRAW} from "./types";

const init = {
    list: []
}


export default (state = init, {type, payload}) => {
    switch (type) {
        case LOTTO12V2_LIST_RECENT_DRAW:
            return {
                ...state,
                list: payload,
            };
        default:
            return state;
    }
}
