import {
    ERROR_A_HAS_STOPPED,
    ERROR_ACCOUNT_NOT_ENOUGH_BALANCE,
    ERROR_BETTING_LO_STOPPED, ERROR_BETTING_POST_STOPPED,
    ERROR_DRAW_EXPIRE,
    ERROR_DRAW_NOT_FOUND, ERROR_USER_HAS_NO_LOTTERY
} from "../../constants/MessageCode";
import PNotify from "pnotify/dist/es/PNotify";

export const showErrorMessage = (error, t) => {
    PNotify.removeAll();
    switch (error?.response?.data?.messageCode) {
        case ERROR_ACCOUNT_NOT_ENOUGH_BALANCE:
            PNotify.info({
                title: t("messages:error"),
                text: t("messages:sale.error.balance_not_enough"),
            });
            break;
        case ERROR_DRAW_NOT_FOUND:
            PNotify.info({
                title: t("messages:error"),
                text: t("messages:sale.error.draw_not_found"),
            });
            break;
        case ERROR_DRAW_EXPIRE:
            PNotify.info({
                title: t("messages:error"),
                text: t("messages:sale.error.draw_expired"),
            });
            break;
        case ERROR_BETTING_LO_STOPPED:
            PNotify.info({
                title: t("messages:error"),
                text: t("messages:sale.error.betting_lo_stopped"),
            });
            break;
        case ERROR_BETTING_POST_STOPPED:
            PNotify.info({
                title: t("messages:error"),
                text: t("messages:sale.error.betting_post_stopped"),
            });
            break;
        case ERROR_USER_HAS_NO_LOTTERY:
            PNotify.info({
                title: t("messages:error"),
                text: t("messages:sale.error.user_has_not_lottery"),
            });
            break;
        case ERROR_A_HAS_STOPPED:
            PNotify.info({
                title: t("messages:error"),
                text: t("messages:sale.error.post_a_stopped"),
            });
            break;
        default:
            PNotify.info({
                title: t("messages:error"),
                text: error?.response?.data?.message,
            });
    }

}
