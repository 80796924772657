import _ from "lodash";
import {gql} from "@apollo/client";

const SUBSCRIPTION_Lo5D = gql`
        subscription Lo5D {
            v_lotto5d_drawing {
                 drawCode,status
            }
           }`;
const post = [
    {id: "A", checked: false, tooltip: "F1"},
    {id: "B", checked: false, tooltip: "F2"},
    {id: "C", checked: false, tooltip: "F3"},
    {id: "D", checked: false, tooltip: "F4"},
];
const betTypes = [
    {id: "NORMAL", title: "ធម្មតា", tooltip: "/"},
    {id: "RANGE", title: "អូស", tooltip: "/"},
    {id: "SPECIAL_RANGE", title: "អូសពិសេស", tooltip: "/"},
];
const multiples = [
    {id: "D1", title: "គុណ", tooltip: "X or *"},
    {id: "D2", title: "គុណ 2D", tooltip: "C or *"},
    {id: "D3", title: "គុណ 3D", tooltip: "V or *"},
    {id: "D4", title: null, tooltip: ""},
];

const postSuffixTime = 33;
const balloonSuffixTime = 3;
const balloonRandomTime = 6;
const drawingTime = 131;

const drawing = _.chunk(Array(20).fill("loading"), 5);


export {
    SUBSCRIPTION_Lo5D, post, betTypes, multiples, postSuffixTime,
    balloonSuffixTime,
    balloonRandomTime,
    drawingTime,
    drawing
}
