import {getLocale} from "../../store/localization/locales";
import React from "react";
import {pfType999Color} from "../../constants/general";

const Header999 = ({...props}) => {
    const {
        t,
        i18n,
        username,
        platformType,
        platform,
        isLotteryMix,
        soundImg,
        listLocales,
        onChangeMute,
        logout
    } = props;

    return <div
        className={"w-1200 h-header position-relative header h-120 d-flex justify-content-between align-items-center header-" + platform + " " + pfType999Color}>
        <img
            className="image-header"
            src={require("../../assets/images/banner/ball-backgroud.png")}
            alt="..."
        />
        <div className={`fade-in d-flex align-items-center position-relative logo-${platform}`}>
            <img src={require(`../../assets/images/logo/${platformType}.png`)} alt="..."/>
            <span className={`px-4 py-2 label-${platform} ${pfType999Color}`}>{username}</span>
        </div>
        <div className="welcome-header text-uppercase">
            {t("labels:welcome")}
        </div>
        <div className={`top-right-${platform}`}>
            <div>
                <div className="mb-2">
                    <div className="dropdown header-social fade-in">
                        <img
                            className="dropbtn img-rounded"
                            src={getLocale(i18n.language).flag}
                            alt="..."
                        />
                        <div className="dropdown-content">{listLocales}</div>
                    </div>
                    <div className="dropdown header-social fade-in">
                        <img
                            className={"dropbtn img-rounded social-top-" + platform}
                            src={require("../../assets/images/logo/youtube.svg")}
                            alt="..."
                        />
                    </div>

                    <div className="dropdown header-social fade-in">
                        <img
                            className={"dropbtn img-rounded social-top-" + platform}
                            src={require("../../assets/images/logo/facebook.svg")}
                            alt="..."
                        />
                    </div>
                </div>
                <div className="logout-999">
                    {
                        !isLotteryMix && <img
                            alt="audio"
                            onClick={onChangeMute}
                            width={30}
                            className="pr-2"
                            style={{cursor: "pointer"}}
                            src={soundImg}/>
                    }
                    <button
                        className={`btn-logout bg-${platform} ${pfType999Color}`}
                        onClick={logout}>{t("labels:logout")}</button>
                </div>
            </div>
        </div>

    </div>
}

export default Header999;