import React, {Component} from "react";
import {connect} from "react-redux";
import 'moment-timezone';
import {withTranslation} from "react-i18next";

import {fetchLotto12RecentDraw} from "../../store/rootAction";
import {JackpotValue} from "../jackpot/JackpotValue";
import {getShortBetType} from "../../utils/tickets/printUtils";
import RefreshButton from "../../utils/RefreshButton";

class DrawResult extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {t, lastDraws} = this.props;
        return (
            <>
                {
                    lastDraws.length > 0 ? <>

                            <div
                                className="col no-padding-div header-div-title header-text header-div-blue-light block-header ">
                                <span className="text-black kh-font-bold">{t("labels:common.lastGame")}</span>
                                <RefreshButton
                                    onClick={() => {
                                        this.setState({imageResultRotate: "rotate"})
                                        this.props.fetchLotto12RecentDraw({query: "?size=3&page=1"})
                                            .then(() => {
                                                setTimeout(() => {
                                                    this.setState({imageResultRotate: ""})
                                                }, 300)
                                            }).catch(() => {
                                            setTimeout(() => {
                                                this.setState({imageResultRotate: ""})
                                            }, 300)
                                        })
                                    }}
                                    className={this.state.imageResultRotate}
                                />
                            </div>

                            <div className="col no-padding-div header-div-title header-text header-div-white ">
                                <table className="table fade-in">
                                    <tbody>
                                    {
                                        lastDraws.map((item, index) => {
                                            const {
                                                drawLabel,
                                                underOverCode,
                                                colorCode,
                                                isJackpot,
                                                numberCode,
                                            } = item;
                                            return (
                                                <tr className="fade-in table-draw-result" key={index}>
                                                    <td className="text-left font-weight-bold w-last-draw">{drawLabel}</td>
                                                    <td className="text-left"><span
                                                        className={"kh-font-bold left-bet-type-12-" + underOverCode}>{t("labels:betPrefix.S_" + getShortBetType(underOverCode))}</span>
                                                        <span> | </span><span
                                                            className={"kh-font-bold left-bet-type-" + colorCode}>{t("labels:drawing.drawPrefix." + colorCode)}</span>
                                                        <span>   </span>
                                                        <span> | </span><b>{t("labels:ticket.number")} {t("labels:drawing.drawPrefix." + numberCode)}</b> {isJackpot ?
                                                            <JackpotValue {...item}/> : ""}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </>
                        : null
                }
            </>
        );
    }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    fetchLotto12RecentDraw: payload => dispatch(fetchLotto12RecentDraw(payload)),
});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(DrawResult));
