import * as React from "react";
import {withTranslation} from "react-i18next";
import MainTableRow from "./MainTableRow";
import Paginated from "../../tables/Paginated";

class MainTable extends React.Component {

    render() {
        const {t, results: {data, filter, size, totals, page}, fetchResults} = this.props;
        return <React.Fragment>
            <Paginated
                size={size}
                total={totals}
                page={page + 1}
                onSizeChange={size => fetchResults({...filter, size, page: 1})}
                onChange={page => fetchResults({...filter, page})}>
                <div id="results">
                    <table className="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th className="f-16 text-center">#</th>
                            <th className="f-16 text-center">{t("labels:reports.table.draw_code")}</th>
                            <th className="f-16">{t("labels:reports.table.result")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data && data?.map((object, i) => {
                                return <MainTableRow key={i}
                                                     index={i + 1}
                                                     object={object}
                                                     {...this.props}/>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </Paginated>
        </React.Fragment>
    }
}

export default (withTranslation()(MainTable));