import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import UnderOverStatistic from "../UnderOverStatistic";
import RangeStatistic from "../RangeStatistic";
import {isOddEven} from "../../../constants/general";

const StatisticTable = (props) => {
    const {t} = props;
    return <div className="lotto5-history-result">
        <table
            id="keno_result_post"
            cellPadding="0"
            cellSpacing="0"
            width="100%"
        >
            <tbody>
            <tr>
                <th colSpan={7} className="lotto5-th-mix border-st-r border-0">
                    <label>{isOddEven ? t("labels:keno.underOverOddEven") : t("labels:keno.underOver")}</label>
                </th>
                <th colSpan={7} className="lotto5-th-mix border-st-l border-0">
                    <label>{t("labels:keno.range")}</label>
                </th>
            </tr>
            <tr>
                <td colSpan={7}>
                    <table
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%">
                        <tbody>
                        <UnderOverStatistic {...props}/>
                        </tbody>
                    </table>
                </td>
                <td colSpan={7}>
                    <table
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%">
                        <tbody>
                        <RangeStatistic {...props}/>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
}

const mapStateToProps = state => ({
    ...state.lotto5.statistic
})


export default withTranslation()(connect(
    mapStateToProps,
    {}
)(StatisticTable));
