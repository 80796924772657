import axios from "../axios";
import API_LOTTO6 from "../../endpoints/lotto6";
import _ from "lodash";
import {hideLoading, showLoading} from "react-redux-loading-bar";

import {
    LOTTO6_REQUEST_FETCH_DRAWING_RESULT,
    LOTTO6_REQUEST_FETCH_RECENT_BET_REPORT, LOTTO6_REQUEST_FETCH_RECENT_DRAW,
    LOTTO6_REQUEST_FETCH_RESULT_REPORT,
    LOTTO6_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO6_REQUEST_LIST_BETTING,
    LOTTO6_SET_BET_STATUS,
    LOTTO6_SET_RENDER_RESULT_STATUS,
    LOTTO6_SET_TOTAL_RESULT_NUMBER, LOTTO6_STATISTIC,
} from "./constant";
import {PAGINATION} from "../rootConstant";
import {getRecentSize} from "../user/action";
import {statisticParam} from "../../constants/general";

function getIndexNumber(timeCountLotto5) {
    if (timeCountLotto5 <= 0) {
        timeCountLotto5 *= -1;
        if (timeCountLotto5 >= 3 && timeCountLotto5 < 27) {
            timeCountLotto5 -= 3;
            return Math.ceil(timeCountLotto5 / 3);
        }
        return -1;
    } else
        return -1;
}


export const setBetStatusLotto6 = (payload) => {
    return {
        type: LOTTO6_SET_BET_STATUS,
        payload: payload,
    };
};

export const setRenderResultStatus = (payload) => {
    return {
        type: LOTTO6_SET_RENDER_RESULT_STATUS,
        payload: payload,
    };
};

export const setResultTotalNumberKeno = (payload) => {
    return {
        type: LOTTO6_SET_TOTAL_RESULT_NUMBER,
        payload: payload,
    };
};

export const fetchLotto6ListBetting = (payload) => (dispatch) => {
    payload["items"] = JSON.parse(sessionStorage.getItem("betting_lotto6"));
    let item = {
        betList: payload.items ?? [],
        summary: {
            total: 0,
            currency: "",
            currencySignal: "",
        },
    };

    if (payload.items?.length > 0)
        item.summary.total = _.sumBy(payload.items, 'amount');

    if (payload.isClear) {
        return dispatch({
            type: LOTTO6_REQUEST_LIST_BETTING,
            payload: item,
        });
    }
    return item.summary.total;
};

export const lotto6AddBetting = (payload) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto6"));
    if (_.isEmpty(betting)) betting = [];
    betting.push(payload);
    sessionStorage.setItem("betting_lotto6", JSON.stringify(betting))
    dispatch(fetchLotto6ListBetting({isClear: true, items: betting}));
};

export const deleteLotto6Betting = (payload) => (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto6"));
    betting.splice(payload.id, 1);
    sessionStorage.setItem("betting_lotto6", JSON.stringify(betting))
    dispatch(fetchLotto6ListBetting({isClear: true, items: betting}));
};

export const lotto6ConfirmBettingNow = (callback) => async (dispatch) => {
    let betting = JSON.parse(sessionStorage.getItem("betting_lotto6"));
    const payload = {
        "drawCode": "",
        "gameType": "LOTTO6",
        "items": []
    };
    if (betting?.length > 0) {
        payload.drawCode = betting[betting.length - 1].drawCode;
        payload.items = betting;

    }
    callback();
    return axios
        .post(API_LOTTO6.BETTING_NOW, payload).then(async (response) => {
            await sessionStorage.removeItem("betting_lotto6");
            await dispatch(fetchLotto6ListBetting({isClear: true, items: []}));
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
};

export const clearCartLotto6 = () => (dispatch) => {
    sessionStorage.removeItem("betting_lotto6");
    dispatch(fetchLotto6ListBetting({isClear: true, items: []}));
}

export const fetchLotto6DrawingResult = () => (dispatch) => {
    return axios
        .get(API_LOTTO6.LOTTO6_FETCH_DRAWING_RESULT)
        .then((response) => {
            let data = response.data.data;
            // let data = drawing6;
            let allNumbers = [];
            let timeCount = data.countDownTimer;
            let isStopBet = true;
            let isStopRender = true;

            if (data.oldResult && timeCount <= 0) {
                const detail = data.oldResult.detail.split(",");
                allNumbers = detail.map(e => parseInt(e));
                isStopRender = false;
                isStopBet = true;
            }

            if (timeCount > 9) {
                isStopBet = false;
                isStopRender = true;
            }

            dispatch({
                type: LOTTO6_REQUEST_FETCH_DRAWING_RESULT,
                payload: {
                    ...data,
                    allNumbers: allNumbers,
                    indexNumber: getIndexNumber(timeCount),
                    isStopBet: isStopBet,
                    isStopRender: isStopRender,
                    countDownTimer: timeCount,
                },
            });

            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const fetchLotto6WinLossReport = (payload) => async (dispatch) => {
    dispatch(showLoading("sectionBar"));

    try {
        const response = await axios.get(API_LOTTO6.LOTTO6_FETCH_WIN_LOSS_REPORT, {
            params: {filterByLotto: "LOTTO6", ...payload}
        });
        const paging = response.data.paging;
        const data = response.data.data;
        dispatch({
            type: LOTTO6_REQUEST_FETCH_WIN_LOSS_REPORT,
            payload: {
                tickets: data.tickets,
                summary: data.summary,
                filter: payload,
                ...paging
            },
        });
        dispatch(hideLoading("sectionBar"));
    } catch (error) {
        dispatch(hideLoading("sectionBar"));
        return await Promise.reject(error);
    }
};

export const fetchLotto6ResultReport = (payload) => (dispatch) => {
    dispatch(showLoading("sectionBar"));

    return axios
        .get(API_LOTTO6.LOTTO6_FETCH_RESULT_REPORT, {params: {size: PAGINATION.SIZE, page: 1, ...payload}})
        .then((response) => {
            const paging = response.data.paging;

            dispatch({
                type: LOTTO6_REQUEST_FETCH_RESULT_REPORT,
                payload: {
                    items: response.data.data,
                    filter: payload,
                    ...paging
                },
            });
            dispatch(hideLoading("sectionBar"));
        })
        .catch((error) => {
            dispatch(hideLoading("sectionBar"));
            return Promise.reject(error);
        });
};

export const fetchLotto6RecentBetReport = () => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LOTTO6.LOTTO6_FETCH_RECENT_BET_REPORT, {
                params: {
                    size: getRecentSize().lo6,
                    page: 1,
                    filterByLotto: "LOTTO6",
                    groupByDraw: "YES",
                }
            });

        dispatch({
            type: LOTTO6_REQUEST_FETCH_RECENT_BET_REPORT,
            payload: response.data.data,
        });

        return await Promise.resolve(response);
    } catch (error) {
        return await Promise.reject(error);
    }

};

export const fetchLotto6RecentBetReportCache = () => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LOTTO6.LOTTO6_FETCH_RECENT_BET_REPORT_CACHE, {
                params: {
                    size: getRecentSize().lo6,
                    page: 1,
                    filterByLotto: "LOTTO6",
                    groupByDraw: "YES",
                }
            });

        dispatch({
            type: LOTTO6_REQUEST_FETCH_RECENT_BET_REPORT,
            payload: response.data.data,
        });

        return await Promise.resolve(response);
    } catch (error) {
        return await Promise.reject(error);
    }

};

export const fetchStatistic6 = (payload) => async (dispatch) => {
    let filter = {...statisticParam, ...payload};
    try {
        const response = await axios.get(API_LOTTO6.LOTTO6_STATISTIC, {
            params: filter
        });
        const data = response.data.data;
        dispatch({
            type: LOTTO6_STATISTIC,
            payload: data
        });
    } catch (error) {
        return await Promise.reject(error);
    }
};


export const fetchLotto6RecentDraw = () => (dispatch) => {
    return axios
        .get(API_LOTTO6.LOTTO6_FETCH_RECENT_DRAW)
        .then((response) => {
            dispatch({
                type: LOTTO6_REQUEST_FETCH_RECENT_DRAW,
                payload: response.data.data
            });
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
