import React from "react";
import ResultHistory from "./ResultHistory";
import TabView from "../lotto/TabView";


const RightSide = (props) => {

    const tabs = ["លទ្ធផលថ្មីៗ"];
    const children = [
        <ResultHistory {...props}/>
    ]

    return <div className="r-side">
        <TabView children={children} tabs={tabs}/>
    </div>
}

export default RightSide;

