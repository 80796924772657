import {
    FETCH_RECENT_TICKET_5D,
    LIST_RECENT_TICKET_5D
} from "./types";

const init = {
    isLoading: false,
    list: [],
    filter: {},
    page: 1,
    totals: 0,
    size: 25
};
export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_RECENT_TICKET_5D:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_RECENT_TICKET_5D:
            return {
                ...state,
                list: payload.data,
                isLoading: false,
            };
        default:
            return state;
    }
}