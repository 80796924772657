import {combineReducers} from "redux"
import authReducer from "./auth/reducer"
import userReducer from "./user/reducer"
import lotto6Reducer from "./lotto6/reducer"
import lotto5Reducer from "./lotto5/reducer"
import lottoReducer from "./lotto/reducer"
import lotto12Reducer from "./lotto12/reducer"
import lotto90Reducer from "./lotto90/reducer"
import audioReducer from "./audio/reducer"
import configReducer from "./config/reducer"
import {loadingBarReducer} from 'react-redux-loading-bar'
import lo5dCombineReducer from "./lotto5d";
import lo12v2CombineReducer from "./lotto12v2";
import lo99CombineReducer from "./lotto99";

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    lotto6: lotto6Reducer,
    lotto5: lotto5Reducer,
    lotto12: lotto12Reducer,
    lotto90: lotto90Reducer,
    audio: audioReducer,
    lotto: lottoReducer,
    config: configReducer,
    lotto5d: lo5dCombineReducer,
    lotto12v2: lo12v2CombineReducer,
    lotto99: lo99CombineReducer,
    loadingBar: loadingBarReducer
})

export default rootReducer
