import React, {Component} from "react";
import _ from "lodash";
import Lotto12Nav from "./Lotto12Nav";
import {connect} from "react-redux";
import {fetchLotto12ResultReport} from "../../store/rootAction";
import moment from "moment";
import 'moment-timezone';
import {withTranslation} from "react-i18next";
import LoadingBar from "react-redux-loading-bar";
import Paginate from "../table/Paginate";
import KenoResultDetail from "../keno/KenoResultDetail";
import DatePicker from "react-datepicker";
import {currentDate, getDateDef} from "../../utils/dateUtils";
import {Jackpot} from "../jackpot/JackpotValue";
import {getShortBetType} from "../../utils/tickets/printUtils";
import LottoMixNav from "../lotto/mix/LottoMixNav";
import {lottoPaths} from "../../constants/general";

class Lotto12Result extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            date: currentDate(),
            showResult: false,
            data: null
        }
    }

    componentDidMount() {
        this.onFilter();
    }

    onChange(page) {
        this.onFilter({page: page})
        this.setState({page: page});
    }


    onFilter = (params = {}) => {
        this.props.fetchLotto12ResultReport({...this.props.filter, filterDate: getDateDef(this.state.date), ...params})
    }

    onToggledResult(isShow, data) {
        this.setState({showResult: isShow, data: data});
    }

    render() {
        const {t} = this.props;
        let isFrame = lottoPaths.includes(this.props.location?.pathname);
        return (
            <div className="main-w">

                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-game-content">
                            {isFrame ? <LottoMixNav {...this.props}/> : <Lotto12Nav/>}
                            <div>
                                <div className="row align-items-center ml-3 mt-2 pb-0">
                                    <div className="form-date-range">
                                        <label>{t("labels:drawing.date")}:</label>
                                    </div>
                                    <div className="pl-2">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={this.state.date}
                                            maxDate={moment().toDate()}
                                            onChange={date => this.setState({date: date})}
                                            className="form-control bg-white"/>
                                    </div>
                                    <div className="wrap-btn-filter">
                                        <button className="btn-game"
                                                onClick={() => this.onFilter()}>{t("labels:common.getResult")}</button>
                                    </div>
                                </div>

                                <LoadingBar scope="sectionBar" style={{backgroundColor: '#448', height: '5px'}}/>

                                <div className="wrapper-game-content">
                                    <div className="table-container pt-0">
                                        <table className="table-custom">
                                            <thead>
                                            <tr>
                                                <th className="text-center">{t("labels:drawing.no")}</th>
                                                <th className="text-center">{t("labels:drawing.date")}</th>
                                                <th className="text-center">{t("labels:drawing.drawCode")}</th>
                                                <th className="text-center">{t("labels:drawing.result")}</th>
                                                <th className="text-center">{t("labels:drawing.underOver")}</th>
                                                <th className="text-center">{t("labels:drawing.oddEvenCode")}</th>
                                                <th className="text-center">{t("labels:drawing.colorCode")}</th>
                                                <th className="text-center">{t("labels:drawing.jackpot")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                _.isEmpty(this.props.items)
                                                    ? <tr>
                                                        <td colSpan={9}>{t("labels:common.noData")}</td>
                                                    </tr>
                                                    : this.props.items.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">{getDateDef(item.createdAt)}</td>
                                                                <td className="text-center">
                                                                    <span
                                                                        className="title-ticket-id-btn">{item.drawLabel}</span>
                                                                </td>
                                                                <td className="text-center">{item.result}</td>
                                                                <td className={"text-center left-bet-type-12-" + item.underOverCode}>{t("labels:betPrefix.S_" + getShortBetType(item.underOverCode))}</td>
                                                                <td className={"text-center"}>{t("labels:betPrefix." + item.oddEvenCode)}</td>
                                                                <td className={"text-center left-bet-type-" + item.colorCode}>{t("labels:drawing.drawPrefix." + item.colorCode)}</td>
                                                                <td className={"text-center"}>
                                                                    <Jackpot item={item} t={t}/>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    {
                                        this.props.totals > 0
                                            ? <div className="mt-2">
                                                <Paginate
                                                    page={this.state.page}
                                                    size={this.props.size}
                                                    total={this.props.totals}
                                                    onChange={page => this.onChange(page)}
                                                />
                                            </div>
                                            : null
                                    }

                                    {
                                        this.state.showResult
                                            ? <div className="bg-overlay-keno-result">
                                                <div className="modal-keno-result">
                                                    <div className="wrap-keno-header-row">
                                                        <div className="title text-left">
                                                            <p>{t("labels:common.result")}</p>
                                                        </div>
                                                        <div className="action-btn text-right">
                                                            <button className="btn-game-close"
                                                                    onClick={this.onToggledResult.bind(this, false, null)}>X
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <KenoResultDetail data={this.state.data}/>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state.lotto12.resultReport
};

const mapDispatchToProps = dispatch => ({
    fetchLotto12ResultReport: payload => dispatch(fetchLotto12ResultReport(payload)),
});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lotto12Result));
