import BettingDashboard from "./bet/BettingDashboardWrapper";
import ResultDashboard from "./ResultDashboard";
import React from "react";

const DashboardWrapper = (props) => {

    return <div className="d-flex wrap-keno-content justify-content-center mt-2">
        {props.isBet()
            ? <BettingDashboard {...props}/>
            : <ResultDashboard {...props}/>}
    </div>
}
export default DashboardWrapper;