import React from "react";
import "../../assets/css/custom/home.css";
import Images from "../../constants/lotto5Images";
import FeatureWrapper from "../../routes/FeatureWrapper";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {homeLottery} from "../../constants/general";

class Home extends React.Component {

    render() {
        const {t} = this.props;
        return <div className="wrapper-home">
            <div className="w-100 h-100">
                {
                    homeLottery.map((object, i) => {
                        return <div key={i} className="wrap-home-item">
                            {
                                object.items.map((item, j) => {
                                    const {value, url, image, title} = item;
                                    return <FeatureWrapper key={j} value={value}>
                                        <div className="home-item">
                                            <Link to={url}>
                                                <img className="wt-100" src={Images[image]} alt="..."/>
                                            </Link>
                                            <div className="m-home-play">
                                                <Link to={url}>
                                                    <div className="home-play">{value === "5D" ? t("labels:menus.lottery") : t("labels:menus.lotto")} {title}</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </FeatureWrapper>
                                })
                            }
                        </div>
                    })
                }
            </div>
        </div>
    }
}

export default withTranslation()(Home);
