import axios from "axios";
import _ from "lodash";
import {env} from "../env";
import history from "../routes/history";

var navigatorInfo = window.navigator;
var screen_info = window.screen;
var uid = navigatorInfo.mimeTypes.length;
uid += navigatorInfo.userAgent.replace(/\D+/g, "");
uid += navigatorInfo.plugins.length;
uid += screen_info.height || "";
uid += screen_info.width || "";
uid += screen_info.pixelDepth || "";

axios.interceptors.request.use((config) => {
    config.baseURL = env.BASE_URL;

    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["X-Platform"] = env.PLATFORM_TYPE;
    config.headers["X-DeviceType"] = "WEB";
    config.headers["X-DeviceId"] = uid;
    config.headers["X-PlayerId"] = uid;
    config.headers["X-Platform-allow"] = env.PLATFORM_ALLOW ?? "";
    config.headers["X-Localization"] = localStorage.getItem("i18nextLng");

    const accessToken = localStorage.getItem("accessToken");
    let authorization = "Basic " + env.BASIC_TOKEN;
    if (!_.isEmpty(accessToken)) {
        authorization = "Bearer " + accessToken;
    }

    config.headers.Authorization = authorization;

    return config;
});

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // return Promise.reject(error)

        if (error.response !== undefined) {
            let statusCode = error.response.status;
            if (statusCode === 401 || statusCode === 403) {
                localStorage.setItem("accessToken", "");
                history.push("/login");
            }
        }

        return Promise.reject(error);
    }
);

export default axios;
