import Images from "../constants/lotto5Images";
import React from "react";
import {pfType999} from "../constants/general";

const LoadingGif = ({...props}) => {
    const {width, height, className} = props;
    const loadingImg = pfType999 ? Images.loading999 : Images.loadingLinear

    return <img width={width} height={height}
                className={className}
                src={loadingImg} alt=""/>
}

export default LoadingGif;