import React, {Component} from "react";
import {inIframe, pfType999Color} from "../constants/general";
import {VscMute, VscUnmute} from "react-icons/vsc";
import {env} from "../env";
import {NavLink} from "react-router-dom";
import {setAudio} from "../store/audio/action";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

class LottoNav extends Component {

    onChangeMute() {
        this.props.setAudio();
    }

    render() {
        const {t, audio: {isMute}} = this.props;
        let isIframe = inIframe();
        const muted = <VscMute className="text-white svg-h-vw"/>;
        const unMuted = <VscUnmute className="text-white svg-h-vw"/>
        return (
            <div className={"new-keno-menu new-keno-menu-" + env.PLATFORM_FOR + " " + pfType999Color}>
                <ul>
                    {this.props.menus.map((e, i) => {
                        return <li key={i}>
                            <NavLink
                                to={e.to}
                                exact={e.exact}
                                activeClassName="active"
                            >{t(e.label)}</NavLink>
                        </li>
                    })}
                    {
                        isIframe && <li className='float-right'>
                            <span
                                className="full-screen-bell"
                                onClick={this.onChangeMute.bind(this)}>
                                {
                                    isMute ? muted : unMuted
                                }
                            </span>
                        </li>

                    }
                </ul>
            </div>
        );
    }
}

LottoNav.propTypes = {
    menus: PropTypes.array
}


const mapStateToProps = state => ({
    audio: state.audio
})

const mapDispatchToProps = dispatch => ({
    setAudio: payload => dispatch(setAudio(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(LottoNav));

