import React from "react";
import StatisticTable from "./StatisticTable";
import DrawingResult from "./DrawingResult";

class RightBoard extends React.Component {

    render() {
        return <div className="col no-padding-div none-select">
            <DrawingResult{...this.props}/>
            {/* History drawing */}
            <StatisticTable/>
        </div>
    }
}

export default RightBoard;