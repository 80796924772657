import React, {Component} from "react";
import PNotify from "pnotify/dist/es/PNotify";


class InputNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            readOnly: props.readOnly,
            validate: props.validate,
            isValid: true
        };
        this.onChange = this.onChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.value !== this.props.value) {
            this.setState((pre) => ({
                ...pre,
                value: this.props.value
            }))
        }
    }

    onChange(e) {
        const {max, min, onChange, isMatrix, isKhr = true} = this.props;
        const re = isKhr ? /^[0-9\b]+$/ : /[+-]?([0-9]*[.])?[0-9]+/;
        let value = e.target.value;

        if (value === "") {
            this.setState({value: value});
            onChange && onChange(e);
            return null;
        }
        if (re.test(value)) {
            if (value.length >= 2 && (max || min)) {
                if (max >= parseInt(value) && min <= parseInt(value)) {
                    this.setState({value: value})
                } else {
                    return null;
                }
            } else {
                if (isMatrix && [...new Set(value.split(""))].length !== value.length) {
                    PNotify.removeAll();
                    PNotify.info({
                        title: this.props.t("messages:error"),
                        text: this.props.t("messages:sale.error.bet_number_invalid"),
                    });
                    return null;
                } else
                    this.setState({value: value})
            }
            onChange && onChange(e);
        }
    }

    validateNum = (value) => {
        return value.length === parseInt(this.props.maxLength, 10)
    }

    render() {
        const {
            maxLength = "5",
            onKeyDown,
            style,
            name,
            myRef,
            id,
            className,
            isActive = true,
            onFocus,
            disabled,
            width,
            onMouseDown,
            onSelect
        } = this.props;
        const {readOnly} = this.state;
        return (<input type={'text'}
                       className={`${className ?? 'input-result form-control'} ${!readOnly ? 'bg-white' : ''}`}
                       onMouseDown={onMouseDown}
                       maxLength={maxLength}
                       onKeyDown={onKeyDown}
                       disabled={disabled}
                       onSelect={onSelect}
                       onFocus={event => {
                           if (isActive) this.setState({readOnly: false});
                           onFocus && onFocus(event);
                           event.target.select();
                       }
                       }
                       onBlur={event => {
                           if (isActive) this.setState({readOnly: true});
                           this.state.validate && this.setState({isValid: this.validateNum(event.target.value)})
                       }}
                       style={style ?? {
                           width: width,
                           fontSize: "1.3vw",
                           borderColor: this.state.isValid ? '#CACBCB' : 'red'
                       }}
                       ref={myRef}
                       name={name}
                       readOnly={readOnly}
                       id={id}
                       autoComplete="off"
                       onChange={this.onChange} value={this.state.value || ""}/>);
    }
}

export default InputNumber;


