import {withTranslation} from 'react-i18next';
import React from "react";
import {Link} from "react-router-dom";
import  {IoIosArrowForward}  from "react-icons/io";

const ResultHistory = ({isDrawing, number, items, t}) => {
    return <div className="right-side lotto90-h-vw">
        {isDrawing && <div id='drawing-ball' className="num-circle-ticket">{number}</div>}
        <div className="row ml-2 mt-5">
            <div className="text-left f-res">{t("labels:ticket.recent")}</div>
        </div>

        {
            items.map((e, i) => {
                return <div key={i} className="draw-sec">
                    <div className="row ml-2 mt-2">
                        <span className="text-right f-res p-1">{e.drawLabel}</span>
                    </div>
                    <div className="row ml-2 mt-0">
                        {e.posts.map((p, i) => {
                            return <span key={i} id="drawing"
                                         className="num-circle-ticket">{p.number}</span>
                        })}
                        <div className="result-ml">
                            <span className="text-right total-draw-sec">{e.result}</span>
                        </div>
                    </div>
                </div>
            })
        }
        <div className="p-1-vw mt-2 d-flex justify-content-center align-items-center">
            <span>
                <Link to="/lottery90/result">
                    {t("labels:ticket.see_more")} <IoIosArrowForward />
                </Link>
            </span>
        </div>
    </div>
}


export default withTranslation()(ResultHistory);
