import zodiac_border from "../../assets/images/lotto12v2/zodiac_border.png";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {sL12v2Label} from "../../constants/general";

const StatisticTable = ({list}) => {
    return <div style={{
        backgroundImage: `url(${zodiac_border})`,
    }} className="content-left-bet-code">
        <div className="zodiac_border">
            <div className="movements">
                {list.map((row, i) => {
                    return <div key={i} className="line">
                        {row.map((col, j) => {
                            return <div key={j} className="column">
                                <span
                                    className={`range1 s-${col}`}>  {col ? sL12v2Label[col] : ""}</span>
                            </div>
                        })}
                    </div>
                })}

            </div>
        </div>
    </div>
}
const mapStateToProps = state => ({
    list: state.lotto12v2.statistic.list
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(StatisticTable));

