import React, { Component } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "react-datepicker/dist/react-datepicker-cssmodules.css"
import { withTranslation } from "react-i18next";

class DateRange extends Component {

	constructor(props) {
		super(props)

		this.state = {
			startDate: new Date(),
			endDate: new Date()
		}
	}

	onChange(dates) {
		const [start, end] = dates
		this.setState({
			startDate: start,
			endDate: end,
		})
	}

	render() {
		const { t } = this.props
		return (
			<>
				<div className="form-date-range-group">
					<div className="form-date-range">
						<label>{t("labels:common.from")}:</label>
					</div>
					<div className="form-date-range">
						<DatePicker
							selected={this.props.startDate}
							onChange={date => this.props.onChangeStartDate( date )}
							selectsStart
							dateFormat="yyyy-MM-dd"
							startDate={this.props.startDate}
							endDate={this.props.endDate}
							maxDate={this.props.endDate}
							className="input-date-range"
						/>
					</div>
				</div>

				<div className="form-date-range-group">
					<div className="form-date-range">
						<label>{t("labels:common.to")}:</label>
					</div>
					<div className="form-date-range">
						<DatePicker
							selected={this.props.endDate}
							onChange={date => this.props.onChangeEndDate( date )}
							selectsEnd
							dateFormat="yyyy-MM-dd"
							startDate={this.props.startDate}
							endDate={this.props.endDate}
							maxDate={this.props.maxDate??new Date()}
							className="input-date-range"
						/>
					</div>
				</div>
			</>
		)
	}
}

export default withTranslation()(DateRange)