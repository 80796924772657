import React from "react";
import {withTranslation} from "react-i18next";
import TicketPrintBody from "./TicketPrintBody";

class PrintTicket extends React.Component {
    render() {
        const {postGroup, bodyClass} = this.props;
        return <div className='f-18 black-c font-weight-bold float-left w-100'>
            <div className="bg-white rounded w-bet-card-ticket w-100">
                {
                    postGroup?.map((item, index) => {
                        return <div key={index}
                                    className={` ${index !== postGroup.length - 1 ? "border-bottom" : ""} p-1`}>
                            <div style={{lineHeight: '13px', paddingBottom: '10px', paddingTop: '5px'}}
                                 className="black-c f-30 f-bayon">{item?.posts}</div>
                            <TicketPrintBody item={item} t={this.props.t} bodyClass={bodyClass}/>
                        </div>
                    })
                }
            </div>
        </div>
    }
}

export default withTranslation()(PrintTicket);