import React, {Component} from "react";
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import moment from "moment";
import 'moment-timezone';
import {withTranslation} from "react-i18next";
import Paginate from "../table/Paginate";
import SweetAlert from "react-bootstrap-sweetalert";
import {currentDate, getDateDef} from "../../utils/dateUtils";
import Lotto99Nav from "./Lotto99Nav";
import {CustomInput, Label} from "reactstrap";
import {fetchLotto99WinLossReport} from "../../store/lotto99/action";
import Ticket58 from "./print/ticket58";
import Lotto99WinReportNewRow from "./Lotto99WinReportNewRow";
import Lotto99WinReportOldRow from "./Lotto99WinReportOldRow";

class Lotto99WinReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            date: currentDate(),
            isShow: false,
            dataDetail: null,
            filterByType: "DRAWING",
            isNewFormat: true
        };
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    componentDidMount() {
        this.onFilter();
    }

    onChange(page) {
        this.onFilter({page: page})
        this.setState({page: page});
    }

    onFilter = (params = {}) => {
        this.props.fetchLotto99WinLossReport({
            ...this.props.filter,
            filterDate: getDateDef(this.state.date),
            filterByType: this.state.filterByType, ...params
        })
    }

    onShowDetail = (item) => {
        this.setState({
            isShow: true,
            dataDetail: item
        });
    }

    onCloseDetail() {
        this.setState({
            isShow: false,
            dataDetail: null
        });
    }

    onChangeValue(event) {
        this.setState({filterByType: event.target.value});
    }


    render() {
        const {t, data: {tickets, totals, size}} = this.props;
        const {date, page, isNewFormat} = this.state;

        return (
            <div className="main-w ">
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-game-content">
                            <Lotto99Nav/>
                            <div className="fade-in">
                                <div className="row align-items-center ml-3 mt-2 pb-0">
                                    <div className="form-date-range">
                                        <label>{t("labels:drawing.date")}:</label>
                                    </div>
                                    <div className="pl-2">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={date}
                                            maxDate={moment().toDate()}
                                            onChange={date => this.setState({date: date})}
                                            className="form-control bg-white"/>
                                    </div>

                                    <div className="d-flex ml-2">
                                        <CustomInput
                                            type='checkbox'
                                            id='new_format'
                                            checked={isNewFormat}
                                            onChange={() => {
                                                this.setState({
                                                    isNewFormat: !isNewFormat
                                                });
                                            }}
                                        />
                                        <Label for='new_format'
                                               className="pr-2 f-15 w-100">{t('labels:lmhsb.new_format')}</Label>
                                    </div>
                                    <div className='d-flex' onChange={this.onChangeValue}>
                                        <input className="m-1 ml-2" type="radio" id="drawing"
                                               name="type"
                                               defaultChecked={true}
                                               value="DRAWING"/>
                                        <label className="mr-2" htmlFor="unpaid">{t("labels:ticket.unpaid")}</label>
                                        <input className="m-1" type="radio" id="finished" name="type"
                                               value="FINISHED"/>
                                        <label className="mr-2" htmlFor="finished">{t("labels:ticket.paid")}</label>
                                        <input className="m-1" type="radio" id="win" name="type" value="WIN"/>
                                        <label htmlFor="win">{t("labels:ticket.win_lotto")}</label>
                                    </div>
                                    <div className="wrap-btn-filter">
                                        <button className="btn-game"
                                                onClick={() => this.onFilter()}>{t("labels:common.getResult")}</button>
                                    </div>

                                </div>

                                <div className="lotto90">
                                    <div className="table-container pt-0">
                                        {isNewFormat ? <Lotto99WinReportNewRow t={t} tickets={tickets}
                                                                               onShowDetail={this.onShowDetail}/> :
                                            <Lotto99WinReportOldRow t={t} tickets={tickets}
                                                                    onShowDetail={this.onShowDetail}/>}
                                    </div>

                                    {
                                        totals > 0
                                            ? <div className="mt-2">
                                                <Paginate
                                                    page={page}
                                                    size={size}
                                                    total={totals}
                                                    onChange={page => this.onChange(page)}
                                                />
                                            </div>
                                            : null
                                    }
                                    <SweetAlert
                                        title=""
                                        show={this.state.isShow}
                                        onConfirm={this.onCloseDetail.bind(this)}
                                        confirmBtnText={t("labels:common.close")}
                                        showCancel={false}
                                        btnSize="lg"
                                    >
                                        <div className='d-flex justify-content-center'>
                                            <Ticket58
                                                object={this.state.dataDetail} {...this.props}/>
                                        </div>
                                    </SweetAlert>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {...state.lotto99.winReport, auth: state.user}
};

const mapDispatchToProps = dispatch => ({
    fetchLotto99WinLossReport: payload => dispatch(fetchLotto99WinLossReport(payload))
});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lotto99WinReport));
