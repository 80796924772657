import Countdown, {zeroPad} from "react-countdown";
import React from "react";
import heart from "../../assets/audios/lotto90/beat_sound_short.mp3";
import release from "../../assets/audios/lotto90/dying_sound.mp3";
import {withTranslation} from "react-i18next";

const renderer = ({hours, minutes, seconds}) => {
    return <div className="time-sec">{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</div>
};

class CountdownTimer extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.time !== this.props.time) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        let {time, isDrawing, playSound, t} = this.props;
        if (time && time > 0) time *= 1000;
        else time = 0;
        if (isDrawing) return <div className="time-sec-lo90v1">{t("labels:drawing.lotto90.drawing")}</div>
        return time > 0 && <Countdown
            onTick={(va) => {
                let sec = va.total / 1000;
                if (sec < 10)
                    playSound(heart);
                if (sec === 1) {
                    setTimeout(() => playSound(release), 1000);
                }
            }}
            date={Date.now() + time}
            renderer={renderer}
        />
    }
}

export default withTranslation()(CountdownTimer);
