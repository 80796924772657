import {AUDIO} from "./constant"
import {getMute, toggleMute} from "../../utils/cached";


const requireAudio = audioName => {
    return require("../../assets/audios/count/" + audioName);
}

const initialState = {
    isMute: getMute() === "ON",
    COUNDOWN_AUDIO: [
        requireAudio("count-1.mp3"),
        requireAudio("count-2.mp3"),
        requireAudio("count-3.mp3"),
        requireAudio("count-4.mp3"),
        requireAudio("count-5.mp3"),
        requireAudio("count-6.mp3"),
        requireAudio("count-7.mp3"),
        requireAudio("count-8.mp3"),
        requireAudio("count-9.mp3"),
        requireAudio("count-10.mp3")
    ],
    goodLuckAudio: requireAudio("good-luck.mp3")
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUDIO:
            toggleMute(!state.isMute ? "ON" : "OFF")
            return {
                ...state,
                isMute: !state.isMute
            }
        default:
            return state
    }
}

export default reducer
