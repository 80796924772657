import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {env} from "../../../env"
import {fullScreen, pfType999Color} from "../../../constants/general";
import {connect} from "react-redux";
import {fetchUserBalance} from "../../../store/user/action";
// import NumberFormat from "react-number-format";
import {moneyFormatter} from "../../../utils/MoneyUtils";

class LottoMixNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageBalanceRotate: ""
        };
    }

    componentDidMount() {
        this.props.fetchUserBalance();
        window.onstorage = () => {
            // When local storage changes, dump the list to
            // the console.
            let balance = localStorage.getItem("balance");
            if (balance) {
                let b = JSON.parse(balance);
                if (b !== this.props.user.userBalance) {
                    this.props.fetchUserBalance(b);
                }
            }
        };
    }

    render() {
        const {
            t, user: {
                userBalance
            }
        } = this.props;
        const {
            pendingLotto5,
            pendingLotto6,
            pendingLotto12
        } = userBalance;

        const bl = [{label: "L5", value: pendingLotto5}, {label: "L6", value: pendingLotto6}, {
            label: "L12",
            value: pendingLotto12
        }];

        console.log(bl)

        return (
            <div className={"new-keno-menu new-keno-menu-" + env.PLATFORM_FOR + " " + pfType999Color}>
                <ul>
                    <li>
                        <NavLink
                            to="/lottery"
                            activeClassName="active"
                        >{t("labels:menus.lottos")}</NavLink>
                    </li>
                    {/*<li>*/}
                    {/*    <NavLink*/}
                    {/*        to="/lottery/lotteries-bet"*/}
                    {/*        activeClassName="active"*/}
                    {/*    >{t("labels:menus.lottoNav.bettingBoard")}</NavLink>*/}
                    {/*</li>*/}
                    <li>
                        <NavLink
                            to="/lottery/win-report"
                            activeClassName="active"
                        >{t("labels:menus.lottoNav.winReport")}</NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/lottery/result-lottery-mix"
                            activeClassName="active"
                        >{t("labels:menus.lottoNav.result")}</NavLink>
                    </li>

                    <li>
                        <NavLink
                            to="/lottery/statement-mix"
                            activeClassName="active"
                        >{t("labels:menus.statement")}</NavLink>
                    </li>
                    <div className="float-right m-2 pointer mt-3" onClick={() => fullScreen("main-screen")}>
                        <img
                            width="25"
                            src={require("../../../assets/images/logo/fullscreen.svg")}
                            alt="..."
                        />
                    </div>

                    <span className="" style={{right: "6vw", position: "absolute", marginTop: "0.6vw"}}>
                        <span className="text-white"><b>{t("labels:common.balance")} : </b></span>
                        <span className="text-white mr-1"><b>
                            {moneyFormatter(userBalance.balance, userBalance.currencyCode, "f-vw text-white")}
                            {/*<NumberFormat value={userBalance.balance}*/}
                            {/*                                          className="text-white"*/}
                            {/*                                          displayType={'text'} thousandSeparator={true}*/}
                            {/*                                          suffix={" " + userBalance?.currencySignal}/>*/}
                        </b>
                        </span>
                        {bl.map((e, i) => {
                            if (e.value <= 0) return null;
                            return <span key={i} className="text-white f-bold mr-1">
                           ({e.label}= {moneyFormatter(e.value, userBalance.currencyCode, "text-white")}
                                {/*<NumberFormat value={userBalance.balance}*/}
                                {/*                                          className="text-white"*/}
                                {/*                                          displayType={'text'} thousandSeparator={true}*/}
                                {/*                                          suffix={" " + userBalance?.currencySignal}/>*/}
                                )
                        </span>
                        })}
                    </span>

                    <NavLink to="/lottery">
                        <div className="float-right m-2 mt-3"
                             onClick={() => {
                                 // let elem = document.getElementById("lotto-mix");
                                 // !elem.classList.contains("full-screen") &&
                                 this.setState({imageBalanceRotate: "rotate"});
                                 setTimeout(() => {
                                     this.setState({imageBalanceRotate: ""})
                                 }, 100);
                                 this.props.fetchUserBalance();
                             }
                             }>
                            <img
                                className={this.state.imageBalanceRotate}
                                width="25"
                                src={require("../../../assets/images/refresh-white.svg")}
                                alt="..."
                            />
                        </div>
                    </NavLink>

                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps, {fetchUserBalance})(withTranslation()(LottoMixNav));
