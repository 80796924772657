import React, {Component} from "react";
import "../assets/css/custom/header.css";
import {locales} from "../store/localization/locales";
import {withTranslation} from "react-i18next";
import {logout, changeLanguage, setAudio} from "../store/rootAction"
import history from "../routes/history"
import {connect} from "react-redux";
import axios from "axios"
import LoadingBar from "react-redux-loading-bar"
import {env} from "../env"
import kpwMute from "../assets/images/audio/mute_red.svg";
import kpwVolume from "../assets/images/audio/volume_red.svg";
import mute from "../assets/images/audio/mute_sky.svg";
import volume from "../assets/images/audio/volume_sky.svg";
import muteWhite from "../assets/images/audio/mute_white.svg";
import volumeWhite from "../assets/images/audio/volume_white.svg";
import {getUser} from "../utils/cached";
import ShowUpdateVersion from "../components/version/ShowUpdateVersion";
import Header888 from "./header/Header888";
import Header999 from "./header/Header999";
import {pfType999} from "../constants/general";

class HeaderWrapper extends Component {


    logout(e) {
        e.preventDefault()
        this.props.logout()
        localStorage.setItem("user", "");
        localStorage.setItem("accessToken", "");
        delete axios.defaults.headers.common["Authorization"]
        history.replace("/login")
    }

    onChangLanguage(lang) {
        const {i18n} = this.props;
        this.props.changeLanguage({languageCode: lang})
            .then(response => {
                window.location.reload()
                i18n.changeLanguage(lang);
            })
    }

    onChangeMute() {
        this.props.setAudio();
    }

    listLocales() {
        const list = [];
        locales().forEach((element) => {
            return list.push(
                <img
                    onClick={(e) => this.onChangLanguage(element.lang)}
                    key={element.lang}
                    className="dropbtn-item"
                    width="50"
                    src={element.flag}
                    alt="..."
                />
            );
        });
        return list;
    }

    render() {
        let platform = env.PLATFORM_FOR;
        let platformType = env.PLATFORM_TYPE;

        const {audio: {isMute}} = this.props;

        let soundImg = () => {
            if (pfType999) {
                return isMute ? muteWhite : volumeWhite;
            } else {
                switch (platformType) {
                    case "KPW": {
                        return isMute ? kpwMute : kpwVolume;
                    }
                    default:
                        return isMute ? mute : volume;
                }
            }
        }

        const user = getUser();
        const isLotteryMix = this.props.location.pathname === "/lottery-mix"

        return (
            <>
                <ShowUpdateVersion/>
                <div className={`w-100 head ${pfType999 ? "h-header" : ""}`}>
                    <LoadingBar style={{backgroundColor: '#448', height: '5px'}}/>
                    {
                        pfType999 ? <Header999
                            logout={this.logout.bind(this)}
                            username={user?.username}
                            platform={platform}
                            platformType={platformType}
                            isLotteryMix={isLotteryMix}
                            soundImg={soundImg()}
                            listLocales={this.listLocales()}
                            onChangeMute={this.onChangeMute.bind(this)}
                            {...this.props}
                        /> : <Header888
                            logout={this.logout.bind(this)}
                            username={user?.username}
                            platform={platform}
                            platformType={platformType}
                            isLotteryMix={isLotteryMix}
                            soundImg={soundImg()}
                            listLocales={this.listLocales()}
                            onChangeMute={this.onChangeMute.bind(this)}
                            {...this.props}
                        />
                    }
                </div>
            </>
        );
    }
}


const mapStateToProps = state => ({
    ...state.user,
    audio: state.audio
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    changeLanguage: payload => dispatch(changeLanguage(payload)),
    setAudio: payload => dispatch(setAudio(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(HeaderWrapper))
