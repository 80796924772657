import * as React from "react";
import {withTranslation} from "react-i18next";
import {getDate} from "../../utils/dateUtils";
import CountdownTimer from "./CountdownTimer";
import {post} from "../../constants/lotto5d";
import RandomNumber from "./v2/RandomNumber";
import {clearInvL5D} from "../../store/lotto5d/action";
import {imgUrl} from "../../utils/asset";
import HistoryResult from "./v2/HistoryResult";
import _ from "lodash";
import Loading from "./v2/Loading";
import {inIframe} from "../../constants/general";

class DrawingResult extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isFull: false
        };
    }

    componentWillUnmount() {
        clearInvL5D()
    }

    toggleFull = () => {
        this.setState({isFull: !this.state.isFull})
    }


    render() {

        const {drawing: {currentDraw, isDrawing, previousDraw, rebate}, t} = this.props;
        const countDownTimer = currentDraw?.countDownTimer;
        const draw = isDrawing ? currentDraw : previousDraw;
        let results = _.chunk(draw?.list, 5) ?? [];
        const isInIframe = inIframe();

        return <div
            className={`l5d-v1-drawing w-100 ${this.state.isFull ? "d-board-Fullscreen row" : "position-relative"}`}>
            {!isInIframe && <img onClick={this.toggleFull}
                  className="l199-fullscreen pointer"
                  src={imgUrl(`lotto12v2/${this.state.isFull ? 'exit_fullscreen' : 'fullscreen'}.png`)}
                  alt=""/>}
            <div
                className={`d-flex justify-content-between align-items-center ${this.state.isFull ? "position-absolute top-0 w-55" : ""}`}>
                <div className="title-draw-wrapper">
                    <span className="title-5d">ឡូតូ ៥ សំណាង</span>
                    <div>
                        <span
                            className="p-2 date-sec text-white">{getDate(isDrawing ? currentDraw.drawAt : previousDraw?.drawAt)}</span>
                        <span
                            className="p-2 ticket-sec text-white">{isDrawing ? currentDraw.drawCode : previousDraw?.drawCode}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <div>
                        <div className="two-d-label">រង្វាន់ (2Dx{rebate.twoD}, 3Dx{rebate.threeD})</div>
                    </div>
                </div>
                <div className="draw-timer-wrap">
                    {isDrawing ?
                        <div className="time-sec-title">{t("labels:drawing.lotto90.drawing")}</div>
                        : <div className="d-flex flex-column justify-content-center align-items-center h-100">
                            <div className="draw-date-wrapper d-flex justify-content-md-around w-100"> <span
                                className="date-sec">{getDate(currentDraw?.drawAt)}</span>
                                <span
                                    className="ticket-sec">{currentDraw?.drawCode}</span>
                            </div>
                            <CountdownTimer isDrawing={isDrawing} time={countDownTimer} {...this.props}/>
                        </div>}


                </div>
            </div>
            <div
                className={`post-5d-wrap ${this.state.isFull ? "col-8 position-absolute l-0 lotto5d-draw-section" : ""}`}>
                {
                    post.map((p, i) => {
                        let res = results[i] ?? [];
                        return <div key={i} className={`post-5d post-5d-${p.id}`}>
                            <img src={require(`../../assets/images/lo5d/post/lo5d_post_${p.id}.svg`)} alt=""/>
                            <div className="number-5d-wrapper">
                                <div className="number-2d">{res.slice(0, 2).map((n, i) => {
                                    if (isDrawing) {
                                        if (n.status === 1) return <RandomNumber key={i} className="" digit={10}/>
                                        else if (n.status === 0) return <Loading key={i}/>;
                                        else return n.number
                                    } else
                                        return n.number
                                })}</div>
                                <div className="number-3d">{res.slice(2, 5).map((n, i) => {
                                    if (isDrawing) {
                                        if (n.status === 1) return <RandomNumber key={i} className="" digit={10}/>
                                        else if (n.status !== 2) return <Loading key={i}/>;
                                        else return n.number
                                    } else
                                        return n.number
                                })}</div>
                            </div>
                        </div>
                    })
                }
            </div>
            {this.state.isFull && <div className="col-4 r-0 position-absolute"><HistoryResult {...this.props}/></div>}
        </div>
    }
}


export default withTranslation()(DrawingResult);
