import {UncontrolledTooltip} from "reactstrap";
import {withTranslation} from "react-i18next";
import React from "react";
import ReactToPrint from "react-to-print";
import {moneyFormatter} from "../../../utils/MoneyUtils";
import {Ticket58} from "./ticket58";
import {getTime} from "../../../utils/dateUtils";
import {getUser} from "../../../utils/cached";

const MainTableRow = props => {
        const {index, object, t, onTicketToggle} = props;
        let componentRef = React.createRef();
        const currencyCode = getUser()?.currencyCode;

        const onShowTicket = () => {
            onTicketToggle({
                ticket: object,
                isShowTicket: true
            });
        };

        const renderNormal = (TotalValue) => {
            return <th
                className='text-center'>
                <span>{moneyFormatter(TotalValue, currencyCode)}</span>
            </th>
        };

        const total = object.total;

        return (
            <tr className='animate-slide-up'>
                <th className="text-center">{index}</th>
                <td className="text-center">
                    <span className="text-primary" onClick={() => onShowTicket()}>{object.ticketNumber}</span>
                </td>
                <td className="text-center">{object.drawCode} {getTime(object.drawAt)}</td>
                <td className="text-center">{getTime(object.createdAt)}</td>
                {renderNormal(total.betAmount)}
                <td className="text-danger">{object.status ? moneyFormatter(total.winAmount, currencyCode, "text-danger") : "???"}</td>
                <td className="text-primary">{object.status ? moneyFormatter(total.winLoseAmount, currencyCode, total.winLoseAmount > 0 ? "text-primary" : "text-danger") : "???"}</td>
                <td className='text-center align-middle'>
                    <div style={{display: 'none'}}>
                        <Ticket58 ref={el => (componentRef = el)}{...props}/>
                    </div>
                    {
                        total.winAmount > 0 && <ReactToPrint
                            copyStyles={true}
                            trigger={() => {
                                return <button className="btn btn-primary btn-sm my-printer-btn" id="print-button">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-printer"
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"/>
                                        <path fillRule="evenodd"
                                              d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"/>
                                        <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                    </svg>
                                    <UncontrolledTooltip placement="bottom" target="print-button">
                                        {t("buttons:print")}
                                    </UncontrolledTooltip>
                                </button>
                            }}
                            content={() => componentRef}
                        />
                    }

                </td>
            </tr>
        );
    }
;

MainTableRow.propTypes = {};

export default withTranslation()(MainTableRow);
