import React, { Component } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next";
import {resetPassword} from "../../store/user/action";

class ResetPassword extends Component {

	constructor(props) {
		super(props)
		this.state = {
			oldPassword: "",
			newPassword: "",
			cNewPassword: "",
			oldPasswordMessage: "",
			newPasswordMessage: "",
			cNewPasswordMessage: "",
			showSuccessPopup: false
		}
	}

	resetPasswordHandle() {
		const user = JSON.parse(localStorage.getItem("user"));
		this.props.resetPassword({
			username: user.username,
			currentPassword: this.state.oldPassword,
			newPassword: this.state.newPassword,
		}).then(() => {
				this.setState({
					showSuccessPopup: true,
					oldPassword: "",
					newPassword: "",
					cNewPassword: ""
				})
			}).catch(() => {
				this.setState({ cNewPasswordMessage: this.props.t("labels:changePassword.resetPasswordFail") })
			})
	}


	onConfirm() {
		if (this.state.oldPassword === "") {
			this.setState({ oldPasswordMessage: this.props.t("labels:changePassword.oldPasswordIsRequired") })
		} else if (this.state.newPassword === "") {
			this.setState({ newPasswordMessage: this.props.t("labels:changePassword.newPasswordIsRequired") })
		} else if (this.state.cNewPassword === "") {
			this.setState({ cNewPasswordMessage: this.props.t("labels:changePassword.confirmPasswordIsRequired") })
		} else if (this.state.newPassword !== this.state.cNewPassword) {
			this.setState({ cNewPasswordMessage: this.props.t("labels:changePassword.passwordIsNotMatch") })
		} else {
			this.resetPasswordHandle()
		}
	}

	onCancel() {
		this.setState({
			oldPassword: "",
			newPassword: "",
			cNewPassword: "",
			oldPasswordMessage: "",
			newPasswordMessage: "",
			cNewPasswordMessage: ""
		})
	}

	setOldPassword(e) {
		this.setState({
			oldPassword: e.target.value,
			oldPasswordMessage: ""
		})
	}

	setNewPassword(e) {
		this.setState({
			newPassword: e.target.value,
			newPasswordMessage: ""
		})
	}

	setCNewPassword(e) {
		this.setState({
			cNewPassword: e.target.value,
			cNewPasswordMessage: ""
		})
	}

	okay() {
		this.setState({
			showSuccessPopup: false
		})
	}

	render() {
		const { t } = this.props
		return (
			<>
				<div className="main-w fade-in">
					<div className="main">
						<div className="w-1200">
							<div className="wrapper-game-content">

								<table className="form-change-password">
									<tbody>
										<tr className="">
											<td colSpan={3} className="title-change-password">
												<div>{t("labels:changePassword.changePassword")}</div>
											</td>
										</tr>
										<tr className="input-password">
											<td className="label-change-password">{ t("labels:changePassword.yourPassword") }</td>
											<td className="form-input-change-password">
												<input
													type="password"
													value={this.state.oldPassword}
													onChange={e => this.setOldPassword(e) }
												/>
											</td>
											<td className="text-left"><p className="text-danger">{this.state.oldPasswordMessage}</p></td>
										</tr>
										<tr>
											<td className="label-change-password">{ t("labels:changePassword.password") }</td>
											<td className="form-input-change-password">
												<input
													type="password"
													value={this.state.newPassword}
													onChange={e => this.setNewPassword(e) }
												/>
											</td>
											<td className="text-left"><p className="text-danger">{this.state.newPasswordMessage}</p></td>
										</tr>
										<tr>
											<td className="label-change-password">{ t("labels:changePassword.confirmPassword") }</td>
											<td className="form-input-change-password">
												<input
													type="password"
													value={this.state.cNewPassword}
													onChange={e => this.setCNewPassword(e) }
												/>
											</td>
											<td className="text-left"><p className="text-danger">{this.state.cNewPasswordMessage}</p></td>
										</tr>
										<tr className="mt-5">
											<td></td>
											<td className="form-input-change-password">
												<div className="wrap-btn-change-password">
													<button className="btn-game btn-game-bottom" onClick={this.onConfirm.bind(this)}>{ t("labels:common.confirm") }</button>
												</div>
												<div className="wrap-btn-change-password">
													<button className="btn-game btn-game-bottom" onClick={this.onCancel.bind(this)}>{ t("labels:common.cancel") }</button>
												</div>
											</td>
											<td></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				{
					this.state.showSuccessPopup
					? <div className="bg-overlay">
						<div className="modal-success">
							<div className="wrap-message-row">
								<div className="message-title">{t("labels:changePassword:resetPasswordSuccess")}</div>
							</div>
							<div className="wrap-message-row">
								<button className="btn-game" onClick={this.okay.bind(this)}>{ t("labels:common.okay") }</button>
							</div>
						</div>
					</div>
					: null
				}
			</>
		);
	}
}

const mapStateToProps = state => ({
	...state.user
})

const mapDispatchToProps = dispatch => ({
	resetPassword: payload => dispatch(resetPassword(payload))
})

export default withTranslation()(connect(
	mapStateToProps,
	mapDispatchToProps
)(ResetPassword))