export default {
    LOTTO12_BETTING_NOW: "/api/v1.0.0/betting",
    FETCH_LOTTO12_DRAWING_RESULT: "/api/v1.0.0/drawing/lotto12",
    FETCH_LOTTO12_RECENT_BET_REPORT: "/api/v1.0.0/reports/recent-betting",
    FETCH_LOTTO12_RECENT_BET_REPORT_CACHE: "/api/v1.0.0/reports/recent-betting-cache",
    FETCH_LOTTO12_WIN_LOSS_REPORT: "/api/v1.0.0/reports/win-lose",
    FETCH_LOTTO12_STATISTIC: "/api/v1.0.0/drawing/lotto12-statistic",
    FETCH_LOTTO12_RESULT_REPORT: "/api/v1.0.0/drawing/lotto12-lists",
    LOTTO12_FETCH_RECENT_DRAW: "/api/v1.0.0/drawing/lotto12-recent-draw?size=3",
};
