import React, {Component} from "react"
import PropTypes from "prop-types"
import Images from "../../media/images"
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

class KenoResultDetail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            winUO: "",
            winOE: "",
            winRange: ""
        }
    }

    componentDidMount() {

        this.setState({
            winUO: " win-" + this.props.data.underOver.underOverCode,
            winOE: " win-" + this.props.data.underOver.oddEvenCode,
            winRange: this.props.data.range.rangeCode
        })

        setTimeout(() => {
            this.setState({
                winUO: "",
                winOE: "",
                winRange: ""
            })

            setTimeout(() => {
                this.setState({
                    winUO: " win-" + this.props.data.underOver.underOverCode,
                    winOE: " win-" + this.props.data.underOver.oddEvenCode,
                    winRange: this.props.data.range.rangeCode
                })

                setTimeout(() => {
                    this.setState({
                        winUO: "",
                        winOE: "",
                        winRange: ""
                    })
                }, 1000)
            }, 1000)
        }, 1000)


    }

    renderResultContentList() {
        const allNumbers = []
        const resultDetail = this.props.data.resultDetail.split(",")
        resultDetail.forEach(item => {
            allNumbers.push(parseInt(item))
        })
        const rows = [];
        for (let index = 0; index < 8; index++) {
            const cols = [];
            for (let num = 1; num <= 10; num++) {
                const element = num + index * 10;
                cols.push(
                    <td
                        key={element}
                        id={"keno-key" + element}
                        width="10%"
                    >
                        <span className={allNumbers.includes(element) ? "active" : ""}>{element}</span>
                    </td>
                );
            }
            rows.push(<tr key={index}>{cols}</tr>);
        }
        return rows;
    }

    render() {
        const {t, data} = this.props
        return (
            <>
                <div className="keno-play-main">
                    <div className="keno-dispaly-result">
                        {/* <div className="keno-logo">
                            <img
                                src={Images.logo}
                                alt=""
                            />
                        </div> */}

                        <div className="keno-total">
                            <div className="keno-row">
                                <label className="label-title">{data.code}</label>
                                <p className="total-result" id="total_result"
                                >{data.createdAtTime}</p>
                            </div>
                            <div className="keno-row">
                                <label className="label-title">{t("labels:keno.total")}:</label>
                                <p className="total-result" id="total_result"
                                >{data.result}</p>
                            </div>
                        </div>

                        <div className="keno-content-result">
                            <table
                                id="keno_result_post"
                                border="1"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                            >
                                <tbody>{this.renderResultContentList()}</tbody>
                            </table>
                        </div>
                    </div>

                    <div className="keno-play-right">
                        <div className="random-play">
                            <div className="loading-gif"></div>
                            <div className="blgo-random-number">
                                <div
                                    className={"total-result-render total-result-render-count-total"}>{data.result}</div>
                                <div className="result-time-countup-render bg-transparent"></div>
                                <div className="show-logo-render">
                                    <img src={Images.logo} alt="..."/>
                                </div>
                            </div>
                        </div>
                        <div className="blog-play">
                            <div className="bet-period-timer">
                                <p id="period-before"></p>
                                <p id="timer-display"></p>
                            </div>
                            <div className="over-under-odd-even">
                                <div className="group-play-box">
                                    <label>{t("labels:keno.underOver")}</label>
                                    {
                                        this.props.gameConfig.overUnder.map((item, i) =>
                                            <div className={"box-bet box-bet-" + item.code} key={i}>
                                                <div id={"ou" + item.code} className="keno-bet">
                                                    <p className={"type " + item.code + this.state.winUO}>{item.name}</p>
                                                    <p className="range">{item.resultRange}</p>
                                                    <p className="odds">{item.value}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="over-under-odd-even range-type">
                            <div className="group-play-box">
                                <label className="range-lable">{t("labels:keno.range")}</label>
                                {
                                    this.props.gameConfig.ranges.map((item, i) =>
                                        <div className={"box-bet box-bet-" + i} key={i}>
                                            <div id={"range" + item.elementId} className="keno-bet">
                                                <p className={"type-rage " + (item.code === this.state.winRange ? this.state.winRange : "")}>{item.name}</p>
                                                <p className="range">{item.resultRange}</p>
                                                <p className="odds">{item.value}</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

KenoResultDetail.propTypes = {
    data: PropTypes.object
}

const mapStateToProps = state => ({
    ...state.keno
})

export default withTranslation()(connect(mapStateToProps)(KenoResultDetail))
