import React from "react";
import {withTranslation} from "react-i18next";

const SpecialX = ({configs, rangeBetting, onClick, t}) => {

    const filterConfig = (type, orType) => configs.filter(e => e.type === type || e.type === orType)

    const underOvers = filterConfig("under_over", "odd_even")
    const oddEven = filterConfig("under_over_odd_even", "under_over_odd_even")
    const range = filterConfig("range", "range")
    const smallBig = filterConfig("small_big", "small_big")

    const active = (item) => rangeBetting.includes(item.code) ? "active" : "";

    const RenderSpecialCard = ({item, className = ""}) => {
        return <div onClick={() => onClick(item.code)} className={`special-card ${className} ${active(item)}`}>
            <div className="code">{t("labels:drawing.lotto90.label.".concat(item.code))}</div>
            <div className={`value ${item.code}`}>
                <div>
                    <div>{item.value}</div>
                    <div className="r-number">{item.rangeNumber}</div>
                </div>
            </div>
        </div>
    }

    return <div className="specialx-wrapper mt-4">
        <div className="s-wrapper">
            {underOvers.map((item, i) => {
                return <RenderSpecialCard item={item} key={i}
                                          className="over-under"/>
            })}
        </div>
        <div className="s-wrapper">
            {oddEven.map((item, i) => {
                return <RenderSpecialCard item={item} key={i}
                                          className="over-under under-over-odd-even"/>
            })}
        </div>
        <div className="s-wrapper">
            {range.map((item, i) => {
                return <RenderSpecialCard item={item} key={i}
                                          className="range"/>
            })}
        </div>
        <div className="s-wrapper">
            {smallBig.map((item, i) => {
                return <RenderSpecialCard item={item} key={i}
                                          className="small-big"/>
            })}
        </div>
    </div>
}

export default withTranslation()(SpecialX);
