import {ballNumbers} from "../../constants/lotto90";
import React from "react";

const BallNumbers = () => {
    return <div className="table-wrapper">
        <div id="tb-number" className="tb-number">
            {ballNumbers.map((r, i) => {
                let formattedNumber = ("0" + (i + 1)).slice(-2);
                return <div id={`drawing-${formattedNumber}`} key={i}
                            className="num-circle">{formattedNumber}</div>
            })}
        </div>
    </div>
}

export default BallNumbers;
