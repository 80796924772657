import React from "react";
import {moneyFormatter} from "../../../utils/MoneyUtils";
import PrintTicket58 from "./PrintTicket58";
import {getFullDateTimePrint, getTimeH} from "../../../utils/dateUtils";
import {getUser} from "../../../utils/cached";

export class Ticket58 extends React.Component {

    render() {
        const {object, t, id} = this.props;
        const currencyCode = getUser()?.currencyCode;
        const isRelease = object?.status;

        return (
            <table id={id ?? ""} className='print-tb-58-1' cellPadding="7px">
                <tbody>
                <tr>
                    <td style={{height: "8px"}}/>
                </tr>
                <tr className="bb-solid-1">
                    <td style={{lineHeight: "55px", paddingRight: 0, paddingLeft: 0, fontSize: "34px"}} colSpan="3"
                        className='text-center f-bayon text-black font-weight-bold pb-1'>{t("labels:lmhsb.ticket.lo5d")}</td>
                </tr>
                <tr>
                    <td className="f-bayon text-black text-center bb-solid-1 p-0 l-h-25">
                        <div
                            className="f-bayon f-22 text-center align-items-center p-0">{getFullDateTimePrint(object?.createdAt)}</div>
                        <div style={{letterSpacing: "-0.5px"}}
                             className="f-bayon f-22 p-0">#{object?.ticketNumber} / {object?.drawCode} {getTimeH(object?.drawAt)}</div>
                    </td>
                </tr>

                <tr>
                    <td colSpan='3' className='p-0 bb-solid-1'>
                        <PrintTicket58 bodyClass='f-t' postGroup={object.postGroup}/>
                    </td>
                </tr>

                <tr>
                    <td style={{lineHeight: "30px"}} colSpan={3}
                        className="p-0 f-25 text-center text-black f-bayon bb-solid-1">{t('labels:ticket.total_bet')} : {moneyFormatter(object?.total?.betAmount, currencyCode, "p-0 f-25 f-bayon")}
                    </td>
                </tr>

                <tr>
                    <td style={{lineHeight: "30px"}} colSpan={3}
                        className="p-0 f-25 text-center text-black f-bayon bb-solid-1">{t('labels:ticket.total_win')} : {
                        !isRelease ? "???" : moneyFormatter(object?.total?.winAmount, currencyCode, "p-0 f-25 f-bayon")
                    }</td>
                </tr>
                <tr>
                    <td>-</td>
                </tr>
                </tbody>
            </table>
        );
    }
}
