import {
    LOTTO12_SET_BET_STATUS,
    LOTTO12_SET_RENDER_RESULT_STATUS,
    LOTTO12_REQUEST_LIST_BETTING,
    LOTTO12_REQUEST_FETCH_RESULT_REPORT,
    LOTTO12_REQUEST_FETCH_RECENT_BET_REPORT,
    LOTTO12_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO12_REQUEST_FETCH_DRAWING_RESULT, LOTTO12_STATISTIC, LOTTO12_REQUEST_FETCH_RECENT_DRAW,
} from "./constant";

const initialState = {
    isStopBet: false,
    isStopRender: true,
    timeCount: 168,
    timeCountWarning: 10,
    allNumbers: [],
    indexNumber: null,
    oldResult: {
        drawCode: "",
        result: 0,
        status: 1,
        createdAt: "",
        createdAtTime: "",
        underOverCode: "",
        oddEvenCode: "",
        colorCode: "",
        lottoDraw: null,
        isJackpot: false,
        jackpotType: "",
        jackpot: 1,
        jackpots: []
    },
    newResult: {
        drawCode: "",
        result: 0,
        status: 1,
        createdAt: "",
        createdAtTime: "",
        underOverCode: "",
        oddEvenCode: "",
        colorCode: "",
        lottoDraw: null,
        isJackpot: false,
        jackpotType: "",
        jackpot: 1
    },
    lastResult: {
        details: []
    },
    bettingLotto12: {
        betList: [],
        summary: {
            total: 0,
            currency: "",
            currencySignal: ""
        }
    },
    winReport: {
        tickets: [],
        summary: {
            totalBetAmount: 0,
            totalWinAmount: "",
            totalWinLoseAmount: "",
            currencyCode: ""
        },
        page: 0,
        size: 0,
        totals: 0
    },
    lastDraws: [],
    resultReport: {
        items: [],
        filter: {},
        page: 0,
        size: 0,
        totals: 0
    },
    recentBetting: [],
    statistic: {
        underOverItems: [],
        numberItems: []
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOTTO12_SET_BET_STATUS:
            return {
                ...state,
                isStopBet: action.payload.isStopBet
            }
        case LOTTO12_SET_RENDER_RESULT_STATUS:
            return {
                ...state,
                isStopRender: action.payload.isStopRender
            }
        case LOTTO12_REQUEST_LIST_BETTING:
            return {
                ...state,
                bettingLotto12: {
                    ...state.bettingLotto12,
                    betList: action.payload.betList,
                    summary: {
                        ...state.bettingLotto12.summary,
                        total: action.payload.summary.total,
                        currency: action.payload.summary.currency,
                        currencySignal: action.payload.summary.currencySignal,
                    }
                }
            }
        case LOTTO12_REQUEST_FETCH_RESULT_REPORT:
            return {
                ...state,
                resultReport: {
                    ...state.resultReport,
                    ...action.payload
                }
            }
            
        case LOTTO12_REQUEST_FETCH_RECENT_DRAW:
            let items = action.payload;
            return {
                ...state,
                lastResult: items[0],
                lastDraws: items,
            }

        case LOTTO12_REQUEST_FETCH_RECENT_BET_REPORT:
            return {
                ...state,
                recentBetting: action.payload
            }
        case LOTTO12_REQUEST_FETCH_WIN_LOSS_REPORT:
            return {
                ...state,
                winReport: {
                    ...state.winReport,
                    ...action.payload
                }
            }
        case LOTTO12_STATISTIC:
            return {
                ...state,
                statistic: action.payload
            }
        case LOTTO12_REQUEST_FETCH_DRAWING_RESULT:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}

export default reducer;
