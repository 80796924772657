import {pfType999} from "../../constants/general";

const suffixImg = (isRound = false) => (pfType999 && isRound) ? "-round" : "";

export const getLocale = (lang) => {
	let locale = locales[1];
	locales(true).forEach((item) => {
		if (item.lang === lang) {
			locale = item;
			return false;
		}
	});
	return locale;
};

export const locales = (isRound = false) => [
	{
		name: "ភាសាខ្មែរ",
		flag: require(`../../assets/images/flags/cambodia${suffixImg(isRound)}.svg`),
		lang: "km",
		locale: "km",
	},
	{
		name: "English",
		flag: require(`../../assets/images/flags/united-state.svg`),
		lang: "en",
		locale: "en-au",
	},
	{
		name: "中文",
		flag: require(`../../assets/images/flags/chinese.svg`),
		lang: "zh",
		locale: "zh-cn",
	},
	{
		name: "ประเทศไทย",
		flag: require(`../../assets/images/flags/thailand.svg`),
		lang: "th",
		locale: "th",
	},
	{
		name: "Việt Nam",
		flag: require(`../../assets/images/flags/vietname.svg`),
		lang: "vi",
		locale: "vi",
	}
];
