export default {
    BETTING_NOW: "/api/v1.0.0/betting",
    LOTTO6_FETCH_DRAWING_RESULT: "/api/v1.0.0/drawing/lotto6",
    LOTTO6_FETCH_RECENT_BET_REPORT: "/api/v1.0.0/reports/recent-betting",
    LOTTO6_FETCH_RECENT_BET_REPORT_CACHE: "/api/v1.0.0/reports/recent-betting-cache",
    LOTTO6_FETCH_WIN_LOSS_REPORT: "/api/v1.0.0/reports/win-lose",
    LOTTO6_FETCH_RESULT_REPORT: "/api/v1.0.0/drawing/lotto6-lists",
    LOTTO6_STATISTIC: "/api/v1.0.0/drawing/lotto6-statistic",
    LOTTO6_FETCH_RECENT_DRAW: "/api/v1.0.0/drawing/lotto6-recent-draw?size=3",
};
