export default {
    LOTTO5_BETTING_NOW: "/api/v1.0.0/betting",
    LOTTO5_FETCH_RECENT_BET_REPORT: "/api/v1.0.0/reports/recent-betting",
    LOTTO5_FETCH_RECENT_BET_REPORT_CACHE: "/api/v1.0.0/reports/recent-betting-cache",
    LOTTO5_FETCH_WIN_LOSS_REPORT: "/api/v1.0.0/reports/win-lose",
    LOTTO5_FETCH_RESULT_REPORT: "/api/v1.0.0/drawing/lotto5-lists",
    LOTTO5_STATISTIC: "/api/v1.0.0/drawing/lotto5-statistic",
    LOTTO5_FETCH_DRAWING_RESULT: "/api/v1.0.0/drawing/lotto5",
    LOTTO5_FETCH_RECENT_DRAW: "/api/v1.0.0/drawing/lotto5-recent-draw?size=3",
};
