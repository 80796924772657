import React, {useState} from "react";
import {fetchLotto6ResultReport} from "../../store/lotto6/action";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import swal from "sweetalert";
import _ from "lodash";
import BetTweenButton from "../lotto/BetTweenButton";
import {moneyFormatter} from "../../utils/MoneyUtils";

const RecentOrdering = (props) => {
    const {
        t, user, betList, summary,
        lotto6ConfirmBettingNow, deleteLotto6Betting,
        fetchLotto6RecentBetReport,
        fetchUserBalance
    } = props;

    const [isEnableBetNow, setIsEnableBetNow] = useState(true);

    const confirmDelete = (index) => {
        deleteLotto6Betting({id: index})
    }


    const submitBetNow = (isPrint) => {
        isEnableBetNow && lotto6ConfirmBettingNow(() => {
            setIsEnableBetNow(false)
        }).then(() => {
            fetchLotto6RecentBetReport().then(() => isPrint && document.getElementById("print-button").click());
            fetchUserBalance();
            setIsEnableBetNow(true)
        }).catch((error) => {
            setIsEnableBetNow(true)
            const {response} = error;
            response && swal(response.data.message, {icon: "error", button: "បិទ"});
        })
    }


    if (_.isEmpty(betList))
        return null;
    return <div className='scroll-recent-ticket'>
        <div
            className="col no-padding-div header-div-title header-text header-div-blue-light block-header ">
                                                        <span
                                                            className="text-black kh-font-bold">{t("labels:common.recentOrder")}</span>
        </div>

        <div
            className="col no-padding-div header-div-title header-text header-div-white">
            <div className="table-responsive fade-in">
                <table className="table table-current-bet">
                    <thead>
                    <tr>
                        <th className="text-center">{t("labels:common.no")}</th>
                        <th className="text-center">{t("labels:common.betType")}</th>
                        <th className="text-center">{t("labels:common.betAmount")}</th>
                        <th className="text-center">{t("labels:common.delete")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        betList.map((item, index) => {
                            return (
                                <tr key={index} className="line-height-betting">
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center"> {t("labels:drawing.drawPrefix." + item.betTypeCode)} </td>
                                    <td className="text-center">
                                        {/*<NumberFormat*/}
                                        {/*value={item.amount} displayType={'text'}*/}
                                        {/*thousandSeparator={true}*/}
                                        {/*suffix={" " + user.userBalance.currencySignal}/> */}
                                        {moneyFormatter(item.amount, user.userBalance.currencyCode)} x {item.rebateRate}
                                    </td>
                                    <td className="text-center">
                                        <button
                                            disabled={!isEnableBetNow}
                                            onClick={() => confirmDelete(index)}
                                            className="btn-remove-betting">
                                            <svg width="1em" height="1em"
                                                 viewBox="0 0 16 16"
                                                 className="bi bi-trash"
                                                 fill="currentColor"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                <path fillRule="evenodd"
                                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr className="header-div-blue-light block-header  line-height-betting">
                        <td colSpan={2} className="text-left kh-font-bold pl-3">
                            <b>{t("labels:common.total")}</b></td>
                        <td className="text-center">
                            <b>
                                {/*<NumberFormat*/}
                                {/*    value={summary.total}*/}
                                {/*    displayType={'text'} thousandSeparator={true}*/}
                                {/*    suffix={" " + user.userBalance.currencySignal}*/}
                                {/*/>*/}
                                {moneyFormatter(summary.total, user.userBalance.currencyCode)}
                            </b>
                        </td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <BetTweenButton t={t} isEnableBetNow={isEnableBetNow} submitBetNow={submitBetNow}/>


    </div>
}

const mapStateToProps = state => ({
    ...state.lotto6.bettingLotto6
})


export default withTranslation()(connect(
    mapStateToProps,
    {fetchLotto6ResultReport}
)(RecentOrdering));