import React, {Component} from "react";
import _ from "lodash";
import 'moment-timezone';
import {getDateTime, getTime} from "../../utils/dateUtils";
import {moneyFormatter} from "../../utils/MoneyUtils";
import Images from "../../constants/lotto5Images";
import Printer from "./print/Printer";
import {currencyCode} from "../../constants/general";

class Lotto90WinReportNewRow extends Component {

    render() {
        const {t, tickets, onShowDetail} = this.props;

        return <table className="table-custom">
            <thead>
            <tr>
                <th className="text-center">{t("labels:lmhsb.no")}</th>
                <th className="text-center">{t("labels:lmhsb.ticket_no")}</th>
                <th className="text-center">{t("labels:lmhsb.draw_at")}</th>
                <th className="text-center">{t("labels:lmhsb.ticket.print_at")}</th>
                <th className="text-center">{t("labels:lmhsb.bet")}</th>
                <th className="text-center">{t("labels:lmhsb.win")}</th>
                <th className="text-center">{t("labels:lmhsb.win_lose")}</th>
                <th className="text-center">{t("labels:action")}</th>
            </tr>
            </thead>
            <tbody>
            {
                _.isEmpty(tickets) && <tr>
                    <td colSpan={8}><img className="data-not-found-img"
                                         src={Images.dataNotFound} alt="..."/></td>
                </tr>
            }
            {
                !_.isEmpty(tickets) && tickets.map((object, index) => {
                    const isRelease = object.status === 2;
                    if (!isRelease) object.detail = Array(5).fill("?").join(",");
                    return <React.Fragment key={index}>
                        <tr className="bg-tr-his">
                            <td colSpan={8}>
                                <div className="d-flex pl-5">
                                                                <span
                                                                    className="mr-3 f-bold">{object.drawLabel}</span>
                                    {
                                        object?.detail?.split(",").map((row, j) => {
                                            return <span
                                                className="f-16 font-weight-bold result-number-cycle"
                                                key={j}>{row}</span>
                                        })}
                                    <span
                                        className="ml-3 f-result font-weight-bold">{isRelease ? object.result : "??"}</span>
                                </div>
                            </td>
                        </tr>
                        {
                            object?.items.map((obj, i) => {
                                obj.drawCode = object.drawCode;
                                obj.drawAt = object.drawAt;
                                obj.drawStatus = object.status;
                                obj.drawLabel = object.drawLabel;
                                return <tr key={i} className="animate-slide-up">
                                    <td
                                        className="text-center align-middle">{i + 1}</td>
                                    <td
                                        className="text-center align-middle f-bold"
                                        onClick={() => onShowDetail(obj)}>
                                        #{obj?.ticketNumber}
                                    </td>
                                    <td>{getDateTime(obj?.drawAt)}</td>
                                    <td>{getTime(obj?.createdAt)}</td>
                                    <td>{moneyFormatter(obj?.totalAmount, currencyCode(), "text-primary")}</td>
                                    <td className="text-danger">{isRelease ? moneyFormatter(obj?.winAmount, currencyCode(), "text-danger") : "???"}</td>
                                    <td className="text-primary">{isRelease ? moneyFormatter(obj?.winLoseAmount, currencyCode(), obj?.winLoseAmount > 0 ? "text-primary" : "text-danger") : "???"}</td>
                                    <td className="text-center align-middle">
                                        <Printer object={obj} drawLabel={object.drawLabel}   {...this.props}/>
                                    </td>
                                </tr>
                            })
                        }
                    </React.Fragment>
                })
            }
            </tbody>
        </table>
    }
}

export default (Lotto90WinReportNewRow);
