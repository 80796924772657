export const LOTTO12_SET_BET_STATUS = "LOTTO12_SET_BET_STATUS"
export const LOTTO12_SET_RENDER_RESULT_STATUS = "LOTTO12_SET_RENDER_RESULT_STATUS"
export const LOTTO12_SET_TOTAL_RESULT_NUMBER = "LOTTO12_SET_TOTAL_RESULT_NUMBER"

export const LOTTO12_REQUEST_LIST_BETTING = "LOTTO12_REQUEST_LIST_BETTING"
export const LOTTO12_REQUEST_FETCH_DRAWING_RESULT = "LOTTO12_REQUEST_FETCH_DRAWING_RESULT"
export const LOTTO12_REQUEST_FETCH_RECENT_BET_REPORT = "LOTTO12_REQUEST_FETCH_RECENT_BET_REPORT"
export const LOTTO12_REQUEST_FETCH_WIN_LOSS_REPORT = "LOTTO12_REQUEST_FETCH_WIN_LOSS_REPORT"
export const LOTTO12_REQUEST_FETCH_RESULT_REPORT = "LOTTO12_REQUEST_FETCH_RESULT_REPORT"
export const LOTTO12_STATISTIC = "LOTTO12_STATISTIC";
export const LOTTO12_REQUEST_FETCH_RECENT_DRAW = "LOTTO12_REQUEST_FETCH_RECENT_DRAW";

export const LOTTO90_REQUEST_FETCH_WIN_LOSS_REPORT = "LOTTO90_REQUEST_FETCH_WIN_LOSS_REPORT"
export const LOTTO90_REQUEST_FETCH_RESULT_REPORT = "LOTTO90_REQUEST_FETCH_RESULT_REPORT"