import React from "react";
import {moneyFormatter} from "../../utils/MoneyUtils";

const PrintItems = (props) => {
    const {object, t, currency, status, isChinese} = props;
    const {betCode, betAmount, betValue, winAmount, isJackpot, jackpot} = object;

    const RenderTR = ({label, item, subItem}) => {
        return <div style={{lineHeight: "35px"}} key={label} className="d-flex m-0 align-items-center">
            <span style={{width: "30%"}}
                  className={isChinese ? "font-weight-none text-right f-bayon" : "font-weight-bold text-right f-bayon f-22 p-0 m-0"}>{t(`labels:ticket.${label}`)}</span>
            <span className="f-bayon f-22">&nbsp;:&nbsp;</span>
            {
                label === "bet_point" ?
                    <span className="text-center">
                        {moneyFormatter(item, currency, "text-black f-bayon f-22 font-weight-bold")} <span
                        className="f-bayon f-22 font-weight-bold">X {subItem}</span>
                    </span> :
                    <span className="f-bayon font-weight-bold f-22 align-items-center text-center">
                        {item}
                    </span>
            }
        </div>
    };


    return <tr>
        <td className="bb-solid-1 p-0">
            <RenderTR label="bet_type" item={t("labels:betPrefix." + betCode)}/>
            <RenderTR label="bet_point" item={betAmount} subItem={betValue}/>
            {isJackpot && <RenderTR label="jackpot" item={`x${jackpot}`}/>}
            <RenderTR label="win"
                      item={status ? moneyFormatter(winAmount, currency, "text-black f-bayon f-22 font-weight-bold") : "????"}/>
        </td>

    </tr>
};

export default PrintItems;
