import {gql} from "@apollo/client";

export const getFirstLabel = (code) => {
    switch (code) {
        case "UNDER":
            return null;
        case "OVER":
            return null;
        case "ONE":
            return "1";
        case "TWO":
            return "2";
        case "THREE":
            return "3";
        case "FOUR":
            return "4";
        case "FIVE":
            return "5";
        case "SIX":
            return "6";
        default:
            return null;
    }
}

export const SUBSCRIPTION_LOTTO6 = gql`
        subscription Lotto5Sub {
            v_lotto6_drawing {
                 drawCode
                 status
            }
           }`;