import React from "react";
import {withTranslation} from "react-i18next";
import {CustomInput, UncontrolledTooltip} from "reactstrap";

class CustomCheckbox extends React.Component {
    render() {
        const {
            tooltip,
            id,
            title,
            onChange,
            checked,
            className,
            onMouseDown,
            disabled,
            isMargin = true,
            key,
            isPost
        } = this.props;
        return (
            <div key={key} className={`d-inline-flex align-items-center ${className} ${isMargin ? "mr-3" : ""}`}>
                <CustomInput onMouseDown={onMouseDown} name={id} id={id} onChange={onChange} checked={checked}
                             disabled={disabled} type="checkbox"/>
                <button className='text-black-50 border-0 p-0 m-0 bg-white'>
                    <div id={`tooltip${id}`} className={`${isPost ? "text-primary font-weight-bold" : ""} mb-1`}
                         style={{marginTop: "5px"}}>{title}</div>
                    {tooltip && <UncontrolledTooltip placement="bottom" target={`tooltip${id}`}>
                        {tooltip}
                    </UncontrolledTooltip>}
                </button>
            </div>
        );
    }
}


export default withTranslation()(CustomCheckbox);