import React from "react";
import "../../assets/css/lotto199.css";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    clearAllInv, createBetting,
    drawingNumber,
    fetchLO99DrawingResult,
    fetchLO99RecentDraw, fetchLotto99RecentTicket, fetchLotto99ResultReport,
    fetchLotto99WinLossReport
} from "../../store/lotto99/action";
import {fetchUserBalance, getRecentSize} from "../../store/user/action";
import {clientAp} from "../../constants/general";
import {SUBSCRIPTION_LOTTO99} from "../../constants/lotto99";
import BettingDashboardWrapper from "../lotto99/bet/BettingDashboard";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";

class Dashboard extends React.Component {

    state = {
        status: undefined,
    }

    componentDidMount() {
        this.subscription();
        this.refreshData();
        this.props.fetchLO99RecentDraw();
    }


    refreshData = () => {
        const {
            fetchLO99DrawingResult,
            fetchUserBalance,
            fetchLotto99RecentTicket
        } = this.props;
        fetchLotto99RecentTicket({size: getRecentSize().lo99})
        fetchLO99DrawingResult();
        fetchUserBalance();
    }


    onDrawStatusChange = (currentDraw) => {
        const {status} = this.state;
        if (currentDraw.status !== status) {
            if (status && currentDraw.status === "OPEN") {
                this.refreshData();
                this.props.fetchLO99RecentDraw(10, false);
            }
            if (status && currentDraw.status === "RELEASE") {
                this.props.fetchLO99DrawingResult();
                this.props.fetchLO99RecentDraw(9, false);
            }
            this.setState({status: currentDraw.status})
        }

    }

    subscription = () => {
        if (socketLottoConfig.l99) {
            socket.on(socketEvent.l99, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            this.sub = clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO99,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto99_drawing) {
                        let currentDraw = event.data.v_lotto99_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    componentWillUnmount() {
        this.sub && this.sub.unsubscribe();
        clearAllInv();
        removeSocketListener(socketEvent.l99);
    }

    isBet = () =>
        document.location.pathname === "/lottery1/99"

    render() {

        return <div className="dash-wrapper l199">
            {/*<LeftSide/>*/}
            {/*<RightSide/>*/}

            <BettingDashboardWrapper status={this.state.status} {...this.props}/>
        </div>
    }


}

const mapStateToProps = state => ({
    ...state.lotto99,
    config: state.config,
    audio: state.audio,
    user: state.user,
    drawing: state.lotto99.drawing
})

export default withTranslation()(connect(
    mapStateToProps,
    {
        fetchLotto99WinLossReport,
        fetchLO99DrawingResult,
        fetchLO99RecentDraw,
        fetchUserBalance,
        clearAllInv,
        drawingNumber,
        fetchLotto99RecentTicket,
        fetchLotto99ResultReport,
        createBetting
    }
)(Dashboard));
