import Balance from "../../../pages/components/Balance";
import RecentTicket from "../bet/RecentTicket";
import lo90_bg from "../../../assets/images/lotto90/lotto90_v1_bg.png";
import {getDate} from "../../../utils/dateUtils";
import CountdownTimer from "../CountdownTimer";
import bg_drawing_img from "../../../assets/images/lotto90/lotto90_drawing_bg.png";
import BallNumbers from "./BallNumber";
import ResultHistoryV1 from "./ResultHistoryV1";
import React, {useState} from "react";
import {env} from "../../../env";
import {imgUrl} from "../../../utils/asset";
import {inIframe} from "../../../constants/general";

let platformType = env.PLATFORM_TYPE;


const DashboardV1 = (props) => {
    const {
        drawing: {number, isDrawing, detail, tempIndex},
        resultReport: {lastResult},
        oldResult,
        newResult,
        countDownTimer,
        user: {userBalance},
        t,
        BettingCard,
        playSound,
        onMouseDown,
        betting,
        onMouseEnter,
        onMouseUp
    } = props;

    const [isFull, setFull] = useState(false);

    const toggleFull = () => setFull(!isFull);
    const isInIframe = inIframe();

    return <div className="lo90-v1-wap">
        <div className="lo90-v1-l-side border">
            <div className="rounded">
                <Balance pendingAmount={userBalance.pendingLotto90}/>
                <RecentTicket/>
            </div>
        </div>
        <div className="lo90-v1-mid-side lotto90-bet-v1 position-relative">
            <div style={{backgroundImage: `url(${lo90_bg})`}} className={`${isFull ? "d-board-Fullscreen row m-0" : "lo90-v1-drawing-board position-relative"}`}>
                {!isInIframe && <img
                    onClick={toggleFull}
                    className="l199-fullscreen pointer"
                    src={imgUrl(`lotto12v2/${isFull ? 'exit_fullscreen' : 'fullscreen'}.png`)}
                    alt=""/>}
                <div className={`${isFull ? "col-9 position-absolute top-0" : "d-flex justify-content-between"}`}>
                    <div>
                        <div className="d-flex align-items-center logo">
                                <span><img src={require(`../../../assets/images/logo/${platformType}.png`)}
                                           alt="logo"/></span><span
                            className="lotto-name kh-font-battambang font-moul">៩០ សំណាង</span>
                        </div>
                        <div className={`${isFull ? "text-left" : ""}`}>
                                  <span
                                      className="p-2 date-sec">{getDate(oldResult?.drawAt)}</span>
                            <span
                                className="p-2 ticket-sec">{oldResult?.drawCode}</span>
                        </div>
                    </div>
                    <div className={`${isFull ? "draw-timer-wrap draw-time-top r-vw position-absolute" : "draw-timer-wrap"}`}>
                        {!isDrawing && newResult ? <>
                                <CountdownTimer playSound={playSound} isDrawing={isDrawing} time={countDownTimer}/>
                                <span
                                    className="p-2-vw date-sec">{getDate(newResult?.drawAt)}</span>
                                <span
                                    className="p-2-vw ticket-sec">{newResult?.drawCode}</span>
                            </>
                            : <div className="time-sec-title">{t("labels:drawing.lotto90.drawing")}</div>}

                    </div>

                </div>
                <div className={`${isFull ? "col-8 position-absolute t-vw l-0 lotto90-bg" : "lo90-v1-drawing"}`}>
                    <img className="draw-img " src={bg_drawing_img} alt="__"/>
                    <div className="draw-number">
                        {oldResult && oldResult?.detail?.split(",").map((e, i) => {
                            let show;
                            const ps = oldResult.posts[i].postCode;
                            if (oldResult?.posts) {
                                if (isDrawing) {
                                    if (i === tempIndex)
                                        show = number === "?" ? "" : number.toString().padStart(2, "0");
                                    else if (!detail.includes(parseInt(e)))
                                        show = ""
                                    else show = e;
                                } else show = e;
                            }
                            return <div className={`number-render lotto90-post-${ps}`} key={i}>
                                {show}
                            </div>
                        })}
                    </div>


                    {
                        isFull && <BallNumbers
                            isFull={isFull}
                            onMouseDown={onMouseDown}
                            betting={{...betting}}
                            onMouseEnter={onMouseEnter}
                            onMouseUp={onMouseUp}
                            isDrawing={isDrawing}
                            {...props}
                        />
                    }
                </div>
                {
                    isFull && <div className="col-4 position-absolute r-0">
                            <ResultHistoryV1 isDrawing={false} items={lastResult} number={number} t={t}/>
                    </div>
                }
            </div>

            <BallNumbers
                onMouseDown={onMouseDown}
                betting={{...betting}}
                onMouseEnter={onMouseEnter}
                onMouseUp={onMouseUp}
                isDrawing={isDrawing}
                {...props}
            />
        </div>
        <div className="lo90-v1-r-side border">
            {BettingCard()}
            <ResultHistoryV1 isDrawing={false} items={lastResult} number={number} t={t}/>
        </div>
    </div>
}
export default DashboardV1;