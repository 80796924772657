import DrawResult from "./DrawResult";
import RecentBetting from "./RecentBetting";
import React from "react";
import RecentOrdering from "./RecentOrdering";
import Balance from "../../pages/components/Balance";
import RightBoard from "./RightBoard";

export default (props) => {
    const {user: {userBalance}} = props;
    return <div className="wrap-keno-content">

        <div className="row recent-betting-table m-0">

            {/* betting history left side */}
            <div className="col-3 border no-padding-div">

                {/* user balance */}
                <Balance pendingAmount={userBalance.pendingLotto6}/>
                {/* End of user balance */}

                {/* Last drawing */}
                <DrawResult/>
                {/* End of last drawing */}

                {/* Recent Ordering */}
                <RecentOrdering {...props}/>

                {/* End of recent ordering */}

                {/* Recent betting */}
                <RecentBetting/>
                {/* End of recent betting */}

            </div>

            {/* betting dashboard and pricing */}
            <RightBoard {...props}/>


        </div>


    </div>;
}