import {LOTTO99_REQUEST_FETCH_WIN_LOSS_REPORT} from "./types";

const init = {
    data: {
        tickets: [],
        filter: {},
        page: 0,
        size: 0,
        totals: 0
    }
}


export default (state = init, {type, payload}) => {
    switch (type) {
        case LOTTO99_REQUEST_FETCH_WIN_LOSS_REPORT:
            return {
                ...state,
                data: payload
            }
        default:
            return state;
    }
}