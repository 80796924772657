import axios from "axios";
import {
    LIST_DRAWING_LO99,
    LO99_LIST_RECENT_DRAW, LOTTO99_RECENT_TICKET,
    LOTTO99_REQUEST_FETCH_RESULT_REPORT,
    LOTTO99_REQUEST_FETCH_WIN_LOSS_REPORT,
} from "./types";
import API_LO99 from "../../endpoints/lotto99";
import {imgUrl} from "../../utils/asset";
import {playAudio, requireAudio} from "../../constants/Audio";
import {getMute} from "../../utils/cached";
import {getDateDef} from "../../utils/dateUtils";
import {getResult} from "../../utils/general";
import {padNumber2} from "../../constants/general";
import {delay} from "../../utils/interval";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import API_LOTTO99 from "../../endpoints/lotto99";


let borderInv;
let drawingSound = new Audio(requireAudio("lotto12v2/drawBgm.mp3"));

let random;
let startDrawing;
let drawingTime;

const playDrawingSound = () => {
    if (getMute() !== "ON") {
        drawingSound.play().then((_) => {
        }).catch((_) => {
        }).finally(() => {
        });
    }
}


function getIndexNumber(time) {
    if (time <= 0) {
        time *= -1;
        if (time > 24) return 5;
        if (time >= 8) {
            time -= 8;
            return Math.ceil(time / 3);
        }
        return 0;
    } else
        return 5;
}


const config = {
    postInv: 3500,
    randomInv: 10,
    randomTimeOut: 3100,
    delayTime: 8,
    borderInv: 200,
    randomMax: 100
}

const verifyDraw = (data, dispatch) => {

    if (data !== null) {
        if (data.oldResult != null) {
            const timeCount = data.countDownTimer;
            data.actives = [];
            data.detail = [];
            data.isWin = false;
            clearAllInv();
            if (timeCount > 0) data.countDownTimer = 0;
            data.isDrawing = true;
            data.isStopBet = true;
            data.drawCode = data.oldResult.drawCode;
            data.drawAt = data.oldResult.drawAt;
            dispatch({
                type: LIST_DRAWING_LO99,
                payload: data
            });
            drawingNumber(data, dispatch).then(r => r);
        } else {
            data.isStopBet = false;
            data.isDrawing = false;
            dispatch({
                type: LIST_DRAWING_LO99,
                payload: data
            });
        }

    }
}


const clearAllInv = () => {
    random && clearInterval(random);
    startDrawing && clearTimeout(startDrawing);
    drawingTime && clearTimeout(drawingTime);
    borderInv && clearInterval(borderInv);
    drawingSound && drawingSound.pause();
}


const borderAnimate = () => {
    let border = 1;
    borderInv && clearInterval(borderInv);
    borderInv = setInterval(() => {
        if (border === 1) border = 2
        else border = 1;
        let doc = document.getElementById("img-border-outline-l99");
        if (doc) doc.src = imgUrl(`lotto99/deng${border}.png`);
    }, config.borderInv);
}


const drawingNumber = async (data, dispatch) => {
    let {oldResult, countDownTimer} = data;
    let indexR = getIndexNumber(countDownTimer);
    countDownTimer = Math.abs(countDownTimer);

    let index = indexR;
    let tempIndex = index + 1;

    borderAnimate();
    playDrawingSound();


    if (indexR < 5) {
        if (indexR > 0) index = indexR - 1;
        let {allNumbersDrawing} = getResult(index, oldResult);
        let list = allNumbersDrawing.map(e => padNumber2(e));
        let actives = allNumbersDrawing.map(e => padNumber2(e));

        let indd = 5 - list.length;
        for (let i = 0; i < indd; i++) {
            list.push("");
            actives.push("");
        }

        const randomNumber = () => {
            index++;
            let resultItem = 1
            random && clearInterval(random);
            random = setInterval(() => {
                if (resultItem === config.randomMax) {
                    resultItem = 1
                }
                list[index - 1] = padNumber2(resultItem++);
                dispatch({
                    type: LIST_DRAWING_LO99,
                    payload: {
                        detail: list,
                        actives: actives
                    }
                });
            }, config.randomInv)
            startDrawing && clearTimeout(startDrawing);
            startDrawing = setTimeout(() => {
                random && clearInterval(random)

                let {resultItem} = getResult(index, oldResult);
                let number = padNumber2(resultItem);
                list[index - 1] = number;
                actives[index - 1] = number;
                tempIndex = index + 1;
                dispatch({
                    type: LIST_DRAWING_LO99,
                    payload: {
                        number: number,
                        index: index,
                        tempIndex: tempIndex,
                        detail: list,
                        actives: actives,
                    }
                });
                playAudio("lotto12v2/result.mp3")
                startDrawing && clearTimeout(startDrawing);

            }, config.randomTimeOut);
        }
        await delay((config.delayTime - countDownTimer) * 1000);

        randomNumber();
        drawingTime = setInterval(() => {
            if (index < 5) {
                randomNumber()
                tempIndex++;
            } else {
                drawingTime && clearInterval(drawingTime);
                random && clearInterval(random)
                drawingSound.pause();
            }
        }, config.postInv)
    } else {
        const res = oldResult?.detail?.split(",").map(e => padNumber2(e)) ?? [];
        dispatch({
            type: LIST_DRAWING_LO99,
            payload: {
                isDrawing: true,
                actives: res,
                detail: res
            }
        });
    }


}

const fetchLO99DrawingResult = () => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LO99.FETCH_LO99_DRAWING_RESULT);
        let data = response.data.data;
        // let data = drawing90;
        verifyDraw(data, dispatch);
        return response;
    } catch (error) {
        return await Promise.reject(error);
    }
};


const fetchLO99RecentDraw = (size = 10, isSetRelease = true) => (dispatch) => {
    return axios
        .get(API_LO99.FETCH_LO99_RESULT_REPORT, {
            params: {
                page: 1,
                size: size,
                filterDate: getDateDef(),
            }
        })
        .then((response) => {
            let data = response.data.data;
            const newFetch = data.length > 9;

            dispatch({
                type: LO99_LIST_RECENT_DRAW,
                payload: newFetch ? data.slice(-(data.length - 1)) : data
            });

            const draw = data[0];
            (isSetRelease && draw) && dispatch({
                type: LIST_DRAWING_LO99,
                payload: {
                    detail: draw.detail?.split(","),
                    drawCode: draw.drawCode,
                    drawAt: draw.drawAt,
                }
            });
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const fetchLotto99WinLossReport = (payload) => (dispatch) => {
    dispatch(showLoading("sectionBar"));

    return axios.get(API_LO99.FETCH_LO99_WIN_LOSS_REPORT, {
        params: {size: 10, ...payload}
    })
        .then((response) => {
            const paging = response.data.paging;
            const data = response.data.data;
            dispatch({
                type: LOTTO99_REQUEST_FETCH_WIN_LOSS_REPORT,
                payload: {
                    tickets: data,
                    filter: payload,
                    ...paging
                },
            });

            dispatch(hideLoading("sectionBar"));

        })
        .catch((error) => {
            dispatch(hideLoading("sectionBar"));
            return Promise.reject(error);
        });
};

const fetchLotto99ResultReport = (payload = {}) => async (dispatch) => {

    try {
        const response = await axios
            .get(API_LO99.FETCH_LO99_RESULT_REPORT, {
                params: {
                    page: 1,
                    size: 16,
                    filterDate: getDateDef(),
                    ...payload
                }
            });
        const paging = response.data.paging;
        const data = response.data.data;
        dispatch({
            type: LOTTO99_REQUEST_FETCH_RESULT_REPORT,
            payload: {
                items: data,
                filter: payload,
                ...paging
            },
        });

    } catch (error) {
        return await Promise.reject(error);
    }

};

const fetchLotto99RecentTicket = (payload = {}) => (dispatch) => {
    return axios.get(API_LOTTO99.FETCH_LO99_RECENT_BET_REPORT, {
        params: {
            page: 1,
            size: 3,
            filterByLotto: "LOTTO99",
            groupByDraw: "YES",
            ...payload
        }
    })
        .then((response) => {
            dispatch({
                type: LOTTO99_RECENT_TICKET,
                payload: response.data.data
            });
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const createBetting = (payload) => (dispatch) => {
    return axios
        .post(API_LOTTO99.LO99_BETTING_NOW, payload)
        .then(async (response) => {
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export {
    fetchLO99DrawingResult,
    fetchLO99RecentDraw,
    clearAllInv,
    fetchLotto99WinLossReport,
    fetchLotto99ResultReport,
    drawingNumber,
    fetchLotto99RecentTicket,
    createBetting
}


