import React from "react";
import PrintTicket58 from "./PrintTicket58";
import {withTranslation} from "react-i18next";
import {moneyFormatter} from "../../../utils/MoneyUtils";
import {getFullDateTimePrint} from "../../../utils/dateUtils";
import {currencyCode} from "../../../constants/general";

class Ticket58 extends React.Component {

    render() {

        const {object, t} = this.props;

        return (
            <div id="print-58">
                <table className='print-tb-58 table' cellPadding="7px">
                    <tbody>
                    <tr className="bb-solid-1">
                        <td style={{lineHeight: "45px", paddingRight: 0, paddingLeft: 0, fontSize: "30px"}} colSpan="3"
                            className='text-center f-bayon font-weight-bold'>សម្បត្តិ ១/៩៩សំណាង
                        </td>
                    </tr>

                    <tr>
                        <td className="f-bayon text-center bb-solid-1 p-0 l-h-25">
                            <div
                                className="f-bayon f-22 text-center align-items-center p-0">{getFullDateTimePrint(object?.createdAt)}</div>
                            <div style={{letterSpacing: "-0.5px"}}
                                 className="f-bayon f-22 p-0">#{object?.ticketNumber} / {object?.drawLabel}</div>
                        </td>
                    </tr>

                    <tr>
                        <td colSpan='3' className='p-0 bb-solid-1'>
                            <PrintTicket58 bodyClass='f-t' postGroup={object?.groupByPosts}/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{lineHeight: "30px"}} colSpan={3}
                            className="p-0 f-25 text-center text-black f-bayon bb-solid-1">{t('labels:ticket.total_bet')} : {moneyFormatter(object?.totalAmount, currencyCode(), "p-0 f-25 f-bayon")}</td>
                    </tr>
                    <tr>
                        <td style={{lineHeight: "30px"}} colSpan={3}
                            className="p-0 f-25 text-center text-black f-bayon bb-solid-1">{t('labels:ticket.total_win')} : {
                            object?.drawStatus < 2 ? "???" : moneyFormatter(object?.winAmount, currencyCode(), "p-0 f-25 f-bayon")
                        }</td>
                    </tr>
                    <tr>
                        <td>-</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(Ticket58);
