import Balance from "../../pages/components/Balance";
import RecentTicket from "./bet/RecentTicket";
import Header from "./Header";
import {BetType} from "../../constants/general";
import SpecialX from "./bet/SpecialX";
import BallNumbers from "./bet/BallNumber";
import ResultHistory from "./ResultHistory";
import React from "react";

const BettingBoard = (props) => {
    const {
        userBalance,
        oldResult,
        isDrawing,
        newResult,
        countDownTimer,
        playSound,
        onChangeValue,
        betType,
        onClickSpecial,
        rangeBetting,
        onMouseDown,
        betting,
        onMouseEnter,
        onMouseUp,
        bItems,
        number,
        config,
        t,
        BettingCard
    } = props;

    return <div id="lotto90" className="col no-padding-div none-select mt-2 lotto90-bet ml-3">
        <div className="row col no-padding-div justify-content-center">
            <div className="col-3">
                <div className="border rounded">
                    <Balance pendingAmount={userBalance.pendingLotto90}/>
                    {BettingCard}
                    <RecentTicket/>
                </div>
            </div>
            <div className="col-6 no-padding-div pad-r-3">
                <div className="left-side">
                    <div className="lo90-drawing">
                        <div className="p-body ml-auto mr-auto">
                            <Header oldResult={oldResult} isDrawing={isDrawing}
                                    newResult={newResult} countDownTimer={countDownTimer}
                                    playSound={playSound}/>
                            <div onChange={onChangeValue} className="text-black f-bold f-20 mt-5">
                                <label htmlFor="radio1" className="f-25">
                                    <input type="radio" defaultChecked={betType === BetType.NORMAL}
                                           value={BetType.NORMAL}
                                           name="betType" id="radio1"/> {t("labels:drawing.lotto90.number")}
                                </label>

                                <label htmlFor="radio2" className="f-25">
                                    <input className="ml-5 f-20" defaultChecked={betType === BetType.RANGE}
                                           type="radio"
                                           value={BetType.RANGE} name="betType"
                                           id="radio2"/> {t("labels:drawing.lotto90.range")}
                                </label>
                                {/*<input className="ml-5" type="radio" defaultChecked={betType === BetType.SPECIALX}*/}
                                {/*       value={BetType.SPECIALX} name="betType"/> {t("labels:drawing.lotto90.special")} X*/}
                            </div>
                            {betType === BetType.SPECIALX
                                ? <SpecialX configs={config.lotto90Config}
                                            onClick={onClickSpecial}
                                            rangeBetting={rangeBetting}/>
                                : <BallNumbers onMouseDown={onMouseDown}
                                               betting={{...betting, betType}}
                                               onMouseEnter={onMouseEnter}
                                               onMouseUp={onMouseUp}/>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3 no-padding-div pad-l-3">
                <ResultHistory isDrawing={false} items={bItems} number={number} t={t}/>
            </div>
        </div>
    </div>
}

export default BettingBoard;