const togglePrint = (value) => {
    let val = value ? "autoPrint" : null;
    return localStorage.setItem("autoPrint", val);
}

const getAutoPrint = () => localStorage.getItem("autoPrint") === "autoPrint";

export {
    togglePrint,
    getAutoPrint
}