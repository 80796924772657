import {
    LOTTO90_REQUEST_FETCH_RESULT_REPORT,
    LOTTO90_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO90_REQUEST_FETCH_DRAWING_RESULT, LOTTO90_SET_DRAWING, LOTTO90_RECENT_TICKET,
} from "./constant";

const initialState = {
    isStopBet: false,
    isStopRender: true,
    indexNumber: null,

    drawing: {
        number: 0,
        index: 0,
        total: 0,
        detail: [],
        tempIndex: 0,
        isDrawing: false,
    },

    tickets: [],

    oldResult: {},
    newResult: {},
    resultReport: {
        items: [],
        lastResult: [],
        filter: {},
        bItems: [],
        page: 0,
        size: 0,
        totals: 0
    },

    winReport: {
        tickets: {},
        summary: {
            totalBetAmount: 0,
            totalWinAmount: "",
            totalWinLoseAmount: "",
            currencyCode: ""
        },
        page: 0,
        size: 0,
        totals: 0
    },

}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case LOTTO90_REQUEST_FETCH_RESULT_REPORT:
            let item = action.payload.items.slice(0, 1)[0];
            let items = action.payload.items.slice(1);
            const lastResult = items.slice(0, 6);
            return {
                ...state,
                oldResult: state.drawing.isDrawing ? state.oldResult : item,
                resultReport: {
                    ...state.resultReport,
                    items: items,
                    bItems: action.payload.items,
                    lastResult: lastResult,
                    ...action.payload.paging
                }
            }
        case LOTTO90_REQUEST_FETCH_WIN_LOSS_REPORT:
            return {
                ...state,
                winReport: {
                    ...state.winReport,
                    ...action.payload
                }
            }
        case LOTTO90_RECENT_TICKET:
            return {
                ...state,
                tickets: action.payload
            }
        case LOTTO90_REQUEST_FETCH_DRAWING_RESULT:
            return {
                ...state,
                ...action.payload,
            }
        case LOTTO90_SET_DRAWING:
            return {
                ...state,
                drawing: {
                    ...state.drawing,
                    ...action.payload
                },
            }
        default:
            return state
    }
}

export default reducer;
