import React from "react";
import CountDownTimer from "./../../timer/CountDownTimer";
import {getShortBetType} from "../../../utils/tickets/printUtils";
import ResultContentListMix from "./ResultContentListMix";
import DatetimeContainer from "../../date/DatetimeContainer";
import {env} from "../../../env";
import LoadingGif from "../../../utils/LoadingGif";
import {getOECode, isOddEven, isOE, pfType999Color} from "../../../constants/general";

export default (props) => {
    const {
        platform, t, oldResult, showJackpotValue, toggleMute, config: {
            lotto5Config
        }, winRange, jackpot
    } = props;
    // const jackpot = oldResult?.jackpot;
    const listOverUnder = isOddEven ? lotto5Config.overUnder : lotto5Config.overUnder.filter(e => !isOE(e.code));
    const jackpots = oldResult?.jackpots;
    const isShow = (code) => jackpots?.includes(code) && showJackpotValue;
    let platformType = env.PLATFORM_TYPE;
    const jp = oldResult?.jackpot;

    return <div>
        <div className="row lotto5-dispaly-result no-margin-div">
            <div
                className={"no-padding-div col-2 loading-gif loading-gif-" + platform + " " + pfType999Color + " header-label head-flex-mix"}>
                <div className="head-flex-mix-label custom-lo5-mix-title">{t("labels:menus.lotto")} 5</div>
            </div>
            <div
                className={"col-10 no-padding-div d-inline-flex bet-period-timer loading-gif loading-gif-" + platform + " " + pfType999Color +  " lotto5-header-" + platform}>

                <p id="period-before"
                   className="w-75 text-left head-flex-mix-label"><LoadingGif
                    className="w-load-mix"/>{props.newResult !== null ? props.newResult.drawLabel : t("labels:upcoming_draw")}
                </p>
                <p id="timer-display-mix" className="float-right head-flex-mix head-flex-mix-label"><DatetimeContainer/>
                </p>
                <div className="mr-2">
                    <img
                        onClick={toggleMute}
                        alt="audio"
                        className="pr-2 mr-2 w-load-mix"
                        style={{cursor: "pointer"}}
                        src={props.isMute === "ON" ? require("../../../assets/images/audio/mute_red.svg") : require("../../../assets/images/audio/volume_red.svg")}/>
                </div>
            </div>

        </div>
        {/*under over*/}
        <div className="row no-padding-div border-r">
            <div className="col-8 no-padding-div lotto5-mix-content-result lotto5-dispaly-result">

                <table
                    id="keno_result_post"
                    border="1"
                    cellPadding="0"
                    cellSpacing="0"
                    width="100%"
                >
                    <tbody>
                    <ResultContentListMix
                        allNumbersDrawing={props.allNumbersDrawing}/>
                    </tbody>
                </table>
            </div>

            <div className="col-4 p-0 card-border">
                <div className="" style={{height: "50%"}}>
                    <CountDownTimer
                        countClass=""  {...props}/>
                    <div className="">
                        {
                            props.indexResultItem > 20 || props.indexResultItem === 0
                                ? <div className="result-time-countup-render-mix bg-transparent"/>
                                : <div
                                    className="result-time-countup-render-mix">{props.indexResultItem}</div>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{height: "50%"}}>
                    <div className="random-play">
                        <div className="blgo-random-number">
                            <div
                                className={props.classNameNumberDraw}>
                                <span>{props.resultItem}</span>
                            </div>
                            <div
                                className={props.classNameLogoRender + " lotto5-show-logo-render"}>
                                <img
                                    className=""
                                    src={require(`../../../assets/images/logo/${platformType}.png`)}
                                    alt="..."/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="no-padding-div col-12 border-r">
            <div className="row card-border">
                <div className="col no-padding-div text-center block-header">
                    <label
                        className="under-over">{isOddEven ? t("labels:keno.underOverOddEven") : t("labels:keno.underOver")}</label>
                </div>
            </div>
            <div className="over-under-odd-even">
                <div className="group-play-box">

                    {
                        listOverUnder.map((item, i) => {
                                return <div
                                    className={"box-bet border box-bet-" + platform + "-" + item.code + " bet-color-" + platform}
                                    key={i}
                                    onClick={() => props.onBetting(item)}>
                                    <div id={"ou" + item.code}
                                         className="lotto5-bet">
                                        {isShow(item.code) &&
                                        <div className="jackpot-div">
                                            <img id={item.code}
                                                 width={40}
                                                 height={40}
                                                 src={require(`../../../assets/images/jackpot/${jackpot ?? 2}.png`)}
                                                 alt=""/>
                                        </div>}
                                        <p className={"type " + item.code + " OU-" + (item.code === props.winUO ? item.code : "")}>{t("labels:betPrefix.S_" + getShortBetType(item.code))}</p>
                                        {
                                            !isOE(item.code) ?
                                                <p className={`m-vw range bg-mix-range-${item.code}`}>{item.rangeNumber}</p> :
                                                <p className={`m-vw range bg-mix-range-${item.code}`}>{getOECode(item.code)}</p>
                                        }
                                        <p className={`odds m-vw bg-mix-range-${item.code}`}>{item.value}</p>
                                    </div>
                                </div>
                            }
                        )
                    }
                </div>
            </div>

            <div className="row card-border">
                <div className="col no-padding-div text-center block-header">
                    <label
                        className="under-over block-header">{t("labels:keno.range")}</label>
                </div>
            </div>

            <div className="no-padding-div">

                <div className="over-under-odd-even range-type">
                    <div className="group-play-box">
                        {

                            lotto5Config.ranges.map((item, i) => {
                                const isActive = (jackpot === i + 1) && oldResult;
                                const isWin = item.code === winRange;
                                const showJackpot = isWin && isActive;
                                const winClass = isWin ? "win-" + item.code : "";
                                let winCl = isWin ?
                                    isActive ? "win-bg-animate" : winClass
                                    : "";

                                return <div
                                    className={"box-bet box-bet-" + platform + "-" + i + " bet-color-" + platform}
                                    key={i}
                                    onClick={() => props.onBetting(item)}>
                                    <div id={"range" + item.elementId}
                                         className="lotto5-bet">
                                        <div
                                            className={`border-mix p-win border-b ${isActive ? "bg-win" : ""} ${winCl}`}>
                                            <p className={"type-rage-mix win-" + (item.code === props.winRange ? item.code : "")}>{item.name}</p>
                                        </div>
                                        <div>
                                            <div
                                                className="position-relative w-100 jackpot-wrapper-mix d-flex justify-content-center align-items-center border-mix">
                                                {showJackpot && < >
                                                    <img className="jackpot-v2-img position-absolute"
                                                         src={require(`../../../assets/images/jackpot/v2/${jp}.png`)}
                                                         alt="__"/>
                                                    <img
                                                        className="jackpot-v2-img position-absolute win-animate-border"
                                                        src={require('../../../assets/images/jackpot/v2/border-jackpot.png')}
                                                        alt="___"/>
                                                </>}
                                                <div>
                                                    <p className="num-range">{item.rangeNumber}</p>
                                                    <p className="num-odds">{item.value}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
            </div>
        </div>

    </div>
}
