import React, {Component} from "react";
import LottoMixNav from "../../components/lotto/mix/LottoMixNav";
import FeatureWrapper from "../../routes/FeatureWrapper";
import {currentDate, getDateDef} from "../../utils/dateUtils";
import {withTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import moment from "moment";
import {lottoPaths} from "../../constants/general";
import Lotto5Nav from "../../components/lotto5/Lotto5Nav";
import {connect} from "react-redux";
import {fetchLottoMixResultReport} from "../../store/lotto/action";
import MainResultLotto5 from "../../components/lotto/result/MainResultLotto5";
import MainResultLotto6 from "../../components/lotto/result/MainResultLotto6";
import MainResultLotto12 from "../../components/lotto/result/MainResultLotto12";

class lottoMixResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            date: currentDate(),
            showResult: false,
            data: null,
            lottoType: "lotto5-lists"
        }
    }

    componentDidMount() {
        this.props.fetchLottoMixResultReport(this.state.lottoType, {
            ...this.props.filter,
            filterDate: getDateDef(this.state.date)
        })
    }

    onChange = (page) => {
        this.onFilter({page: page})
        this.setState({page: page});
    }

    onFilter = (params = {}) => {
        this.props.fetchLottoMixResultReport(this.state.lottoType, {
            ...this.props.filter,
            filterDate: getDateDef(this.state.date), ...params
        })
    }

    onToggledResult = (isShow, data) => {
        this.setState({showResult: isShow, data: data});
    }

    onLotteryTypeChange(value) {
        this.setState({
            lottoType: value.target.value
        });
    }

    renderTable = () => {
        const {showResult, data, page} = this.state;
        const {lottoType} = this.props;
        switch (lottoType) {
            case "lotto12-lists":
                return <MainResultLotto12 showResult={showResult}
                                          data={data}
                                          pages={page}
                                          onChange={this.onChange}
                                          onToggledResult={this.onToggledResult}
                                          {...this.props}/>
            case "lotto6-lists":
                return <MainResultLotto6 showResult={showResult}
                                         data={data}
                                         pages={page}
                                         onChange={this.onChange}
                                         onToggledResult={this.onToggledResult}
                                         {...this.props}/>
            default:
                return <MainResultLotto5 showResult={showResult}
                                         data={data}
                                         pages={page}
                                         onChange={this.onChange}
                                         onToggledResult={this.onToggledResult}
                                         {...this.props}/>

        }
    }

    render() {
        const {t} = this.props;
        let isFrame = lottoPaths.includes(this.props.location?.pathname);

        return (
            <div className="main-w">
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-game-content">
                            {isFrame ? <LottoMixNav {...this.props}/> : <Lotto5Nav/>}
                            <div>
                                <div className="row align-items-center ml-3 mt-2 pb-0">
                                    <div className="lottery-type mb-2">
                                        <label>{t("labels:common.lotteryType")} :</label>
                                        <select onChange={this.onLotteryTypeChange.bind(this)} className="border">
                                            <FeatureWrapper value="5">
                                                <option value="lotto5-lists">{t("labels:menus.lotto")} 5</option>
                                            </FeatureWrapper>
                                            <FeatureWrapper value="6">
                                                <option value="lotto6-lists">{t("labels:menus.lotto")} 6/36</option>
                                            </FeatureWrapper>
                                            <FeatureWrapper value="12">
                                                <option value="lotto12-lists">{t("labels:menus.lotto")} 12</option>
                                            </FeatureWrapper>
                                        </select>
                                    </div>
                                    <div className="form-date-range">
                                        <label>{t("labels:drawing.date")}:</label>
                                    </div>
                                    <div className="pl-2">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={this.state.date}
                                            maxDate={moment().toDate()}
                                            onChange={date => this.setState({date: date})}
                                            className="form-control bg-white"/>
                                    </div>
                                    <div className="wrap-btn-filter">
                                        <button className="btn-game"
                                                onClick={() => this.onFilter()}>{t("labels:common.getResult")}</button>
                                    </div>
                                </div>

                                {
                                    this.renderTable()
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state.lotto.resultMixReport
};

const mapDispatchToProps = dispatch => ({
    fetchLottoMixResultReport: (url, payload) => dispatch(fetchLottoMixResultReport(url, payload))
});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(lottoMixResult));