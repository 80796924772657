import React from "react";

export default ({allNumbers}) => {
    const rows = [];
    for (let index = 0; index < 6; index++) {
        const cols = [];
        for (let num = 1; num <= 6; num++) {
            const element = num + index * 6;
            cols.push(
                <td
                    key={element}
                    id={"keno-key" + element}
                    // width="10%"
                >
                    <span className={allNumbers?.includes(element) ? "active" : ""}>{element}</span>
                </td>
            );
        }
        rows.push(<tr key={index}>{cols}</tr>);
    }
    return rows;
}