import axios from "axios";
import {
    CLEAR_ORDER, CLEAR_TICKET, CREATE_BETTING,
    ERROR_LOADING,
    FETCH_LO5D_BETTING, FETCH_RECENT_TICKET_5D,
    FETCH_RESULTS_LO5D, FETCH_TICKET_5D,
    LIST_DRAWING_LO5D, LIST_LO5D_BETTING, LIST_PAGE, LIST_RECENT_TICKET_5D,
    LIST_RESULTS_LO5D, LIST_TICKET_5D
} from "./types";
import {getDateDef} from "../../utils/dateUtils";
import {delay} from "../../utils/interval";
import {playDrawingL5D} from "../../constants/Audio";

const fetchResults = (params = {}) => dispatch => {
    const filter = {size: 50, page: 1, ...params};
    dispatch({type: FETCH_RESULTS_LO5D, payload: params});
    return axios.get(`/api/v1.0.0/drawing/lotto5d-lists`, {
        params: filter,
    }).then(response => {
        dispatch({
            type: LIST_RESULTS_LO5D,
            payload: response.data,
        });
        return response;
    });
};


const checkDrawing = (draw, dispatch) => {
    let {
        currentDraw: {
            results, countDownTimer, status,
        }
    } = draw;

    if (status === 1 && results) {
        dispatch({
            type: LIST_DRAWING_LO5D,
            payload: {...draw, isDrawing: true}
        });
        startDrawing(draw, countDownTimer, dispatch).then(e => e);
    } else {
        dispatch({
            type: LIST_DRAWING_LO5D,
            payload: {isDrawing: false}
        });
    }

}
let invDraw;

// const getIndex = (time) => {
//     if (time >= 3) time -= 3;
//     let index = parseInt(time / 8);
//     return index;
// }
// const startDrawing = async (draw, countDownTimer, dispatch) => {
//     countDownTimer = Math.abs(countDownTimer);
//     clearInvL5D();
//     if (countDownTimer <= 3) await delay(3000);
//
//     let index = getIndex(countDownTimer);
//     let ind = index;
//     const startRandom = () => {
//         draw.currentDraw.results.map((e, i) => {
//             if (i === ind) e["status"] = 1;
//             else if (ind > i) e["status"] = 2;
//             else e["status"] = 0;
//             return e;
//         })
//
//         dispatch({
//             type: LIST_DRAWING_LO5D,
//             payload: draw
//         });
//         ind < 4 && playDrawingL5D()
//         if (ind >= 4)
//             clearInvL5D();
//         else
//             ind++;
//     }
//     startRandom();
//     invDraw = setInterval(() => {
//         startRandom();
//     }, 8000)
// }

const getIndex = (time) => {
    if (time >= 3) time -= 3;
    let index = parseInt(time / 2);
    return index;
}

const startDrawing = async (draw, countDownTimer, dispatch) => {
    countDownTimer = Math.abs(countDownTimer);
    clearInvL5D();
    if (countDownTimer <= 3) await delay(3000);

    let index = getIndex(countDownTimer);
    const startRandom = () => {
        if (index > 19) {
            clearInvL5D();
            draw.currentDraw.list.map((e) => {
                e.status = 2;
                return e;
            });
        } else {
            draw.currentDraw.list.map((e, i) => {
                if (i === index) e.status = 1;
                else if (index > i) e.status = 2;
                else e.status = 0;
                return e;
            });

        }
        dispatch({
            type: LIST_DRAWING_LO5D,
            payload: draw
        });
        index > 0 && playDrawingL5D();
        index++;
    }
    startRandom();
    invDraw = setInterval(() => {
        startRandom();
    }, 2000)
}

const clearInvL5D = () => {
    invDraw && clearInterval(invDraw);
}

const tsResult = (results, status = 2) =>
    results.map(e => e.fiveDigits).join("")?.split("").map(p => {
        return {
            number: p,
            status: status
        }
    });


const fetchCurrentDraw5D = (params = {}) => dispatch => {
    return axios.get(`api/v1.0.0/drawing/lotto5d`, {
        params: {...params}
    }).then(response => {
        let draw = response.data.data;
        // let draw = drawing5d;
        draw.currentDraw.list = tsResult(draw.currentDraw.results ?? [], 0);
        draw.previousDraw.list = tsResult(draw.previousDraw.results ?? [], 2);
        dispatch({
            type: LIST_DRAWING_LO5D,
            payload: draw
        })
        checkDrawing(draw, dispatch);
        return draw;
    });
};

const updateBettingCart = ({data, type}) => dispatch => {
    dispatch({
        type: type,
        payload: data
    })
}


const fetchBetting = (params = {}, uri = "", isClear = false, filters = {}) => dispatch => {

    const filter = {filterByDate: getDateDef(), ...params};
    if (isClear) {
        dispatch({type: CLEAR_ORDER});
        return null
    }
    dispatch({type: FETCH_LO5D_BETTING, payload: filter, filters: filters});

    return axios.get(`api/v1.0.0/betting/lotto5d`, {
        params: filter
    }).then(response => {
        dispatch({
            type: ERROR_LOADING,
        });
        let data = response.data;
        if (data != null) {
            dispatch({
                type: LIST_LO5D_BETTING,
                payload: data?.data,
                paging: data?.paging
            });
        } else {
            dispatch({
                type: LIST_LO5D_BETTING,
                payload: {
                    drawShifts: [],
                    pageNumber: 1,
                    lanes: [],
                }
            });
        }
        return response;
    });
};

const fetchPaging = (param) => dispatch => {
    return axios.get(`api/v1.0.0/LO5D/paging`, {
        params: param
    }).then(response => {
        if (response.data?.data != null) {
            dispatch({
                type: LIST_PAGE,
                payload: response.data?.data
            });
            return response.data?.data;
        }
    });
};

const postBetting = (params = {}) => dispatch => {
    dispatch({type: CREATE_BETTING});
    return axios.post(`api/v1.0.0/betting/lotto5d`, params).then(response => {
        dispatch({type: ERROR_LOADING});
        return response?.data?.data;
    })
}


const updateBet = (data, type) => dispatch => {
    dispatch({
        type: type,
        payload: data
    });
};

const setLoading = (type) => dispatch => {
    dispatch({type: type});
};

const fetchTicket5D = (params = {}) => dispatch => {
    const filter = {page: 1, size: 25, filterByLotto: "LOTTO5D", isWin: false, filterDate: getDateDef(), ...params};
    dispatch({type: FETCH_TICKET_5D, payload: filter});
    return axios.get(`api/v1.0.0/reports/ticket`, {
        params: filter
    }).then(response => {
        if (response.data.data != null) {
            dispatch({
                type: LIST_TICKET_5D,
                payload: {
                    data: response.data.data,
                    ...response.data.paging
                }
            });
        } else {
            dispatch({type: LIST_TICKET_5D});
        }
        return response;
    });
};

const fetchLotto5dRecentTicket = (payload = {}) => (dispatch) => {
    const filter = {
        page: 1,
        size: 3,
        filterByLotto: "LOTTO5D",
        ...payload
    };
    dispatch({type: FETCH_RECENT_TICKET_5D, payload: filter});
    return axios.get(`api/v1.0.0/reports/recent-betting`, {
        params: filter
    })
        .then((response) => {
            if (response.data.data != null) {
                dispatch({
                    type: LIST_RECENT_TICKET_5D,
                    payload: {
                        data: response.data.data,
                        ...response.data.paging
                    }
                });
            } else {
                dispatch({type: LIST_RECENT_TICKET_5D});
            }
            return response;
        });
};


const clearTicketReport = () => dispatch => dispatch({type: CLEAR_TICKET});


export {
    fetchCurrentDraw5D,
    updateBettingCart,
    fetchResults,
    fetchBetting,
    fetchPaging,
    postBetting,
    updateBet,
    setLoading,
    fetchTicket5D,
    clearTicketReport,
    clearInvL5D,
    fetchLotto5dRecentTicket
}


