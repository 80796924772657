import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";

import en from "../../assets/locales/en";
import km from "../../assets/locales/km";
import zh from "../../assets/locales/zh";
import th from "../../assets/locales/th";
import vi from "../../assets/locales/vi";

i18n
	.use(LanguageDetector)
	.use(intervalPlural)
	.init({
		resources: {
			en,
			km,
			zh,
			th,
			vi
		},
		compatibilityJSON: "v3",
		ns: ["messages", "buttons", "validations", "labels"],
		defaultNS: "validations",
		// Array of supported languages
		supportedLngs: ["en", "km", "zh", "th", "vi"],
		// Language to use if translations in user language are not available
		fallbackLng: "km",
		// Logs info level to console output. Helps finding issues with loading not working
		debug: process.env.NODE_ENV === "development",
		// Options for react
		react: {
			// To wait for loaded in every translated hoc
			wait: true,
			// Set it to false if you do not want to use Suspense
			useSuspense: true,
		},
		lng: localStorage.getItem("i18nextLng") || "km",
	});

export default i18n;
