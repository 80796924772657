import React from "react";


const RebateRate = props => {
    const {rate, classRebate} = props;
    return (
        <div className={`rebate-rate ${classRebate}`}><span>{rate}</span></div>
    );
};

export default RebateRate;


