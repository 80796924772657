const env = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    BASIC_TOKEN: process.env.REACT_APP_BASIC_TOKEN,
    PLATFORM_FOR: process.env.REACT_APP_STYLE_FOR,
    IS_ODD_EVEN: process.env.REACT_APP_IS_ODD_EVEN,
    PLATFORM_COLOR_999: process.env.REACT_APP_STYLE_COLOR_999,
    PLATFORM_TYPE: process.env.REACT_APP_PLATFORM_FOR,
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
    SOCKET_IO_URL: process.env.REACT_APP_SOCKET_IO_URL,
    FEATURES: process.env.REACT_APP_FEATURES,
    PLATFORM_ALLOW: process.env.REACT_APP_PLATFORM_ALLOW,
    VERSION: process.env.REACT_APP_VERSION
};

export {env};
