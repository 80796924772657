import React from 'react';
import DrawingResult from "./DrawingResult";
import DrawingResultCircle from "./../lotto12v3/DrawingResult";
import BettingBoard from "./BettingBoard";
import Balance from "../../pages/components/Balance";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import RecentOrdering from "./RecentOrdering";
import _ from "lodash";
import BettingDialog from "../lotto5/BettingDialog";
import moment from "moment";
import {fetchUserBalanceValidate, setIsAddToCart, setIsAutoPrint} from "../../store/user/action";
import {
    addLotto12v2Betting, deleteLotto12v2Betting, fetchLotto12v2DrawingResult,
    fetchLotto12v2ListBetting,
    fetchLotto12v2RecentBetReport,
    lotto12v2ConfirmBettingNow
} from "../../store/lotto12v2/action";
import swal from "sweetalert";
import RecentBetting from "./RecentBetting";
import PropTypes from "prop-types";
import {lotto12DrawStyle} from "../../constants/general";
import {fetchUserBalance} from "../../store/user/action";


class LeftSide extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showDeleteBetting: false,
            showBetting: false,
            betCode: "",
            betAmount: 0,
            betGameType: "",
            betGameOdds: "",
            betGameRange: "",
            betGameTime: "",
            betMessage: "",
            isShowValidateBalance: false,
            messageValidateBalance: null,
            isEnableBetNow: true,
        };
    }


    onCancelBetting = () => {
        this.setState({
            showBetting: false,
            betAmount: 0,
            betGameType: '',
            betGameOdds: '',
            pendingLotto12: this.props.user.userBalance.pendingLotto12,
            betMessage: "",
            betGameRange: "",
            betGameTime: ""
        })
    }


    onBetting = (item) => {
        if (!this.props.isStopBet) {
            this.props.fetchUserBalanceValidate()
                .then(() => {
                    this.setState({
                        showBetting: true,
                        betGameType: item.name,
                        betGameOdds: item.value,
                        betGameRange: item.resultRange,
                        betCode: item.code,
                        betGameTime: moment(new Date()).format('HH:mm:ss')
                    });
                }).catch(error => {
                const {t} = this.props;
                this.setState({
                    isShowValidateBalance: true,
                    messageValidateBalance: t("labels:common." + error.response.data.message)
                });
            })
        }
    }

    clickAmountBetBtn = (amount) => {
        let betAmount = this.state.betAmount || 0

        this.setState({
            betAmount: parseInt(betAmount) + amount
        })
    }

    resetDefaultAmount = () => {
        this.setState({betAmount: 0})
    }

    changeBetAmount = (e) => {
        if (e.target.value >= 0 || !isNaN(e.target.value) || e.target.value === undefined) {
            this.setState({betAmount: e.target.value})
        }
    }

    isValidBetAmount = (betAmount = 0) => {
        betAmount = parseInt(betAmount);
        if (typeof betAmount !== "number") {
            return false;
        }
        const {maxBet, minBet} = this.props.config.limit;

        if (betAmount < minBet || betAmount > maxBet)
            return false;

        return true
    }


    onConfirmBetting = async (logs) => {
        const {t, user: {userBalance, isAddToCart, isAutoPrint}, fetchLotto12v2ListBetting} = this.props
        const betAmount = this.state.betAmount;
        let totalCartAmount = fetchLotto12v2ListBetting({isClear: false});
        if (!this.isValidBetAmount(betAmount)) {
            this.setState({betMessage: t("labels:keno.requireAmount")})
        } else if ((totalCartAmount + betAmount) > userBalance.balance) {
            this.setState({betMessage: t("labels:common.NOT_ENOUGH_BALANCE")})
        } else {
            const {
                betCode,
                betAmount,
                betGameOdds
            } = this.state;
            const data = {
                drawCode: this.props.newResult.drawCode,
                betTypeCode: betCode,
                amount: betAmount,
                betLog: logs,
                rebateRate: betGameOdds
            }

            await this.props.addLotto12v2Betting(data);
            !isAddToCart && this.submitBetNow(isAutoPrint);

            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betCode: "",
                betGameRange: "",
                betGameTime: "",
                betMessage: null
            })
        }
    }

    showConfirmDelete = (id) => {
        this.props.deleteLotto12v2Betting({id: id})
    }

    submitBetNow = (isPrint) => {
        const {isEnableBetNow} = this.state;
        isEnableBetNow && this.props.lotto12v2ConfirmBettingNow(() => this.setState({isEnableBetNow: false}))
            .then(() => {
                this.props.fetchLotto12v2RecentBetReport().then(() => isPrint && document.getElementById("print-button").click());
                this.props.fetchUserBalance();
                this.setState({isEnableBetNow: true})
            }).catch((error) => {
                this.setState({isEnableBetNow: true})
                const {response} = error;
                response && swal(response.data.message, {icon: "error", button: "បិទ"});
            })
    }

    afterPrint = () => {
        this.props.fetchLotto12v2DrawingResult(this.props.drawStyle === lotto12DrawStyle.circle);
    }

    render() {
        const {user: {userBalance, isAddToCart}, betting, drawStyle} = this.props;

        return (
            <div className="l-side">
                <div className="d-flex">
                    <div className="lotto12v2-h-vw border">

                        {/* user balance */}
                        <Balance pendingAmount={userBalance.pendingYuki}/>
                        {/* End of user balance */}

                        {/*/!* Recent Ordering *!/*/}
                        {(!_.isEmpty(betting.betList) && isAddToCart) &&
                        <RecentOrdering
                            showConfirmDelete={this.showConfirmDelete}
                            isEnableBetNow={this.state.isEnableBetNow}
                            submitBetNow={this.submitBetNow}
                            {...this.props}/>}
                        {/*/!* End of recent ordering *!/*/}

                        {/*/!* Recent betting *!/*/}
                        <RecentBetting afterPrint={this.afterPrint} {...this.props}/>
                        {/*/!* End of recent betting *!/*/}

                    </div>
                    <div>
                        {drawStyle === lotto12DrawStyle.square && <DrawingResult/>}
                        {drawStyle === lotto12DrawStyle.circle && <DrawingResultCircle/>}
                        <BettingBoard onBetting={this.onBetting}/>
                    </div>
                </div>
                <BettingDialog changeBetAmount={this.changeBetAmount}
                               clickAmountBetBtn={this.clickAmountBetBtn}
                               onConfirmBettingLotto5={this.onConfirmBetting}
                               resetDefaultAmount={this.resetDefaultAmount}
                               onCancelBetting={this.onCancelBetting}
                               {...this.state} {...this.props}/>


            </div>
        );
    };
}

LeftSide.propTypes = {
    drawStyle: PropTypes.string
}


const mapStateToProps = state => ({
    user: state.user,
    betting: state.lotto12v2.betting,
    config: state.config,
    ...state.lotto12v2.drawing
})

export default withTranslation()(connect(
    mapStateToProps,
    {
        fetchUserBalanceValidate,
        fetchLotto12v2ListBetting,
        addLotto12v2Betting,
        lotto12v2ConfirmBettingNow,
        setIsAutoPrint,
        fetchLotto12v2DrawingResult,
        setIsAddToCart,
        fetchLotto12v2RecentBetReport,
        deleteLotto12v2Betting,
        fetchUserBalance
    }
)(LeftSide));

