import React, {useState} from "react";


const TabView = ({tabs = [], children = []}) => {
    const [tabIndex, setTabIndex] = useState(0);

    const Tab = ({title, index, tabIndex}) => {
        const className = index === tabIndex ? "red" : "";
        return <span
            key={index}
            onClick={() => setTabIndex(index)}
            className={className}>{title}</span>
    }

    return <>
        <div className="left-tabs">
            {tabs.map((title, index) => {
                return <Tab key={index} title={title} index={index} tabIndex={tabIndex}/>
            })}
        </div>

        {children.map((view, index) => {
            const className = index !== tabIndex ? "d-none" : "d-block";
            return <div key={index} className={className}>
                {view}
            </div>
        })}
    </>
}

export default TabView;
