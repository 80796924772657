export const LOTTO5_SET_BET_STATUS = "LOTTO5_SET_BET_STATUS";
export const LOTTO5_SET_RENDER_RESULT_STATUS =
    "LOTTO5_SET_RENDER_RESULT_STATUS";
export const LOTTO5_SET_TOTAL_RESULT_NUMBER = "LOTTO5_SET_TOTAL_RESULT_NUMBER";

export const LOTTO5_REQUEST_LIST_BETTING = "LOTTO5_REQUEST_LIST_BETTING";

export const LOTTO5_REQUEST_FETCH_DRAWING_RESULT =
    "LOTTO5_REQUEST_FETCH_DRAWING_RESULT";

export const LOTTO5_REQUEST_FETCH_RECENT_BET_REPORT =
    "LOTTO5_REQUEST_FETCH_RECENT_BET_REPORT";
export const LOTTO5_REQUEST_FETCH_WIN_LOSS_REPORT =
    "LOTTO5_REQUEST_FETCH_WIN_LOSS_REPORT";
export const LOTTO5_REQUEST_FETCH_RESULT_REPORT =
    "LOTTO5_REQUEST_FETCH_RESULT_REPORT";
export const LOTTO5_REQUEST_FETCH_GAME_CONFIG =
    "LOTTO5_REQUEST_FETCH_GAME_CONFIG";
export const LOTTO5_REQUEST_FETCH_RECENT_DRAW =
    "LOTTO5_REQUEST_FETCH_RECENT_DRAW";

export const LOTTO5_SET_CURRENT_DRAW = "LOTTO5_SET_CURRENT_DRAW";

export const LOTTO5_STATISTIC = "LOTTO5_STATISTIC";

