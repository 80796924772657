import React from "react";

class CountDownTimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.countDownTimer !== this.props.countDownTimer && this.props.countDownTimer) {
            this.setState({count: this.props.countDownTimer});
            this.countSecond(this.props.countDownTimer);
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    componentDidMount() {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    countSecond = (countDownTimer) => {
        if (this.timer || this.state.count <= 0 || countDownTimer <= 0) {
            clearInterval(this.timer)
        }
        if (countDownTimer >= 1) {
            this.setState({count: countDownTimer});

            this.timer = setInterval(() => {
                let {count} = this.state
                if (count <= 11 && count > 0) {
                    this.props.tenSecondCallback(count - 1);
                }
                if (count <= 1) {
                    if (this.timer) {
                        clearInterval(this.timer)
                    }
                }
                this.props.countDownCallBack && this.props.countDownCallBack(count);
                this.setState({count: this.state.count - 1})
            }, 1000)
        }

    }

    render() {
        const {totalResult, classCountDown, countClass} = this.props;
        const {count} = this.state;
        return <div
            className={countClass + classCountDown}>
            {
                count < 0 || totalResult > 0
                    ? totalResult ?? 0
                    : count
            }
        </div>
    }
}

export default CountDownTimer;
