import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    deleteLotto6Betting,
    lotto6ConfirmBettingNow,
    fetchLotto6RecentBetReport,
    fetchUserBalance,
    setIsAutoPrint,
} from "../../store/rootAction";
import Dashboard2 from "../../components/lotto6/Dashboard2";
import {env} from "../../env";
import LottoNav from "../../components/LottoNav";
import {lottoMenus} from "../../constants/nav";

class Lotto6Dashboard extends Component {

    render() {
        const platform = env.PLATFORM_FOR;

        return (
            <div className="main-w">
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-new-keno">
                            <LottoNav menus={lottoMenus("lottery6")}/>
                            <Dashboard2
                                platform={platform}
                                {...this.props}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    audio: state.audio
})


export default withTranslation()(connect(
    mapStateToProps,
    {
        setIsAutoPrint,
        lotto6ConfirmBettingNow,
        fetchLotto6RecentBetReport,
        deleteLotto6Betting,
        fetchUserBalance,
    }
)(Lotto6Dashboard))
