import React from "react"
import PropTypes from "prop-types"
import Shake from "react-reveal/Shake"
import { Alert } from "reactstrap"

class MessageValidation extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			isShow: false
		}
	}

	componentDidUpdate(props, state) {
		if (this.props.isShake && !state.isShow) {
			this.setState({ isShow: true })
		} else if (!this.props.isShake && state.isShow) {
			this.setState({ isShow: false })
		}
	}

	onDismiss() {
		this.setState({ isShow: false })
	}

	render() {
		return (
			<>
				<Shake spy={this.state.isShow}>
					<Alert color="danger" isOpen={this.state.isShow} toggle={this.onDismiss.bind(this)}>{ this.props.message }</Alert>
				</Shake>
			</>
		)
	}
}

MessageValidation.propTypes = {
	message: PropTypes.string.isRequired,
	isShake: PropTypes.bool
}

export default MessageValidation