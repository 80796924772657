import axios from "../axios";
import API_LOTTO5 from "../../endpoints/lotto5";
import {showLoading, hideLoading} from "react-redux-loading-bar";

import {
    LOTTO5_SET_BET_STATUS,
    LOTTO5_SET_RENDER_RESULT_STATUS,
    LOTTO5_SET_TOTAL_RESULT_NUMBER,
    LOTTO5_REQUEST_LIST_BETTING,
    LOTTO5_REQUEST_FETCH_DRAWING_RESULT,
    LOTTO5_REQUEST_FETCH_RECENT_BET_REPORT,
    LOTTO5_REQUEST_FETCH_RESULT_REPORT,
    LOTTO5_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO5_STATISTIC, LOTTO5_REQUEST_FETCH_RECENT_DRAW,
} from "./constant";
import {PAGINATION} from "../rootConstant";
import _ from "lodash";
import {getRecentSize} from "../user/action";
import {statisticParam} from "../../constants/general";

function getIndexNumber(timeCountLotto5) {
    if (timeCountLotto5 <= 0) {
        timeCountLotto5 *= -1;
        if (timeCountLotto5 >= 3 && timeCountLotto5 < 49) {
            timeCountLotto5 -= 3;
            return Math.ceil(timeCountLotto5 / 2);
        }
        return -1;
    } else
        return -1;
}


export const setBetStatusLotto5 = (payload) => {
    return {
        type: LOTTO5_SET_BET_STATUS,
        payload: payload,
    };
};

export const setRenderResultStatus = (payload) => {
    return {
        type: LOTTO5_SET_RENDER_RESULT_STATUS,
        payload: payload,
    };
};

export const setResultTotalNumberKeno = (payload) => {
    return {
        type: LOTTO5_SET_TOTAL_RESULT_NUMBER,
        payload: payload,
    };
};

export const fetchListLotto5Betting = (payload) => (dispatch) => {
    payload["items"] = JSON.parse(sessionStorage.getItem("betting_lotto5") || "[]");

    let item = {
        betList: payload.items,
        summary: {
            total: 0,
            currency: "",
            currencySignal: "",
        },
    };

    if (payload.items?.length > 0)
        item.summary.total = _.sumBy(payload.items, 'amount');

    if (payload.isClear) {
        return dispatch({
            type: LOTTO5_REQUEST_LIST_BETTING,
            payload: item,
        });
    }
    return item.summary.total;

};

export const deleteLotto5Betting = (payload) => (dispatch) => {
    let betList = JSON.parse(sessionStorage.getItem("betting_lotto5") || "[]");
    if (betList) {
        betList.splice(payload.id, 1);
        sessionStorage.setItem("betting_lotto5", JSON.stringify(betList));
    }
    dispatch(fetchListLotto5Betting({isClear: true}));
};

export const addLotto5Betting = (payload) => (dispatch) => {
    let betList = JSON.parse(sessionStorage.getItem("betting_lotto5") || "[]");
    betList.push(payload);
    sessionStorage.setItem("betting_lotto5", JSON.stringify(betList));
    dispatch(fetchListLotto5Betting({isClear: true}));
};


export const lotto5ConfirmBettingNow = (callback) => (dispatch, getState) => {
    const betItemList = getState().lotto5.bettingLotto5.betList;
    let body = {
        drawCode: "",
        gameType: "LOTTO5",
        items: betItemList,
    };
    if (betItemList?.length > 0) {
        body.drawCode = betItemList[betItemList.length - 1].drawCode;
    }
    callback();
    return axios
        .post(API_LOTTO5.LOTTO5_BETTING_NOW, body)
        .then(async (response) => {
            await sessionStorage.removeItem("betting_lotto5");
            await dispatch(fetchListLotto5Betting({isClear: true}));
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const clearCartLotto5 = () => (dispatch) => {
    sessionStorage.removeItem("betting_lotto5");
    dispatch(fetchListLotto5Betting({isClear: true}));
};

export const fetchLotto5DrawingResult = () => (dispatch) => {
    return axios
        .get(API_LOTTO5.LOTTO5_FETCH_DRAWING_RESULT)
        .then((response) => {
            let data = response.data.data;
            // let data = drawing5;
            let countDownTimer = data.countDownTimer;
            let isStopBet = true;
            let isStopRender = true;

            if (data.oldResult && countDownTimer <= 0) {
                isStopRender = false;
                isStopBet = true;
            }
            if (countDownTimer > 9) {
                isStopBet = false;
                isStopRender = true;
            }

            dispatch({
                type: LOTTO5_REQUEST_FETCH_DRAWING_RESULT,
                payload: {
                    ...data,
                    indexNumber: getIndexNumber(countDownTimer),
                    isStopBet: isStopBet,
                    isStopRender: isStopRender,
                },
            });

            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
};


export const fetchLotto5WinLossReport = (payload) => async (dispatch) => {
    try {
        const response = await axios.get(API_LOTTO5.LOTTO5_FETCH_WIN_LOSS_REPORT, {
            params: {filterByLotto: "LOTTO5", ...payload}
        });
        const paging = response.data.paging;
        const data = response.data.data;
        dispatch({
            type: LOTTO5_REQUEST_FETCH_WIN_LOSS_REPORT,
            payload: {
                tickets: data.tickets,
                summary: data.summary,
                filter: payload,
                ...paging
            },
        });
    } catch (error) {
        return await Promise.reject(error);
    }
};
export const fetchStatistic5 = (payload) => async (dispatch) => {
    let filter = {...statisticParam, ...payload};
    try {
        const response = await axios.get(API_LOTTO5.LOTTO5_STATISTIC, {
            params: filter
        });
        const data = response.data.data;
        dispatch({
            type: LOTTO5_STATISTIC,
            payload: {
                ...data
            },
        });
    } catch (error) {
        return await Promise.reject(error);
    }
};

export const fetchLotto5ResultReport = (payload) => (dispatch) => {
    dispatch(showLoading("sectionBar"));

    return axios
        .get(API_LOTTO5.LOTTO5_FETCH_RESULT_REPORT, {
            params: {size: PAGINATION.SIZE, page: 1, ...payload}
        })
        .then((response) => {
            const paging = response.data.paging;
            dispatch({
                type: LOTTO5_REQUEST_FETCH_RESULT_REPORT,
                payload: {
                    items: response.data.data,
                    filter: payload,
                    ...paging
                },
            });
            dispatch(hideLoading("sectionBar"));
        })
        .catch((error) => {
            dispatch(hideLoading("sectionBar"));
            return Promise.reject(error);
        });
};


export const fetchLotto5RecentDraw = () => (dispatch) => {
    return axios
        .get(API_LOTTO5.LOTTO5_FETCH_RECENT_DRAW)
        .then((response) => {
            dispatch({
                type: LOTTO5_REQUEST_FETCH_RECENT_DRAW,
                payload: response.data.data
            });
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const fetchLotto5RecentBetReport = () => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LOTTO5.LOTTO5_FETCH_RECENT_BET_REPORT,
                {
                    params: {
                        size: getRecentSize().lo5,
                        page: 1,
                        filterByLotto: "LOTTO5",
                        groupByDraw: "YES",
                    }
                });
        dispatch({
            type: LOTTO5_REQUEST_FETCH_RECENT_BET_REPORT,
            payload: response.data.data,
        });
        return await Promise.resolve(response);
    } catch (error) {
        return await Promise.reject(error);
    }
};


export const fetchLotto5RecentBetReportCache = () => async (dispatch) => {
    try {
        const response = await axios
            .get(API_LOTTO5.LOTTO5_FETCH_RECENT_BET_REPORT_CACHE,
                {
                    params: {
                        size: getRecentSize().lo5,
                        page: 1,
                        filterByLotto: "LOTTO5",
                        groupByDraw: "YES",
                    }
                });
        dispatch({
            type: LOTTO5_REQUEST_FETCH_RECENT_BET_REPORT,
            payload: response.data.data,
        });
        return await Promise.resolve(response);
    } catch (error) {
        return await Promise.reject(error);
    }
};