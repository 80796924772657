import {getCardVersion} from "./cached";

const imgUrl = (path) => {
    return require("../assets/images/" + path)
}

const audioUrl = (path) => {
    return require("../assets/audios/" + path)
}

const isKp = () => window.location.hostname.includes("kp.")

const lotto12CardUrl = (code) => {
    const isDomainKP = isKp();
    const isImgKP = isDomainKP ? "kp" : `normal/${getCardVersion()}`;
    return imgUrl(`lotto12/cards/no-price/${isImgKP}/${code}.png`)
}

const cardAudioUrl = (code) => audioUrl(`lotto12/l12_voice_${code.toLowerCase()}_v2.mp3`);

export {
    lotto12CardUrl,
    imgUrl,
    cardAudioUrl,
    audioUrl,
    isKp
}
