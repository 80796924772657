import React from "react";
import {Button, CustomInput, Form, FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import moment from "moment";
import DatePicker from "react-datepicker";
import {getDateDef} from "../../../utils/dateUtils";


const initState = {
    keyword: "",
    isWin: false,
    filterDate: new Date(),
    isOpen: true
};

class Filter extends React.Component {
    state = {
        ...initState,
    };
    reset = undefined;


    componentDidMount() {
        this.props.onFilter({...initState, filterDate: getDateDef(this.state.filterDate)});
    }

    render() {
        const {
            t,
            onFilter,
            onReset,
            onIsWin,
            onChangeDate
        } = this.props;

        const filter = this.state;
        const {lotteryType} = filter;
        const setFilter = state => this.setState(state);
        return <Form className="fade-in mx-2" inline onSubmit={e => {
            e.preventDefault();
        }}>
            <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={this.state.filterDate}
                maxDate={moment().toDate()}
                onChange={date => {
                    this.setState({filterDate: date}, () => {
                        onChangeDate(getDateDef(date))
                    })
                }}
                className="form-control bg-white"/>
            <div className={'label-padding mx-2'}>
                <FormGroup row className="justify-content-end">
                    <Label for='is_win' className={'label-padding'}>{t('labels:lmhsb.is_win')}</Label>
                    <CustomInput
                        className={'switch-padding mx-2'}
                        type='checkbox'
                        id='is_win'
                        checked={filter.isWin}
                        onChange={() => {
                            this.setState({
                                isWin: !filter.isWin
                            }, () => onIsWin(!filter.isWin));
                        }}
                    />
                </FormGroup>
            </div>
            <FormGroup className="mr-3 mb-sm-3">
                <Input
                    className="mt-2"
                    name="keyword"
                    placeholder={t("labels:filter.search")}
                    value={filter.keyword}
                    onChange={e => {
                        e.preventDefault();
                        this.setState({keyword: e.target.value});
                    }}/>
            </FormGroup>

            <Button
                className="ml-sm-3 ml-md-3 ml-lg-0 mb-sm-2 mx-2"
                onClick={() => {
                    setFilter({
                        ...initState,
                    });
                    onReset && onReset();
                }}
                color="secondary">
                {t("labels:filter.reset")}
            </Button>
            <Button
                className="mb-sm-2"
                disabled={lotteryType === null}
                type="button"
                color="primary"
                onClick={() => onFilter({keyword: filter.keyword})}>
                {t("labels:filter.search")}
            </Button>
        </Form>

    }
}

Filter.propTypes = {
    onFilter: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
};

export default (withTranslation()(Filter));

