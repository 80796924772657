import {Modal, ModalBody} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import React from "react";
import {Ticket} from "./ticket";

const TicketModal = props => {
    const {isOpen, toggle, ticket} = props;
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody className='d-inline-flex justify-content-center'>
                <Ticket object={ticket} {...props}/>
            </ModalBody>
        </Modal>
    );
};

TicketModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    ticket: PropTypes.object
};


export default (withTranslation()(TicketModal));
