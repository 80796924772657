import React, { Component } from 'react'
import moment from "moment"

class DatetimeContainer extends Component {

	constructor(props) {
		super(props)
		let date = new Date()
		this.state = {
			time: moment(date).format('HH:mm:ss')
		}
	}

	countingSecond() {
		let date = new Date()
		this.setState({
			time: moment(date).format('HH:mm:ss')
		})
	}

	componentDidMount() {
		if(this.timer){
			clearInterval(this.timer)
		}
		this.timer = setInterval(() => {
			this.countingSecond()
		}, 1000)
	}

	componentWillUnmount() {
		if(this.timer){
			clearInterval(this.timer)
		}
	}
	
	render() {
		return (
			<>{this.state.time}</>
		)
	}
}
export default DatetimeContainer