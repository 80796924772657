import React, {Component} from "react";
import _ from "lodash";
import 'moment-timezone';
import {getDateTime} from "../../utils/dateUtils";
import {moneyFormatter} from "../../utils/MoneyUtils";
import {Badge} from "reactstrap";
import Images from "../../constants/lotto5Images";
import Printer from "./print/Printer";
import {currencyCode} from "../../constants/general";

class Lotto90WinReportOldRow extends Component {

    render() {
        const {t, tickets, onShowDetail} = this.props;

        return <table className="table-custom">
            <thead>
            <tr>
                <th className="text-center">{t("labels:report.no")}</th>
                <th className="text-center">{t("labels:report.ticketCode")}</th>
                <th className="text-center">{t("labels:report.betAt")}</th>
                <th className="text-center">{t("labels:report.betTypeB")}</th>
                <th className="text-center">{t("labels:report.betNumber")}</th>
                <th className="text-center">{t("labels:report.result")}</th>
                <th className="text-center">{t("labels:report.winB")}</th>
                <th className="text-center">{t("labels:report.print")}</th>
            </tr>
            </thead>
            <tbody>
            {
                _.isEmpty(tickets) && <tr>
                    <td colSpan={8}><img className="data-not-found-img"
                                         src={Images.dataNotFound} alt="..."/></td>
                </tr>
            }
            {
                !_.isEmpty(tickets) && tickets.map((object, index) => {
                    const isRelease = object.status === 2;
                    if (!isRelease) object.detail = Array(5).fill("?").join(",");
                    return <React.Fragment key={index}>
                        <tr className="bg-tr-his">
                            <td colSpan={8}>
                                <div className="d-flex pl-5">
                                                                <span
                                                                    className="mr-3 f-bold">{object.drawLabel}</span>
                                    {
                                        object?.detail?.split(",").map((row, j) => {
                                            return <span
                                                className="f-16 font-weight-bold result-number-cycle"
                                                key={j}>{row}</span>
                                        })}
                                    <span
                                        className="ml-3 f-result font-weight-bold">{isRelease ? object.result : "??"}</span>
                                </div>
                            </td>
                        </tr>
                        {
                            object?.items.map((obj, i) => {
                                obj.drawCode = object.drawCode;
                                obj.drawAt = object.drawAt;
                                obj.drawStatus = object.status;
                                obj.drawLabel = object.drawLabel;
                                return <tr key={i} className="animate-slide-up">
                                    <td
                                        className="text-center align-middle">{i + 1}</td>
                                    <td
                                        className="text-center align-middle f-bold"
                                        onClick={() => onShowDetail(obj)}>
                                        #{obj?.ticketNumber}
                                    </td>
                                    <td className="text-center align-middle">{getDateTime(obj?.createdAt)}</td>
                                    <td className="text-center align-middle">
                                        <div className="d-flex justify-content-center">
                                            {
                                                obj?.groupByPosts?.map((p, g) => {
                                                    if (_.isEmpty(p.postGroup)) {
                                                        return <Badge
                                                            className="mr-2 bg-orange"
                                                            key={g}>
                                                            {t("labels:drawing.lotto90.special_x")}
                                                        </Badge>

                                                    }
                                                    return <Badge
                                                        className="mr-2 bg-orange"
                                                        key={g}>
                                                        {p.postGroup}
                                                    </Badge>
                                                })
                                            }
                                        </div>
                                    </td>
                                    <td className="w-result-his align-middle">
                                        <div className="d-flex justify-content-center">
                                            <div className="">
                                                {
                                                    obj?.groupByPosts.map(g => {
                                                        return g.items?.map((p, n) => {
                                                            if (!_.isEmpty(g.postGroup)) {
                                                                const numberDetail = p.numberDetail?.split(",");
                                                                return numberDetail.map((o, y) => {
                                                                    return <span key={y}
                                                                                 className="f-16 font-weight-bold result-history"
                                                                    >{o}</span>
                                                                })
                                                            } else {
                                                                return <Badge
                                                                    key={n}
                                                                    className="mr-2 num-color f-16"
                                                                >
                                                                    {p.numberFrom.split("_").map((n, i) => {
                                                                        return <span
                                                                            key={i}
                                                                            className="text-white">{t("labels:drawing.lotto90.label.".concat(n))}</span>
                                                                    })}
                                                                </Badge>
                                                            }

                                                        })
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center align-middle">{
                                        obj.resultItems.map((r, i) => {
                                            if (r.type !== "SPECIAL") {
                                                return <Badge key={i}
                                                              className="f-14 font-weight-bold bg-orange mr-1">{t("labels:drawing.lotto90.special_x")} > {!isRelease ? "??" : t("labels:drawing.lotto90.label.".concat(r.number))}</Badge>
                                            }
                                            return <Badge key={i}
                                                          className="f-14 font-weight-bold bg-orange mr-1">{r.postCode} > {isRelease ? r.number : "??"}</Badge>
                                        })
                                    }
                                    </td>

                                    <td className="text-center align-middle f-bold">
                                        <span>{!isRelease ? "??" : moneyFormatter(obj.winAmount, currencyCode())}</span>
                                    </td>
                                    <td className="text-center align-middle">
                                        <Printer object={obj} drawLabel={object.drawLabel}   {...this.props}/>
                                    </td>
                                </tr>
                            })
                        }
                    </React.Fragment>
                })
            }
            </tbody>
        </table>
    }
}

export default (Lotto90WinReportOldRow);
