import React from "react";
import {getFullDateTimePrint} from "../../utils/dateUtils";
import {moneyFormatter} from "../../utils/MoneyUtils";
import PrintItems from "./PrintItems";

export class Ticket58 extends React.Component {
    renderLabel = (lottoType) => {
        switch (lottoType) {
            case "LOTTO5" : {
                return "bct_lotto5"
            }
            case "LOTTO6" : {
                return "bct_lotto6"
            }
            case "LOTTO1/12" : {
                return "bct_lotto1/12"
            }
            case "LOTTO1/99" : {
                return "bct_lotto1/99"
            }
            default : {
                return "bct_lotto12"
            }
        }
    }

    render() {
        const {t, data, lottoType, i18n: {language}} = this.props;
        const {ticketCode, createdAt, items, betAmount, currencyCode, status, drawLabel, bonusAmount} = data;
        const isChinese = language === "zh";

        return (
            <div id="print-58">
                <table className='print-tb-58 table' cellPadding="7px">
                    <tbody>
                    <tr className="bb-solid-1">
                        <td style={{lineHeight: "45px", paddingRight: 0, paddingLeft: 0, fontSize: "34px"}} colSpan="3"
                            className='text-center f-bayon font-weight-bold'>{t("labels:ticket." + this.renderLabel(lottoType))}
                        </td>
                    </tr>
                    <tr>
                        <td className="f-bayon text-center bb-solid-1 p-0 l-h-25">
                            <div
                                className="f-bayon f-22 text-center align-items-center p-0">{getFullDateTimePrint(createdAt)}</div>
                            <div style={{letterSpacing: "-0.5px"}}
                                 className="f-bayon f-23 p-0">#{ticketCode} / {drawLabel}</div>
                        </td>
                    </tr>
                    {
                        items.map((object, i) => {
                            return <PrintItems
                                key={i}
                                lastIndex={items.length - 1 === i}
                                t={t}
                                object={object}
                                currency={currencyCode}
                                status={status}
                                isChinese={isChinese}
                            />
                        })
                    }
                    <tr>
                        <td className="text-center f-bayon font-weight-bold f-26 bb-solid-1" colSpan={3}>
                            {t('labels:ticket.total')}: {moneyFormatter(betAmount, currencyCode, "text-black font-weight-bold f-26 f-bayon")}
                        </td>
                    </tr>
                    {bonusAmount > 0 && <tr>
                        <td className="text-center f-bayon font-weight-bold f-26 bb-solid-1" colSpan={3}>
                            {t('labels:ticket.bonus')}: {moneyFormatter(bonusAmount, currencyCode, "text-black font-weight-bold f-26 f-bayon")}
                        </td>
                    </tr>}
                    <tr>
                        <td className="pt-5">-</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
