import moment from "moment";

export const currentDate = () => {
    const current = moment.tz('Asia/Phnom_Penh');
    return new Date(current.get('year'), current.get('month'), current.get('date'));
}

export const getDateDef = (date) => moment(date).format('YYYY-MM-DD');
export const getDate = (date) => moment(date).format('DD-MM-YYYY');


export const getDateHHmmss = (date) => {
    return moment(date).format('HH:mm:ss');
}

export const getHHmm = (date) => {
    return moment(date).format('HH:mm');
}

export const getDateTimeS = date => {
    return moment(date).format("DD/MM/YYYY hh:mm A")
};

export const getFullDateTime = date => {
    return moment(date).format("YYYY/MM/DD HH:mm:ss")
};


export const getFullDateTimePrint = date => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
};

export const getTimeH = date => {
    return moment(date).format("HH:mm")
};
export const getTime24h = date => {
    return moment(date).format("HH:mm")
};

export const getDateTime = date => {
    return moment(date).format("DD-MM-YYYY hh:mm A")
};

export const getDateTimeFullDef = date => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
};

export const getTimeN = date => {
    return moment(date).format("hh:mm")
};
export const getTime = date => {
    return moment(date).format("hh:mm A")
};
export const getDateDisplay = date =>
    moment(date).format("DD / MM / YYYY");
