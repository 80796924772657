import React from "react";
import LottoMixNav from "../../components/lotto/mix/LottoMixNav";
import ShowUpdateVersion from "../../components/version/ShowUpdateVersion";


class Lotto extends React.Component {

    render() {
        return <div id="lotto-mix">
            <ShowUpdateVersion/>
            <LottoMixNav {...this.props}/>
            <div className="row no-margin-div">
                <div className="col row no-margin-div no-padding-div">
                    <iframe title="Lottery5" className="col-4 h-lotto no-padding-div"
                            src={`${window.location.origin}/lottery/lottery5-mix`}/>
                    <iframe title="Lottery6" className="col-4 h-lotto no-padding-div"
                            src={`${window.location.origin}/lottery/lottery6-mix`}/>

                    <iframe title="Lottery12" className="col-4 h-lotto no-padding-div"
                            src={`${window.location.origin}/lottery/lottery12-mix`}/>

                </div>
            </div>
        </div>
    }
}

export default Lotto;
