import React from "react";
import ResultHistory from "./ResultHistory";
import TabView from "../lotto/TabView";


const RightSide = ({t, ...props}) => {

    const tabs = [t("labels:drawing.drawPrefix.new_result")];
    const children = [
        <ResultHistory/>
    ]

    return <div className="r-side">
        <TabView children={children} tabs={tabs}/>
    </div>
}

export default RightSide;

