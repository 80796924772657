import React from 'react';
import {sL12v2Label} from "../../constants/general";
import {imgUrl} from "../../utils/asset";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import _ from "lodash";

const BetItem = ({item, onClickBetItem, colorClass = "zodiac-color1", bgClass = "bgzodiac1"}) => {
    let nCode = sL12v2Label[item.code];


    return <div className={`bet-bottom-list-item ${colorClass}`}>
        <div className="sign-wrapper" onClick={() => onClickBetItem(item)}>
            <span className={`bet-bottom-list-item-odds ${bgClass}`}>{nCode}</span>
            <img className="zodiac-img" src={imgUrl('lotto12v2/signs/' + parseInt(nCode) + ".png")} alt=""/>
        </div>
    </div>
}

const ColorBetItem = ({item, onClickBetItem, colorClass, bgClass, t}) => {

    return <div onClick={() => onClickBetItem(item)} className={`bet-bottom-list-item ${colorClass}`}>
        <div className="sign-wrapper">
            <span className={`bet-bottom-list-item-odds ${bgClass}`}/>
            <span className="bet-bottom-list-item-title">{t(`labels:betPrefix.` + item.code)}</span>
        </div>
    </div>
}

const BettingBoard = ({betTypes, onBetting, t, ...props}) => {
    const colorBetTypes = betTypes?.colors;
    const nBetTypes = _.chunk(betTypes?.numbers, 4);

    return (
        <div>
            <div className="bet-bottom mt-1">
                {colorBetTypes && <div className="bet-bottom-list">
                    <span
                        className="bet-bottom-list-title">{t("labels:drawing.drawPrefix.color")}
                        <span className="text-danger ml-1">x {colorBetTypes[0]?.value}</span></span>
                    {colorBetTypes.map((e, j) => {
                        const index = j + 1;
                        return <ColorBetItem bgClass={`bgzodiac${index}`}
                                             t={t}
                                             colorClass={`zodiac-color${index}`}
                                             classActive={`select${index}`}
                                             onClickBetItem={() => onBetting(e)}
                                             key={j}
                                             item={e}/>
                    })}
                </div>}
                {
                    nBetTypes && nBetTypes.map((list, i) => {
                        const index = i + 1;
                        return <div key={i} className="bet-bottom-list">
                            <span
                                className="bet-bottom-list-title">{i === 0 ? t("labels:drawing.drawPrefix.number") : ""} {i === 0 &&
                            <span className='text-danger ml-1'>x {list[0].value}</span>} </span>
                            {list.map((e, j) => {
                                return <BetItem bgClass={`bgzodiac${index}`}
                                                colorClass={`zodiac-color${index}`}
                                                classActive={`select${index}`}
                                                onClickBetItem={() => onBetting(e)}
                                                key={j}
                                                item={e}/>
                            })}
                        </div>
                    })
                }

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    betTypes: state.config.yukiBetTypes
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(BettingBoard));
