const lottoMenus = (type) => [
    {
        to: "/" + type,
        exact: true,
        label: "labels:menus.lotto"
    },
    {
        to: `/${type}/win-report`,
        exact: false,
        label: "labels:menus.lottoNav.winReport"
    },
    {
        to: `/${type}/result`,
        exact: false,
        label: "labels:menus.lottoNav.result"
    }
]

const lotto5DMenus = [
    {
        to: "/lottery5D",
        exact: true,
        label: "labels:menus.lotto"
    },
    {
        to: "/lottery5D/ticket",
        exact: true,
        label: "labels:menus.lottoNav.ticket"
    },
    {
        to: "/lottery5D/result",
        exact: true,
        label: "labels:menus.lottoNav.result"
    },
];


export {lottoMenus, lotto5DMenus}