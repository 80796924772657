import ResultHistoryTable from "./StatisticTable";
import React from "react";
import DrawingResult from "./DrawingResult";

export default (props) => {
    return <div className="col-9 no-padding-div none-select">
        <div className="wrap-lotto5-top col no-padding-div">
            <div className="lotto5-play-main">
                <DrawingResult {...props}/>
                <ResultHistoryTable/>
            </div>
        </div>
    </div>

}