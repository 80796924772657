import {LOTTO12V2_REQUEST_FETCH_WIN_LOSS_REPORT} from "./types";

const init = {
    data: {
        tickets: [],
        summary: {
            totalBetAmount: 0,
            totalWinAmount: "",
            totalWinLoseAmount: "",
            currencyCode: ""
        },
        page: 0,
        size: 0,
        totals: 0
    }
}


export default (state = init, {type, payload}) => {
    switch (type) {
        case LOTTO12V2_REQUEST_FETCH_WIN_LOSS_REPORT:
            return {
                ...state,
                data: payload
            }
        default:
            return state;
    }
}