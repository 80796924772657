import React from "react";
import {withTranslation} from "react-i18next";
import TicketPrintBody from "./TicketPrintBody";
import _ from "lodash";

class PrintTicket58 extends React.Component {
    render() {
        const {postGroup, bodyClass} = this.props;
        return <div id="print-ticket-58" className='f-18 black-c float-left'>
            <div className="bg-white rounded w-bet-card-ticket">
                {
                    postGroup?.map((item, index) => {
                        return <div key={index}
                                    className={` ${index !== postGroup.length - 1 ? "border-bottom" : ""} p-1`}>
                            {
                                (!_.isEmpty(item?.postGroup)) &&
                                <div style={{
                                    lineHeight: '10px',
                                    paddingBottom: '10px',
                                    paddingTop: '10px',
                                    color: "black"
                                }} className="black-c f-30 f-bayon">{item?.postGroup}</div>
                            }
                            <TicketPrintBody is58 item={item} t={this.props.t} bodyClass={bodyClass}/>
                        </div>
                    })
                }
            </div>
        </div>
    }
}

export default withTranslation()(PrintTicket58);
