import React, {Component} from "react";
import {Link} from "react-router-dom";
import  {IoIosArrowForward}  from "react-icons/io";

class HistoryResult extends Component {
    render() {
        const {results: {lastResult}, t} = this.props;
        return <div className="lo5d-last-result">
            <div className="bg-white  pt-1">
                {
                    lastResult && lastResult.map((result, index) => {
                        return <div key={index} className={`lo5d-his-result ${index === 0 ? "" : "border-top"}`}>
                            <table
                                className={`w-100 mb-0`}>
                                <tbody>
                                <tr>
                                    <td className="pl-3">
                                        <div
                                            className="custom-last-draw text-left text-black font-weight-bold">{result.drawLabel}</div>
                                        <div className="font-weight-bold">
                                            {
                                                result.results.map((item, i) => {
                                                    const number = item?.fiveDigits?.split("");
                                                    return <div className="w-100 text-left" key={i}>
                                                    <span
                                                        className={`num-last-result last-result-post post-result-${item?.postCode}`}>{item?.postCode}</span>
                                                        {
                                                            number === undefined ? <span/> : number?.map((num, key) => {
                                                                return <span key={key}
                                                                             className={`num-last-result res-last-result fs-num ${key < 2 ? "result-2d" : "result-3d"}`}>{num}</span>
                                                            })
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    })
                }
                <div className="mt-3 p-1-vw">
                    <span>
                        <Link to="/lottery5D/result">{t("labels:ticket.see_more")}<IoIosArrowForward />
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    }
}

export default HistoryResult;