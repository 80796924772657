import React from "react";
import _ from "lodash";
import history from "./history";

export default (WrappedComponent) => {

	class WithoutAuth extends React.Component {

		componentDidMount() {
			const accessToken = localStorage.getItem("accessToken");
			if (!_.isEmpty(accessToken)) {
				history.push("/lottery5")
			}
		}

		render() {
			const accessToken = localStorage.getItem("accessToken");
			if (!_.isEmpty(accessToken)) {
				return null
			}
			
			return (<WrappedComponent {...this.props} />);
		}
	}

	return WithoutAuth
}