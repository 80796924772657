import React from "react";
import {getFullDateTimePrint, getTimeH} from "../../../utils/dateUtils";
import PrintTicket from "./PrintTicket";
import {moneyFormatter} from "../../../utils/MoneyUtils";
import {currencyCode} from "../../../constants/general";

export class Ticket extends React.Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
    }

    render() {
        const {object, t} = this.props;
        const isRelease = object?.status;

        return (
            <table className='print-tb-58 table mb-5' cellPadding="7px">
                <tbody>
                <tr className="bb-solid-1">
                    <td style={{lineHeight: "45px", paddingRight: 0, paddingLeft: 0, fontSize: "34px"}} colSpan="3"
                        className='text-center f-bayon font-weight-bold'>{t("labels:lmhsb.ticket.lo5d")}</td>
                </tr>

                <tr>
                    <td className="f-bayon text-center bb-solid-1 p-0 l-h-25">
                        <div
                            className="f-bayon f-22 text-center align-items-center p-0">{getFullDateTimePrint(object?.createdAt)}</div>
                        <div style={{letterSpacing: "-0.5px"}}
                             className="f-bayon f-22 p-0">#{object?.ticketNumber} / {object?.drawCode} {getTimeH(object?.drawAt)}</div>
                    </td>
                </tr>

                <tr>
                    <td colSpan='3' className='p-0 bb-solid-1'>
                        <PrintTicket bodyClass='pt-1' postGroup={object.postGroup}/>
                    </td>
                </tr>
                <tr>
                    <td style={{lineHeight: "30px"}} colSpan={3}
                        className="p-0 f-25 text-center text-black f-bayon bb-solid-1">{t('labels:ticket.total_bet')} : {moneyFormatter(object?.total?.betAmount, currencyCode(), "p-0 f-25 f-bayon")}</td>
                </tr>
                <tr>
                    <td style={{lineHeight: "30px"}} colSpan={3}
                        className="p-0 f-25 text-center text-black f-bayon bb-solid-1">{t('labels:ticket.total_win')} : {
                        !isRelease ? "???" : moneyFormatter(object?.total?.winAmount, currencyCode(), "p-0 f-25 f-bayon")
                    }</td>
                </tr>
                </tbody>
            </table>
        );
    }
}
