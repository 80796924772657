export default {
    lo5: require('../assets/images/home/lo5.png'),
    lo6: require('../assets/images/home/lo6.png'),
    lo12: require('../assets/images/home/lo12.png'),
    lo90: require('../assets/images/home/lo90.png'),
    lo5d: require('../assets/images/home/lo5d.png'),
    lo12v2: require('../assets/images/home/lo12v2.png'),
    lotto5: require('../assets/images/home/Lotto5.jpg'),
    lotto12: require('../assets/images/home/Lotto12.jpg'),
    refeshIcon: require('../assets/images/refresh.svg'),
    refeshIcon999: require('../assets/images/refresh-circle.svg'),
    lottoSix36: require('../assets/images/home/Lotto6.jpg'),
    lotto90: require('../assets/images/home/Lotto90.jpg'),
    lotto99: require('../assets/images/home/Lotto99.png'),
    dataNotFound: require('../assets/images/data-found.gif'),
    logoLotto5: require('../assets/images/logo/MHSB168.png'),
    logoKPW: require('../assets/images/logo/KPW.png'),
    logo: require('../assets/images/lotto5/logo.png'),
    logo90: require('../assets/images/lotto90/logo.png'),
    lmhsb: require('../assets/images/logo/LMHSB168.png'),
    logoHeader: {
        kh: require("../assets/images/logo/BCT168.png"),
        en: require("../assets/images/logo/logo-en.png"),
    },
    loadingLinear: require('../assets/images/lotto5/Loading_Linear.gif'),
    loading999: require('../assets/images/lotto5/Loading999.gif'),

}