import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Dashboard from "../../components/lotto99/Dashboard";
import Lotto99Nav from "../../components/lotto99/Lotto99Nav";

class Index extends Component {

    render() {
        return (
            <div className="main-w">
                <div className="main">
                    <div className="w-1200">
                        <Lotto99Nav/>
                        <Dashboard/>
                    </div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(Index)
