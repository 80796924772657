import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import UnderOverStatistic from "../UnderOverStatistic";
import RangeStatistic from "../RangeStatistic";

const StatisticTable = (props) => {
    const {t} = props;
    return <div className="row no-margin-div border-all">
        <div className="col-6 no-padding-div">
            <div className="col no-padding-div">
                <div className="text-center header-div-title header-history-light bg-red-l">
                    <span className="last-result-betting-lo6-mix">{t("labels:keno.range")}</span>
                </div>
                <div className="div-bg-gray lotto6-history-result fade-in border-t">

                    <div className="bet-grid-container">

                        <table
                            id="keno_result_post"
                            border="1"
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%"
                            className="fade-in"
                        >
                            <tbody>
                            <RangeStatistic {...props}/>
                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>


        <div className="col-6 no-padding-div">

            <div
                className="text-center header-div-title  header-history-light card-border-left bg-red-l">
                <span className="last-result-betting-lo6-mix">{t("labels:keno.underOverOddEven")}</span>
            </div>
            <div
                className="no-padding-div-left div-bg-gray lotto6-history-result fade-in border-t border-left-0">


                <div className=" bet-grid-container">

                    <table
                        id=" keno_result_post"
                        border="1"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%">
                        <tbody>
                        <UnderOverStatistic {...props}/>
                        </tbody>

                    </table>

                </div>

            </div>

        </div>

    </div>
}

const mapStateToProps = state => ({
    ...state.lotto6.statistic
})


export default withTranslation()(connect(
    mapStateToProps,
    {}
)(StatisticTable));