import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    fetchLotto90ResultReport,
    drawingNumber,
    fetchLotto90DrawingResult,
    createBetting, fetchLotto90RecentTicket, setMute90
} from "../../store/lotto90/action";
import {clientAp} from "../../constants/general";
import {SUBSCRIPTION_LOTTO90} from "../../constants/lotto90";
import {fetchUserBalance, getRecentSize} from "../../store/user/action";
import DashboardWrapper from "./DashboardWrapper";
import BettingDashboard from "./bet/BettingDashboardWrapper";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";


class Dashboard extends React.Component {
    state = {
        status: null,
    }

    componentWillUnmount() {
        setMute90(true);
        this.sp && this.sp.unsubscribe();
        removeSocketListener(socketEvent.l90);
    }

    componentDidMount() {
        this.checkDrawingResult();
        this.subscription();
        setMute90(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.layoutVersion !== this.props.layoutVersion) {
            this.checkDrawingResult();
        }
    }

    subscription = () => {
        if (socketLottoConfig.l90) {
            socket.on(socketEvent.l90, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {

            this.sp = clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO90,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto90_drawing) {
                        let currentDraw = event.data.v_lotto90_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw)
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    onDrawStatusChange = (currentDraw) => {
        const {status} = this.state;
        if (currentDraw.status !== status) {
            if (status && currentDraw.status === "OPEN") {
                this.checkDrawingResult();
                this.props.fetchLotto90ResultReport();
                this.props.fetchLotto90RecentTicket({size: getRecentSize().lo90});
            }
            if (currentDraw.status === "RELEASE") {
                this.checkDrawingResult();
            }
            this.setState({status: currentDraw.status})
        }
    }

    checkDrawingResult = () => {
        this.props.fetchLotto90DrawingResult().then(() => {
            if (!this.isBet()) {
                !this.props.drawing.isDrawing && this.props.drawingNumber();
            }
        });
    }

    isBet = () =>
        document.location.pathname === "/lottery90/bet"


    render() {
        const layoutVersion = this.props.layoutVersion;
        if (layoutVersion === "V1")
            return <DashboardWrapper status={this.state.status}
                                     {...this.props}
                                     isBet={this.isBet}/>
        return <BettingDashboard status={this.state.status} {...this.props}/>
    }
}

const mapStateToProps = state => ({
    ...state.lotto90,
    config: state.config,
    audio: state.audio,
    user: state.user
});

export default withTranslation()(connect(mapStateToProps, {
    fetchLotto90ResultReport,
    drawingNumber,
    fetchLotto90DrawingResult,
    fetchLotto90RecentTicket,
    createBetting,
    fetchUserBalance,
})(Dashboard));
