import React, {Component} from "react";
import _ from "lodash";
import Images from "../../../constants/lotto5Images";
import Printer from "../print/Printer";
import {moneyFormatter} from "../../../utils/MoneyUtils";
import {currencyCode} from "../../../constants/general";

class NewRecentTicketRow extends Component {

    render() {
        const {t, tickets, onShowDetail} = this.props;

        return <div className="table-container pt-0 recent-ticket">
            <table className="table-custom border-0">
                <tbody>
                {
                    _.isEmpty(tickets) && <tr>
                        <td colSpan={8}><img className="data-not-found-img"
                                             src={Images.dataNotFound} alt="..."/></td>
                    </tr>
                }
                {!_.isEmpty(tickets) && <tr>
                    <th className="text-center">{t("labels:lmhsb.ticket_no")}</th>
                    <th className="text-center">{t("labels:lmhsb.bet")}</th>
                    <th className="text-center">{t("labels:lmhsb.win")}</th>
                    <th className="text-center">{t("labels:action")}</th>
                </tr>}
                {
                    !_.isEmpty(tickets) && tickets.map((object, index) => {
                        const isRelease = object.status === 2;
                        if (!isRelease) object.detail = Array(5).fill("?").join(",");
                        return <React.Fragment key={index}>
                            <tr className="bg-tr-his">
                                <td colSpan={4}>
                                    <div className="d-flex justify-content-between p-1 pl-2 pr-2">
                                        <div
                                            className="mr-3 f-bold">{object.drawLabel}</div>
                                        <div className="d-flex pl-5">

                                            {
                                                object?.detail?.split(",").map((row, j) => {
                                                    return <span
                                                        className="font-weight-bold result-number-cycle"
                                                        key={j}>{row}</span>
                                                })}
                                            <span
                                                className="ml-3 f-result font-weight-bold">{isRelease ? object.result : "??"}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {
                                object?.items.map((obj, i) => {
                                    obj.drawCode = object.drawCode;
                                    obj.drawAt = object.drawAt;
                                    obj.drawStatus = object.status;
                                    obj.drawLabel = object.drawLabel;
                                    return <tr key={i} className="animate-slide-up">
                                        <td>
                                            <div className="f-bold btn p-0"
                                                 onClick={() => onShowDetail(obj)}>#{obj.ticketNumber}</div>

                                        </td>
                                        <td>{moneyFormatter(obj.totalAmount, currencyCode(), "text-primary")}</td>
                                        <td className="text-danger">{isRelease ? moneyFormatter(obj.winAmount, currencyCode(), "text-danger") : "???"}</td>
                                        <td>
                                            <div>
                                                <Printer
                                                    object={obj}
                                                    drawLabel={object.drawLabel} {...this.props}/>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </React.Fragment>
                    })
                }
                </tbody>
            </table>
        </div>
    }
}

export default NewRecentTicketRow;