import {FETCH_RESULTS_LO5D, LIST_RESULTS_LO5D} from "./types";


const init = {
    isLoading: false,
    filter: {},
    data: [],
    lastResult: [],
    page: 1,
    totals: 0,
    size: 10
};


export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_RESULTS_LO5D:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_RESULTS_LO5D:
            return {
                ...state,
                isLoading: false,
                data: payload.data,
                lastResult: payload.data?.slice(1, 3),
                ...payload.paging
            };
        default:
            return state;
    }
}
