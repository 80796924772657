import {ballNumbers} from "../../constants/lotto99";
import React from "react";
import {padNumber2} from "../../constants/general";

const BallNumber99 = ({drawing, status}) => {

    const {actives, countDigits} = drawing;

    return <>
        <div className="table-wrapper ball-99-bg">
            <div id="tb-number" className="tb-number">
                {ballNumbers.map((r, i) => {

                    let padNumber = padNumber2(i + 1);
                    const isActive = actives.includes(padNumber);
                    const digitCount = countDigits[i];

                    return <div data-count-digit={digitCount} className="count-digits" key={i}>
                        <div
                            id={`drawing-${padNumber}`}
                            className={`num-circle ${status === "RELEASE" && isActive ? "code99-active" : ""}`}>
                            {padNumber}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </>
}

export default BallNumber99;
