export default {
    logo: require("../assets/images/logo/BCT168.png"),
    keno: {
        loadingLinear: require("../assets/images/keno/Loading_Linear.gif"),
    },
    flags: {
        Cambodia: require("../assets/images/flags/cambodia.svg"),
        Chinese: require("../assets/images/flags/chinese.svg"),
        UnitedState: require("../assets/images/flags/united-state.svg"),
        Thailand: require("../assets/images/flags/thailand.svg"),
        Vietnam: require("../assets/images/flags/vietname.svg")
    }
}