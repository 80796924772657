export const LOTTO6_SET_BET_STATUS = "LOTTO6_SET_BET_STATUS"
export const LOTTO6_SET_RENDER_RESULT_STATUS = "LOTTO6_SET_RENDER_RESULT_STATUS"
export const LOTTO6_SET_TOTAL_RESULT_NUMBER = "LOTTO6_SET_TOTAL_RESULT_NUMBER"

export const LOTTO6_REQUEST_LIST_BETTING = "LOTTO6_REQUEST_LIST_BETTING"

export const LOTTO6_REQUEST_FETCH_DRAWING_RESULT = "LOTTO6_REQUEST_FETCH_DRAWING_RESULT"
export const LOTTO6_REQUEST_FETCH_RECENT_BET_REPORT = "LOTTO6_REQUEST_FETCH_RECENT_BET_REPORT"
export const LOTTO6_REQUEST_FETCH_WIN_LOSS_REPORT = "LOTTO6_REQUEST_FETCH_WIN_LOSS_REPORT"
export const LOTTO6_REQUEST_FETCH_RESULT_REPORT = "LOTTO6_REQUEST_FETCH_RESULT_REPORT"
export const LOTTO6_REQUEST_FETCH_RECENT_DRAW = "LOTTO6_REQUEST_FETCH_RECENT_DRAW";

export const LOTTO6_STATISTIC = "LOTTO6_STATISTIC";