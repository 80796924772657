import axios from "../axios";
import API_LOTTO5 from "../../endpoints/lotto5";
import {
    LOTTO_MIX_REQUEST_FETCH_RESULT_REPORT,
    LOTTO_REQUEST_FETCH_RESULT_REPORT,
    LOTTO_REQUEST_FETCH_WIN_LOSS_REPORT
} from "./constant";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {PAGINATION} from "../rootConstant";
import API_LOTTO_MIX from "../../endpoints/lotto";

export const fetchLottoWinLossReport = (params) => async (dispatch) => {
    try {
        const response = await axios.get(API_LOTTO5.LOTTO5_FETCH_WIN_LOSS_REPORT, {
            params: params
        });

        const data = response.data?.data;
        dispatch({
            type: LOTTO_REQUEST_FETCH_WIN_LOSS_REPORT,
            payload: {
                ...data,
                filter: params,
            },
        });
    } catch (error) {
        return await Promise.reject(error);
    }
};

export const fetchLottoResultReport = (payload) => (dispatch) => {
    dispatch(showLoading("sectionBar"));

    return axios
        .get(API_LOTTO5.LOTTO5_FETCH_RESULT_REPORT, {
            params: {size: PAGINATION.SIZE, page: 1, ...payload}
        })
        .then((response) => {
            let items = response.data.data;
            const paging = response.data.paging;
            dispatch({
                type: LOTTO_REQUEST_FETCH_RESULT_REPORT,
                payload: {
                    items: items,
                    filter: payload,
                    ...paging
                },
            });
            dispatch(hideLoading("sectionBar"));
        })
        .catch((error) => {
            dispatch(hideLoading("sectionBar"));
            return Promise.reject(error);
        });
};

export const fetchLottoMixResultReport = (url, payload) => (dispatch) => {
    dispatch(showLoading("sectionBar"));
    return axios
        .get(`${API_LOTTO_MIX.LOTTO_MIX_FETCH_RESULT_REPORT}/${url}`, {
            params: {
                size: PAGINATION.SIZE,
                page: 1, ...payload
            }
        })
        .then((response) => {
            const paging = response.data.paging;
            dispatch({
                type: LOTTO_MIX_REQUEST_FETCH_RESULT_REPORT,
                payload: {
                    items: response.data.data,
                    filter: payload,
                    lottoType: url,
                    ...paging
                },
            });
            dispatch(hideLoading("sectionBar"));
        })
        .catch((error) => {
            dispatch(hideLoading("sectionBar"));
            return Promise.reject(error);
        });
};