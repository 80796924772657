import React from "react";

import RecentOrdering from "./RecentOrdering";
import RecentBetting from "./RecentBetting";
import DrawResult from "./DrawResult";
import Balance from "../../pages/components/Balance";
import RightBoard from "./RightBoard";

export default (props) => {
    const {user: {userBalance}} = props;
    return <div className="wrap-lotto5-content">
        <div className="row recent-betting-table w-100">
            {/* betting history left side */}
            <div className="col-3 no-padding-div border">
                {/* user balance */}
                <Balance pendingAmount={userBalance.pendingLotto5}/>
                {/* End of user balance */}

                {/* Last drawing */}
                <DrawResult {...props}/>
                {/* End of last drawing */}

                {/* Recent Ordering */}
                <RecentOrdering {...props}/>
                {/* End of recent ordering */}

                {/* Recent betting */}
                <RecentBetting {...props}/>
                {/* End of recent betting */}

            </div>
            {/* end of betting history left side */}

            {/* betting dashboard and pricing */}
            <RightBoard {...props}/>
            {/* end of betting dashboard and pricing */}
        </div>


    </div>
}