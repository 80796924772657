import React from "react";
import {UncontrolledTooltip} from "reactstrap";
import {moneyFormatter} from "../../utils/MoneyUtils";
import _ from "lodash";


const BetItem = (props) => {
    const {t, onEditItem, item, onDeleteItem} = props;
    const returnMargin = (length) => {
        switch (length) {
            case 1:
                return "ml-1";
            case 2:
                return "ml-2";
            case 3:
                return "ml-3";
            case 4:
                return "ml-3";
            case 5:
                return "ml-4";
            case 6:
                return "ml-4";
            case 7:
                return "ml-5";
            case 8:
                return "ml-5";
            case 9:
                return "ml-5";
            default:
                return "";
        }
    };

    const vertLine = (obj) => {
        let isDigitOne = false;
        let isDigitTwo = false;
        let isDigitThree = false;
        let isDigitFour = false;
        let titles = obj.betTitle?.split("_");
        titles.forEach((e) => {
            switch (true) {
                case e === "J1":
                    isDigitOne = true;
                    break;
                case e === "J2":
                    isDigitTwo = true;
                    break;
                case e === "J3":
                    isDigitThree = true;
                    break;
                case e === "J4":
                    isDigitFour = true;
                    break;
                default:
                    break;
            }
        });
        return (
            <div className="d-inline-flex ml-hr">
                <div className={`hr-vert ${isDigitOne ? "" : "border-0"}`}/>
                <div className={`hr-vert ${isDigitTwo ? "" : "border-0"}`}/>
                {obj.numberFrom.length > 2 && (
                    <div className={`hr-vert ${isDigitThree ? "" : "border-0"}`}/>
                )}
                {obj.numberFrom.length > 3 && (
                    <div className={`hr-vert ${isDigitFour ? "" : "border-0"}`}/>
                )}
                {obj.multiDigit != null && (
                    <div
                        className={`hr-right ${
                            obj.numberFrom.length === obj.multiDigit ? "ml-hr" : "ml-3"
                        }`}
                    />
                )}
            </div>
        );
    };

    const getPairLabel = (length) => {
        if (length === 2) return "NOT_PAIR";
        if (length === 3) return "NOT_TRIPLE";
        if (length === 4) return "NOT_SQUARE";
    }


    const renderNormal = (obj, i) => {
        let num = _.isNaN(parseInt(obj.numberFrom)) ? t("labels:reports.table." + obj.numberFrom) : obj.numberFrom;
        return (
            <div
                key={i}
                className="row p-0 m-0 mb-1 f-bold f-16 position-relative">
                <div className={`row p-0 m-0 align-content-center ${obj.winQty > 0 && "bg-win"}`}>
                    <div>{num}</div>
                    &nbsp;:&nbsp;
                    <div>{moneyFormatter(obj.betAmount, obj.currencyCode)}
                    </div>
                    <div className="position-absolute" style={{right: "0"}}>
                        <button id="edit-btn" onClick={() => onEditItem(obj, i)}
                                className="border-0 bg-transparent mx-1">
                            <i className="feather icon-edit-2 f-15 text-primary pointer"/>
                        </button>
                        <button className="border-0 bg-transparent mx-1" onClick={() => onDeleteItem(obj, i)}>
                            <i className="feather icon-trash-2 f-15 text-danger pointer"/>
                        </button>
                    </div>
                </div>
                {obj?.winQty > 0 && (
                    <div className="win-animate p-0 m-0 text-center ml-1">
                        {obj?.winQty}
                    </div>
                )}
            </div>
        )
            ;
    }

    const renderRange = (obj, i) => {
        return (
            <div key={i} className="row p-0 m-0 f-bold f-16 position-relative">
                <div>
                    <div>
                        {`${obj.numberFrom} ${
                            obj.multiDigit == null
                                ? ""
                                : obj.numberFrom.length === obj.multiDigit
                                    ? "x"
                                    : `(x${obj.multiDigit}D)`
                        }`}
                    </div>
                    <div className="row justify-content-between m-0 ">
                        <div className={`hr-vert ${returnMargin(obj.numberFrom.length)}`}/>
                        {obj.multiDigit != null && (
                            <div
                                className={`hr-right ${
                                    obj.numberFrom.length === obj.multiDigit ? "mr-1" : "mr-3"
                                }`}
                            />
                        )}
                    </div>
                    <div>
                        {`${obj.numberTo} ${obj.multiDigit == null
                            ? ""
                            : obj.numberFrom.length === obj.multiDigit
                                ? "x"
                                : `(x${obj.multiDigit}D)`
                        }`}
                    </div>
                </div>
                <div>
                    <div>
                        {" "}
                        &nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode)}
                        <div className="position-absolute" style={{right: "0"}}>
                            <button id="edit-btn" onClick={() => onEditItem(obj, i)}
                                    className="border-0 bg-transparent mx-1">
                                <i className="feather icon-edit-2 f-15 text-primary pointer"/>
                            </button>
                            <button className="border-0 bg-transparent mx-1" onClick={() => onDeleteItem(obj, i)}>
                                <i className="feather icon-trash-2 f-15 text-danger pointer"/>
                            </button>
                        </div>
                    </div>
                    <div className="row p-0 m-0">
                        {obj?.winQty > 0 && (
                            <div className="win-animate p-0 m-0 text-center ml-1">
                                {obj?.winQty}
                            </div>
                        )}
                        <div className="text-primary">
                            &nbsp;{`${obj.numberQuantity}លេខ `}
                            {obj.pairStatus === 1 && (
                                <span className="text-danger">
                                        {t(`messages:sale.bet_title.PAIR`)}
                                    </span>
                            )}
                        </div>
                    </div>
                    <div>
                        {" "}
                        &nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode)}
                    </div>
                </div>
            </div>
        );
    }

    const renderMultiple = (obj, i) => {
        return (
            <div
                key={i}
                className="row p-0 m-0 mb-1 f-bold f-16 position-relative">
                <div className={`row p-0 m-0  ${obj.winQty > 0 ? "bg-win" : ""}`}>
                    {obj.numberFrom.length < 5
                        ? <div>{obj.numberFrom}</div>
                        : <div>{obj.numberFrom.substring(0, 4)}...</div>}
                    &nbsp;
                    <button
                        className={`text-black-50 border-0 p-0 m-0 ${
                            obj.winQty > 0 ? "bg-win" : "bg-white"
                        }`}>
                        <div id={`multiply${obj.id}${i}`} className="text-primary f-12">
                            {obj.numberFrom.length === obj.multiDigit
                                ? "X"
                                : `(X${obj.multiDigit}D)`}
                        </div>
                        <UncontrolledTooltip placement="bottom" target={`multiply${obj.id}${i}`}>
                            {`លោកអ្នកចាក់ ${obj.numberFrom} គុណ${
                                obj.numberFrom.length === obj.multiDigit
                                    ? ""
                                    : `${obj.multiDigit}D`
                            } = ${obj.numberQuantity}លេខ ${obj.pairStatus === 1 ? t(`messages:sale.bet_title.PAIR`) : obj.pairStatus === 2 ? t(`messages:sale.bet_title.PAIR`) : ""}`}
                        </UncontrolledTooltip>
                    </button>
                    &nbsp;:
                    <div>{moneyFormatter(obj.betAmount, obj.currencyCode)}</div>
                    <div className="position-absolute" style={{right: "0"}}>
                        <button id="edit-btn" onClick={() => onEditItem(obj, i)}
                                className="border-0 bg-transparent mx-1">
                            <i className="feather icon-edit-2 f-15 text-primary pointer"/>
                        </button>
                        <button className="border-0 bg-transparent mx-1" onClick={() => onDeleteItem(obj, i)}>
                            <i className="feather icon-trash-2 f-15 text-danger pointer"/>
                        </button>
                    </div>
                </div>
                {obj?.winQty > 0 && (
                    <div className="win-animate p-0 m-0 text-center ml-1">
                        {obj?.winQty}
                    </div>
                )}
            </div>
        );
    }

    const renderSpeRange = (obj, i) => {
        return (
            <div key={i} className="row p-0 m-0">
                <div>
                    <div key={i} className="row p-0 m-0">
                        <div>
                            <div>{`${obj.numberFrom} ${
                                obj.multiDigit == null
                                    ? ""
                                    : obj.numberFrom.length === obj.multiDigit
                                        ? "x"
                                        : `(x${obj.multiDigit}D)`
                            }`}</div>
                            <div className="d-inline-flex">{vertLine(obj)}</div>
                            <div>{`${obj.numberTo} ${
                                obj.multiDigit == null
                                    ? ""
                                    : obj.numberFrom.length === obj.multiDigit
                                        ? "x"
                                        : `(x${obj.multiDigit}D)`
                            }`}</div>
                        </div>
                        <div>
                            <div>
                                {" "}
                                &nbsp;:&nbsp;{moneyFormatter(obj.betAmount, obj.currencyCode)}
                                <div className="position-absolute" style={{right: "0"}}>
                                    <button id="edit-btn" onClick={() => onEditItem(obj, i)}
                                            className="border-0 bg-transparent mx-1">
                                        <i className="feather icon-edit-2 f-15 text-primary pointer"/>
                                    </button>
                                    <button className="border-0 bg-transparent mx-1"
                                            onClick={() => onDeleteItem(obj, i)}>
                                        <i className="feather icon-trash-2 f-15 text-danger pointer"/>
                                    </button>
                                </div>
                            </div>
                            <div className="row p-0 m-0 pb-1 pt-1">
                                {obj?.winQty > 0 && (
                                    <div className="win-animate p-0 m-0 text-center ml-1">
                                        {obj?.winQty}
                                    </div>
                                )}
                                <div className="text-primary ">
                                    &nbsp;{`${obj.numberQuantity}លេខ `}
                                    {obj.pairStatus === 1 && (
                                        <span className="text-danger">
                                        {t(`messages:sale.bet_title.PAIR`)}
                                    </span>
                                    )}
                                    {obj.pairStatus === 2 && (
                                        <span className="text-danger">
                                        {t("messages:sale.bet_title.".concat(getPairLabel(obj.numberFrom?.length)))}
                                    </span>
                                    )}
                                </div>
                            </div>
                            <div>
                                &nbsp;:&nbsp;
                                {moneyFormatter(obj.betAmount, obj.currencyCode)}
                            </div>
                        </div>
                    </div>
                    <div className="m-0 row mb-1">
                        {obj.betTitle && (
                            <div className="text-center badge-danger rounded pl-1 pr-1">
                                {t(`messages:sale.bet_title.${obj.betTitle}`)}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        );
    }

    return item.items.map((obj, i) => {
        obj.posts = item.posts;
        if (obj.betTitle === "MULTIPLE") return renderMultiple(obj, i);
        switch (obj.betType) {
            case "RANGE":
                return renderRange(obj, i);
            case "MULTIPLE":
                return renderMultiple(obj, i);
            case "SPECIAL_RANGE":
                return renderSpeRange(obj, i);
            default:
                return renderNormal(obj, i);
        }
    });
};

export default BetItem;
