import axios from "../axios"
import API_AUTH from "../../endpoints/auth"
import {AUTH_LOGIN} from "./constant"
import {showLoading, hideLoading} from 'react-redux-loading-bar'
import {AUDIO} from "../audio/constant";

const authLogin = payload => {
    return {
        type: AUTH_LOGIN,
        payload: payload
    }
}

const audio = payload => {
    return {
        type: AUDIO,
        payload: payload
    }
}

export const login = payload => dispatch => {

    const requestBody = {
        username: payload.username,
        password: payload.password
    }

    dispatch(showLoading())
    dispatch(authLogin({loading: true}))

    return axios.post(API_AUTH.LOGIN, requestBody)
        .then(response => {
            localStorage.setItem("user", JSON.stringify(response.data.data.user))
            localStorage.setItem("accessToken", response.data.data.token)
            dispatch(hideLoading())
            dispatch(authLogin({loading: false, isLoggedIn: true}))
            dispatch(audio(false))
            return response
        }).catch(error => {
            dispatch(hideLoading())
            dispatch(authLogin({loading: false, isLoggedIn: false}))
            return Promise.reject(error)
        })
}
