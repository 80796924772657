import React, {Component} from "react";
import {getTimeN} from "../../../utils/dateUtils";

class MainTableRow extends Component {

    render() {
        const {object, index} = this.props;
        return <tr>
            <td className="w-5 text-center align-middle f-16 font-weight-bold">{index}</td>
            <td className="w-10 text-center align-middle f-16 font-weight-bold">{object.drawCode} {getTimeN(object.drawAt)}</td>
            <td className="font-weight-bold row align-middle">
                {
                    object.results.map((item, i) => {
                        const number = item?.fiveDigits?.split("");
                        return <div key={i} className={`pr-2 pl-2 ${i === 0 ? "" : "border-left"}`}>
                            <span
                                className={`num-result res-post post-result-${item?.postCode}`}>{item?.postCode}</span>
                            {
                                number === undefined ? <span/> : number?.map((num, key) => {
                                    return <span key={key}
                                                 className={`num-result res-num ${key < 2 ? "result-2d" : ""}`}>{num}</span>
                                })
                            }
                        </div>
                    })
                }
            </td>
        </tr>
    }
}

export default MainTableRow;
