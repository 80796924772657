import React from "react";
import ReactDOM from "react-dom";
import App from "./layout/App";
import * as serviceWorker from "./serviceWorker";
import {Provider} from "react-redux";
import store from "./store/store";
import moment from "moment";
import "moment/min/locales.min";
import i18n from "./store/localization/i18n";
import {I18nextProvider} from "react-i18next";
import {ApolloProvider} from "@apollo/client";
import "./assets/fonts/feather/css/feather.css";
import {createApolloClient} from "./connections/socketServices";

window.moment = moment;
const client = createApolloClient();

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <App/>
            </Provider>
        </ApolloProvider>
    </I18nextProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
