import React, {Component} from "react";
import {connect} from "react-redux";
import 'moment-timezone';
import NumberFormat from "react-number-format";
import {withTranslation} from "react-i18next";
import Images from "../../constants/lotto5Images";
import Printer from "../print/Printer";
import SweetAlert from "react-bootstrap-sweetalert";
import TicketDetail from "../../components/lotto6/TicketDetail";

import {
    fetchLotto12RecentBetReport,
    fetchLotto12DrawingResult,
    getRecentSize,
    setRecentSize
} from "../../store/rootAction";
import {moneyFormatter} from "../../utils/MoneyUtils";
import RefreshButton from "../../utils/RefreshButton";


class RecentBetting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowRecentBet: false,
            recentBetDetail: null,
            size: getRecentSize().lo12
        };
    }

    onShowDetail(item) {
        this.setState({
            isShowRecentBet: true,
            recentBetDetail: item
        });
    }

    onCloseDetail() {
        this.setState({
            isShowRecentBet: false,
            recentBetDetail: null
        });
    }

    onChangeSize = (size) => {
        this.setState({size: size});
        setRecentSize({lo12: size});
    }

    getRecentBetting = () => {
        this.setState({imageRotate: "rotate"})
        this.props.fetchLotto12RecentBetReport()
            .then(() => {
                setTimeout(() => {
                    this.setState({imageRotate: ""})
                }, 300)
            }).catch(() => {
            setTimeout(() => {
                this.setState({imageRotate: ""})
            }, 300)
        })
    }

    render() {
        const {t, afterPrint, recentBetting} = this.props;
        const {size} = this.state;
        return (
            <div>
                {
                    recentBetting ? <div>
                            <div
                                className="col no-padding-div header-div-title header-text header-div-blue-light block-header ">
                                <span className="text-black kh-font-bold">{t("labels:common.recentBet")}
                                    <NumberFormat
                                        className="in-recent-size"
                                        isAllowed={({value}) => value < 11}
                                        onBlur={() => {
                                            if (size > 0 && size < 11) this.getRecentBetting()
                                            else this.onChangeSize(3)
                                        }}
                                        onValueChange={({floatValue}) => this.onChangeSize(floatValue)}
                                        value={size}/>
                                        <span>
                                            {t("labels:common.draw")}
                                        </span>
                                </span>
                                <RefreshButton
                                    onClick={this.getRecentBetting}
                                    className={this.state.imageRotate}
                                />
                            </div>

                            <div className="table-responsive dashboard-fix-height header-div-white fade-in">
                                <table className="table table-current-bet">

                                    <tbody className=" header-div-white">

                                    {
                                        recentBetting.map((item, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <tr key={i}
                                                        className="draw-header-bg">
                                                        <td className="text-left draw-code-recent-bet dashboard-padding-left"
                                                            colSpan={7}>{`${item.drawLabel}`}</td>
                                                    </tr>
                                                    {
                                                        item.tickets.map((row, k) => {
                                                            let items = row.items;
                                                            return (
                                                                <tr key={k} className="table-recent-betting">
                                                                    {/*{items.length === 1 && <td*/}
                                                                    {/*    className="text-left ticket-code-recent-bet dashboard-padding-left text-primary f-bold"*/}
                                                                    {/*    onClick={this.onShowDetail.bind(this, row)}>{row.ticketCode}</td>}*/}

                                                                    <td colSpan={3}
                                                                        className="text-center">
                                                                        <div
                                                                            className="header-draw">
                                                                            <div
                                                                                className="text-left ticket-code-recent-bet text-primary"
                                                                                onClick={this.onShowDetail.bind(this, row)}>{row.ticketCode}</div>
                                                                            <div>{row?.winAmount > 0 || row?.bonusAmount > 0 || !row.status ?
                                                                                <Printer data={row}
                                                                                         lottoType="LOTTO12"
                                                                                         {...this.props}
                                                                                         afterPrint={afterPrint}/> : null}</div>

                                                                        </div>
                                                                        {items.map((e, i) => {
                                                                            return <div key={i}
                                                                                        className="d-flex justify-content-start text-center">
                                                                                <div className="w-25"
                                                                                     key={i}>{t("labels:drawing.drawPrefix." + e.betCode)}</div>
                                                                                <NumberFormat
                                                                                    value={e.betAmount}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    suffix={" "}
                                                                                />
                                                                                <div className="pl-1 pr-1">X</div>
                                                                                <div>{e.betValue}</div>
                                                                                <div className="pl-1 pr-1">=</div>
                                                                                {
                                                                                    row.status === false
                                                                                        ?
                                                                                        <span
                                                                                            className="text-danger">???</span>
                                                                                        : e.winAmount <= 0
                                                                                            ? <span
                                                                                                className="text-danger">0</span>
                                                                                            : <span>
                                                                                            {moneyFormatter(e.winAmount, this.props.user.userBalance.currencyCode, "text-primary")}
                                                                                                {/*<NumberFormat*/}
                                                                                                {/*    value={e.winAmount}*/}
                                                                                                {/*    renderText={value => <span*/}
                                                                                                {/*        className="text-primary">{value}</span>}*/}
                                                                                                {/*    displayType={'text'}*/}
                                                                                                {/*    thousandSeparator={true}*/}
                                                                                                {/*    suffix={" " + this.props.user.userBalance.currencySignal}*/}
                                                                                                {/*/>*/}
                                                                                            </span>
                                                                                }
                                                                            </div>
                                                                        })}
                                                                        {items.length > 1 &&
                                                                        <div className='text-left d-flex'>
                                                                            <div
                                                                                className="w-25 text-center">{t("labels:recentBet.sub_total")}
                                                                            </div>
                                                                            <span>{moneyFormatter(row.betAmount, row.currencyCode)} / {!row.status ?
                                                                                <span
                                                                                    className="text-danger">???</span> :
                                                                                moneyFormatter(row.winAmount, row.currencyCode, "text-primary")}</span>
                                                                        </div>}

                                                                    </td>
                                                                    {/*{items.length === 1 &&*/}
                                                                    {/*    <td className="text-right">{row?.winAmount > 0 || !row.status ?*/}
                                                                    {/*        <Printer data={row}*/}
                                                                    {/*                 lottoType="LOTTO12"*/}
                                                                    {/*                 {...this.props}*/}
                                                                    {/*                 afterPrint={afterPrint}/> : null}</td>}*/}
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </React.Fragment>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                                {
                                    this.props.recentBetting.length === 0 ?
                                        <img className="data-not-found" src={Images.dataNotFound} alt="..."/>
                                        : ''
                                }
                            </div>

                        </div>
                        : null


                }

                <SweetAlert
                    title=" "
                    show={this.state.isShowRecentBet}
                    onConfirm={this.onCloseDetail.bind(this)}
                    confirmBtnText={t("labels:common.close")}
                    showCancel={false}
                    btnSize="lg"
                >
                    <TicketDetail dataDetail={this.state.recentBetDetail}/>
                </SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    recentBetting: state.lotto12.recentBetting
})

const mapDispatchToProps = dispatch => ({
    fetchLotto12DrawingResult: () => dispatch(fetchLotto12DrawingResult()),
    fetchLotto12RecentBetReport: () => dispatch(fetchLotto12RecentBetReport())
});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(RecentBetting));
