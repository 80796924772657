import React, {Component} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import 'moment-timezone';
import {withTranslation} from "react-i18next";
import Images from "../../constants/lotto5Images";
import Printer from "./ticket/Printer";
import NumberFormat from "react-number-format";
import {currentDate} from "../../utils/dateUtils";
import {fetchLotto5dRecentTicket} from "../../store/lotto5d/action";
import {moneyFormatter} from "../../utils/MoneyUtils";
import {currencyCode} from "../../constants/general";
import TicketModal from "./ticket/ticketModal";
import {getRecentSize, setRecentSize} from "../../store/user/action";
import RefreshButton from "../../utils/RefreshButton";

class RecentTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            date: currentDate(),
            isShow: false,
            dataDetail: null,
            size: getRecentSize().lo5d
        };
    }

    onFilter = () => {
        this.setState({imageRotate: "rotate"})
        this.props.fetchLotto5dRecentTicket({size: this.state.size}).then(() => {
            setTimeout(() => {
                this.setState({imageRotate: ""})
            }, 300)
        }).catch(() => {
            setTimeout(() => {
                this.setState({imageRotate: ""})
            }, 300)
        })
    }

    onChangeSize = (size) => {
        this.setState({size: size});
        setRecentSize({lo5d: size});
    }


    onShowDetail(item) {
        this.setState({
            isShow: true,
            dataDetail: item
        });
    }

    onCloseDetail() {
        this.setState({
            isShow: false,
            dataDetail: null
        });
    }

    render() {
        const {t, recentTicket: {list}} = this.props;
        const {size} = this.state;
        return (
            <div className="lotto90">
                <div
                    className="col no-padding-div header-div-title header-text header-div-blue-light block-header position-static border-b-1 d-flex align-items-center">
                    <span className="text-black kh-font-bold">{t("labels:common.recentBet")}
                        <NumberFormat
                            className="in-recent-size"
                            isAllowed={({value}) => value < 11}
                            onBlur={() => {
                                if (size > 0 && size < 11) this.onFilter()
                                else this.onChangeSize(3)
                            }}
                            onValueChange={({floatValue}) => this.onChangeSize(floatValue)}
                            value={size}/>
                                        <span>
                                            {t("labels:common.draw")}
                                        </span>
                                </span>
                    <RefreshButton
                        onClick={() => this.onFilter()}
                        className={this.state.imageRotate}
                    />
                </div>
                <div className="table-container pt-0 recent-ticket">
                    <table className="table-custom border-0">
                        <tbody>
                        {
                            _.isEmpty(list) && <tr>
                                <td colSpan={8}><img className="data-not-found-img"
                                                     src={Images.dataNotFound} alt="..."/></td>
                            </tr>
                        }
                        {!_.isEmpty(list) && <tr>
                            <th className="text-center">{t("labels:lmhsb.ticket_no")}</th>
                            <th className="text-center">{t("labels:lmhsb.bet")}</th>
                            <th className="text-center">{t("labels:lmhsb.win")}</th>
                            <th className="text-center">{t("labels:action")}</th>
                        </tr>}
                        {
                            !_.isEmpty(list) && list.map((object, index) => {
                                return <React.Fragment key={index}>
                                    <tr className="bg-tr-his">
                                        <td colSpan={4}>
                                            <div className="d-flex justify-content-between p-1 pl-2 pr-2">
                                                <div
                                                    className="mr-3 f-bold">{object.drawLabel}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        object?.items.map((obj, i) => {
                                            obj.drawCode = object.drawCode;
                                            obj.drawAt = object.drawAt;
                                            obj.drawStatus = object.status;
                                            obj.drawLabel = object.drawLabel;
                                            return <tr key={i} className="animate-slide-up">
                                                <td>
                                                    <div className="f-bold btn p-0"
                                                         onClick={this.onShowDetail.bind(this, obj)}>#{obj.ticketNumber}</div>

                                                </td>
                                                <td>{moneyFormatter(obj.total.betAmount, currencyCode(), "text-primary")}</td>
                                                <td className="text-danger">{obj.status ? moneyFormatter(obj.total.winAmount, currencyCode(), "text-danger") : "???"}</td>
                                                <td>
                                                    {(obj.total.winAmount > 0 || !obj.status) && <div>
                                                        <Printer
                                                            object={obj}
                                                            drawLabel={object.drawLabel} {...this.props}/>
                                                    </div>}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </React.Fragment>
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <TicketModal isOpen={this.state.isShow}
                             toggle={() => this.setState({isShow: false})}
                             {...this.props}
                             ticket={this.state.dataDetail}
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        recentTicket: state.lotto5d.recentTicket
    }
};

export default withTranslation()(connect(
    mapStateToProps,
    {fetchLotto5dRecentTicket}
)(RecentTicket));
