import {moneyFormatter} from "../../../utils/MoneyUtils";
import React from "react";
import {BetType} from "../../../constants/general";
import {withTranslation} from "react-i18next";

const BetCard = ({ps, t, currencyCode}) => {
    let total = 0;
    ps.map(e => {
        total += e.total;
        return e;
    })
    const RenderRange = ({e}) => {
        let num = parseInt(e.numberTo) - parseInt(e.numberFrom);
        return <div className="">
            <div>{e.numberFrom} : {moneyFormatter(e.amount, "KHR")}</div>
            <div className=""> | <span className="text-success">({num + 1} លេខ)</span></div>
            <div>{e.numberTo} : {moneyFormatter(e.amount, "KHR")}</div>
        </div>
    }

    const RenderNormal = ({e}) => {
        return <div className="">
            <div>{e.betTypeCode !== BetType.NORMAL ? t("labels:drawing.lotto90.label.".concat(e.numberFrom)) : e.numberFrom} : {moneyFormatter(e.amount, currencyCode)}</div>
        </div>

    }

    return <div className="bet-card-wrapper mt-2 mb-4">
        <div className="bet-card f-bold f-20 card p-2">
            {
                ps.map((p, j) => {
                    return <div key={j}>
                        <div className="text-info mt-2">{p.posts}</div>
                        {p.items.map((e, i) => {
                            if (e.betTitle === BetType.RANGE) {
                                return <RenderRange key={i} e={e}/>
                            }
                            return <RenderNormal key={i} e={e}/>
                        })}
                    </div>
                })
            }
            <div>
                <div className="border-total mb-1 mt-1"/>
                <div className="">{t("labels:ticket.total")}: {moneyFormatter(total, currencyCode)}</div>
            </div>
        </div>
    </div>
}

export default withTranslation()(BetCard);
