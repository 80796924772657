import React, {Component} from "react";
import 'moment-timezone';
import {withTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import moment from "moment";
import {moneyFormatter} from "../../utils/MoneyUtils";

class TicketDetail extends Component {

    render() {
        const {t, dataDetail} = this.props;

        return (
            <>
                {
                    dataDetail !== null
                        ? <div className="wrap-alert">
                            <div className="mb-2">
                                <div className="text-left game-bet-info-left">
                                    <div>
                                        <span className="label-bet-info">{t("labels:report.drawCode")}: </span>
                                        <span className="value-bet-info">{dataDetail.drawCode}</span>
                                    </div>
                                    <div>
                                        <span className="label-bet-info">{t("labels:report.ticketCode")}: </span>
                                        <span className="value-bet-info">{dataDetail.ticketCode}</span>
                                    </div>
                                </div>
                                <div className="text-right game-bet-info-right">
                                    <div>
                                        <span
                                            className="text-right">{moment(dataDetail.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                                    </div>
                                    <div>
                                        <span
                                            className="label-bet-info platform-type">{t("labels:common.BET_BY_" + dataDetail.platformType)}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table detail-report">
                                    <thead>
                                    <tr>
                                        <th className="text-center">{t("labels:report.no")}</th>
                                        <th className="text-center">{t("labels:report.betType")}</th>
                                        <th className="text-center">{t("labels:report.betValue")}</th>
                                        <th className="text-center">{t("labels:report.jackpot")}</th>
                                        <th className="text-center">{t("labels:report.win")}</th>
                                        <th className="text-center">{t("labels:report.winLose")}</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        dataDetail.items.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="text-center">{i + 1}</td>
                                                    <td className="text-center">{t("labels:drawing.drawPrefix." + item.betCode)}</td>
                                                    <td className="text-center">
                                                        <NumberFormat value={item.betAmount} displayType={'text'}
                                                                      thousandSeparator={true}/> x {item.betValue}
                                                    </td>
                                                    <td className="text-center ">
                                                        {
                                                            !dataDetail.status ?
                                                                <span className="text-center">???</span>
                                                                :
                                                                <b> {item?.isJackpot && item.isWin ? "X" + item.jackpot : "---"}</b>

                                                        }

                                                    </td>

                                                    <td className="text-center">
                                                        {
                                                            !dataDetail.status ?
                                                                <span className="text-danger">???</span>
                                                                : moneyFormatter(item.winAmount, dataDetail.currencyCode, "text-danger")
                                                        }

                                                    </td>

                                                    <td className="text-center">
                                                        {
                                                            !dataDetail.status ?
                                                                <span className="text-danger">???</span>
                                                                : moneyFormatter(item.winLoseAmount, dataDetail.currencyCode, "text-primary")
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {dataDetail.bonusAmount > 0 && <tr>
                                        <td colSpan={2} className="text-center text-danger">{t("labels:common.bonus")}</td>
                                        <td colSpan={2}/>
                                        <td className="text-center">
                                            {moneyFormatter(dataDetail.bonusAmount, dataDetail.currencyCode, "text-danger")}
                                        </td>
                                        <td colSpan={3}/>
                                    </tr>}
                                    <tr>
                                        <td colSpan={2} className="text-center">{t("labels:common.total")}</td>

                                        <td className="text-center">
                                            {moneyFormatter(dataDetail.betAmount, dataDetail.currencyCode)}
                                        </td>
                                        <td/>

                                        <td className="text-center">
                                            {
                                                !dataDetail.status ?
                                                    <span className="text-danger">???</span>
                                                    : moneyFormatter((dataDetail.winAmount + dataDetail.bonusAmount), dataDetail.currencyCode, "text-danger")
                                            }

                                        </td>

                                        <td className="text-center">
                                            {
                                                !dataDetail.status ?
                                                    <span className="text-danger">???</span>
                                                    : moneyFormatter(dataDetail.winLoseAmount, dataDetail.currencyCode, "text-primary")
                                            }
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : null
                }
            </>
        );
    }
}

export default withTranslation()(TicketDetail);