import React from "react";
import {Row} from "reactstrap";
import {withTranslation} from "react-i18next";

class RowCard extends React.Component {
    render() {
        const {children, bodyClass = "card-body", mainClass = "align-items-end justify-content-end"} = this.props;
        return (
            <Row>
                <div className="col p-0">
                    <div className={`card border-radius-0 box-shadow-0 mb-1 ${mainClass}`}>
                        <div className={bodyClass}>
                            {children}
                        </div>
                    </div>
                </div>
            </Row>
        );
    }
}

export default withTranslation()(RowCard)