const setLotto5 = (value) => setLocal("lotto5", value);
const getLotto5 = () => getLocal("lotto5");

const setLotto6 = (value) => setLocal("lotto6", value);
const getLotto6 = () => getLocal("lotto6");

const setLotto12 = (value) => setLocal("lotto12", value);
const getLotto12 = () => getLocal("lotto12");

const toggleMute = (value) =>
    setLocal("mute", value);
const getMute = () =>
    getLocal("mute") ?? "ON";

const toggleMute5 = (value) =>
    setLocal("mute5", value);
const getMute5 = () =>
    getLocal("mute5") ?? "ON";

const toggleMute6 = (value) =>
    setLocal("mute6", value);
const getMute6 = () =>
    getLocal("mute6") ?? "ON";

const toggleMute12 = (value) =>
    setLocal("mute12", value);
const getMute12 = () =>
    getLocal("mute12") ?? "ON";

const toggleIsPrint = (value) =>
    setLocal("isPrint", value ? "ON" : "OFF");
const getIsPrint = () =>
    getLocal("isPrint") ?? "ON";

const toggleAddToCart = (value) =>
    setLocal("isAddToCart", value ? "ON" : "OFF");
const getIsAddToCart = () =>
    getLocal("isAddToCart") ?? "ON";

const setLocal = (key, value) =>
    localStorage.setItem(key, JSON.stringify(value))

const getLocal = (key) => {
    let data = localStorage.getItem(key);
    if (!data) return null;
    try {
        const res = JSON.parse(data)
        return res;
    } catch (e) {
        return data;
    }
}

const getUser = () => getLocal("user");

const getCardVersion = () => getUser()?.lotto12CardVersion ?? 'v1';

export
{
    getCardVersion,
    setLotto5,
    getLotto5,
    setLotto6,
    getLotto6,
    setLotto12,
    getLotto12,
    toggleMute,
    getMute,
    toggleMute5,
    getMute5,
    toggleMute6,
    getMute6,
    toggleMute12,
    getMute12,
    toggleIsPrint,
    getIsPrint,
    toggleAddToCart,
    getIsAddToCart,
    setLocal,
    getLocal,
    getUser
}
