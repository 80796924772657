import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";

const HistoryTableRow = ({t, index, object}) => {

    return (

        <tr className="animate-slide-up">
            <th className="text-center align-middle" scope="row">{index}</th>
            <th className="text-center align-middle" scope="row">{object.drawCode}</th>
            <td className="text-center align-middle">{moment(object.drawAt).format("YYYY-MM-DD hh:mm")}</td>

            <td className="text-center align-middle">{
                object.posts && object.posts.map((row, i) => {
                    return <span className={`item-value-result l99-post-${row.postCode}`}
                                 key={i}>{row.number} {object.posts.length === (i + 1) ? "" : ""}</span>
                })
            }</td>
        </tr>
    );
};

HistoryTableRow.propTypes = {
    index: PropTypes.number.isRequired,
    object: PropTypes.object.isRequired
};

export default withTranslation()(HistoryTableRow);
