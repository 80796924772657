import React, {useEffect, useRef} from "react";
import Lottie from "react-lottie";
import {useDispatch} from "react-redux";
import {
    setPlayAudio,
} from "../../../store/rootAction";
import cardSmallAudio from "../../../assets/audios/card-small.mp3";
import StatisticTableMix from "./StatisticTableMix";

import DatetimeContainer from "../../date/DatetimeContainer";
import {getHHmm} from "../../../utils/dateUtils";
import RebateRate from "../../../pages/components/RebateRate";
import CountDownTimer from "../../timer/CountDownTimer";
import ColorItems from "./ColorItems";
import {getColorNumbers, pfType} from "../../../constants/general";
import {getCardVersion, setLotto12} from "../../../utils/cached";
import {cardAudioUrl, isKp, lotto12CardUrl} from "../../../utils/asset";
import TabView from "../../lotto/TabView";
import RecentOrdering from "../RecentOrdering";
import RecentBetting from "../RecentBetting";
import LoadingGif from "../../../utils/LoadingGif";


export default (props) => {
    const {
        t, oldResult, isStopped, isStartFlip, setIsStartFlip, toggleMute, isMute,
        lastResult: {
            drawCode,
            result,
            drawAt,
            underOverCode,
            oddEvenCode,
            colorCode,
        },
        winUnderOver,
        winOddEven,
        winNumber,
        winColor,
        config: {lotto12Config}
    } = props;
    const jackpot = oldResult?.jackpot;
    const isShow = oldResult?.numberCode === oldResult?.jackpotType;
    const platformType = pfType;


    let lottoDraw = oldResult?.lottoDraw;
    const dispatch = useDispatch();
    const playCardAudio = (src) => {
        dispatch(setPlayAudio(src));
    };
    // init 11 cards arrays
    let list = Array(11).fill();
    const startDrawing = useRef(null);

    const clearInv = () => {
        startDrawing.current && clearInterval(startDrawing.current);
    }

    useEffect(() => {
        if (isStartFlip && lottoDraw) {
            setLotto12({showJackpotValue: jackpot > 1});
            if (isStopped) {
                // get index drawing result
                let drawingItem = lottoDraw.items.filter(e => e.isDrawRelease === 1);
                let index = lottoDraw.items.indexOf(drawingItem[0]);

                // set 11 card images
                lottoDraw.items.forEach((e, i) => {
                    if (e.isDrawRelease === 0) {
                        let doc = document.getElementById(`card-inner-${i}`);
                        if (doc) {
                            doc.classList.add("flip-180");
                        }
                        if (i === 10) {
                            let doc = document.getElementById(`card-inner-result`);
                            if (doc) doc.classList.add("flip-180");
                        }
                    }
                    let img = document.getElementById(`img-${i}`);
                    if (img) img.src = lotto12CardUrl(e?.numberCode);
                });

                // set image result
                let imgResult = document.getElementById(`img-result`);
                if (imgResult) imgResult.src = lotto12CardUrl(lottoDraw?.resultNumber);

                // start flip 11 cards
                clearInv();
                startDrawing.current = setInterval(() => {
                    let doc = document.getElementById(`card-inner-${index}`);

                    if (doc && !doc.classList.contains("flip-180")) {
                        isMute === "OFF" && playCardAudio(cardSmallAudio)
                        doc.classList.add("flip-180");
                    }
                    if (index >= 10) {
                        // flip result card
                        let doc = document.getElementById(`card-inner-result`);

                        if (doc) doc.classList.add("flip-180");
                        !isKp() && playCardAudio(cardAudioUrl(lottoDraw?.resultNumber));
                        let setWin = setTimeout(() => {
                            props.setWinLose();
                            clearTimeout(setWin);
                        }, 1000);
                        clearInv();
                        setIsStartFlip(false);
                    }
                    index++;

                }, 1400)

            }
        } else {
            clearInv();
        }
        return () => {
            clearInv();
        }

    })
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require(`../../../assets/images/lotto12/${platformType}.json`),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    const tabs = [
        t("labels:drawing.betting"),
        t("labels:drawing.result")
    ];

    const children = [
        <>
            <RecentOrdering {...props}/>
            <RecentBetting {...props}/>
        </>,
        <StatisticTableMix/>
    ]


    return <div className="no-padding-div">
        <div className="col row no-margin-div no-padding-div">

            <div className="col-10 header-div-title header-text fade-in no-padding-div lotto12-new-result">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <div className="title-bet-mix ml-3">{t("labels:menus.lotto")} 12</div>
                    <div className="text-white header-bet-mix">
                        <LoadingGif/>
                        {(props.newResult !== null && props.newResult.drawCode) ? props.newResult.drawCode + " " + getHHmm(props.newResult.createdAt) : t("labels:upcoming_draw")}
                    </div>
                    <div className="text-white fade-in datetime"
                         id="timer-display"><DatetimeContainer/></div>
                    <div className="">
                        <img
                            onClick={toggleMute}
                            alt="audio"
                            width={30}
                            className="mute-img"
                            style={{cursor: "pointer"}}
                            src={isMute === "ON" ? require("../../../assets/images/audio/mute_red.svg") : require("../../../assets/images/audio/volume_red.svg")}/>
                    </div>
                </div>
            </div>
            <div className="col-2 lotto12-countdown-number p-0">
                <CountDownTimer countClass="text-center f-32 " {...props} />
            </div>
        </div>
        <div className="lotto12-bg">
            {!isStopped ? <div className="lotto12-betting-animation">
                    <Lottie options={defaultOptions}
                            isStopped={isStopped}
                            height="51vw"
                            width="93%"
                            isPaused={false}/>
                </div> :
                <div className="h-random-card">
                    <div className="col-12">
                        <div className="pt-vw">
                            <div className=" row justify-content-center">
                                {
                                    list.map((_, i) => {
                                            return <div key={i} className="flip-card card-size no-padding-div">
                                                <div className="flip-card-inner" id={`card-inner-${i}`}>
                                                    <div className="flip-card-front">
                                                        <img className="img-responsive"
                                                             src={require(`../../../assets/images/lotto12/${platformType}.png`)}
                                                             alt=""/>
                                                    </div>
                                                    <div className="flip-card-back">
                                                        <img id={`img-${i}`} className="img-responsive"
                                                             src={require(`../../../assets/images/lotto12/${platformType}.png`)}
                                                             alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 no-padding-div pb-4">
                        <div className="row mt-vw justify-content-center no-margin-div">
                            <div className="no-padding-div">
                                <div className="flip-card-inner " id="card-inner-result">
                                    <div className="flip-card-front">
                                        <img className="img-responsive"
                                             src={require(`../../../assets/images/lotto12/${platformType}.png`)}
                                             alt=""/>
                                    </div>
                                    <div className="flip-card-back">
                                        {isShow &&
                                            <div className="jackpot-12-number">
                                                <img
                                                    width={50}
                                                    height={50}
                                                    className="jp-animate"
                                                    src={require(`../../../assets/images/jackpot/${jackpot ?? 2}.png`)}
                                                    alt=""/>
                                            </div>}
                                        <img id="img-result" className="img-responsive"
                                             src={require(`../../../assets/images/lotto12/${platformType}.png`)}
                                             alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>

        <div className=" no-padding-div">
            <div className="row no-margin-div">
                <div className="col-6 div-body-white fade-in">
                    <div className="row">
                        {
                            lotto12Config.underOverOddEven.map((item, i) =>
                                <div
                                    id={`card-${item.code}`}
                                    className={`col-6 no-padding-div pointer lotto6-box-bet lotto12-bet-pricing-card no-radius ${(item.code === winUnderOver || item.code === winOddEven) ? " color-animated" : ""}`}
                                    key={i}
                                    onClick={() => props.onBetting(item)}>
                                    <div className="slot-prefix-card">
                                        <div
                                            className={"lotto12-bet-" + item?.code}>
                                            <div
                                                className="lotto12-bet-label">{t("labels:betPrefix." + item?.code)}</div>
                                            <RebateRate classRebate={`rebate-${item.type}`} rate={item.value}/>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>

                <div className="col-6 div-body-white  fade-in">
                    <div className="row">
                        {
                            lotto12Config.colors.map((item, i) =>
                                <div id={`card-${item.code}`}
                                     className={`col-4 no-padding-div lotto12-bet-pricing-card no-radius ${item.code === winColor ? "color-animated" : ""}`}
                                     key={i}
                                     onClick={() => props.onBetting(item)}>
                                    <div className="slot-prefix-card">
                                        <div
                                            className={"lotto12-bet-" + item?.code}>
                                            <div
                                                className="lotto12-bet-label-color">{t("labels:betPrefix." + item?.code)}</div>
                                            <ColorItems values={getColorNumbers(item?.code)}/>
                                            <RebateRate classRebate={`rebate-${item.type}`} rate={item.value}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="col-12 border border-dark div-body-white fade-in">
                    <div className="row">
                        {
                            lotto12Config.numbers.map((item, i) =>
                                <div id={`card-${item.code}`}
                                     className={`col-2 no-padding-div ${item.code === winNumber ? "color-animated" : ""}`}
                                     key={i}
                                     onClick={() => props.onBetting(item)}>
                                    <div>
                                        {(isShow && winNumber === item.code) &&
                                            <div className="jackpot-12-number">
                                                <img id={item.code}
                                                     width={30}
                                                     className="jp-animate"
                                                     height={30}
                                                     src={require(`../../../assets/images/jackpot/${jackpot ?? 2}.png`)}
                                                     alt=""/>
                                            </div>}
                                        <img className="img-responsive-vw"
                                             src={lotto12CardUrl(item?.code)}
                                             alt=""/>
                                        {getCardVersion() === "v1" &&
                                            <RebateRate classRebate={`rebate-${item.type}`} rate={item.value}/>}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="col-12 header header-div-title-label header-text fade-in border-t border-b lotto12-new-result">
                            <span
                                className="text-white last-result-betting">{drawCode} {getHHmm(drawAt)}</span>
            {
                result ?
                    <span
                        className="text-white last-result-betting fade-in">{t("labels:betPrefix." + underOverCode) + "  /   " + t("labels:betPrefix." + oddEvenCode) + "  /   " + t("labels:betPrefix." + colorCode) + "   /   " + result}</span>
                    : <span className="text-white last-result-betting fade-in"/>
            }

        </div>
        <div className="tab-view border-all">
            <TabView children={children} tabs={tabs}/>
        </div>
    </div>;
}
