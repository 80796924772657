import {LIST_DRAWING_LO5D} from "./types";

const init = {
    data: {
        rebate: {twoD: 95, threeD: 950},
        isDrawing: false,
    }
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case LIST_DRAWING_LO5D:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload
                },
            };
        default:
            return state;
    }
}
