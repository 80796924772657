import React, {Component} from "react"
import {connect} from "react-redux"
import {login} from "../../store/rootAction"
import history from "../../routes/history"
import MessageValidation from "../../components/validation/MessageValidation"
import {env} from "../../env"

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            username: "",
            password: "",
            messageValid: "",
            showMessage: false,
        }
    }

    setUsername(e) {
        this.setState({
            username: e.target.value
        })
    }

    setPassword(e) {
        this.setState({
            password: e.target.value
        })
    }

    login(e) {
        e.preventDefault()

        if (this.state.username === "") {
            this.setState({showMessage: true, messageValid: "Username is required"})
        } else if (this.state.password === "") {
            this.setState({showMessage: true, messageValid: "Password is required"})
        } else {
            this.props.login({
                username: this.state.username,
                password: this.state.password
            }).then(() => {
                history.push("/");
                window.location.reload();
            }).catch(error => {
                this.setState({
                    showMessage: true,
                    messageValid: "Username and Password are not match"
                })
            })
        }
    }

    render() {
        let platform = env.PLATFORM_TYPE;
        return (
            <div className={`main-w platform-type-${env.PLATFORM_TYPE} wrap-bg-image`}>
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-game-content-login">

                            <div className="wrap-image-content">
                                <img className="image-content-one" alt=""/>
                                <form>
                                    <div className="wrap-form-login">
                                        <div className="wrap-input-form">
                                            <img className="mt-5" width={150}
                                                 src={require(`../../assets/images/logo/${platform}.png`)}
                                                 alt="..."/>
                                        </div>

                                        <div className="form-login-title text-white">Login</div>
                                        <div className="wrap-input-form-login">

                                            <input
                                                type="text"
                                                className="input-form-login"
                                                placeholder="Username"
                                                name="username"
                                                value={this.state.username}
                                                onChange={this.setUsername.bind(this)}
                                            />
                                        </div>

                                        <div className="wrap-input-form-login">
                                            {/* <div className="label-form-login text-white">Password</div> */}
                                            <input
                                                type="password"
                                                className="input-form-login"
                                                placeholder="Password"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.setPassword.bind(this)}
                                            />
                                        </div>

                                        <MessageValidation
                                            message={this.state.messageValid}
                                            isShake={this.state.showMessage}
                                        />

                                        <button className="btn-submit-login" onClick={this.login.bind(this)}>Login
                                        </button>
                                    </div>
                                </form>

                                <div className="wrap-input-form login-right-logo">
                                    <img className="login-logo" width={350}
                                         src={require(`../../assets/images/logo/${platform}.png`)} alt="..."/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.auth
})

const mapDispatchToProps = dispatch => ({
    login: payload => dispatch(login(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)
