import {LOTTO12V2_LIST_BETTING} from "./types";


const init = {
    betList: [],
    summary: {
        total: 0,
        currency: "",
        currencySignal: ""

    }
}


export default (state = init, {type, payload}) => {
    switch (type) {
        case LOTTO12V2_LIST_BETTING:
            return {
                ...state,
                ...payload,
            }
        default:
            return state;
    }
}
