import React, {Component} from "react";
import Lotto12Nav from "../../components/lotto12/Lotto12Nav";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    setBetStatus,
    setRenderResultStatus,
    setResultTotalNumberKeno,
    fetchLotto12ListBetting,
    addLotto12Betting,
    deleteLotto12Betting,
    lotto12ConfirmBettingNow,
    fetchUserBalance,
    fetchLotto12RecentDraw,
    fetchLotto12DrawingResult,
    fetchLotto12RecentBetReport,
    fetchUserBalanceValidate,
    clearCartLotto12,
    setIsAutoPrint,
    playCountDownAudio,
    playGoodLuckAudio, setIsAddToCart, fetchStatistic12, fetchLotto12RecentBetReportCache
} from "../../store/rootAction";
import moment from "moment";
import Dashboard from "../../components/lotto12/Dashboard";
import {clientAp} from "../../constants/general";
import {SUBSCRIPTION_LOTTO12} from "../../constants/lotto12";
import {env} from "../../env";
import swal from "sweetalert";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";


class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            counter: 1,
            bettingId: null,
            showDeleteBetting: false,
            showBetting: false,
            betCode: "",
            betAmount: 0,
            betGameType: "",
            betGameOdds: "",
            betGameRange: "",
            betGameTime: "",
            resultItem: null,
            resultItemRandom: null,
            indexResultItem: 0,
            allNumbers: [],
            timeCount: null,
            numberCode: null,
            winUnderOver: "",
            winOddEven: "",
            winNumber: "",
            winColor: "",
            betMessage: "",
            isShowRecentBet: false,
            recentBetDetail: null,
            isShowValidateBalance: false,
            imgLotto12: "",
            messageValidateBalance: null,
            status: null,
            isStopped: true,
            isStartFlip: false,
            isStoppedJackpot: true,
            showJackpotValue: false,
            isEnableBetNow: true
        };
    }


    onCloseValidateBalance() {
        this.setState({
            isShowValidateBalance: false,
            messageValidateBalance: null
        });
    }

    setWinLose() {
        let data = this.props.oldResult;
        data && this.setState({
            winUnderOver: data.underOverCode,
            winOddEven: data.oddEvenCode,
            winNumber: data.numberCode,
            winColor: data.colorCode,
        })
    }

    preSetNewGame() {
        this.setState({
            winUnderOver: "",
            winOddEven: "",
            winNumber: "",
            winColor: "",
            resultItem: null,
            indexResultItem: 0,
            totalResult: null,
        });

    }


    checkDrawingResult() {
        this.props.fetchLotto12DrawingResult()
            .then(() => {
                let countDownTimer = this.props.countDownTimer;
                if (countDownTimer <= 0) {
                    this.props.setRenderResultStatus({isStopRender: false});
                    this.getReleaseNum();
                }
            });
    }

    subscription = () => {
        if (socketLottoConfig.l12) {
            socket.on(socketEvent.l12, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            this.sp = clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO12,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto12_drawing) {
                        let currentDraw = event.data.v_lotto12_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    onDrawStatusChange(currentDraw) {
        if (currentDraw.status !== this.state.status) {
            if (this.state.status && currentDraw.status === "OPEN") {
                this.refreshData();
            }
            if (currentDraw.status === "RELEASE") this.props.fetchLotto12DrawingResult().then(() => this.getReleaseNum(true));
            this.setState({status: currentDraw.status});
            this.verfiredCurrentDraw(currentDraw.drawCode);
        }
    }

    verfiredCurrentDraw = currentDrawCode => {
        let betList = JSON.parse(sessionStorage.getItem("betting_lotto12") || "[]");
        if (betList.length) {
            let firstItem = betList[0];
            if (firstItem.drawCode !== currentDrawCode) {
                this.props.clearCart();
            }
        }

    }

    refreshData = () => {
        this.setState({isStopped: false, showJackpotValue: false});
        this.refresh = setTimeout(() => {
            this.setState({isStopped: true}, () => clearTimeout(this.refresh));
        }, 13000);
        this.checkDrawingResult();
        this.props.fetchLotto12RecentBetReportCache();
        this.props.fetchUserBalance()
        this.props.fetchLotto12RecentDraw();
        this.preSetNewGame();
        this.props.fetchStatistic12();
    }

    componentDidUpdate() {
        if (this.props.isStopBet && this.state.showBetting) {
            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betGameRange: "",
                betGameTime: ""
            })
        }
    }

    componentDidMount() {
        this.subscription();
        this.checkDrawingResult();
        this.props.fetchUserBalance()
        this.props.fetchStatistic12();
        this.props.fetchLotto12ListBetting({isClear: true})
        this.props.fetchLotto12RecentBetReport();
        this.props.fetchLotto12RecentDraw();
    }

    componentWillUnmount() {
        if (this.drawingTime) {
            clearTimeout(this.drawingTime)
        }
        this.sp && this.sp.unsubscribe();
        removeSocketListener(socketEvent.l12);
    }

    onBetting(item) {
        if (!this.props.isStopBet) {
            this.props.fetchUserBalanceValidate()
                .then(() => {
                    this.setState({
                        showBetting: true,
                        betGameType: item.name,
                        betGameOdds: item.value,
                        betGameRange: item.resultRange,
                        betCode: item.code,
                        betGameTime: moment(new Date()).format('HH:mm:ss')
                    });
                }).catch(error => {
                const {t} = this.props;
                this.setState({
                    isShowValidateBalance: true,
                    messageValidateBalance: t("labels:common." + error.response.data.message)
                });
            })
        }
    }

    clickAmountBetBtn(amount) {
        let betAmount = this.state.betAmount || 0

        this.setState({
            betAmount: parseInt(betAmount) + amount
        })
    }

    resetDefaultAmount() {
        this.setState({betAmount: 0, pendingLotto12: this.props.user.userBalance.pendingLotto12})
    }

    changeBetAmount(e) {
        if (e.target.value >= 0 || !isNaN(e.target.value) || e.target.value === undefined) {
            this.setState({betAmount: e.target.value})
        }
    }

    isValidBetAmount = (betAmount = 0) => {
        betAmount = parseInt(betAmount);
        if (typeof betAmount !== "number") {
            return false;
        }
        const {maxBet, minBet} = this.props.config.limit;

        if (betAmount < minBet || betAmount > maxBet)
            return false;

        return true
    }

    async onConfirmBetting(logs) {
        const {t, user: {userBalance, isAddToCart, isAutoPrint}, fetchLotto12ListBetting} = this.props
        const betAmount = this.state.betAmount;
        let totalCartAmount = fetchLotto12ListBetting({isClear: false});
        if (!this.isValidBetAmount(betAmount)) {
            this.setState({betMessage: t("labels:keno.requireAmount")})
        } else if ((totalCartAmount + betAmount) > userBalance.balance) {
            this.setState({betMessage: t("labels:common.NOT_ENOUGH_BALANCE")})
        } else {
            const {
                betCode,
                betAmount,
                betGameOdds
            } = this.state;
            const data = {
                drawCode: this.props.newResult.drawCode,
                betTypeCode: betCode,
                amount: betAmount,
                betLog: logs,
                rebateRate: betGameOdds
            }

            await this.props.addLotto12Betting(data);
            !isAddToCart && this.submitBetNow(isAutoPrint);

            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betCode: "",
                betGameRange: "",
                betGameTime: "",
                betMessage: null
            })
        }
    }

    getReleaseNum = () => {
        this.setIsStartFlip(true);
    }

    countDownCallBack = (count) => {
        if (this.state.winNumber !== "" && count > 0) {
            this.checkDrawingResult();
            this.preSetNewGame();
            this.setIsStartFlip(false);
            return true;
        } else return false;
    }

    countingSecond(count) {
        if (this.props.isStopRender) {
            if (this.state.countDownTimer < 0) {
                this.props.setRenderResultStatus({isStopRender: true})
            } else {
                let audio = new Audio();
                if (count >= 10) {
                    // this.setState({classCountDown: "lotto6-v2-total-result-render-count"})
                } else if (count < 10 && count > 0) {
                    this.props.playCountDownAudio(count - 1);
                    // this.setState({classCountDown: "lotto6-v2-total-result-render-count-warning"})
                    this.props.setBetStatus({isStopBet: true})
                } else if (count === 0) {
                    this.props.playGoodLuckAduio();
                    // this.setState({classCountDown: "lotto6-v2-total-result-render-count-total"})
                    this.props.clearCart();
                    this.props.setRenderResultStatus({isStopRender: false})
                    this.props.fetchLotto12ListBetting({isClear: true})
                    this.props.fetchUserBalance()
                        .then(() => {
                            this.setState({pendingLotto12: this.props.user.userBalance.pendingLotto12})
                        })
                }
                // when the sound has been loaded, execute your code
                audio.oncanplaythrough = (event) => {
                    let playedPromise = audio.play();
                    if (playedPromise) {
                        playedPromise.catch((e) => {
                            if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                console.log(e.name);
                            }
                        }).then(() => {
                            console.log("playing sound !!!");
                        });
                    }
                }


            }
        }
    }

    onCancelBetting() {
        this.setState({
            showBetting: false,
            betAmount: 0,
            betGameType: '',
            betGameOdds: '',
            pendingLotto12: this.props.user.userBalance.pendingLotto12,
            betMessage: "",
            betGameRange: "",
            betGameTime: ""
        })
    }

    showConfirmDelete(id) {
        this.setState({showDeleteBetting: false, bettingId: null})
        this.props.deleteLotto12Betting({id: id})
    }

    submitBetNow(isPrint) {
        const {isEnableBetNow} = this.state;
        isEnableBetNow && this.props.lotto12ConfirmBettingNow(() => this.setState({isEnableBetNow: false}))
            .then(() => {
                this.props.fetchLotto12RecentBetReport().then(() => isPrint && document.getElementById("print-button").click());
                this.props.fetchUserBalance();
                this.setState({isEnableBetNow: true})
            }).catch((error) => {
                this.setState({isEnableBetNow: true})
                const {response} = error;
                response && swal(response.data.message, {icon: "error", button: "បិទ"});
            })
    }

    onShowDetail(item) {
        this.setState({
            isShowRecentBet: true,
            recentBetDetail: item
        });
    }

    setIsStartFlip = (value) => this.setState({isStartFlip: value});

    onCloseDetail() {
        this.setState({
            isShowRecentBet: false,
            recentBetDetail: null
        });
    }

    afterPrint() {
        this.props.fetchLotto12DrawingResult().then(response => {
            this.setState({
                timeCount: response.data.data.countDownTimer
            });
        })
    }

    render() {
        const betFunctions = {
            showConfirmDelete: this.showConfirmDelete.bind(this),
            onBetting: this.onBetting.bind(this),
            onCancelBetting: this.onCancelBetting.bind(this),
            changeBetAmount: this.changeBetAmount.bind(this),
            clickAmountBetBtn: this.clickAmountBetBtn.bind(this),
            onConfirmBetting: this.onConfirmBetting.bind(this),
            resetDefaultAmount: this.resetDefaultAmount.bind(this),
            onCloseValidateBalance: this.onCloseValidateBalance.bind(this),
            submitBetNow: this.submitBetNow.bind(this),
            setWinLose: this.setWinLose.bind(this),
            afterPrint: this.afterPrint.bind(this),
            setIsStartFlip: this.setIsStartFlip,
            tenSecondCallback: this.countingSecond.bind(this),
            countDownCallBack: this.countDownCallBack,
            platform: env.PLATFORM_FOR,
            setIsAddToCart: this.props.setIsAddToCart
        }
        return (
            <div className="main-w">
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-new-keno">
                            <Lotto12Nav/>
                            <Dashboard
                                {...betFunctions}
                                {...this.props}
                                {...this.state}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.lotto12,
    config: state.config,
    user: state.user,
    audio: state.audio
})

const mapDispatchToProps = dispatch => ({
    setBetStatus: payload => dispatch(setBetStatus(payload)),
    setRenderResultStatus: payload => dispatch(setRenderResultStatus(payload)),
    setResultTotalNumber: payload => dispatch(setResultTotalNumberKeno(payload)),
    fetchLotto12ListBetting: payload => dispatch(fetchLotto12ListBetting(payload)),
    addLotto12Betting: payload => dispatch(addLotto12Betting(payload)),
    deleteLotto12Betting: payload => dispatch(deleteLotto12Betting(payload)),
    lotto12ConfirmBettingNow: callback => dispatch(lotto12ConfirmBettingNow(() => callback())),
    fetchUserBalance: () => dispatch(fetchUserBalance()),
    fetchLotto12DrawingResult: () => dispatch(fetchLotto12DrawingResult()),
    fetchLotto12RecentDraw: payload => dispatch(fetchLotto12RecentDraw(payload)),
    fetchLotto12RecentBetReport: () => dispatch(fetchLotto12RecentBetReport()),
    fetchLotto12RecentBetReportCache: () => dispatch(fetchLotto12RecentBetReportCache()),
    fetchUserBalanceValidate: () => dispatch(fetchUserBalanceValidate()),
    setIsAutoPrint: payload => dispatch(setIsAutoPrint(payload)),
    clearCart: () => dispatch(clearCartLotto12()),
    playCountDownAudio: index => dispatch(playCountDownAudio(index)),
    playGoodLuckAduio: () => dispatch(playGoodLuckAudio()),
    fetchStatistic12: () => dispatch(fetchStatistic12()),
    setIsAddToCart: payload => dispatch(setIsAddToCart(payload)),
})

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index))
