import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Dashboard from "../../components/lotto12v2/Dashboard";
import Lotto12v2Nav from "../../components/lotto12v2/Lotto12v2Nav";
import {lotto12DrawStyle} from "../../constants/general";

class Index extends Component {
    render() {
        return (
            <div>
                <Lotto12v2Nav/>
                <Dashboard drawStyle={lotto12DrawStyle.circle}/>
            </div>
        );
    }
}


export default withTranslation()(Index)
