import zodiac_left_bg from "../../assets/images/lotto12v2/zodiac_left_bg.png";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import CountdownTimer from "./CountdownTimer";
import {imgUrl} from "../../utils/asset";
import {lott12v2Config, padNumber2} from "../../constants/general";

const DrawingTable = ({drawing}) => {

    const {isDrawing, countDownTimer, drawCode, active, isWin, oldResult} = drawing;

    const imageSrc = (code) => imgUrl(`lotto12v2/cards/${code}.png`)
    const imageSrcActive = (code) => imgUrl(`lotto12v2/cards/active/${code}.png`)


    const Card = ({code, className = "zodiac_item"}) => {
        const isActive = active.includes(code);
        let src = isActive ? imageSrcActive(code) : imageSrc(code);
        return <div className={className}><img
            className="zodiac_item_img"
            src={src}
            alt=""/></div>
    }

    return <div className="turntable">
        <img id="img-border-outline" src={imgUrl("lotto12v2/bg-light-1.png")} alt=""
             className="deng-img"/>
        <div style={{
            backgroundImage: `url(${zodiac_left_bg})`,
        }}
             className="zodiac_div">
            <div className="flex-one">
                {lott12v2Config.red.map((e, i) => {
                    return <Card key={i} code={e}/>
                })}
            </div>
            <div className="flex-two">
                <div className="flex-f">
                    <Card code={12} className="zodiac_item_w14"/>
                    <Card code={11} className="zodiac_item_w14"/>
                </div>
                <div className="w72">
                    <div className="zodiac_center"><p
                        className="content-left-bet-issueno">{drawCode}</p>
                        {isWin && oldResult ? <div className="currentNumber_on">
                            <div className="text-result">{padNumber2(oldResult?.result)}</div>
                            <img className="img-result" src={imgUrl(`lotto12v2/signs/${oldResult?.result}.png`)}
                                 alt=""/>
                        </div> : <CountdownTimer isDrawing={isDrawing} time={countDownTimer}/>}
                    </div>
                </div>
                <div className="flex-f">
                    <Card code={5} className="zodiac_item_w14"/>
                    <Card code={6} className="zodiac_item_w14"/>

                </div>
            </div>
            <div className="flex-one">
                {lott12v2Config.rd.map((e, i) => {
                    return <Card key={i} code={e}/>
                })}
            </div>
        </div>
    </div>
}
const mapStateToProps = state => ({
    drawing: state.lotto12v2.drawing
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(DrawingTable));
