import React, {Component} from "react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import moment from "moment";
import 'moment-timezone';
import {withTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import Paginate from "../../table/Paginate";
import Printer from "../../print/Printer";
import SweetAlert from "react-bootstrap-sweetalert";
import Images from "../../../constants/lotto5Images";
import {currentDate, getDateDef} from "../../../utils/dateUtils";
import TicketDetail from "../../../components/lotto6/TicketDetail";
import {getHHmm} from "../../../utils/dateUtils";
import LottoMixNav from "../../lotto/mix/LottoMixNav";
import {fetchLottoWinLossReport} from "../../../store/lotto/action";

class LottoWinReportMix extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            lottoType: "LOTTO5",
            date: currentDate(),
            isShow: false,
            dataDetail: null
        };
    }

    componentDidMount() {
        this.onFilter();
    }

    onChange(page) {
        this.onFilter({page: page})
        this.setState({page: page});
    }

    onFilter = (params = {}) => {
        const {lottoType, date} = this.state;
        this.props.fetchLottoWinLossReport({
            ...this.props.filter,
            filterDate: getDateDef(date),
            filterByLotto: lottoType, ...params
        })
    }
    onChangeLottery = (e) => {
        this.setState({lottoType: e.target?.value});
    }

    onShowDetail(item) {
        this.setState({
            isShow: true,
            dataDetail: item
        });
    }

    onCloseDetail() {
        this.setState({
            isShow: false,
            dataDetail: null
        });
    }

    getResultType = (code, last = " / ") => {
        return code !== "" ? this.props.t("labels:drawing.drawPrefix." + code) + last : ""
    }

    render() {
        const {t, winReport: {tickets, summary}} = this.props;
        const dataDetail = this.state.dataDetail;
        return (
            <div className="main-w ">
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-game-content">
                            <LottoMixNav {...this.props}/>
                            <div className="fade-in">
                                <div className="row align-items-center ml-3 mt-2 pb-0">
                                    <div className="lottery-type">
                                        <label>{t("labels:common.lotteryType")} :</label>
                                        <select className="border" onChange={this.onChangeLottery}>
                                            <option value="LOTTO5">{t("labels:menus.lotto")} 5</option>
                                            <option value="LOTTO6">{t("labels:menus.lotto")} 6/36</option>
                                            <option value="LOTTO12">{t("labels:menus.lotto")} 12</option>
                                        </select>
                                    </div>
                                    <div className="form-date-range">
                                        <label>{t("labels:drawing.date")}:</label>
                                    </div>
                                    <div className="pl-2">
                                        <DatePicker
                                            dateFormat="dd-MM-yyyy"
                                            selected={this.state.date}
                                            maxDate={moment().toDate()}
                                            onChange={date => this.setState({date: date})}
                                            className="form-control bg-white"/>
                                    </div>
                                    <div className="wrap-btn-filter">
                                        <button className="btn-game"
                                                onClick={() => this.onFilter()}>{t("labels:common.getResult")}</button>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="table-container pt-0">
                                        <table className="table-custom">
                                            <thead>
                                            <tr>
                                                <th className="text-center">{t("labels:report.no")}</th>
                                                <th className="text-center">{t("labels:report.ticketCode")}</th>
                                                <th className="text-center">{t("labels:report.drawCodeTime")}</th>
                                                <th className="text-center">{t("labels:report.result")}</th>
                                                <th className="text-center">{t("labels:report.qty")}</th>
                                                <th className="text-center">{t("labels:report.betAmount")}</th>
                                                <th className="text-center">{t("labels:report.win")}</th>
                                                <th className="text-center">{t("labels:report.winLose")}</th>
                                                <th className="text-center">{t("labels:report.print")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                _.isEmpty(tickets)
                                                    ? <tr>
                                                        <td colSpan={10}><img className="data-not-found-img"
                                                                              src={Images.dataNotFound} alt="..."/></td>
                                                    </tr>
                                                    : tickets.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td className="text-center">{item.ticketCode}</td>
                                                                <td className="text-center"><span
                                                                    className="title-ticket-id-btn"
                                                                    onClick={this.onShowDetail.bind(this, item)}>{`${item.drawCode} ${getHHmm(item.drawAt)}`}</span>
                                                                </td>
                                                                <td className="text-center">{item.result + " => "
                                                                + t("labels:drawing.drawPrefix." + item.underOverCode) + " / "
                                                                + this.getResultType(item.oddEvenCode)
                                                                + this.getResultType(item.colorCode)
                                                                + this.getResultType(item.rangeCode,)
                                                                }</td>
                                                                <td className="text-center">{item.quantity}</td>
                                                                <td className="text-right pr-2">
                                                                    <NumberFormat
                                                                        value={item.betAmount}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        suffix={" " + item.currencyCode}
                                                                    />
                                                                </td>
                                                                <td className="text-right pr-2">
                                                                    <NumberFormat
                                                                        value={item.winAmount}
                                                                        renderText={value => <span
                                                                            className="text-danger">{value}</span>}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        suffix={" " + item.currencyCode}
                                                                    />
                                                                </td>
                                                                <td className="text-right pr-2">
                                                                    <NumberFormat
                                                                        value={item.winLoseAmount}
                                                                        renderText={value => <span
                                                                            className={item.winLoseAmount > 0 ? "text-primary" : "text-danger"}>{value}</span>}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        suffix={" " + item.currencyCode}
                                                                    />
                                                                </td>
                                                                <td>{item.winAmount > 0 ?
                                                                    <Printer data={item}
                                                                             lottoType={this.state.lottoType} {...this.props}/> : null}</td>
                                                            </tr>
                                                        );
                                                    })
                                            }
                                            <tr className="win-footer">
                                                <td className="text-center" colSpan={5}><span
                                                    className="pl-2">{t("labels:common.total")}</span></td>
                                                <td className="text-right pr-2"><NumberFormat
                                                    value={summary.totalBetAmount} displayType={'text'}
                                                    thousandSeparator={true} suffix={" " + summary.currencyCode}/></td>
                                                <td className="text-right pr-2"><NumberFormat className="text-danger"
                                                                                              value={summary.totalWinAmount}
                                                                                              displayType={'text'}
                                                                                              thousandSeparator={true}
                                                                                              suffix={" " + summary.currencyCode}/>
                                                </td>
                                                <td className="text-right pr-2"><NumberFormat
                                                    className={summary.totalWinLoseAmount > 0 ? "text-primary" : "text-danger"}
                                                    value={summary.totalWinLoseAmount} displayType={'text'}
                                                    thousandSeparator={true} suffix={" " + summary.currencyCode}/></td>
                                                <td className="text-right pr-2"/>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    {
                                        this.props.totals > 0
                                            ? <div className="mt-2">
                                                <Paginate
                                                    page={this.state.page}
                                                    size={this.props.size}
                                                    total={this.props.totals}
                                                    onChange={page => this.onChange(page)}
                                                />
                                            </div>
                                            : null
                                    }

                                    <SweetAlert
                                        title=""
                                        show={this.state.isShow}
                                        onConfirm={this.onCloseDetail.bind(this)}
                                        confirmBtnText={t("labels:common.close")}
                                        showCancel={false}
                                        btnSize="lg"
                                    >
                                        <TicketDetail dataDetail={dataDetail}/>
                                    </SweetAlert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    winReport: state.lotto.winReport,
});

const mapDispatchToProps = dispatch => ({
    fetchLottoWinLossReport: payload => dispatch(fetchLottoWinLossReport(payload)),

});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(LottoWinReportMix));
