import Countdown, {zeroPad} from "react-countdown";
import React from "react";
import {withTranslation} from "react-i18next";
import {playAudio} from "../../constants/Audio";
import PropTypes from "prop-types";

const renderer = ({hours, minutes, seconds, props}) => {
    const warnClass = minutes * 60 + seconds <= 6 ? "text-danger" : "";
    return <div className={props.className}><span
        className={`countdown ${warnClass}`}>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    </div>
};

class CountdownTimer extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.time !== this.props.time || nextProps.isDrawing !== this.props.isDrawing) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        let {time, isDrawing, t, className} = this.props;
        if (time && time > 0) time *= 1000;
        else time = 0;
        if (isDrawing) return <div className={className}><span
            className="countdown-title">{t("labels:drawing.lotto90.drawing")}</span>
        </div>

        return time > 0 && <Countdown
            onTick={(va) => {
                let sec = va.total / 1000;
                if (sec === 6)
                    playAudio("lotto12v2/countdownNew.mp3")
            }}
            className={className}
            date={Date.now() + time}
            renderer={renderer}
        />
    }
}


CountdownTimer.propTypes = {
    time: PropTypes.number.isRequired,
    isDrawing: PropTypes.bool,
    className: PropTypes.string
}
CountdownTimer.defaultProps = {
    className: "currentNumber_off"
}


export default withTranslation()(CountdownTimer);
