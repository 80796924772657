import {LOTTO12V2_FETCH_RECENT_BET_REPORT} from "./types";


const init = {
    list: [],
}


export default (state = init, {type, payload}) => {
    switch (type) {
        case LOTTO12V2_FETCH_RECENT_BET_REPORT:
            return {
                ...state,
                list: payload,
            }
        default:
            return state;
    }
}
