import groups_bg from "../../assets/images/lotto99/groups_bg.png";
import React from "react";
import CountdownTimer from "../lotto12v2/CountdownTimer";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {imgUrl} from "../../utils/asset";
import BallNumber99 from "./BallNumber99";
import ResultHistory from "./ResultHistory";
import {env} from "../../env";
import {getDate} from "../../utils/dateUtils";

const DrawingResult = ({drawing, isFull, status, t}) => {
    const {countDownTimer, isDrawing, detail, drawCode, drawAt, newResult} = drawing;
    let platformType = env.PLATFORM_TYPE;


    return <>
        <div className="content-left-bet">
            <div className="flex-between">
                <div className="lotto99">
                    <img className="logo2" src={imgUrl(`logo/${platformType}.png`)} alt=""/>
                    <span className="lotto-name kh-font-battambang font-moul">៩៩ សំណាង</span>
                    <div className="text-left">
                        <span className="p-2 date-sec-lo99">{getDate(drawAt)}</span>
                        <span className="p-2 ticket-sec-lo99">{drawCode}</span>
                    </div>
                </div>
                <div className="draw-timer-wrap">
                    {!isDrawing ? <>
                            <CountdownTimer time={countDownTimer}
                                            isDrawing={isDrawing}
                                            className="content-left-bet-countdown"/>
                            <span
                                className="p-2-vw date-sec">{getDate(newResult?.drawAt)}</span>
                            <span
                                className="p-2-vw ticket-sec">{newResult?.drawCode}</span>
                        </>
                        : <div className="time-sec-title">{t("labels:drawing.lotto90.drawing")}</div>}
                </div>
            </div>
            <div style={{
                backgroundImage: `url(${groups_bg})`,
            }} className="issueNo">
                {detail?.map((e, i) => {
                    return <div key={i} className="wrap-result-l99">
                        <div className={`issueNo-row-bottom color${i + 1}`}><span>{e}</span></div>
                    </div>
                })}
            </div>
            {isFull && <BallNumber99 drawing={drawing} status={status}/>}
        </div>
        {isFull && <div className="col-3 p-0 bg-his-result">
            <ResultHistory isFull={isFull}/>
        </div>
        }
    </>
}

const mapStateToProps = state => ({
    drawing: state.lotto99.drawing
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(DrawingResult));
