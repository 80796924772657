import RowCard from "./RowCard";
import {Button} from "react-bootstrap";
import React from "react";

export default ({
                    currentTicket, handleSelectChange, onClearBetItem, onAdded,
                    activeSubmit,
                    handleSubmitBetting, ...props
                }) => {
    return (
        <RowCard mainClass="lo5d-bet-btn align-items-end justify-content-center" bodyClass="betting-h">
            <div className="row align-items-start justify-content-between">
                <div>
                    <div className="d-flex justify-content-center w-100 m-auto">
                        <Button id="save"
                                className="bg-green border-0 m-btn-bet custom-btn"
                                disabled={!activeSubmit}
                                onClick={() => handleSubmitBetting(false)}>
                            {props.t("buttons:save")}
                        </Button>
                        <Button disabled={!activeSubmit}
                                className='m-btn-bet custom-btn'
                                id="saveprint"
                                onClick={() => handleSubmitBetting(true)}>
                            {props.t("buttons:print")}
                        </Button>
                        <Button
                            id="clear"
                            className="bg-danger border-0 m-btn-bet custom-btn"
                            onClick={onClearBetItem}>
                            {props.t("buttons:clear")}
                        </Button>
                        <Button
                            className='m-btn-bet custom-btn'
                            id="ready"
                            onClick={onAdded}>
                            {props.t("buttons:added")}
                        </Button>
                    </div>
                </div>
            </div>
        </RowCard>
    );
}