import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {
    setBetStatusLotto6,
    setRenderResultStatus,
    setResultTotalNumberKeno,
    fetchLotto6RecentDraw,
    fetchLotto6DrawingResult,
    setIsAutoPrint,
    playCountDownAudio,
    playGoodLuckAudio,
    fetchStatistic6,
    lotto6AddBetting,
    deleteLotto6Betting,
    lotto6ConfirmBettingNow,
    fetchLotto6ListBetting,
    fetchLotto6RecentBetReport,
    fetchUserBalance,
    fetchUserBalanceValidate,
    clearCartLotto6,
    setIsAddToCart, fetchLotto6RecentBetReportCache,
} from "../../store/rootAction";
import {BetCodes6, clientAp, jackpotNum} from "../../constants/general";
import {SUBSCRIPTION_LOTTO6} from "../../constants/lotto6";
import {playAudio} from "../../store/audio/action";
import {env} from "../../env";
import Dashboard2Mix from "../../components/lotto6/mix/Dashboard2Mix";
import {getResult} from "../../utils/general";
import {getMute6, setLotto6, toggleMute6} from "../../utils/cached";
import _ from "lodash";
import winSound from "../../assets/audios/lotto5_win.mp3";
import BettingDialog from "../../components/lotto5/BettingDialog";
import moment from "moment/moment";
import swal from "sweetalert";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";

class Lotto6DashboardMix extends Component {

    constructor(props) {
        super(props)
        this.state = {
            resultItem: null,
            resultItemRandom: null,
            indexResultItem: 0,
            totalResult: null,
            allNumbers: [],
            timeCount: null,
            classNameNumberDraw: "lotto6-hide-number-draw",
            classNameLogoRender: "lotto6-hide-logo-render",
            classCountDown: "lotto6-mix-total-result-render-count",
            winUnderOver: "",
            winOddEven: "",
            winRange: "",
            winRangeLine: "",
            winRangeLineOne: "",
            isShowRecentBet: false,
            imageRotate: "",
            imageBalanceRotate: "",
            imageResultRotate: "",
            isStoppedJackpot: true,
            showJackpotValue: false,
            isEnableBetNow: true,
            isMute: "ON",
            counter: 1,
            bettingId: null,
            showDeleteBetting: false,
            showBetting: false,
            betCode: "",
            betAmount: 0,
            betGameType: "",
            betGameOdds: "",
            betGameRange: "",
            betGameTime: "",
            betMessage: "",
            isShowValidateBalance: false,
            messageValidateBalance: null,
        };
    }

    componentDidMount() {
        this.subscription();
        this.checkDrawingResult()
        this.props.fetchStatistic6({row: 6, column: 7});
        this.listenLocalStorage();
        this.getRecentDraw();
        this.props.fetchLotto6ListBetting({isClear: true})
        this.props.fetchLotto6RecentBetReport()
        this.props.fetchUserBalance();
    }

    listenLocalStorage = () => {
        window.onstorage = () => {
            let mute = getMute6();
            if (mute !== this.state.isMute) {
                this.setState({isMute: mute});
            }
        }
    }

    getRecentDraw = () => {
        this.props.fetchLotto6RecentDraw()
            .then(() => {
                let lastResult = this.props.lastResult;
                if (this.props.isStopRender) {
                    this.setState({allNumbers: lastResult.details})
                }
            });
    }

    componentDidUpdate() {
        if (this.props.isStopBet && this.state.showBetting) {
            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betGameRange: "",
                betGameTime: ""
            })
        }
    }

    componentWillUnmount() {
        if (this.drawingTime) clearTimeout(this.drawingTime)
        removeSocketListener(socketEvent.l6);
    }

    toggleMute = () => {
        this.setState((preState) => ({
            isMute: preState.isMute === "OFF" ? "ON" : "OFF"
        }), () => toggleMute6(this.state.isMute));
    }

    playAudio = (src) => {
        const {audio} = this.props;
        if (audio && this.state.isMute === "OFF") playAudio(src);
    };

    subscription = () => {
        if (socketLottoConfig.l6) {
            socket.on(socketEvent.l6, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO6,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto6_drawing) {
                        let currentDraw = event.data.v_lotto6_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    onDrawStatusChange(currentDraw) {
        if (currentDraw.status !== this.state.status) {
            if (this.state.status && currentDraw.status === "OPEN") {
                this.refreshData();
            }
            this.setState({status: currentDraw.status});
        }
        this.verifyCurrentDraw(currentDraw.drawCode);
    }

    verifyCurrentDraw = currentDrawCode => {
        let betList = JSON.parse(sessionStorage.getItem("betting_lotto6") || "[]");
        if (betList.length) {
            let firstItem = betList[0];
            if (firstItem.drawCode !== currentDrawCode) {
                this.props.clearCart();
            }
        }

    }


    showResultRender = () => {
        this.random && clearInterval(this.random);

        this.jackpotInv && clearInterval(this.jackpotInv);
        let num = 0;
        if (this.props.oldResult?.jackpot > 1) {
            this.jackpotInv = setInterval(() => {
                if (num >= 6) num = 0;
                num += 1;
                this.setState({
                    jackpot: num,
                });
            }, 800);
        } else {
            this.setState({
                jackpot: 0,
            });
        }

        this.setState({
            classNameNumberDraw: "lotto6-mix-show-number-draw",
            classNameLogoRender: "lotto6-hide-logo-render"
        });
        this.drawReleaseResult(3000);
        if (this.drawingTime) {
            clearInterval(this.drawingTime)
        }
        this.drawingTime = setInterval(() => {
            this.drawReleaseResult(2500);
        }, 3500)
    }

    drawReleaseResult = (timeout) => {
        let indexResultItem = this.state.indexResultItem + 1
        if (indexResultItem < 7) {
            this.playAudio("https://bct168-s3.s3-ap-southeast-1.amazonaws.com/sound/drawing/drawing-3-new.mp3")
        }
        if (indexResultItem === 7) {
            this.setWinLose(indexResultItem);
        } else if (indexResultItem === 8) {
            // this.presetNewResult();
        } else {
            let resultItem = 1
            if (this.random) {
                clearInterval(this.random);
            }
            this.random = setInterval(() => {
                if (resultItem === 37) {
                    resultItem = 1
                }
                this.setState({resultItem: resultItem++})
            }, 10)
            this.startDrawing && clearTimeout(this.startDrawing);
            this.startDrawing = setTimeout(() => {
                if (this.random) {
                    clearInterval(this.random)
                }
                let {resultItem, totalResult, allNumbersDrawing} = getResult(indexResultItem, this.props.oldResult);
                this.setState({
                    resultItem: resultItem,
                    indexResultItem: indexResultItem,
                    totalResult: totalResult,
                    allNumbers: allNumbersDrawing,
                    classNameNumberDraw: "lotto6-mix-show-number-draw"
                }, () => clearTimeout(this.startDrawing));
            }, timeout)
        }
    }

    setWinLose(indexResultItem) {
        let {oldResult} = this.props;
        let jp = _.toUpper(oldResult?.rangeCode) === oldResult.jackpotType ? jackpotNum[oldResult.jackpotType] : 0

        this.jackpotInv && clearInterval(this.jackpotInv);
        jp > 0 && this.playAudio(winSound);
        let src = `https://bct168-s3.s3-ap-southeast-1.amazonaws.com/sound/result/result-${BetCodes6[oldResult?.rangeCode]}-${BetCodes6[oldResult?.underOverCode]}.mp3`;
        const winUnderOver = oldResult?.underOverCode;
        const winOddEven = oldResult?.oddEvenCode;
        const winRange = oldResult?.rangeCode;
        const winRangeLine = oldResult?.rangeLineCode;
        const winRangeLineOne = oldResult?.rangeLineOneCode;

        let win = {
            winUnderOver: winUnderOver,
            winOddEven: winOddEven,
            winRange: winRange,
            winRangeLine: winRangeLine,
            winRangeLineOne: winRangeLineOne,
        }
        setLotto6({...win})
        this.setState({
            ...win,
            resultItem: null,
            indexResultItem: indexResultItem,
            totalResult: null,
            jackpot: jp,
            classNameNumberDraw: "lotto6-hide-number-draw",
            classNameLogoRender: "lotto6-show-logo-render",
            classCountDown: "lotto6-mix-total-result-render-count "
        })
        // when the sound has been loaded, execute your code
        this.playAudio(src)
    }

    presetNewResult() {
        if (this.drawingTime) {
            clearInterval(this.drawingTime);
        }
        this.setState({
            winUnderOver: "",
            winOddEven: "",
            winRange: "",
            winRangeLine: "",
            winRangeLineOne: "",
            resultItem: null,
            indexResultItem: 0,
            totalResult: null,
            showJackpotValue: false,
            classNameNumberDraw: "lotto6-hide-number-draw",
            classNameLogoRender: "lotto6-show-logo-render",
            classCountDown: "lotto6-mix-total-result-render-count "
        });

        this.props.setRenderResultStatus({isStopRender: true});
        this.setNewResult();
    }

    setNewResult() {
        this.props.fetchLotto6DrawingResult()
            .then(() => {
                const {countDownTimer} = this.props;
                if (countDownTimer > 0) {
                    this.setState({
                        classNameNumberDraw: "lotto6-hide-number-draw",
                        classNameLogoRender: "lotto6-show-logo-render",
                        classCountDown: "lotto6-mix-total-result-render-count"
                    })
                    this.countingSecond(countDownTimer);
                }
            })
    }


    refreshData = () => {
        this.presetNewResult();
        this.props.fetchStatistic6({row: 6, column: 7});
        this.refresh = setTimeout(() => {
            this.playAudio("https://bct168-s3.s3-ap-southeast-1.amazonaws.com/sound/thank/thank.mp3");
            clearTimeout(this.refresh);
        }, 1000);
        this.getRecentDraw();
        this.props.fetchLotto6ListBetting({isClear: true})
        this.props.fetchLotto6RecentBetReportCache()
        this.props.fetchUserBalance();
    }

    countingSecond(count) {
        if (this.props.isStopRender) {
            if (this.props.countDownTimer < 0) {
                this.props.setRenderResultStatus({isStopRender: true})
            } else {
                if (count >= 10) {
                    this.setState({classCountDown: "lotto6-mix-total-result-render-count"})
                } else if (count < 10 && count > 0) {
                    this.props.setBetStatus({isStopBet: true});
                    this.props.playCountDownAudio(count - 1, this.state.isMute);
                    this.setState({classCountDown: "lotto6-mix-total-result-render-count-warning"})
                } else if (count === 0) {
                    this.props.playGoodLuckAduio(this.state.isMute);
                    this.setState({classCountDown: "lotto6-mix-total-result-render-count-total"})
                    this.props.setRenderResultStatus({isStopRender: false})
                    this.getResultDrawing();
                }
            }
        }
    }

    checkDrawingResult() {
        this.props.fetchLotto6DrawingResult()
            .then(() => {
                if (this.props.indexNumber <= 6 && this.props.indexNumber >= 0 && this.props.indexNumber !== null) {
                    this.setState({allNumbers: []})
                    let totalResult = 0
                    let allNumbers = this.props.allNumbers.slice(0, this.props.indexNumber)
                    allNumbers.forEach(item => {
                        totalResult = totalResult + item
                    })
                    this.setState({
                        allNumbers: allNumbers,
                        indexResultItem: this.props.indexNumber,
                        totalResult: totalResult,
                        classNameNumberDraw: "lotto6-mix-show-number-draw",
                        classNameLogoRender: "lotto6-hide-logo-render",
                        classCountDown: "lotto6-mix-total-result-render-count-total"
                    })
                    this.showResultRender()
                } else if (this.props.countDownTimer > 0) {
                    this.setState({
                        classNameNumberDraw: "lotto6-hide-number-draw",
                        classNameLogoRender: "lotto6-show-logo-render",
                        classCountDown: "lotto6-mix-total-result-render-count"
                    })

                } else if (this.props.countDownTimer <= 0) {
                    this.setState({
                        classNameNumberDraw: "lotto6-mix-show-number-draw",
                        classNameLogoRender: "lotto6-hide-logo-render",
                        classCountDown: "lotto6-mix-total-result-render-count-total"
                    })
                    this.props.setRenderResultStatus({isStopRender: false});
                    return this.getReleaseNum();
                }
            }).catch(() => {
            this.setState({allNumbers: []});
        })
    }

    getResultDrawing() {
        this.props.fetchLotto6DrawingResult()
            .then(() =>
                this.getReleaseNum()).catch(() => {
            this.setState({allNumbers: []});
        })
    }

    getReleaseNum = async () => {
        const {indexNumber, countDownTimer} = this.props;
        let count = Math.abs(countDownTimer);
        if (count <= 3) {
            this.showLoading = setTimeout(() => {
                this.drawResult(0);
                clearTimeout(this.showLoading)
            }, 3000);
            return false;
        } else if (indexNumber <= 20 && indexNumber >= 0) {
            this.drawResult(indexNumber);
        }
    }

    drawResult = (indexNumber) => {
        const {oldResult} = this.props;
        setLotto6({
            showJackpotValue: oldResult?.jackpot > 1
        })
        this.setState({allNumbers: []})
        let {totalResult, allNumbersDrawing} = getResult(indexNumber, this.props.oldResult);

        this.setState({
            allNumbers: allNumbersDrawing,
            indexResultItem: indexNumber,
            totalResult: totalResult,
            classNameNumberDraw: "lotto6-mix-show-number-draw",
            classNameLogoRender: "lotto6-hide-logo-render"
        }, () => this.showResultRender())

    }


    onBetting = (item) => {
        if (!this.props.isStopBet) {
            this.props.fetchUserBalanceValidate()
                .then(() => {
                    this.setState({
                        showBetting: true,
                        betGameType: item.name,
                        betGameOdds: item.value,
                        betGameRange: item.resultRange,
                        betCode: item.code,
                        betGameTime: moment(new Date()).format('HH:mm:ss')
                    });
                }).catch(error => {
                const {t} = this.props;
                this.setState({
                    isShowValidateBalance: true,
                    messageValidateBalance: t("labels:common." + error.response.data.message)
                });
            })
        }
    }

    isValidBetAmount = (betAmount = 0) => {
        betAmount = parseInt(betAmount);
        if (typeof betAmount !== "number") {
            return false;
        }
        const {maxBet, minBet} = this.props.config.limit;

        if (betAmount < minBet || betAmount > maxBet)
            return false;

        return true
    }

    onConfirmBetting = async (logs) => {
        const {t, user: {userBalance, isAddToCart}, newResult, fetchLotto6ListBetting} = this.props
        const betAmount = this.state.betAmount;
        let totalCartAmount = fetchLotto6ListBetting({isClear: false});
        if (!this.isValidBetAmount(betAmount)) {
            this.setState({betMessage: t("labels:keno.requireAmount")})
        } else if ((totalCartAmount + betAmount) > userBalance.balance) {
            this.setState({betMessage: t("labels:common.NOT_ENOUGH_BALANCE")})
        } else {
            const {
                betCode,
                betAmount,
                betGameOdds
            } = this.state;
            const data = {
                drawCode: newResult.drawCode,
                betTypeCode: betCode,
                amount: betAmount,
                betLog: logs,
                rebateRate: betGameOdds
            }
            await this.props.lotto6AddBetting(data);
            !isAddToCart && this.submitBetNow();

            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betCode: "",
                betGameRange: "",
                betGameTime: ""
            })
        }
    }

    onCancelBetting = () => {
        this.setState({
            showBetting: false,
            betAmount: 0,
            betGameType: '',
            betGameOdds: '',
            betMessage: "",
            betGameRange: "",
            betGameTime: ""
        })
    }

    showConfirmDelete = (id) => {
        this.setState({showDeleteBetting: false, bettingId: null});
        this.props.deleteLotto6Betting({id: id});
    }


    submitBetNow = () => {
        const {isEnableBetNow} = this.state;
        const {user} = this.props;
        isEnableBetNow && this.props.lotto6ConfirmBettingNow(() => this.setState({isEnableBetNow: false}))
            .then(() => {
                this.setState({isEnableBetNow: true})
                this.props.fetchLotto6RecentBetReport().then(() => user.isAutoPrint && document.getElementById("print-button").click());
                this.props.fetchUserBalance();
            }).catch((error) => {
                const {response} = error;
                this.setState({isEnableBetNow: true})
                response && swal(response.data.message, {icon: "error", button: "បិទ"});
            })
    }


    clickAmountBetBtn = (amount) => {
        let betAmount = this.state.betAmount || 0;

        this.setState({
            betAmount: parseInt(betAmount) + amount,
        })
    }

    resetDefaultAmount = () => {
        this.setState({betAmount: 0})
    }

    changeBetAmount = (e) => {
        if (e.target.value >= 0 || !isNaN(e.target.value) || e.target.value === undefined) {
            this.setState({betAmount: e.target.value})
        }
    }


    render() {
        const platform = env.PLATFORM_FOR;

        const betFunctions = {
            tenSecondCallback: this.countingSecond.bind(this),
            toggleMute: this.toggleMute,
            showConfirmDelete: this.showConfirmDelete,
            onCloseValidateBalance: this.onCloseValidateBalance,
            submitBetNow: this.submitBetNow,
            onBetting: this.onBetting,
            afterPrint: this.props.fetchLotto6DrawingResult
        }

        return (
            <div className="lotto6-mix m-lotto-1">
                <Dashboard2Mix
                    platform={platform}
                    {...betFunctions}
                    {...this.props}
                    {...this.state}/>
                <BettingDialog changeBetAmount={this.changeBetAmount}
                               clickAmountBetBtn={this.clickAmountBetBtn}
                               onConfirmBettingLotto5={this.onConfirmBetting}
                               resetDefaultAmount={this.resetDefaultAmount}
                               onCancelBetting={this.onCancelBetting}
                               {...this.state}
                               classDialog="bet-mix-dialog"
                               {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state.lotto6,
    ...state.lotto6.draw,
    config: state.config,
    user: state.user,
    audio: state.audio
})

const mapDispatchToProps = dispatch => ({
    setBetStatus: payload => dispatch(setBetStatusLotto6(payload)),
    setRenderResultStatus: payload => dispatch(setRenderResultStatus(payload)),
    setResultTotalNumber: payload => dispatch(setResultTotalNumberKeno(payload)),
    fetchLotto6DrawingResult: () => dispatch(fetchLotto6DrawingResult()),
    fetchLotto6RecentDraw: payload => dispatch(fetchLotto6RecentDraw(payload)),
    setIsAutoPrint: payload => dispatch(setIsAutoPrint(payload)),
    playCountDownAudio: (index, isMute) => dispatch(playCountDownAudio(index, isMute)),
    playGoodLuckAduio: (isMute) => dispatch(playGoodLuckAudio(isMute)),
    fetchStatistic6: (payload) => dispatch(fetchStatistic6(payload)),
    lotto6AddBetting: payload => dispatch(lotto6AddBetting(payload)),
    deleteLotto6Betting: payload => dispatch(deleteLotto6Betting(payload)),
    lotto6ConfirmBettingNow: (callback) => dispatch(lotto6ConfirmBettingNow(() => callback())),
    fetchLotto6ListBetting: payload => dispatch(fetchLotto6ListBetting(payload)),
    fetchLotto6RecentBetReport: () => dispatch(fetchLotto6RecentBetReport()),
    fetchLotto6RecentBetReportCache: () => dispatch(fetchLotto6RecentBetReportCache()),
    fetchUserBalance: () => dispatch(fetchUserBalance()),
    fetchUserBalanceValidate: () => dispatch(fetchUserBalanceValidate()),
    clearCart: () => dispatch(clearCartLotto6()),
    setIsAddToCart: payload => dispatch(setIsAddToCart(payload)),
})

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Lotto6DashboardMix))
