import React, {Component} from "react";
import Lotto90Nav from "../../components/lotto90/Lotto90Nav";
import {withTranslation} from "react-i18next";
import Dashboard from "../../components/lotto90/Dashboard";
import {getLocal, setLocal} from "../../utils/cached";

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layoutVersion: getLocal("l90_layout") ?? "V1"
        }
    }


    setLVersion = (version) => {
        this.setState({
            layoutVersion: version
        });
        setLocal("l90_layout", version);
    }

    render() {
        const layoutVersion = this.state.layoutVersion;
        return (
            <div className="main-w">
                <div className="main">
                    <div className="w-1200">
                        <Lotto90Nav setLVersion={this.setLVersion} layoutVersion={layoutVersion}/>
                        <Dashboard layoutVersion={layoutVersion}/>
                    </div>
                </div>
            </div>
        );
    }
}


export default withTranslation()(Index)
