import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import 'moment-timezone';
import {withTranslation} from "react-i18next";

import {fetchUserBalance} from "../../store/rootAction";
import {moneyFormatter} from "../../utils/MoneyUtils";
import {getZhCurrency} from "../../constants/general";
import i18n from "i18next";
import RefreshButton from "../../utils/RefreshButton";

class Balance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageBalanceRotate: ""
        };
    }

    render() {
        const {t, user: {userBalance}, pendingAmount} = this.props;
        const isZh = i18n.language === 'zh';

        return (
            <>
                <div className="col no-padding-div header-div-title header-text header-div-blue-light block-header">
                    <span>{`${moment(new Date()).format('YYYY-MM-DD')} (${isZh ? getZhCurrency[userBalance?.currencyCode] : userBalance?.currencyCode})`}</span>
                    <RefreshButton
                        onClick={() => {
                            this.setState({imageBalanceRotate: "rotate"})
                            this.props.fetchUserBalance()
                                .then(() => {
                                    setTimeout(() => {
                                        this.setState({imageBalanceRotate: ""})
                                    }, 300)
                                }).catch(() => {
                                setTimeout(() => {
                                    this.setState({imageBalanceRotate: ""})
                                }, 300)
                            })
                        }}
                        className={this.state.imageBalanceRotate}
                    />
                </div>
                <div className="col no-padding-div header-div-title header-text header-div-white ">
                    <table className="table fade-in">
                        <tbody>
                        <tr>
                            <td className="text-left kh-font-bold"><b>{t("labels:common.balance")}</b></td>
                            <td className="text-right"><b>
                                {moneyFormatter(userBalance.balance, userBalance.currencyCode, "f-22 text-success")}
                            </b>
                            </td>
                        </tr>
                        <tr className="bg-grey-l">
                            <td className="text-left kh-font-bold"><b>{t("labels:common.outstanding")}</b></td>
                            <td className="text-right"><b>
                                {moneyFormatter(pendingAmount, userBalance.currencyCode, "f-22 text-success")}
                            </b>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    fetchUserBalance: () => dispatch(fetchUserBalance()),
});

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Balance));
