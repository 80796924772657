import React from "react";
import {getFirstLabel} from "../../constants/lotto6";

export default (props) => {
    const rows = [];
    const {numberItems} = props;
    for (let i = 0; i < numberItems?.length; i++) {
        let cols = [];
        let rowNum = numberItems[i];
        for (let j = 0; j < rowNum?.length; j++) {
            let col = rowNum[j];
            if (col) {
                cols.push(<td
                    key={j}
                    id={"keno-history-key" + j}
                    width="5%"
                >
                    {(col.length > 0 ?
                        <span className={"statistic-ball-" + col}>{getFirstLabel(col)}</span>
                        : <span></span>)}
                </td>);
            } else {
                cols.push(<td
                    key={j}
                    id={"keno-history-key" + j}
                    width="5%">
                    <span></span>
                </td>);
            }
        }
        rows.push(<tr key={i}>{cols}</tr>);
    }

    return rows;
}