import React, {Component} from "react";
import {connect} from "react-redux";
import {fetchLotto99RecentTicket, getRecentSize, setRecentSize} from "../../../store/rootAction";
import 'moment-timezone';
import {withTranslation} from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import {currentDate} from "../../../utils/dateUtils";
import {CustomInput, Label} from "reactstrap";
import Ticket58 from "../print/ticket58";
import NumberFormat from "react-number-format";
import OldRecentTicketRow from "./OldRecentTicketRow";
import NewRecentTicketRow from "./NewRecentTicketRow";
import RefreshButton from "../../../utils/RefreshButton";

class RecentTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            date: currentDate(),
            isShow: false,
            dataDetail: null,
            size: getRecentSize().lo99,
            isNewFormat: true
        };
    }

    onFilter = () => {
        this.setState({imageRotate: "rotate"})
        this.props.fetchLotto99RecentTicket({size: this.state.size}).then(() => {
            setTimeout(() => {
                this.setState({imageRotate: ""})
            }, 300)
        }).catch(() => {
            setTimeout(() => {
                this.setState({imageRotate: ""})
            }, 300)
        })
    }

    onChangeSize = (size) => {
        this.setState({size: size});
        setRecentSize({lo99: size});
    }


    onShowDetail = (item) => {
        this.setState({
            isShow: true,
            dataDetail: item
        });
    }

    onCloseDetail() {
        this.setState({
            isShow: false,
            dataDetail: null
        });
    }

    render() {
        const {t, tickets:{tickets}} = this.props;
        const {size, isNewFormat} = this.state;

        return (
            <div className="lotto99">
                <div
                    className="col no-padding-div header-div-title header-text header-div-blue-light block-header position-static border-b-1 d-flex align-items-center">
                    <span className="text-black kh-font-bold ">{t("labels:common.recentBet")}
                        <NumberFormat
                            className="in-recent-size"
                            isAllowed={({value}) => value< 11}
                            onBlur={() => {
                                if (size > 0 && size< 11) this.onFilter()
                                else this.onChangeSize(3)
                            }}
                            onValueChange={({floatValue}) => this.onChangeSize(floatValue)}
                            value={size}/>
                                        <span>
                                            {t("labels:common.draw")}
                                        </span>
                                </span>

                    <div className="d-flex align-item-center mr-2">
                        <CustomInput
                            className="mt-1"
                            type='checkbox'
                            id='new_format'
                            checked={isNewFormat}
                            onChange={() => {
                                this.setState({
                                    isNewFormat: !isNewFormat
                                });
                            }}
                        />
                        <Label for='new_format'
                               className="pr-2 f-15 w-100 mt-1 text-black">{t('labels:lmhsb.new_format')}</Label>
                        <RefreshButton
                            onClick={() => this.onFilter()}
                            className={this.state.imageRotate}
                        />
                    </div>

                </div>
                {!isNewFormat && <OldRecentTicketRow onShowDetail={this.onShowDetail} t={t} tickets={tickets}/>}

                {isNewFormat && <NewRecentTicketRow onShowDetail={this.onShowDetail} t={t} tickets={tickets}/>}

                <SweetAlert
                    title=""
                    show={this.state.isShow}
                    onConfirm={this.onCloseDetail.bind(this)}
                    confirmBtnText={t("labels:common.close")}
                    showCancel={false}
                    btnSize="lg"
                >
                    <div className='d-flex justify-content-center'>
                        <Ticket58
                            object={this.state.dataDetail} {...this.props}/>
                    </div>
                </SweetAlert>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {tickets: state.lotto99.tickets}
};

export default withTranslation()(connect(
    mapStateToProps,
    {fetchLotto99RecentTicket}
)(RecentTicket));
