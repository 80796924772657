import {LIST_PAGE} from "./types";


const init = {
    list: []
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case LIST_PAGE:
            return {
                ...state,
                list: payload
            };

        default:
            return state;
    }
}