import {LOTTO99_RECENT_TICKET} from "./types";

const init = {
    tickets: [],
}


export default (state = init, {type, payload}) => {

    switch (type) {
        case LOTTO99_RECENT_TICKET:
            return {
                ...state,
                tickets: payload
            }

        default:
            return state;
    }
}