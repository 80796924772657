import React from "react";
import {ballNumbers} from "../../../constants/lotto90";
import {BetType, post90} from "../../../constants/general";

const BallNumbers = ({onMouseDown, onMouseEnter, onMouseUp, betting}) => {

    const {listBetting, postBetting, betType} = betting;

    const isActive = (formattedNumber) => {
        let active;
        if (betType === BetType.RANGE && listBetting.length === 2) {
            let list = listBetting.sort();
            let first = parseInt(list[0]);
            let last = parseInt(list[1]);
            let number = parseInt(formattedNumber);
            if (number >= first && number <= last) {
                active = "active";
            } else active = ""
        } else {
            active = listBetting.includes(formattedNumber) ? "active" : "";
        }
        return active;
    }

    return <div>
        <div className="post-wrapper">
            {post90.map((p, i) => {
                let active = postBetting.includes(p) ? "active" : "";
                return <button key={i}
                               onClick={(e) => {
                                   e.preventDefault()
                               }}
                               onMouseDown={() => onMouseDown(p, true)}
                               onMouseEnter={() => onMouseEnter(p, true)}
                               onMouseUp={() => onMouseUp()}
                               className={`post-circle ${active}`}>{p}
                </button>
            })}
        </div>
        <div className="table-wrapper">
            <div id="tb-number" className="tb-number">
                {ballNumbers.map((r, i) => {
                    let formattedNumber = ("0" + (i + 1)).slice(-2);
                    let active = isActive(formattedNumber);
                    return <button
                        onClick={(e) => {
                            e.preventDefault()
                        }}
                        onMouseDown={() => onMouseDown(formattedNumber)}
                        onMouseEnter={() => onMouseEnter(formattedNumber)}
                        onMouseUp={() => onMouseUp()}
                        id={`bet-${formattedNumber}`} key={i}
                        className={`num-circle ${active}`}>{formattedNumber}</button>
                })}
            </div>
        </div>
    </div>
}

export default BallNumbers;
