import React from "react";
import Sale from "../../components/lo5d/SaleV2";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {fetchUserBalance} from "../../store/user/action";
import {
    fetchBetting,
    fetchCurrentDraw5D, fetchLotto5dRecentTicket,
    fetchPaging,
    fetchResults,
    postBetting, setLoading,
    updateBet
} from "../../store/lotto5d/action";
import Lotto5DNav from "../../components/lo5d/Lotto5DNav";

const initState = {
    isFocus: false,
    isRow: false,
    isEdit: false,
    currentTicket: 1,
};

class Lotto5DDashboard extends React.Component {

    constructor(props) {
        super(props);
        const filters = this.props.sale.filters;
        if (filters) {
            this.state = {
                ...filters
            }
        } else {
            this.state = {
                ...initState
            }
        }
    }


    handleSelectChange = (name, value, callback) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }), () => {
            callback && callback()
        });
    };


    render() {
        return (
            <div className="main-w">
                <div className="main">
                    <div className="w-1200">
                        <div className="wrapper-new-lotto5 lotto5">
                            <Lotto5DNav/>
                            <Sale {...this.props}
                                  isFocus={this.state.isFocus}
                                  isRow={this.state.isRow}
                                  filters={{...this.state}}
                                  handleSelectChange={this.handleSelectChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


const mapStateToProps = state => ({
    sale: state.lotto5d.sale,
    pages: state.lotto5d.page,
    user: state.user,
    config: state.config,
    drawing: state.lotto5d.drawing.data,
    results: state.lotto5d.result,
    audio: state.audio,
    recentTicket: state.lotto5d.recentTicket
});


export default connect(mapStateToProps,
    {
        fetchCurrentDraw5D,
        fetchBetting,
        postBetting,
        updateBet,
        fetchPaging,
        fetchResults,
        fetchUserBalance,
        setLoading,
        fetchLotto5dRecentTicket
    })(withTranslation()(Lotto5DDashboard));
