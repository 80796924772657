import ResultContentList from "./ResultContentList";
import DatetimeContainer from "../date/DatetimeContainer";
import CountDownTimer from "../timer/CountDownTimer";
import {getShortBetType} from "../../utils/tickets/printUtils";
import React from "react";
import {playAudio, playCountDownAudio, playGoodLuckAudio} from "../../store/audio/action";
import {setRenderResultStatus, setResultTotalNumberKeno} from "../../store/lotto12/action";
import {fetchUserBalance, fetchUserBalanceValidate, setIsAddToCart, setIsAutoPrint} from "../../store/user/action";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    clearCartLotto6,
    deleteLotto6Betting,
    fetchLotto6DrawingResult,
    fetchLotto6ListBetting,
    fetchLotto6RecentBetReport, fetchLotto6RecentBetReportCache,
    fetchLotto6RecentDraw, fetchStatistic6,
    lotto6AddBetting,
    lotto6ConfirmBettingNow,
    setBetStatusLotto6
} from "../../store/lotto6/action";
import {BetCodes6, clientAp, getOECode, isOE, jackpotNum, pfType999} from "../../constants/general";
import {SUBSCRIPTION_LOTTO6} from "../../constants/lotto6";
import moment from "moment";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import {fetchLottoConfig} from "../../store/config/action";
import BettingDialog from "../lotto5/BettingDialog";
import {getResult} from "../../utils/general";
import {env} from "../../env";
import _ from "lodash";
import winSound from "../../assets/audios/lotto5_win.mp3";
import {socketEvent, socketLottoConfig} from "../../constants/socket";
import {removeSocketListener, socket} from "../../connections/socketServices";
import LoadingGif from "../../utils/LoadingGif";

class DrawingResult extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showBetting: false,
            betCode: "",
            betAmount: 0,
            betGameType: "",
            betGameOdds: "",
            betGameRange: "",
            betGameTime: "",
            resultItem: null,
            resultItemRandom: null,
            indexResultItem: 0,
            totalResult: null,
            allNumbers: [],
            classNameNumberDraw: "lotto6-hide-number-draw",
            classNameLogoRender: "lotto6-hide-logo-render",
            classCountDown: "lotto6-total-result-render-count ",
            winUnderOver: "",
            winOddEven: "",
            winRange: "",
            winRangeLine: "",
            winRangeLineOne: "",
            betMessage: "",
            isShowRecentBet: false,
            imageRotate: "",
            imageBalanceRotate: "",
            imageResultRotate: "",
            isShowValidateBalance: false,
            messageValidateBalance: null,
            isStoppedJackpot: true,
            showJackpotValue: false,
            isEnableBetNow: true,
            jackpot: 0,
        };
    }

    playAudio = (src) => {
        const {audio} = this.props;
        if (audio && !audio.isMute) playAudio(src);
    }


    onCloseValidateBalance = () => {
        this.setState({
            isShowValidateBalance: false,
            messageValidateBalance: null
        });
    }

    showResultRender = () => {
        this.random && clearInterval(this.random);

        this.jackpotInv && clearInterval(this.jackpotInv);
        let num = 0;
        if (this.props.oldResult?.jackpot > 1) {
            this.jackpotInv = setInterval(() => {
                if (num >= 6) num = 0;
                num += 1;
                this.setState({
                    jackpot: num,
                });
            }, 800);
        } else {
            this.setState({
                jackpot: 0,
            });
        }

        this.setState({
            classNameNumberDraw: "lotto6-show-number-draw",
            classNameLogoRender: "lotto6-hide-logo-render"
        });
        this.drawReleaseResult(3000);
        if (this.drawingTime) {
            clearInterval(this.drawingTime)
        }
        this.drawingTime = setInterval(() => {
            this.drawReleaseResult(2500);
        }, 3500)
    }

    drawReleaseResult = (timeout) => {
        let indexResultItem = this.state.indexResultItem + 1
        if (indexResultItem < 7) {
            this.playAudio("https://bct168-s3.s3-ap-southeast-1.amazonaws.com/sound/drawing/drawing-3-new.mp3")
        }
        if (indexResultItem === 7) {
            this.setWinLose(indexResultItem);
        } else if (indexResultItem === 8) {
            // this.preSetnewResult();
        } else {
            let resultItem = 1
            if (this.random) {
                clearInterval(this.random);
            }
            this.random = setInterval(() => {
                if (resultItem === 37) {
                    resultItem = 1
                }
                this.setState({resultItem: resultItem++})
            }, 10)
            this.startDrawing && clearTimeout(this.startDrawing);
            this.startDrawing = setTimeout(() => {
                if (this.random) {
                    clearInterval(this.random)
                }
                let {resultItem, totalResult, allNumbersDrawing} = getResult(indexResultItem, this.props.oldResult);
                this.setState({
                    resultItem: resultItem,
                    indexResultItem: indexResultItem,
                    totalResult: totalResult,
                    allNumbers: allNumbersDrawing,
                    classNameNumberDraw: "lotto6-show-number-draw"
                }, () => clearTimeout(this.startDrawing));
            }, timeout)
        }
    }

    setWinLose = (indexResultItem) => {
        let {oldResult} = this.props;
        let jp = _.toUpper(oldResult?.rangeCode) === oldResult.jackpotType ? jackpotNum[oldResult.jackpotType] : 0

        this.jackpotInv && clearInterval(this.jackpotInv);
        jp > 0 && this.playAudio(winSound);

        let src = `https://bct168-s3.s3-ap-southeast-1.amazonaws.com/sound/result/result-${BetCodes6[oldResult?.rangeCode]}-${BetCodes6[oldResult?.underOverCode]}.mp3`;
        this.setState({
            winUnderOver: oldResult?.underOverCode,
            winOddEven: oldResult?.oddEvenCode,
            winRange: oldResult?.rangeCode,
            winRangeLine: oldResult?.rangeLineCode,
            winRangeLineOne: oldResult?.rangeLineOneCode,
            resultItem: null,
            indexResultItem: indexResultItem,
            totalResult: null,
            jackpot: jp,
            classNameNumberDraw: "lotto6-hide-number-draw",
            classNameLogoRender: "lotto6-show-logo-render",
            classCountDown: "lotto6-total-result-render-count "
        })
        // when the sound has been loaded, execute your code
        this.playAudio(src)
    }

    preSetnewResult = () => {
        if (this.drawingTime) {
            clearInterval(this.drawingTime);
        }
        this.setState({
            winUnderOver: "",
            winOddEven: "",
            winRange: "",
            winRangeLine: "",
            winRangeLineOne: "",
            resultItem: null,
            indexResultItem: 0,
            totalResult: null,
            showJackpotValue: false,
            classNameNumberDraw: "lotto6-hide-number-draw",
            classNameLogoRender: "lotto6-show-logo-render",
            classCountDown: "lotto6-total-result-render-count "
        });

        this.props.setRenderResultStatus({isStopRender: true});
        this.setnewResult();
    }

    setnewResult = () => {
        this.props.fetchLotto6DrawingResult()
            .then(() => {
                const {countDownTimer} = this.props;
                if (countDownTimer > 0) {
                    this.setState({
                        classNameNumberDraw: "lotto6-hide-number-draw",
                        classNameLogoRender: "lotto6-show-logo-render",
                        classCountDown: "lotto6-total-result-render-count"
                    })
                    this.countingSecond(countDownTimer);
                }
            })
    }

    componentDidUpdate() {
        if (this.props.isStopBet && this.state.showBetting) {
            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betGameRange: "",
                betGameTime: ""
            })
        }
    }

    subscription = () => {
        if (socketLottoConfig.l6) {
            socket.on(socketEvent.l6, (e) => {
                this.onDrawStatusChange(e[0]);
            });
        } else {
            clientAp.subscribe({
                query: SUBSCRIPTION_LOTTO6,
            }).subscribe({
                next: (event) => {
                    if (event.data && event.data.v_lotto6_drawing) {
                        let currentDraw = event.data.v_lotto6_drawing[0] || {};
                        this.onDrawStatusChange(currentDraw);
                    }
                },
                error: (err) => {
                    console.error("err", err);
                }
            });
        }
    }

    onDrawStatusChange = (currentDraw) => {
        if (currentDraw.status !== this.state.status) {
            if (this.state.status && currentDraw.status === "OPEN") {
                this.refreshData();
            }
            this.setState({status: currentDraw.status});
            this.verifyCurrentDraw(currentDraw.drawCode);
        }
    }

    verifyCurrentDraw = currentDrawCode => {
        let betList = JSON.parse(sessionStorage.getItem("betting_lotto6") || "[]");
        if (betList.length) {
            let firstItem = betList[0];
            if (firstItem.drawCode !== currentDrawCode) {
                this.props.clearCart();
            }
        }

    }

    refreshData = () => {
        this.preSetnewResult();
        this.refresh = setTimeout(() => {
            this.playAudio("https://bct168-s3.s3-ap-southeast-1.amazonaws.com/sound/thank/thank.mp3");
            clearTimeout(this.refresh);
        }, 1000);
        this.props.fetchLotto6RecentBetReportCache();
        this.props.fetchUserBalance()
        this.props.fetchStatistic6();
        this.getRecentDraw();

    }

    componentDidMount() {
        this.props.fetchLottoConfig();
        this.subscription();
        this.props.fetchUserBalance()
        this.props.fetchLotto6ListBetting({isClear: true})
        this.checkDrawingResult()
        this.props.fetchLotto6RecentBetReport();
        this.props.fetchStatistic6();
        this.getRecentDraw();
    }

    getRecentDraw = () => {
        this.props.fetchLotto6RecentDraw().then(() => {
            if (this.props.isStopRender) {
                const details = this.props.lastResult.details
                this.setState({allNumbers: details})
            }
        });
    }

    componentWillUnmount() {
        if (this.drawingTime) {
            clearTimeout(this.drawingTime)
        }
        removeSocketListener(socketEvent.l6);
    }

    clickAmountBetBtn = (amount) => {
        let betAmount = this.state.betAmount || 0;

        this.setState({
            betAmount: parseInt(betAmount) + amount,
        })
    }

    resetDefaultAmount = () => {
        this.setState({betAmount: 0})
    }

    changeBetAmount = (e) => {
        if (e.target.value >= 0 || !isNaN(e.target.value) || e.target.value === undefined) {
            this.setState({betAmount: e.target.value})
        }
    }

    countingSecond = (count) => {
        if (this.props.isStopRender) {
            if (this.props.countDownTimer < 0) {
                this.props.setRenderResultStatus({isStopRender: true})
            } else {
                if (count >= 10) {
                    this.setState({classCountDown: "lotto6-total-result-render-count"})
                } else if (count < 10 && count > 0) {
                    this.props.setBetStatus({isStopBet: true});
                    this.props.playCountDownAudio(count - 1);
                    this.setState({classCountDown: "lotto6-total-result-render-count-warning"})
                } else if (count === 0) {
                    this.props.playGoodLuckAduio();
                    this.setState({classCountDown: "lotto6-total-result-render-count-total"})
                    this.props.clearCart();
                    this.props.setRenderResultStatus({isStopRender: false})
                    this.getResultDrawing(true);
                    this.props.fetchUserBalance();

                }
            }
        }
    }

    checkDrawingResult = () => {
        this.props.fetchLotto6DrawingResult()
            .then(() => {
                if (this.props.indexNumber <= 6 && this.props.indexNumber >= 0 && this.props.indexNumber !== null) {
                    this.setState({allNumbers: []})
                    let totalResult = 0
                    let allNumbers = this.props.allNumbers.slice(0, this.props.indexNumber)
                    allNumbers.forEach(item => {
                        totalResult = totalResult + item
                    })
                    this.setState({
                        allNumbers: allNumbers,
                        indexResultItem: this.props.indexNumber,
                        totalResult: totalResult,
                        classNameNumberDraw: "lotto6-show-number-draw",
                        classNameLogoRender: "lotto6-hide-logo-render",
                        classCountDown: "lotto6-total-result-render-count-total"
                    })
                    this.showResultRender()
                } else if (this.props.countDownTimer > 0) {
                    this.setState({
                        classNameNumberDraw: "lotto6-hide-number-draw",
                        classNameLogoRender: "lotto6-show-logo-render",
                        classCountDown: "lotto6-total-result-render-count"
                    })

                } else if (this.props.countDownTimer <= 0) {
                    this.setState({
                        classNameNumberDraw: "lotto6-show-number-draw",
                        classNameLogoRender: "lotto6-hide-logo-render",
                        classCountDown: "lotto6-total-result-render-count-total"
                    })
                    this.props.setRenderResultStatus({isStopRender: false});
                    return this.getReleaseNum();
                }
            }).catch(() => {
            this.setState({allNumbers: []});
        })
    }

    getResultDrawing = (value = false) => {
        this.props.fetchLotto6DrawingResult()
            .then(() =>
                this.getReleaseNum(value)).catch(() => {
            this.setState({allNumbers: []});
        })
    }

    getReleaseNum = async () => {
        const {indexNumber, countDownTimer} = this.props;
        let count = Math.abs(countDownTimer);
        if (count <= 3) {
            this.showLoading = setTimeout(() => {
                this.drawResult(0);
                clearTimeout(this.showLoading)
            }, 3000);
            return false;
        } else if (indexNumber <= 20 && indexNumber >= 0) {
            this.drawResult(indexNumber);
        }

    }

    drawResult = (indexNumber) => {
        this.setState({allNumbers: []})
        let {allNumbersDrawing, totalResult} = getResult(indexNumber, this.props.oldResult);
        this.setState({
            allNumbers: allNumbersDrawing,
            indexResultItem: indexNumber,
            totalResult: totalResult,
            classNameNumberDraw: "lotto6-show-number-draw",
            classNameLogoRender: "lotto6-hide-logo-render"
        }, () => this.showResultRender());
    }

    onBetting = (item) => {
        if (!this.props.isStopBet) {
            this.props.fetchUserBalanceValidate()
                .then(() => {
                    this.setState({
                        showBetting: true,
                        betGameType: item.name,
                        betGameOdds: item.value,
                        betGameRange: item.resultRange,
                        betCode: item.code,
                        betGameTime: moment(new Date()).format('HH:mm:ss')
                    });
                }).catch(error => {
                const {t} = this.props;
                error && this.setState({
                    isShowValidateBalance: true,
                    messageValidateBalance: t("labels:common." + error.response.data.message)
                });
            })
        }
    }

    isValidBetAmount = (betAmount = 0) => {
        betAmount = parseInt(betAmount);
        if (typeof betAmount !== "number") {
            return false;
        }
        const {minBet, maxBet} = this.props.config.limit;

        if (betAmount < minBet || betAmount > maxBet)
            return false;

        return true
    }

    onConfirmBetting = async (logs) => {
        const {t, user: {userBalance, isAddToCart}, newResult, fetchLotto6ListBetting} = this.props
        let totalCartAmount = fetchLotto6ListBetting({isClear: false});
        const betAmount = this.state.betAmount;
        if (!this.isValidBetAmount(betAmount)) {
            this.setState({betMessage: t("labels:keno.requireAmount")})
        } else if ((totalCartAmount + betAmount) > userBalance.balance) {
            this.setState({betMessage: t("labels:common.NOT_ENOUGH_BALANCE")})
        } else {
            const {
                betCode,
                betAmount,
                betGameOdds
            } = this.state;
            const data = {
                drawCode: newResult.drawCode,
                betTypeCode: betCode,
                amount: betAmount,
                betLog: logs,
                rebateRate: betGameOdds
            }
            await this.props.lotto6AddBetting(data);
            !isAddToCart && this.submitBetNow();

            this.setState({
                showBetting: false,
                betAmount: 0,
                betGameType: "",
                betGameOdds: "",
                betCode: "",
                betGameRange: "",
                betGameTime: "",
                betMessage: ""
            })
        }
    }

    onCancelBetting = () => {
        this.setState({
            showBetting: false,
            betAmount: 0,
            betGameType: '',
            betGameOdds: '',
            betMessage: "",
            betGameRange: "",
            betGameTime: "",
        })
    }

    showConfirmDelete = (id) => {
        this.setState({showDeleteBetting: false, bettingId: null});
        this.props.deleteLotto6Betting({id: id});
    }

    submitBetNow = () => {
        const {isEnableBetNow} = this.state;
        const {user} = this.props;
        isEnableBetNow && this.props.lotto6ConfirmBettingNow(() => this.setState({isEnableBetNow: false}))
            .then(() => {
                this.setState({isEnableBetNow: true})
                this.props.fetchLotto6RecentBetReport().then(() => user.isAutoPrint && document.getElementById("print-button").click());
                this.props.fetchUserBalance();
            }).catch((error) => {
                const {response} = error;
                this.setState({isEnableBetNow: true});
                response && swal(response.data.message, {icon: "error", button: "បិទ"});
            })
    }


    render() {
        const {
            t, countDownTimer, newResult, lastResult: {
                drawLabel,
                result,
                underOverCode,
                oddEvenCode,
                number
            },
            config: {
                lotto6Config
            },
            oldResult
        } = this.props;

        const {
            classNameNumberDraw,
            classNameLogoRender,
            indexResultItem,
            resultItem,
            winUnderOver,
            winOddEven,
            winRange,
            winRangeLine,
            winRangeLineOne,
            isShowValidateBalance,
            allNumbers,
            totalResult,
            classCountDown,
            messageValidateBalance,
            jackpot
        } = this.state;
        let platformType = env.PLATFORM_TYPE;
        const jp = oldResult?.jackpot;
        const sizeLoading = pfType999 ? "30" : "25vw";


        return <div className="row m-0">
            <div className="col-4 no-padding-div">

                <div className="col header header-div-title header-text fade-in">
                            <span
                                className="text-white last-result-betting">{drawLabel}</span>
                    {
                        (underOverCode && oddEvenCode) ?
                            <span
                                className="text-white last-result-betting fade-in">{t("labels:betPrefix." + underOverCode) + "  /   " + t("labels:betPrefix." + oddEvenCode) + "  /   " + t("labels:betPrefix." + number) + "   /   " + result}</span>
                            : <span className="text-white last-result-betting fade-in"/>
                    }

                </div>

                <div
                    className="col div-body-white no-padding-div-left no-padding-div-right lotto6-content-result lotto6-v2-result fade-in">
                    <table
                        id="keno_result_post"
                        border="1"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                    >
                        <tbody>
                        <ResultContentList allNumbers={allNumbers}/>
                        </tbody>
                    </table>
                </div>


            </div>

            <div className="col-8 no-padding-div">


                <div className="col header header-div-title header-text no-padding-div-left fade-in">
                            <span className="text-white last-result-betting">
                                <LoadingGif width={sizeLoading}
                                            height={sizeLoading}/> {newResult !== null ? newResult.drawLabel : t("labels:upcoming_draw")}</span>
                    <span className="text-white fade-in last-result-betting"
                          id="timer-display"><DatetimeContainer/></span>
                </div>

                <div className="row m-0">

                    <div className="col-3 div-body-white no-padding-div">

                        <div className="col lotto6-v2-card-count-down-logo bet-bg-logo card-border">
                            {
                                indexResultItem > 6 || indexResultItem === 0
                                    ? <div
                                        className="lotto6-v2-result-time-countup-render bg-transparent fade-in"/>
                                    : <div
                                        className="lotto6-v2-result-time-countup-render fade-in">{indexResultItem}</div>
                            }
                            <div
                                className={"fade-in " + classNameNumberDraw}>{resultItem}</div>
                            <div className={"fade-in " + classNameLogoRender}>

                                <img src={require(`../../assets/images/logo/${platformType}.png`)}
                                     alt="..."/>
                            </div>

                        </div>

                        <div className="col lotto6-v2-card-count-down-logo bg-count-down card-border">
                            <CountDownTimer countClass="fade-in total-result-render "
                                            totalResult={totalResult}
                                            classCountDown={classCountDown}
                                            countDownTimer={countDownTimer}
                                            tenSecondCallback={this.countingSecond}
                            />
                        </div>

                    </div>

                    <div className="col-9">
                        <div className="div-body-white row fade-in">

                            {
                                lotto6Config.slots.map((item, i) => {
                                        const isActive = jackpot === jackpotNum[item.code];
                                        const isWin = item.code === winRange;
                                        const showJackpot = isWin && isActive;
                                        let classActive = isActive && oldResult ? " bg-win " : "";
                                        const winClass = isWin ? " win-" + item.code : "";
                                        let winCl = isWin ?
                                            isActive ? " win-bg-animate" : winClass
                                            : "";
                                        const subItem = item.sub;


                                        const RenderSlot = ({onClick, item}) => {
                                            let OUWinClass = item.code === winUnderOver ? ` OU-${item.code}` : "";
                                            let OEWinClass = item.code === winOddEven ? ` OE-${item.code}` : "";

                                            return <div
                                                className=" no-padding-div lotto6-box-bet lotto6-v2-bet-pricing-card pointer"
                                                onClick={onClick}>

                                                <div
                                                    className={"lotto6-v2-slot-prefix-card lotto6-v2-card-bg-color-" + item.code + classActive + winCl + OUWinClass + OEWinClass}>
                                                    <h1 className={`lotto6-v2-card-type-${item.code} lotto6-v2-card-bg-color-` + item.code + winClass + OUWinClass + OEWinClass}>{item.type === "underOver" ? t("labels:betPrefix.S_" + getShortBetType(item.code)) : t("labels:betPrefix." + item.code)}</h1>
                                                </div>


                                                <div className="lotto6-v2-slot-value-card position-relative">
                                                    {showJackpot && < >
                                                        <img className="lotto6-jackpot-v2-img position-absolute"
                                                             src={require(`../../assets/images/jackpot/v2/${jp}.png`)}
                                                             alt="__"/>
                                                        <img
                                                            className="lotto6-jackpot-v2-img position-absolute win-animate-border"
                                                            src={require('../../assets/images/jackpot/v2/border-jackpot.png')}
                                                            alt="___"/>
                                                    </>}
                                                    <div
                                                        className="d-flex justify-content-center align-items-center h-100">
                                                        <div>
                                                            <div
                                                                className="lotto6-v2-range-number">{isOE(item.code) ? getOECode(item.code) : item.rangeNumber}</div>
                                                            <div
                                                                className={`lotto6-v2-bet-value`}>{item.value}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        return <div key={i}
                                                    className="col-3 no-padding-div lotto6-box-bet lotto6-v2-bet-pricing-card">
                                            <RenderSlot onClick={() => this.onBetting(item)} item={item}/>
                                            {subItem &&
                                            <RenderSlot onClick={() => this.onBetting(subItem)} item={subItem}/>}
                                        </div>


                                    }
                                )

                            }
                            {
                                lotto6Config.rangeLine.map((item, i) => {
                                    return <div
                                        className={"col-4 no-padding-div lotto6-box-bet lotto6-v2-bet-pricing-card pointer"}
                                        key={i} onClick={() => this.onBetting(item)}>

                                        <div
                                            className={"lotto6-v2-slot-prefix-card lotto6-v2-card-bg-color-" + item.code + " win-" + (item.code === winRangeLine ? item.code : "") + " win36-" + (item.code === winRangeLineOne ? item.code : "")}>
                                            <h1>{t("labels:betPrefix." + item.code)}</h1>
                                        </div>

                                        <div
                                            className="lotto6-v2-slot-value-card d-flex justify-content-center align-items-center">
                                            <div>
                                                <div className="lotto6-v2-range-number">{item.rangeNumber}</div>
                                                <div className="lotto6-v2-bet-value">{item.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }


                        </div>

                    </div>

                </div>


            </div>
            <BettingDialog changeBetAmount={this.changeBetAmount}
                           clickAmountBetBtn={this.clickAmountBetBtn}
                           onConfirmBettingLotto5={this.onConfirmBetting}
                           resetDefaultAmount={this.resetDefaultAmount}
                           onCancelBetting={this.onCancelBetting}
                           {...this.state}
                           {...this.props}/>
            {/*{!isStoppedJackpot && <JackpotDialogGif jackpot={oldResult?.jackpot}/>}*/}

            <SweetAlert
                title={messageValidateBalance !== null ? messageValidateBalance : "message"}
                show={isShowValidateBalance}
                onConfirm={this.onCloseValidateBalance}
                confirmBtnText={t("labels:common.okay")}
                showCancel={false}
                btnSize="lg"
                confirmBtnBsStyle="warning"
            />

        </div>
    }
}


const mapStateToProps = state => ({
    ...state.lotto6.draw,
    config: state.config
})

const mapDispatchToProps = dispatch => ({
    setBetStatus: payload => dispatch(setBetStatusLotto6(payload)),
    setRenderResultStatus: payload => dispatch(setRenderResultStatus(payload)),
    setResultTotalNumber: payload => dispatch(setResultTotalNumberKeno(payload)),
    lotto6AddBetting: payload => dispatch(lotto6AddBetting(payload)),
    deleteLotto6Betting: payload => dispatch(deleteLotto6Betting(payload)),
    lotto6ConfirmBettingNow: (callback) => dispatch(lotto6ConfirmBettingNow(() => callback())),
    fetchLotto6ListBetting: payload => dispatch(fetchLotto6ListBetting(payload)),
    fetchLotto6DrawingResult: () => dispatch(fetchLotto6DrawingResult()),
    fetchLotto6RecentDraw: payload => dispatch(fetchLotto6RecentDraw(payload)),
    fetchLotto6RecentBetReport: () => dispatch(fetchLotto6RecentBetReport()),
    fetchLotto6RecentBetReportCache: () => dispatch(fetchLotto6RecentBetReportCache()),
    fetchUserBalance: () => dispatch(fetchUserBalance()),
    fetchUserBalanceValidate: () => dispatch(fetchUserBalanceValidate()),
    setIsAutoPrint: payload => dispatch(setIsAutoPrint(payload)),
    clearCart: () => dispatch(clearCartLotto6()),
    playCountDownAudio: index => dispatch(playCountDownAudio(index)),
    playGoodLuckAduio: () => dispatch(playGoodLuckAudio()),
    fetchLottoConfig: () => dispatch(fetchLottoConfig()),
    setIsAddToCart: payload => dispatch(setIsAddToCart(payload)),
    fetchStatistic6: () => dispatch(fetchStatistic6())
})

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(DrawingResult))
