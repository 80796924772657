import {
    LOTTO_MIX_REQUEST_FETCH_RESULT_REPORT,
    LOTTO_REQUEST_FETCH_RESULT_REPORT,
    LOTTO_REQUEST_FETCH_WIN_LOSS_REPORT
} from "./constant";

const initialState = {
    winReport: {
        tickets: [],
        summary: {
            totalBetAmount: 0,
            totalWinAmount: "",
            totalWinLoseAmount: "",
            currencyCode: ""
        },
        page: 0,
        size: 0,
        totals: 0,
        filter: {}
    },
    resultLotto5Report: {
        items: [],
        page: 0,
        size: 0,
        totals: 0,
        filter: {}
    },
    resultMixReport: {
        items: [],
        filter: {},
        lottoType: {},
        page: 0,
        size: 0,
        totals: 0
    },

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOTTO_REQUEST_FETCH_RESULT_REPORT:
            return {
                ...state,
                resultLotto5Report: {
                    ...state.resultLotto5Report,
                    ...action.payload
                },
            };
        case LOTTO_REQUEST_FETCH_WIN_LOSS_REPORT:
            return {
                ...state,
                winReport: action.payload
            }
        case LOTTO_MIX_REQUEST_FETCH_RESULT_REPORT:
            return {
                ...state,
                resultMixReport: {
                    ...state.resultMixReport,
                    ...action.payload
                }
            }
        default:
            return state;
    }
};

export default reducer;
