import React from "react";

export const ButtonAutoPrint = props => {
    const {isPrint, setIsAutoPrint, className} = props;
    return (
        <span className={`pointer mr-3 p-2 text-white ${isPrint ? 'enable-auto-print ' : ''}` + className}
              onClick={() => setIsAutoPrint(!isPrint)}>
        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-printer" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"/>
            <path fillRule="evenodd"
                  d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"/>
            <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
        </svg>
    </span>
    )
};