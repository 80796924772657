import React from "react";
import {moneyFormatter} from "../../utils/MoneyUtils";
import {withTranslation} from "react-i18next";
import BetItem from "./BetItem";
import {currencyCode} from "../../constants/general";

class BetCard extends React.Component {
    state = {
        isShow: false
    };
    onEditItem = () => {
        const {
            isBetting = false
        } = this.props;
        if (!isBetting) this.setState((pre) => ({isShow: !pre.isShow}));
    };


    render() {
        let {
            totalBalance, classes, isBetting = false, postGroup, onEditPost, summaryAmount, t
        } = this.props;
        if (classes == null) {
            classes = "w-bet-item-v1";
        }

        if (isBetting) {
            totalBalance = 0;
            postGroup.map(p => {
                totalBalance += p.summaryAmount.betAmount;
                return p
            })
        }

        return <div className={classes}>

            <div className={`bg-white rounded ${classes}`}>
                {
                    postGroup?.map((item, index) => {
                        return <div key={index}
                                    className="w-bet-item">
                            {item.posts !== "RANGE" &&
                            <div onClick={() => onEditPost({index: index, postGroup: item})}
                                 className="font-weight-bold text-info text-left pl-1">{item?.posts?.replaceAll("LoABCD", "Lo")?.replaceAll("ABCDlo", "Lo")}</div>}
                            <div className="pl-1"><BetItem onDeleteItem={this.onDeleteItem} onClearItem={this.onClearItem} item={item} {...this.props}/></div>
                            <div className="border-total mb-1"/>
                            <div className="row font-weight-bold bg-green">
                                <div className="w-left-side row text-white">
                                    <div>សរុប</div>
                                    &nbsp;:&nbsp;
                                    <div>{moneyFormatter(item.summaryAmount?.betAmount, currencyCode(), 'text-white py-2')}</div>
                                </div>
                                {item.summaryAmount?.winAmount > 0 && <div className="w-left-side row text-white">
                                    <div>{t('labels:ticket.total_win')}</div>
                                    &nbsp;:&nbsp;
                                    <div>{moneyFormatter(item.summaryAmount?.winAmount, currencyCode(), 'text-white py-2')}</div>
                                </div>}
                            </div>
                        </div>
                    })
                }
            </div>
            {isBetting && postGroup.length > 1 && totalBalance > 0 &&
            <div className="row p-0 m-0 font-weight-bold bg-total text-white">
                <div className="w-left-side row p-0 m-0">
                    <div className="w-total text-black">សរុប</div>
                    &nbsp;:&nbsp;
                    <div>{moneyFormatter(totalBalance ?? 0, currencyCode())}</div>
                </div>
            </div>}

            {(!isBetting && postGroup.length > 1) &&
            <div onClick={this.onEditItem} className="w-bet-item-v1 bg-total text-white rounded">

                {summaryAmount?.betAmount > 0 && <div className="row p-0 m-0 font-weight-bold ">
                    <div className="w-left-side row p-0 m-0 bg-green">
                        <div className="w-total text-white">សរុបរួម</div>
                        &nbsp;:&nbsp;
                        <div>{moneyFormatter(summaryAmount?.betAmount ?? 0, currencyCode(), "text-white")}</div>
                    </div>
                </div>}

                <div className="bg-danger rounded">
                    {summaryAmount?.winAmount > 0 && <div className="row p-0 m-0 font-weight-bold">
                        <div className="w-left-side row p-0 m-0">
                            <div className="w-total text-black">រង្វាន់</div>
                            &nbsp;:&nbsp;
                            <div>{moneyFormatter(summaryAmount?.winAmount ?? 0, currencyCode())}</div>
                        </div>
                    </div>}
                </div>
            </div>}
        </div>
    }
}

export default withTranslation()(BetCard);
