import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {env} from "../../env"
import {setAudio} from "../../store/audio/action";
import {connect} from "react-redux";
import {inIframe, pfType999Color} from "../../constants/general";
import {VscMute, VscUnmute} from "react-icons/vsc";

class Lotto99Nav extends Component {

    onChangeMute() {
        this.props.setAudio();
    }

    render() {
        let platform = env.PLATFORM_FOR;
        const {t, audio: {isMute}} = this.props;
        let isIframe = inIframe();
        const muted = <VscMute  className="text-white svg-h-vw"/>;
        const unMuted = <VscUnmute className="text-white svg-h-vw"/>
        const isFull = document.location.search.includes("fullscreen");

        const search = isFull ? "fullscreen" : "";

        const CNavLink = ({activeClassName = "active", pathname, exact = false, children}) => {
            return <li>
                <NavLink
                    to={{
                        pathname: pathname,
                        search: search
                    }}
                    exact={exact}
                    activeClassName={activeClassName}
                >{children}</NavLink>
            </li>
        }

        return (
            <div className={"new-keno-menu new-keno-menu-" + platform + " " + pfType999Color}>
                <ul>
                    <CNavLink exact={true} pathname="/lottery1/99">
                        {t("labels:menus.lotto")}
                    </CNavLink>

                    <CNavLink pathname="/lottery1/99/win-report">
                        {t("labels:menus.lottoNav.winReport")}
                    </CNavLink>

                    <CNavLink pathname="/lottery1/99/result">
                        {t("labels:menus.lottoNav.result")}
                    </CNavLink>

                    {
                        isIframe && <li className='float-right'>
                            <span
                                className="full-screen-bell"
                                onClick={this.onChangeMute.bind(this)}>
                                {
                                    isMute ? muted : unMuted
                                }
                            </span>
                        </li>

                    }
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    audio: state.audio
})

const mapDispatchToProps = dispatch => ({
    setAudio: payload => dispatch(setAudio(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Lotto99Nav));
