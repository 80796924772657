import React from 'react';
import HeaderWrapper from "../../layout/HeaderWrapper";
import {inIframe} from "../../constants/general";


const MixTwoScreensPage = (props) => {
    let isIframe = inIframe();

    return (
        <div className="row no-margin-div no-padding-div">
            <HeaderWrapper {...props} isIframe={isIframe}/>
            <div className="col-6 padding-r-vw">
                <iframe title="Lottery6" className="h-lotto w-100"
                        src={`${window.location.origin}`}/>
            </div>
            <div className="col-6 padding-l-vw">
                <iframe title="Lottery5" className="h-lotto w-100"
                        src={`${window.location.origin}`}/>
            </div>
        </div>
    );
};

export default MixTwoScreensPage;