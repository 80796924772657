import {
    LOTTO5_SET_BET_STATUS,
    LOTTO5_SET_RENDER_RESULT_STATUS,
    LOTTO5_REQUEST_LIST_BETTING,
    LOTTO5_REQUEST_FETCH_RESULT_REPORT,
    LOTTO5_REQUEST_FETCH_RECENT_BET_REPORT,
    LOTTO5_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO5_REQUEST_FETCH_DRAWING_RESULT,
    LOTTO5_REQUEST_FETCH_GAME_CONFIG,
    LOTTO5_SET_CURRENT_DRAW, LOTTO5_STATISTIC, LOTTO5_REQUEST_FETCH_RECENT_DRAW,
} from "./constant";

const initialState = {

    draw: {
        newResult: {},
        oldResult: null,
        isStopBet: false,
        isStopRender: true,
        countDownTimer: 180,
        currentDraw: {},
        indexNumber: null,
        lastResult: {
            detail: ""
        },
    },

    bettingLotto5: {
        betList: [],
        summary: {
            total: 0,
            currency: "",
            currencySignal: "",
        },
    },

    winReport: {
        tickets: [],
        summary: {
            totalBetAmount: 0,
            totalWinAmount: "",
            totalWinLoseAmount: "",
            currencyCode: ""
        },
        page: 0,
        size: 0,
        totals: 0
    },

    lastDraws: [],

    resultLotto5Report: {
        items: [],
        page: 0,
        size: 0,
        totals: 0,
        filter: {}
    },

    recentBetting: [],

    statistic: {
        underOverItems: [],
        numberItems: []
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOTTO5_SET_BET_STATUS:
            return {
                ...state,
                draw: {
                    ...state.draw,
                    isStopBet: action.payload.isStopBet,
                }
            };
        case LOTTO5_SET_RENDER_RESULT_STATUS:
            return {
                ...state,
                draw: {
                    ...state.draw,
                    isStopRender: action.payload.isStopRender,
                }
            };

        case LOTTO5_REQUEST_FETCH_GAME_CONFIG:
            return {
                ...state,
                lotto5Config: action.payload.lotto5Config,
                packagePriceLotto5: action.payload.packagePriceLotto5,
            };
        case LOTTO5_SET_CURRENT_DRAW:
            return {
                ...state,
                currentDraw: action.payload || {},
            };
        case LOTTO5_REQUEST_LIST_BETTING:
            return {
                ...state,
                bettingLotto5: {
                    ...state.bettingLotto5,
                    betList: action.payload.betList,
                    summary: {
                        ...state.bettingLotto5.summary,
                        ...action.payload.summary
                    },
                },
            };
        case LOTTO5_REQUEST_FETCH_RESULT_REPORT:
            return {
                ...state,
                resultLotto5Report: {
                    ...state.resultLotto5Report,
                    ...action.payload
                },
            };
        case LOTTO5_REQUEST_FETCH_RECENT_DRAW:
            return {
                ...state,
                draw: {
                    ...state.draw,
                    lastResult: action.payload[0]
                },
                lastDraws: action.payload,
            }
        case LOTTO5_REQUEST_FETCH_RECENT_BET_REPORT:
            return {
                ...state,
                recentBetting: action.payload,
            };
        case LOTTO5_STATISTIC:
            return {
                ...state,
                statistic: action.payload,
            };
        case LOTTO5_REQUEST_FETCH_WIN_LOSS_REPORT:
            return {
                ...state,
                winReport: {
                    tickets: action.payload.tickets,
                    summary: action.payload.summary,
                    ...action.payload
                }
            }
        case LOTTO5_REQUEST_FETCH_DRAWING_RESULT:
            return {
                ...state,
                draw: {
                    ...state.draw,
                    ...action.payload,
                }
            };
        default:
            return state;
    }
};

export default reducer;
