import {combineReducers} from "redux";
import drawing from "./drawing";
import statistic from "./statistic";
import recentDraw from "./recentDraw";
import winReport from "./winReport";
import resultReport from "./resultReport";
import betting from "./betting";
import recentBetting from "./recentBetting";


const lo12v2CombineReducer = combineReducers({
    drawing,
    statistic,
    recentDraw,
    winReport,
    resultReport,
    betting,
    recentBetting
});
export default lo12v2CombineReducer;
