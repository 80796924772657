import {getUser} from "../utils/cached";

const socketEvent = {
    yuki: "v_yuki_drawing",
    l90: "v_lotto90_drawing",
    l5: "v_lotto5_drawing",
    l6: "v_lotto6_drawing",
    l12: "v_lotto12_drawing",
    l5d: "v_lotto5d_drawing",
    l99: "v_lotto99_drawing",
}

const isSocket = (enable = false) => getUser()?.isSocket && enable;
const socketLottoConfig = {
    yuki: isSocket(false),
    l5: isSocket(false),
    l6: isSocket(false),
    l12: isSocket(false),
    l90: isSocket(false),
    l99: isSocket(false),
    l5d: isSocket(false)
}

export {socketEvent, socketLottoConfig, isSocket}