import {getMute} from "../utils/cached";

const requireAudio = (path) => require(`../assets/audios/` + path);

const playAudio = (src, isNotPlay = false) => {
    if (getMute() !== "ON") {
        let audio = new Audio(requireAudio(src));
        if (isNotPlay) return audio;
        const playPromise = audio.play();
        if (playPromise !== undefined) {
            playPromise
                .then((_) => {
                })
                .catch((_) => {
                })
                .finally(() => {
                    audio = null;
                });
        }
        return audio;
    }
};


const playPost = (index) =>
    playAudio(`posts/${index}.mp3`);

const playBalloon = (index, number) =>
    playAudio(`numbers/ball_${index + 1}_${number}.mp3`);

const playDrawingL5D = () =>
    playAudio("lotto90/bling_sound.mp3");


export {
    playAudio,
    playPost,
    playBalloon,
    requireAudio,
    playDrawingL5D
};
