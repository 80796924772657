import React, {useState} from 'react';
import background from "../../assets/images/lotto12v2/zodiac_bg@1x.a104956b.png";
import StatisticTable from "./StatisticTable";
import DrawingTable from "./DrawingTable";
import {imgUrl} from "../../utils/asset";

const DrawingResult = () => {
    const [isFull, setFull] = useState(false);

    const toggleFull = () => setFull(!isFull);

    return (
        <div style={{
            backgroundImage: `url(${background})`,
        }} className={`${isFull ? "d-board-Fullscreen" : "d-board"}`}>
            <img onClick={toggleFull}
                 className="i-fullscreen pointer"
                 src={imgUrl(`lotto12v2/${isFull ? 'exit_fullscreen' : 'fullscreen'}.png`)}
                 alt=""/>
            <div className="d-flex justify-content-center align-items-center">
                <DrawingTable/>
                <StatisticTable/>
            </div>
        </div>
    );
};

export default DrawingResult;
