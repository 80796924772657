import {gql} from "@apollo/client";

export const SUBSCRIPTION_LOTTO12 = gql`
        subscription Lotto12Sub {
            v_lotto12_drawing {
                 drawCode
                 status
            }
           }`;


export const SUBSCRIPTION_LOTTO12V2 = gql`
        subscription YukiSub {
            v_yuki_drawing {
                 drawCode
                 status
            }
           }`;

