import React from "react";
import {env} from "../../env";

export default ({allNumbersDrawing}) => {
    let platform = env.PLATFORM_FOR;
    const rows = [];
    for (let index = 0; index < 8; index++) {
        const cols = [];
        for (let num = 1; num <= 10; num++) {
            const element = num + index * 10;
            cols.push(
                <td
                    key={element}
                    className={platform}
                    id={"lotto5-key" + element}
                    // width="10%"
                >
                        <span
                            className={allNumbersDrawing?.includes(element) ? "active" : ""}>{element}</span>
                </td>
            );
        }
        rows.push(<tr key={index}>{cols}</tr>);
    }
    return rows;
}