import {withTranslation} from 'react-i18next';
import React from "react";
import {Link} from "react-router-dom";
import  {IoIosArrowForward}  from "react-icons/io";

const ResultHistoryV1 = ({isDrawing, number, items, t}) => {
    return <div className="right-side lotto90v1-h-vw">
        {isDrawing && <div id='drawing-ball' className="num-circle-ticket">{number}</div>}
        <div className="row recent-result">
            <div className="text-left f-res">{t("labels:ticket.new")}</div>
        </div>

        {
            items.map((e, i) => {
                return <div key={i} className="draw-sec">
                    <div className="row ml-2 mt-2">
                        <span className="text-right f-res p-1">{e.drawLabel}</span>
                    </div>
                    <div className="row ml-2 mt-0">
                        {e.posts.map((p, i) => {
                            return <div key={i} className="text-center draw-history">
                                <div id="drawing" className={`lotto90-post-${p.postCode}`}>{p.postCode}</div>
                                <div id="drawing" className={`num-circle-ticket num-circle-ticket-${p.postCode}`}>{p.number}</div>
                            </div>
                        })}
                        {/*<div className="result-ml">*/}
                        {/*    <span className="text-right total-draw-sec">{e.result}</span>*/}
                        {/*</div>*/}
                    </div>
                </div>
            })
        }
        <div className="mt-1 p-1-vw">
                <span>
                    <Link to="/lottery90/result">{t("labels:ticket.see_more")}
                        <IoIosArrowForward />
                    </Link>
                </span>
        </div>
    </div>
}


export default withTranslation()(ResultHistoryV1);
