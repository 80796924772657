import React from "react";

const ColorItems = props => {
    const {values} = props;
    return (
        <div className="color-items d-inline-flex justify-content-between">
            {values.map((p, i) => <span className="mr-2" key={i}>{p}</span>)}
        </div>
    );
};

export default ColorItems;