import {LIST_DRAWING_LO99} from "./types";

const init = {
    countDownTimer: 0,
    isStopBet: false,
    newResult: null,
    oldResult: null,
    index: 0,
    number: "",
    detail: [],
    drawCode: null,
    drawAt: null,
    actives: []
}


export default (state = init, {type, payload}) => {
    switch (type) {
        case LIST_DRAWING_LO99:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
}
