import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {IoIosArrowForward} from "react-icons/io";

const ResultHistory = ({list, t, listFull, isFull}) => {
    return <div className="results">
        <div className="list">
            {!isFull && list.map((e, i) => {
                return <div key={i} className="item_99"><span
                    style={{color: "rgb(90, 68, 168)"}}>{e.drawLabel}</span>
                    <div className="list-groups"><span>ប៉ុស្តិ៍</span>
                        <div className="list-group">
                            {e.posts.map((p, j) => {
                                return <div key={j} className="list-group-item-l99"><p
                                    className="item-group">{p.postCode}</p>
                                    <p className={`item-value l99-post-${p.postCode}`}>{p.number}</p></div>
                            })}
                        </div>
                    </div>
                </div>
            })}

            {isFull && listFull.map((e, i) => {
                return <div key={i} className="item_99"><span
                    style={{color: "rgb(90, 68, 168)"}}>{e.drawLabel}</span>
                    <div className="list-groups"><span>ប៉ុស្តិ៍</span>
                        <div className="list-group">
                            {e.posts.map((p, j) => {
                                return <div key={j} className="list-group-item-l99"><p
                                    className="item-group">{p.postCode}</p>
                                    <p className={`item-value l99-post-${p.postCode}`}>{p.number}</p></div>
                            })}
                        </div>
                    </div>
                </div>
            })}

            <div className="p-1-vw w-100 d-flex justify-content-center align-items-center mt-1 text-center">
                <span>
                    <Link to="/lottery1/99/result">{t("labels:ticket.see_more")}
                        <IoIosArrowForward/>
                    </Link>
                </span>
            </div>
        </div>
    </div>
}
const mapStateToProps = state => ({
    list: state.lotto99.recentDraw.list,
    listFull: state.lotto99.recentDraw.listFull
})

export default withTranslation()(connect(
    mapStateToProps,
    {}
)(ResultHistory));
