export default class BetRQ {
    constructor({
                    currencyCode,
                    posts,
                    betType,
                    multiDigit,
                    numberFrom,
                    numberTo,
                    betAmount,
                    numberDetail,
                    numberQuantity,
                    betTitle,
                    totalAmount,
                    sectionNumber,
                    pairStatus
                }) {
        this.currencyCode = currencyCode;
        this.posts = posts;
        this.betType = betType;
        this.multiDigit = multiDigit;
        this.numberFrom = numberFrom;
        this.numberTo = numberTo;
        this.betAmount = betAmount;
        this.numberDetail = numberDetail;
        this.numberQuantity = numberQuantity;
        this.betTitle = betTitle;
        this.totalAmount = totalAmount;
        this.sectionNumber = sectionNumber;
        this.pairStatus = pairStatus;
    }
}
