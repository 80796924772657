import React from "react";
import {ballNumbers} from "../../../constants/lotto90";
import {BetType, post90} from "../../../constants/general";

const BallNumbers = ({onMouseDown, onMouseEnter, onMouseUp, betting, onChangeValue, t, isDrawing, isFull}) => {

    const {listBetting, postBetting, betType} = betting;

    const isActive = (formattedNumber) => {
        let active;
        if (betType === BetType.RANGE && listBetting.length === 2) {
            let list = listBetting.sort();
            let first = parseInt(list[0]);
            let last = parseInt(list[1]);
            let number = parseInt(formattedNumber);
            if (number >= first && number <= last) {
                active = "active";
            } else active = ""
        } else {
            active = listBetting.includes(formattedNumber) ? "active" : "";
        }
        return active;
    }

    return <div>
        {
           !isFull && <div className="post-wrapper">

                <div onChange={onChangeValue}
                     className="d-flex text-black f-bold justify-content-center align-items-center">
                    <label htmlFor="radio1" className="f-vw-v1">
                        <input type="radio" defaultChecked={betType === BetType.NORMAL}
                               value={BetType.NORMAL}
                               className="mr-ra cursor-pointer"
                               name="betType" id="radio1"/> {t("labels:drawing.lotto90.number")}
                    </label>

                    <label htmlFor="radio2" className="f-vw-v1">
                        <input className="ml-5 mr-ra cursor-pointer" defaultChecked={betType === BetType.RANGE}
                               type="radio"
                               value={BetType.RANGE} name="betType"
                               id="radio2"/> {t("labels:drawing.lotto90.range")}
                    </label>

                </div>
                {post90.map((p, i) => {
                    let active = postBetting.includes(p) ? "active" : "";
                    return <button key={i}
                                   onClick={(e) => {
                                       e.preventDefault()
                                   }}
                                   onMouseDown={() => onMouseDown(p, true)}
                                   onMouseEnter={() => onMouseEnter(p, true)}
                                   onMouseUp={() => onMouseUp()}
                                   className={`post-circle-lo90 ${active}`}>{p}
                    </button>
                })}
            </div>
        }
        <div className="table-wrapper">{
            isDrawing ? <div id="tb-number" className="tb-number">
                {ballNumbers.map((r, i) => {
                    let formattedNumber = ("0" + (i + 1)).slice(-2);
                    return <div id={`drawing-${formattedNumber}`} key={i}
                                className="num-circle-lo90v1 none-even">{formattedNumber}</div>
                })}
            </div> : <div id="tb-number" className="tb-number">
                {ballNumbers.map((r, i) => {
                    let formattedNumber = ("0" + (i + 1)).slice(-2);
                    let active = isActive(formattedNumber);
                    return <button
                        onClick={(e) => {
                            e.preventDefault()
                        }}
                        onMouseDown={() => onMouseDown(formattedNumber)}
                        onMouseEnter={() => onMouseEnter(formattedNumber)}
                        onMouseUp={() => onMouseUp()}
                        id={`bet-${formattedNumber}`} key={i}
                        className={`num-circle-lo90v1 ${active}`}>{formattedNumber}</button>
                })}
            </div>
        }
        </div>
    </div>
}

export default BallNumbers;
