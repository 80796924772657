import React from "react";
import RangeStatistic from "./RangeStatistic";
import UnderOverStatistic from "./UnderOverStatistic";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

const StatisticTable = (props) => {
    const {t} = props;
    return <div className="row m-0">
        <div className="col-6 no-padding-div">
            <div className="col no-padding-div">
                <div className="col header-div-title header-history-light text-uppercase">
                    <span className="">{t("labels:keno.underOverOddEven")}</span>
                </div>
                <div className="col no-padding-div div-bg-gray lotto6-history-result fade-in">
                    <div className="bet-grid-container">
                        <table
                            id="keno_result_post"
                            border="1"
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%">
                            <tbody>
                            <UnderOverStatistic {...props}/>
                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
        </div>
        <div className="col-6 no-padding-div">
            <div className="col no-padding-div header-div-title header-history-light card-border-left text-uppercase">
                <span className="">{t("labels:keno.range")}</span>
            </div>
            <div className="col no-padding-div div-bg-gray lotto6-history-result fade-in">
                <div className="bet-grid-container">
                    <table
                        id="keno_result_post"
                        border="1"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        className="fade-in">
                        <tbody>
                        <RangeStatistic {...props}/>
                        </tbody>
                    </table>

                </div>

            </div>

        </div>

    </div>
}


const mapStateToProps = state => ({
    ...state.lotto6.statistic
})


export default withTranslation()(connect(
    mapStateToProps,
    {}
)(StatisticTable));
