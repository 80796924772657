import React from "react";
import {withTranslation} from "react-i18next";

const Paginate = ({t, page, size, total, onChange, ...props}) => {
    const totalPage = Math.ceil(total / size);
    // const from = (page - 1) * size + 1;
    // const to = page * size > total ? total : page * size;

    const pages = () => {
        const list = [];
        if (page > 3)
            list.push(
                <li key={-1} className="page-item disabled">
                    <button className="page-link">...</button>
                </li>
            );
        for (let i = 1; i <= totalPage; i++) {
            if (
                i === page ||
                i - 1 === page ||
                i - 2 === page ||
                i + 1 === page ||
                i + 2 === page
            )
                list.push(
                    <li key={i} className={"page-item" + (i === page ? " active" : "")}>
                        <button className="page-link" onClick={() => onChange(i)}>
                            {i}
                        </button>
                    </li>
                );
        }
        if (page < totalPage - 2)
            list.push(
                <li key={-2} className="page-item disabled">
                    <button className="page-link">...</button>
                </li>
            );
        return list;
    };

    return (
        <div className="mb-5">
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    <li className={"page-item" + (page === 1 ? " disabled" : "")}>
                        <button
                            className="page-link"
                            tabIndex="-1"
                            onClick={(e) => onChange(1)}
                            aria-disabled="true"
                        >{t("labels:pagination.first")}</button>
                    </li>
                    <li className={"page-item" + (page === 1 ? " disabled" : "")}>
                        <button
                            className="page-link"
                            tabIndex="-1"
                            onClick={(e) => onChange(page - 1)}
                            aria-disabled="true"
                        >{t("labels:pagination.previous")}</button>
                    </li>
                    {pages()}
                    <li className={"page-item" + (page === totalPage ? " disabled" : "")}>
                        <button
                            className="page-link"
                            onClick={(e) => onChange(page + 1)}
                            aria-disabled="true"
                        >{t("labels:pagination.next")}</button>
                    </li>
                    <li className={"page-item" + (page === totalPage ? " disabled" : "")}>
                        <button
                            className="page-link"
                            onClick={(e) => onChange(totalPage)}
                            aria-disabled="true"
                        >{t("labels:pagination.last")}</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default withTranslation()(Paginate);
