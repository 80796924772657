import { AUTH_LOGIN } from "./constant"

const initialState = {
	isLoggedIn: null,
	loading: null
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_LOGIN:
			return {
				...state,
				loading: action.payload.loading,
				isLoggedIn: action.payload.isLoggedIn
			}
		default:
			return state
	}
}

export default reducer