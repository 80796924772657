import React, {Component} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import DateRange from "../../components/date/DateRange"
import Paginate from "../../components/table/Paginate";
import {withTranslation} from "react-i18next";
import moment from "moment";
import 'moment-timezone';
import {fetchStatement} from "../../store/user/action";
import LoadingBar from "react-redux-loading-bar";
import {currentDate, getDateDef} from "../../utils/dateUtils";
import Images from "../../constants/lotto5Images";
import LottoMixNav from "../../components/lotto/mix/LottoMixNav";
import FeatureWrapper from "../../routes/FeatureWrapper";
import {moneyFormatter} from "../../utils/MoneyUtils";

class Statement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            startDate: currentDate(),
            endDate: currentDate(),
            maxDate: currentDate(),
            lotteryType: "ALL",
        };
    }

    componentDidMount() {
        this.getSettlement({})
    }

    onChange(page) {
        this.getSettlement({page: page});
        this.setState({page: page});
    }

    onChangeStartDate(date) {
        this.setState({startDate: date});
    }

    onChangeEndDate(date) {
        this.setState({endDate: date});
    }

    onFilter(type) {
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        if (type === "TODAY") {
            startDate = new Date(moment().startOf('day'));
            endDate = new Date(moment().endOf('day'));
        } else if (type === "YESTERDAY") {
            startDate = new Date(moment().startOf('day').subtract('days', 1));
            endDate = new Date(moment().endOf('day').subtract('days', 1));
        } else if (type === "THIS_WEEK") {
            startDate = new Date(moment().startOf('week').add('days', 1));
            endDate = new Date(moment().endOf('week').add('days', 1));
        } else if (type === "LAST_WEEK") {
            startDate = new Date(moment().startOf('week').subtract('days', 6));
            endDate = new Date(moment().endOf('week').subtract('days', 6));
        } else if (type === "THIS_MONTH") {
            startDate = new Date(moment().startOf('month'));
            endDate = new Date(moment().endOf('month'));
        } else if (type === "LAST_MONTH") {
            startDate = new Date(moment().subtract(1, "month").startOf("month"));
            endDate = new Date(moment().subtract(1, "month").endOf("month"));
        }

        this.setState({
            startDate: startDate,
            endDate: endDate
        });

        this.getSettlement({startDate: startDate, endDate: endDate})
    }

    getSettlement = (params = {}) => {
        if (!params.startDate)
            params.startDate = this.state.startDate;
        if (!params.endDate)
            params.endDate = this.state.endDate;
        this.props.fetchStatement({
            ...this.props.filter,
            startDate: getDateDef(params.startDate),
            endDate: getDateDef(params.endDate),
            filterByLotto: this.state.lotteryType
        });
    }

    onLotteryTypeChange(value) {
        this.setState({
            lotteryType: value.target.value
        });
    }

    render() {
        const {
            t, items, summary: {
                currencyCode,
                betAmount,
                winAmount,
                winLoseAmount,
            }
        } = this.props;
        // const currency = getCurrency(currencyCode);
        return (
            <div className="main-w">
                {window.location?.pathname === "/lottery/statement-mix" && <LottoMixNav/>}
                <div className="main">
                    <div className="w-1200">
                        <div className="mt-1">
                            <div className="fade-in row align-items-center ml-1">
                                <div className="lottery-type pl-4 mb-2">
                                    <label>{t("labels:common.lotteryType")} :</label>
                                    <select onChange={this.onLotteryTypeChange.bind(this)} className="border">
                                        <option value="ALL">{t("labels:common.all")}</option>
                                        <FeatureWrapper value="5">
                                            <option value="LOTTO5">{t("labels:menus.lotto")} 5</option>
                                        </FeatureWrapper>
                                        <FeatureWrapper value="6">
                                            <option value="LOTTO6">{t("labels:menus.lotto")} 6/36</option>
                                        </FeatureWrapper>
                                        <FeatureWrapper value="12">
                                            <option value="LOTTO12">{t("labels:menus.lotto")} 12</option>
                                        </FeatureWrapper>
                                        <FeatureWrapper value="90">
                                            <option value="LOTTO90">{t("labels:menus.lotto")} 90</option>
                                        </FeatureWrapper>
                                        <FeatureWrapper value="5D">
                                            <option value="LOTTO5D">{t("labels:menus.lotto")} 5D</option>
                                        </FeatureWrapper>
                                        <FeatureWrapper value="yuki">
                                            <option value="YUKI">{t("labels:menus.lotto")} 1/12</option>
                                        </FeatureWrapper>
                                        <FeatureWrapper value="99">
                                            <option value="LOTTO99">{t("labels:menus.lotto")} 1/99</option>
                                        </FeatureWrapper>
                                    </select>
                                </div>
                                <DateRange
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    maxDate={this.state.maxDate}
                                    onChangeStartDate={this.onChangeStartDate.bind(this)}
                                    onChangeEndDate={this.onChangeEndDate.bind(this)}
                                />
                                <div className="wrap-btn-filter">
                                    <button className="btn-game"
                                            onClick={this.onFilter.bind(this, '')}>{t("labels:common.search")}</button>
                                    <button className="btn-game-green"
                                            onClick={this.onFilter.bind(this, 'TODAY')}>{t("labels:common.today")}</button>
                                    <button className="btn-game-green"
                                            onClick={this.onFilter.bind(this, 'YESTERDAY')}>{t("labels:common.yesterday")}</button>
                                    <button className="btn-game-green"
                                            onClick={this.onFilter.bind(this, 'THIS_WEEK')}>{t("labels:common.thisWeek")}</button>
                                    <button className="btn-game-green"
                                            onClick={this.onFilter.bind(this, 'LAST_WEEK')}>{t("labels:common.lastWeek")}</button>
                                    <button className="btn-game-green"
                                            onClick={this.onFilter.bind(this, 'THIS_MONTH')}>{t("labels:common.thisMonth")}</button>
                                    <button className="btn-game-green"
                                            onClick={this.onFilter.bind(this, 'LAST_MONTH')}>{t("labels:common.lastMonth")}</button>
                                </div>
                            </div>

                            <LoadingBar scope="sectionBar" style={{backgroundColor: '#448', height: '5px'}}/>

                            <div className="wrapper-game-content fade-in">
                                <div className="table-container pt-0">
                                    <table className="table-custom">
                                        <thead>
                                        <tr>
                                            <th className="text-center">{t("labels:common.no")}</th>
                                            <th className="text-center">{t("labels:common.date")}</th>
                                            <th className="text-center">{t("labels:statement.turnover")}</th>
                                            <th className="text-center">{t("labels:common.reward")}</th>
                                            <th className="text-center">{t("labels:common.winLose")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            _.isEmpty(items)
                                                ? <tr>
                                                    <td colSpan={11}><img className="data-not-found-img"
                                                                          src={Images.dataNotFound} alt="..."/></td>
                                                </tr>
                                                : items.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center">{index + 1}</td>
                                                            <td className="text-center">{getDateDef(item.drawAt)}</td>
                                                            <td className="text-right pr-2">
                                                                {moneyFormatter(item.betAmount, currencyCode)}
                                                                {/*<NumberFormat*/}
                                                                {/*    value={item.betAmount}*/}
                                                                {/*    renderText={value => <span*/}
                                                                {/*        className="number-bold text-dark">{value}</span>}*/}
                                                                {/*    displayType={'text'}*/}
                                                                {/*    thousandSeparator={true}*/}
                                                                {/*    suffix={currency}*/}
                                                                {/*/>*/}
                                                            </td>
                                                            <td className="text-right pr-2">
                                                                {moneyFormatter(item.winAmount, currencyCode, "", true)}
                                                                {/*<NumberFormat*/}
                                                                {/*    renderText={value => <p*/}
                                                                {/*        className="text-danger">{value}</p>}*/}
                                                                {/*    value={item.winAmount}*/}
                                                                {/*    displayType={'text'}*/}
                                                                {/*    thousandSeparator={true}*/}
                                                                {/*    suffix={currency}*/}
                                                                {/*/>*/}
                                                            </td>
                                                            <td className="text-right pr-2">
                                                                {moneyFormatter(item.winLoseAmount, currencyCode)}
                                                                {/*<NumberFormat*/}
                                                                {/*    renderText={value => <p*/}
                                                                {/*        className={item.winLoseAmount < 0 ? "text-danger" : "text-primary"}>{value}</p>}*/}
                                                                {/*    value={item.winLoseAmount}*/}
                                                                {/*    displayType={'text'}*/}
                                                                {/*    thousandSeparator={true}*/}
                                                                {/*    suffix={currency}*/}
                                                                {/*/>*/}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                        }
                                        <tr className="win-footer">
                                            <td className="text-center" colSpan={2}><span
                                                className="pl-2">{t("labels:common.total")}</span></td>
                                            <td className="text-right pr-2">
                                                {moneyFormatter(betAmount, currencyCode)}
                                                {/*<NumberFormat*/}
                                                {/*    value={betAmount}*/}
                                                {/*    displayType={'text'}*/}
                                                {/*    thousandSeparator={true}*/}
                                                {/*    suffix={currency}*/}
                                                {/*/>*/}
                                            </td>
                                            <td className="text-right pr-2">
                                                <span className="text-danger number-bold">
                                                {moneyFormatter(winAmount, currencyCode, "", true)}
                                                </span>
                                                {/*<NumberFormat*/}
                                                {/*    renderText={value => <p*/}
                                                {/*        className="text-danger number-bold">{value}</p>}*/}
                                                {/*    value={winAmount}*/}
                                                {/*    displayType={'text'}*/}
                                                {/*    thousandSeparator={true}*/}
                                                {/*    suffix={currency}*/}
                                                {/*/>*/}
                                            </td>
                                            <td className="text-right pr-2">
                                                {moneyFormatter(winLoseAmount, currencyCode)}
                                                {/*<NumberFormat*/}
                                                {/*    value={winLoseAmount}*/}
                                                {/*    renderText={value => <p*/}
                                                {/*        className={winLoseAmount < 0 ? "text-danger number-bold" : "text-primary number-bold"}>{value}</p>}*/}
                                                {/*    displayType={'text'}*/}
                                                {/*    thousandSeparator={true}*/}
                                                {/*    suffix={currency}*/}
                                                {/*/>*/}
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {
                                    this.props.totals > 0
                                        ? <div className="mt-2">
                                            <Paginate
                                                page={this.state.page}
                                                size={this.props.size}
                                                total={this.props.totals}
                                                onChange={page => this.onChange(page)}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state.user.statement
};

const mapDispatchToProps = dispatch => ({
    fetchStatement: payload => dispatch(fetchStatement(payload))
})

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(Statement));
