const FETCH_RESULTS_LO5D = 'FETCH_RESULTS_LO5D';
const LIST_RESULTS_LO5D = 'LIST_RESULTS_LO5D';
const FETCH_RESULTS_LO90 = 'FETCH_RESULTS_LO90';
const LIST_RESULTS_LO90 = 'LIST_RESULTS_LO90';


const FETCH_RESULTS_HISTORY_LO90 = 'FETCH_RESULTS_HISTORY_LO90';
const LIST_RESULTS_HISTORY_LO90 = 'LIST_RESULTS_HISTORY_LO90';

const FETCH_DRAWING_LO5D = 'FETCH_DRAWING_LO5D';
const LIST_DRAWING_LO5D = 'LIST_DRAWING_LO5D';
const FETCH_DRAWING_LO90 = 'FETCH_DRAWING_LO90';
const LIST_DRAWING_LO90 = 'LIST_DRAWING_LO90';

const ADD_BET = 'ADD_BET';
const CLEAR_BET = 'CLEAR_BET';

const LIST_TICKET = 'LIST_TICKET';
const FETCH_TICKET = 'FETCH_TICKET';
const CLEAR_TICKET = 'CLEAR_TICKET';
const CREATE_BETTING_LO6N = 'CREATE_BETTING_LO6N';
const LIST_BETTING_LO6N = 'LIST_BETTING_LO6N';
const FETCH_TICKET_5D = 'FETCH_TICKET_5D';
const LIST_TICKET_5D = 'LIST_TICKET_5D';
const LIST_RECENT_TICKET_5D = 'LIST_RECENT_TICKET_5D';
const FETCH_RECENT_TICKET_5D = 'FETCH_RECENT_TICKET_5D';

const ERROR_LOADING = 'ERROR_LOADING';
const MODIFIED_BETTING = 'MODIFIED_BETTING';
const CREATE_BETTING = 'CREATE_BETTING';
const CLEAR_ORDER = 'CLEAR_ORDER';
const UPDATE_LIST_BETTING = 'UPDATE_LIST_BETTING';
const FETCH_LO5D_BETTING = 'FETCH_LO5D_BETTING';
const LIST_LO5D_BETTING = 'LIST_LO5D_BETTING';
const LIST_MEMBER = 'LIST_MEMBER';
const LIST_PAGE = 'LIST_PAGE';
const LIST_SALE_REPORTS = 'LIST_SALE_REPORTS';
const FETCH_SALE_REPORTS = 'FETCH_SALE_REPORTS';
const CLEAR_SALE_REPORTS = 'CLEAR_SALE_REPORTS';
const LIST_DAILY_REPORTS = 'LIST_DAILY_REPORTS';
const FETCH_DAILY_REPORTS = 'FETCH_DAILY_REPORTS';
const CLEAR_DAILY_REPORT = 'CLEAR_DAILY_REPORT';


export {
    FETCH_RESULTS_LO5D,
    LIST_RESULTS_LO5D,
    FETCH_RESULTS_LO90,
    LIST_RESULTS_LO90,
    FETCH_RESULTS_HISTORY_LO90,
    LIST_RESULTS_HISTORY_LO90,
    FETCH_DRAWING_LO5D,
    LIST_DRAWING_LO5D,
    FETCH_DRAWING_LO90,
    LIST_DRAWING_LO90,
    ADD_BET,
    CLEAR_BET,
    LIST_TICKET,
    FETCH_TICKET,
    CLEAR_TICKET,
    CREATE_BETTING_LO6N,
    LIST_BETTING_LO6N,
    FETCH_TICKET_5D,
    LIST_TICKET_5D,
    ERROR_LOADING,
    MODIFIED_BETTING,
    CREATE_BETTING,
    CLEAR_ORDER,
    UPDATE_LIST_BETTING,
    FETCH_LO5D_BETTING,
    LIST_LO5D_BETTING,
    LIST_MEMBER,
    LIST_PAGE,
    LIST_SALE_REPORTS,
    FETCH_SALE_REPORTS,
    CLEAR_SALE_REPORTS,
    LIST_DAILY_REPORTS,
    FETCH_DAILY_REPORTS,
    CLEAR_DAILY_REPORT,
    LIST_RECENT_TICKET_5D,
    FETCH_RECENT_TICKET_5D
}





