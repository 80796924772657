import {env} from "../env";

export default {
    FETCH_PROFILE_USER: env.BASE_URL + "/api/v1.0.0/account/profile",
    UPDATE_PROFILE_USER: env.BASE_URL + "/auth/api/v1.0.0/user",
    RESET_PASSWORD_USER: env.BASE_URL + "/api/v1.0.0/account/reset-password",
    VERIFIED_PASSWORD_USER:
        env.BASE_URL + "/auth/api/v1.0.0/user/verify-reset-password",
    CHANGE_LANGAUGE_USER: env.BASE_URL + "/api/v1.0.0/account/language",
    FETCH_ACTIVITY: env.BASE_URL + "/activity/api/v1.0.0/activity",
    FETCH_BALANCE: env.BASE_URL + "/api/v1.0.0/account/balance",
    FETCH_BALANCE_VALIDATE: env.BASE_URL + "/api/v1.0.0/account/balance",
    FETCH_STATEMENT: env.BASE_URL + "/api/v1.0.0/reports/sale-report",
    FETCH_BETTYPE_CONFIG: env.BASE_URL + "/api/v1.0.0/account/configuration",
};
