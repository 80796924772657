import React from "react";
import history from "../routes/history"
import {connect} from "react-redux";
import {fetchLottoConfig} from "../store/config/action";
import {fetchUserProfile} from "../store/user/action";

export default (WrappedComponent) => {

    class WithAuth extends React.Component {

        componentDidMount() {
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken === "" || accessToken === null || accessToken === undefined) {
                history.push("/login")
                return;
            }
            this.props.fetchLottoConfig();
            this.props.fetchUserProfile();
        }

        render() {
            const accessToken = localStorage.getItem("accessToken");

            if (accessToken === "" || accessToken === null || accessToken === undefined) {
                return null
            } else {
                return (<WrappedComponent {...this.props} />)
            }
        }
    }

    const mapStateToProps = state => ({})

    return connect(mapStateToProps, {fetchLottoConfig, fetchUserProfile})(WithAuth)
}
