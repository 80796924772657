import {
    CLEAR_ORDER,
    CREATE_BETTING,
    ERROR_LOADING,
    FETCH_LO5D_BETTING,
    LIST_LO5D_BETTING,
    MODIFIED_BETTING,
    UPDATE_LIST_BETTING
} from "./types";

export const SaleModel = {
    drawShifts: [],
    pageNumber: 1,
    lanes: [],
    lastDraws: []
};

const init = {
    isLoading: false,
    filter: {},
    paging: {},
    data: {...SaleModel},
    filters: null
};

export default (state = init, {type, payload, filters, paging}) => {
    switch (type) {
        case FETCH_LO5D_BETTING:
            return {
                ...state,
                filter: payload,
                filters: filters,
                paging: paging,
                isLoading: true
            };
        case MODIFIED_BETTING:
        case CREATE_BETTING:
            return {
                ...state,
                isLoading: true,
            };
        case LIST_LO5D_BETTING:
            return {
                ...state,
                isLoading: false,
                data: payload.lanes.length === 0 ? {...SaleModel, lastDraws: payload.lastDraws} : payload,
                paging: paging,
            };
        case UPDATE_LIST_BETTING:
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    lanes: payload,
                },
            };
        case ERROR_LOADING:
            return {
                ...state,
                isLoading: false,
            };
        case CLEAR_ORDER:
            return init;
        default:
            return state;
    }
}
