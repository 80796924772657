const LIST_DRAWING_LO12V2 = 'LIST_DRAWING_LO12V2';
const LIST_STATISTIC_LO12V2 = 'LIST_STATISTIC_LO12V2';
const LOTTO12V2_LIST_RECENT_DRAW = 'LOTTO12V2_LIST_RECENT_DRAW';
const LOTTO12V2_REQUEST_FETCH_WIN_LOSS_REPORT = "LOTTO12V2_REQUEST_FETCH_WIN_LOSS_REPORT"
const LOTTO12V2_REQUEST_FETCH_RESULT_REPORT = "LOTTO12V2_REQUEST_FETCH_RESULT_REPORT"
const LOTTO12V2_LIST_BETTING = 'LOTTO12V2_LIST_BETTING';
const LOTTO12V2_FETCH_RECENT_BET_REPORT = 'LOTTO12V2_FETCH_RECENT_BET_REPORT';


export {
    LIST_DRAWING_LO12V2,
    LIST_STATISTIC_LO12V2,
    LOTTO12V2_LIST_RECENT_DRAW,
    LOTTO12V2_REQUEST_FETCH_WIN_LOSS_REPORT,
    LOTTO12V2_REQUEST_FETCH_RESULT_REPORT,
    LOTTO12V2_LIST_BETTING,
    LOTTO12V2_FETCH_RECENT_BET_REPORT
}





