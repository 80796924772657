import {
    USER_FETCH_PROFILE_USER,
    USER_FETCH_STATEMENT,
    USER_FETCH_ACTIVITY,
    USER_FETCH_BALANCE,
    USER_RESET_PASSWORD,
    USER_LOGOUT,
    IS_AUTO_PRINT, IS_ADD_TO_CART
} from "./constant";
import {getIsAddToCart, getIsPrint, toggleAddToCart, toggleIsPrint} from "../../utils/cached";

const initialState = {
    statement: {
        items: [],
        summary: {
            betAmount: 0,
            currencyCode: "KHR",
            winAmount: 0,
            winLoseAmount: 0
        },
        page: 0,
        size: 0,
        totals: 0,
        filter: {}
    },
    activityLog: {
        items: [],
        page: 0,
        size: 0,
        total: 0
    },
    user: {},
    userBalance: {
        balance: 0,
        fullPending: 0,
        pendingLotto5: 0,
        pendingLotto6: 0,
        pendingLotto12: 0,
        pendingLotto90: 0,
        pendingLotto5d: 0,
        pendingLotto99: 0,
        pendingYuki: 0,
        currencyCode: "",
        currencySignal: ""
    },
    isPasswordVerified: undefined,
    isPasswordReset: undefined,
    userError: undefined,
    userLoading: undefined,
    isAutoPrint: getIsPrint() !== "OFF",
    isAddToCart: getIsAddToCart() !== "OFF"
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_FETCH_PROFILE_USER:
            return {
                ...state,
                user: action.payload,
            }
        case USER_FETCH_STATEMENT:
            return {
                ...state,
                statement: {
                    ...state.statement,
                    items: action.payload.items,
                    summary: action.payload.summary,
                    page: action.payload.page,
                    size: action.payload.size,
                    total: action.payload.total
                }
            }
        case USER_FETCH_ACTIVITY:
            return {
                ...state,
                activityLog: {
                    ...state.activityLog,
                    items: action.payload.items
                }
            }
        case USER_FETCH_BALANCE:
            return {
                ...state,
                userBalance: {
                    ...state.userBalance,
                    ...action.payload
                }
            }
        case USER_RESET_PASSWORD:
            return {
                ...state,
                isPasswordVerified: action.payload.isPasswordVerified,
                isPasswordReset: action.payload.isPasswordReset
            }
        case USER_LOGOUT:
            return {
                ...state,
                user: {}
            }
        case IS_AUTO_PRINT:
            toggleIsPrint(action.isAutoPrint);
            return {
                ...state,
                isAutoPrint: action.isAutoPrint
            }
        case IS_ADD_TO_CART:
            toggleAddToCart(action.isAddToCart);
            return {
                ...state,
                isAddToCart: action.isAddToCart
            }

        default:
            return state
    }
}

export default reducer;
