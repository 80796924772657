import React from "react";

export default (props) => {
    let rows = [];
    const {underOverItems} = props;
    for (let i = 0; i < underOverItems.length; i++) {
        let cols = [];
        let rowNum = underOverItems[i];
        for (let j = 0; j < rowNum?.length; j++) {
            const col = rowNum[j];
            let balls = col.split("_");
            cols.push(<td
                key={i + "" + j}
                id={"keno-history-key" + i + "" + j}
                width="5%"
            >
                {
                    col.length > 0 ?
                        <span
                            className={"d-flex justify-content-center align-items-center text-black lotto12-statistic-ball-" + balls[0]}>
                         {props.t("labels:drawing.drawPrefix." + balls[1])}
                        </span>
                        : <span/>
                }
            </td>);
        }
        rows.push(<tr key={i}>{cols}</tr>);
    }

    return rows;

}
