import React, {useEffect, useState} from "react";
import {playAudio} from "../../../store/audio/action";
import {BetType, getZh} from "../../../constants/general";
import Label from "reactstrap/es/Label";
import Button from "react-bootstrap/Button";
import BetCard from "./BetCard";
import {moneyFormatter} from "../../../utils/MoneyUtils";
import _ from "lodash";
import swal from "sweetalert";
import DashboardV1 from "../v1/DashboardWrapperV1";
import BettingBoard from "../BettingBoard";
import {getRecentSize} from "../../../store/user/action";

const initBet = {
    listBetting: [],
    postBetting: [],
    rangeBetting: [],
}

const field = {
    betAmount: 0,
    numberFrom: "",
    numberTo: "",
    betLogs: []
}

const BettingDashboard = (props) => {
    let [id, setId] = useState("");
    let [betType, setBetType] = useState(BetType.NORMAL);
    let [betting, setBetting] = useState({...initBet});
    let [fields, setFields] = useState({...field});
    let [postGroup, setPostGroup] = useState([]);
    let [isBetting, setIsBetting] = useState(false);
    let {
        listBetting,
        postBetting,
        rangeBetting
    } = betting;

    const {
        drawing: {number, isDrawing}, drawingNumber,
        fetchLotto90ResultReport,
        resultReport: {bItems},
        createBetting,
        newResult,
        fetchUserBalance,
        user: {userBalance},
        t,
        config,
        audio,
        i18n,
        isBet
    } = props;
    const getTempPostBet = () => {
        const betAmount = parseInt(fields.betAmount);
        if (listBetting.length > 0 && postBetting.length > 0 && betAmount > 0) {
            postBetting.sort()
            let posts = postBetting.join("");
            let items = listBetting.map(p => {
                return {
                    betTypeCode: "NORMAL",
                    betTitle: "NORMAL",
                    posts: posts,
                    numberFrom: p,
                    numberTo: "",
                    amount: betAmount,
                    betLog: fields.betLogs.join(",")
                }
            })
            let total = betAmount * posts.length;
            if (betType === BetType.RANGE && listBetting.length === 2) {
                items.sort();
                let uItem = items[0];
                uItem.numberTo = items[1].numberFrom;
                uItem.betTitle = "RANGE";
                uItem.betTypeCode = "RANGE";
                items = [uItem]
                let num = (parseInt(uItem.numberTo) - parseInt(uItem.numberFrom)) + 1;
                total *= num;
            } else {
                total *= items.length;
            }
            return {
                items: items,
                total: total,
                posts: posts
            };
        }
        if (rangeBetting.length > 0 && fields.betAmount > 0) {
            let items = rangeBetting.map(p => {
                return {
                    betTypeCode: p,
                    betTitle: "NORMAL",
                    posts: "",
                    numberFrom: p,
                    numberTo: "",
                    amount: betAmount,
                    betLog: fields.betLogs.join(",")
                }
            })
            return {
                items: items,
                total: betAmount * items.length,
                posts: ""
            };
        }

        return null;
    }

    let tItems = getTempPostBet();
    let ps = tItems ? [...postGroup, tItems] : postGroup;


    const playSound = (src) => {
        if (audio && !audio.isMute) playAudio(src);
    }


    useEffect(() => {
        fetchLotto90ResultReport();
        fetchUserBalance();
        setBetting({
            postBetting: [],
            listBetting: [],
            rangeBetting: []
        })
    }, [drawingNumber, fetchLotto90ResultReport, fetchUserBalance, setBetting])


    const onMouseUp = () => {
        setId("")
    }
    const onMouseDown = (number, isPost = false) => {
        setId(number);
        if (!isPost) setActiveNumber(number)
        else setActivePost(number)
    }

    const onMouseEnter = (item, isPost = false) => {
        if (id !== "") {
            if (!isPost) setActiveNumber(item)
            else setActivePost(item)
        }
    }
    const setActiveNumber = (number) => {
        if (listBetting.includes(number)) {
            listBetting = listBetting.filter(e => e !== number);
        } else {
            if (betType === BetType.RANGE && listBetting.length === 2) {
                listBetting[0] = number;
            } else
                listBetting.push(number);
        }
        setBetting({...betting, listBetting: listBetting})
    }


    const setActivePost = (item) => {
        if (postBetting.includes(item)) {
            postBetting = postBetting.filter(e => e !== item)
        } else postBetting.push(item);
        setBetting({...betting, postBetting: postBetting})
    }

    const onChangeValue = (event) => {
        setBetType(event.target.value);
        onAdded();
    }


    /* handle on bet input change value  */
    const onClickAmount = (p) => {
        let amount = fields.betAmount;
        if (amount === "") amount = 0;
        let betAmount = parseInt(amount);
        fields.betLogs.push(`${p.value}`);
        setFields({...fields, betAmount: betAmount + p.value});
    };

    const clearAll = () => {
        clearTempBet()
        setPostGroup([]);
    }

    const handleSubmitBetting = (isPrint) => {
        if (isBetting) return;
        let list = [];
        ps.map(e => {
            e.items.map(p => {
                list.push(p)
                return p;
            });
            return e;
        })
        let payload = {
            drawCode: newResult?.drawCode,
            items: list
        }
        if (!payload.drawCode) {
            swal("Drawing", {icon: "error", button: "បិទ"});
            return;
        }
        setIsBetting(true);
        createBetting(payload).then((_ => {
            clearAll();
            setFields({...fields, betAmount: 0, betLogs: []});
            fetchUserBalance();
            props.fetchLotto90RecentTicket({size: getRecentSize().lo90})
                .then(_ => {
                    isPrint && document.getElementById("print-button").click()
                });
            setIsBetting(false);
        })).catch(error => {
            let {response} = error;
            error && swal(response.data.message, {icon: "error", button: "បិទ"});
            setIsBetting(false);
        })
    }

    const clearTempBet = () => {
        setBetting({
            ...betting,
            postBetting: [],
            listBetting: [],
            rangeBetting: []
        })
    }

    const onClearBetItem = () => {
        clearTempBet();
        setPostGroup([]);
    }


    const onAdded = () => {
        tItems && postGroup.push(tItems);
        setPostGroup(postGroup);
        clearTempBet();
    }

    const onClickSpecial = (code) => {
        if (rangeBetting.includes(code)) {
            rangeBetting = rangeBetting.filter(e => e !== code);
        } else {
            rangeBetting.push(code);
        }
        setBetting({...betting, rangeBetting: rangeBetting})
    }

    const isZh = i18n.language === 'zh';


    const BettingCard = () => {
        return <>
            <div className="ml-1 mr-3 d-flex mt-4 align-items-center justify-content-end">
                <Label className="m-0 f-bold">{t("labels:ticket.bet_amount")}</Label>
                <div
                    className='border text-left w-50 p-2 rounded mr-2 ml-2 f-bold'>{moneyFormatter(fields.betAmount, userBalance?.currencyCode)}</div>
                <button onClick={() => setFields({...fields, betAmount: 0, betLogs: []})}
                        className="btn btn-secondary">{t("labels:common.resetBet")}
                </button>

            </div>
            <div className="price-wrapper">
                {
                    config.lotto90PackagePrices.map((p, i) => {
                        return <button
                            key={i}
                            onClick={() => onClickAmount(p)}
                            className={`mb-1 bet-price`}>{isZh ? getZh[p.value] : p.label}</button>
                    })
                }
            </div>
            <div className="d-flex justify-content-end btn-wrapper mt-2 mb-2">
                <Button id="save"
                        disabled={_.isEmpty(ps) || isBetting}
                        className="mr-3"
                        onClick={() => handleSubmitBetting(false)}>
                    {t("buttons:save")}
                </Button>
                <Button className="bg-green border-0 mr-3"
                        disabled={_.isEmpty(ps) || isBetting}
                        id="saveprint"
                        onClick={() => handleSubmitBetting(true)}>
                    {t("buttons:print")}
                </Button>
                <Button
                    id="clear"
                    disabled={_.isEmpty(ps)}
                    className="bg-secondary border-0 mr-3"
                    onClick={onClearBetItem}>
                    {t("buttons:clear")}
                </Button>
                <Button
                    id="ready"
                    disabled={_.isEmpty(ps)}
                    className="bg-green border-0 mr-3"
                    onClick={onAdded}>
                    {t("buttons:added")}
                </Button>
            </div>
            {(tItems || postGroup.length > 0) && <BetCard ps={ps} currencyCode={userBalance.currencyCode}/>}</>
    }

    if (isBet) return <BettingBoard
        userBalance={userBalance}
        isDrawing={isDrawing}
        playSound={playSound}
        onChangeValue={onChangeValue}
        betType={betType}
        onClickSpecial={onClickSpecial}
        rangeBetting={rangeBetting}
        onMouseDown={onMouseDown}
        betting={betting}
        onMouseEnter={onMouseEnter}
        onMouseUp={onMouseUp}
        bItems={bItems}
        number={number}
        BettingCard={<BettingCard/>}
        {...props}
    />;
    return <DashboardV1
        onChangeValue={onChangeValue}
        BettingCard={BettingCard}
        onMouseDown={onMouseDown}
        betting={{...betting, betType}}
        onMouseEnter={onMouseEnter}
        onMouseUp={onMouseUp}
        playSound={playSound}
        {...props}/>

}

export default BettingDashboard;
