import React from "react";
import UnderOverStatistic from "./UnderOverStatistic";
import RangeStatistic from "./RangeStatistic";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isOddEven} from "../../constants/general";

const StatisticTable = (props) => {
    const {t} = props;
    return <div className="row col no-padding-div position-static">
        <div className="lotto5-history-result">
            <table
                id="keno_result_post"
                cellPadding="0"
                cellSpacing="0"
                width="100%"
            >
                <tbody>
                <tr>
                    <td colSpan={13} className="border-0 ">
                        <table
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%">
                            <tbody>
                            <tr>
                                <td colSpan={16} className="border-top-0" style={{height: "30px"}}>
                                    <label className="bg-main" style={{lineHeight: "30px"}}>{isOddEven ? t("labels:keno.underOverOddEven") : t("labels:keno.underOver")}</label>
                                </td>
                            </tr>
                            <UnderOverStatistic {...props}/>
                            </tbody>
                        </table>
                    </td>
                    <td className="w-space border-0"/>
                    <td colSpan={13} className='border-0'>
                        <table
                            cellPadding="0"
                            cellSpacing="0"
                            width="100%">
                            <tbody>
                            <tr>
                                <td colSpan={16} className="border-bottom-0 border-top-0" style={{height: "30px"}}>
                                    <label className="pad-3 bg-main" style={{lineHeight: "30px"}}>{t("labels:keno.range")}</label>
                                </td>
                            </tr>
                            <RangeStatistic {...props}/>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>;
}

const mapStateToProps = state => ({
    ...state.lotto5.statistic
})


export default withTranslation()(connect(
    mapStateToProps,
    {}
)(StatisticTable));
