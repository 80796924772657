import {combineReducers} from "redux";
import drawing from "./drawing";
import result from "./result";
import ticket from "./ticket";
import betting from "./betting";
import sale from "./sale";
import page from "./page";
import recentTicket from "./recentTicket";


const lo5dCombineReducer = combineReducers({
    drawing,
    result,
    ticket,
    betting,
    sale,
    page,
    recentTicket
});
export default lo5dCombineReducer;
