import NumberFormat from "react-number-format";
import React from "react";
import Modal from "react-bootstrap/Modal";
import {ButtonAutoPrint} from "../print/ButtonAutoPrint";
import {moneyFormatter} from "../../utils/MoneyUtils";
import {getZh, inIframe} from "../../constants/general";

class BettingDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: []
        }
    }

    clickAmountBetBtn = (value) => {
        this.state.logs.push(value);
        this.props.clickAmountBetBtn(value);
    }
    resetDefaultAmount = () => {
        this.setState({logs: []})
        this.props.resetDefaultAmount()
    }

    onConfirmBettingLotto = () => {
        let logs = this.state.logs.join(",");
        this.props.onConfirmBettingLotto5(logs);
        this.setState({logs: []});
    }

    onCancelBetting = () => {
        this.setState({logs: []});
        this.props.onCancelBetting();
    }

    render() {

        const {
            t, user: {isAddToCart, isAutoPrint}, setIsAddToCart,
            newResult,
            config: {
                packagePrices,
                limit
            },
            betMessage,
            betGameType,
            betGameOdds,
            betGameTime,
            betAmount,
            showBetting,
            setIsAutoPrint,
            classDialog,
            isEnableBetNow,
            i18n
        } = this.props;

        const isZh = i18n.language === 'zh';
        return <Modal show={showBetting} onHide={this.onCancelBetting}>
            <Modal.Body>
                <div className={`bg-overlay-keno-betting ${inIframe() ? "dialog-iframe" : ""}`}>
                    <div className={"modal-keno-betting ".concat(classDialog)}>
                        <div className="wrap-keno-betting-header-row">
                            <div className="title text-left">
                                <p>{`${newResult?.drawLabel}`}</p>
                            </div>
                            <div
                                className="action-betting-btn text-right d-flex align-items-center justify-content-end">
                                <div className="btn-auto-print">
                                    <ButtonAutoPrint isPrint={isAutoPrint} setIsAutoPrint={setIsAutoPrint}/>
                                </div>

                                <button className="btn-game-close-betting"
                                        onClick={this.onCancelBetting}>
                                    X
                                </button>
                            </div>
                        </div>
                        <div className="wrap-keno-betting-row">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th scope="col">{t("labels:common.betType")}:</th>
                                    <th scope="col">{
                                        betGameType && <span
                                            className="modal-text-green">
                                        {t("labels:betPrefix." + (betGameType).toUpperCase())}
                                    </span>
                                    }
                                    </th>
                                    <th scope="col">{t("labels:common.betPoint")}:</th>
                                    <th scope="col"><span
                                        className="modal-text-green">{betGameOdds}</span>
                                    </th>
                                    <th scope="col">{t("labels:common.time")}:</th>
                                    <th scope="col"><span
                                        className="modal-text-green">{betGameTime}</span>
                                    </th>
                                </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="wrap-keno-betting-row">
                            <div className="body">
                                <div className="modal-bet-amount-row">
                                    <div className="cols title">{t("labels:common.betAmount")}:
                                    </div>
                                    <div className="cols">
                                        <div
                                            className="input-bet-amount-red d-flex  align-items-center bg-white none-select">
                                            {moneyFormatter(betAmount)}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    {
                                        packagePrices.map((item, i) =>
                                            <button
                                                className="btn-bet-amount-red"
                                                onClick={() => this.clickAmountBetBtn(item.value)}
                                                key={i}
                                            >{isZh ? getZh[item.value] : item.label}</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="wrap-keno-betting-row">
                            <div className="cols">{t("labels:common.minBet")}: <NumberFormat
                                value={limit.minBet} displayType={'text'}
                                thousandSeparator={true}/></div>
                            <div className="cols">{t("labels:common.maxBet")}: <NumberFormat
                                value={limit.maxBet} displayType={'text'}
                                thousandSeparator={true}/></div>
                        </div>

                        {betMessage !== "" ? <div className="text-center text-danger">
                            <strong>{betMessage}</strong></div> : null}

                        <div className="wrap-keno-betting-row">
                            <div className="w-50 d-flex align-items-center justify-content-center">
                                <input checked={!isAddToCart} onChange={(_) => setIsAddToCart(!isAddToCart)}
                                       type="checkbox"/><label
                                className="mb-2 checkbox-label">{t("labels:submit_one_bet")}</label>
                            </div>
                        </div>
                        <div className="wrap-keno-betting-row">
                            <div className="cols">
                                <button className="btn-game-bet"
                                        disabled={!isEnableBetNow && !isAddToCart}
                                        onClick={this.onConfirmBettingLotto}>{t("labels:common.bet")}</button>
                            </div>

                            <div className="cols">
                                <button className="btn-game-reset"
                                        onClick={this.resetDefaultAmount}>{t("labels:common.resetBet")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    }
}

export default BettingDialog;
