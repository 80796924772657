import React from "react";
import BettingDashboardMix from "./BettingDashboardMix";
import StatisticTableMix from "./StatisticTableMix";
import TabView from "../../lotto/TabView";
import RecentOrdering from "../RecentOrdering";
import RecentBetting from "../RecentBetting";
import {isOddEven, pfType999Color} from "../../../constants/general";


export default (props) => {
    const {
        t, lastResult: {
            oddEvenCode, underOverCode, number, result, drawLabel
        }
    } = props;
    const tabs = [
        t("labels:drawing.betting"),
        t("labels:drawing.result")
    ];
    const children = [
        <>
            <RecentOrdering {...props}/>
            <RecentBetting {...props}/>
        </>,
        <StatisticTableMix/>
    ]

    return <div className="lotto5-mix">
        {/*    /!* betting dashboard and pricing *!/*/}
        <BettingDashboardMix {...props}/>
        {/*    /!* end of betting dashboard and pricing *!/*/}
        <div
            className={"col-12 header header-div-title-mix header-text fade-in loading-gif-" + props.platform + " " + pfType999Color}>
                            <span
                                className="text-white">{drawLabel}</span>
            {
                underOverCode ?
                    <span
                        className="text-white fade-in">{t("labels:betPrefix." + underOverCode) + "  /   " + (isOddEven ? t("labels:betPrefix." + oddEvenCode) + " / " : "") + t("labels:betPrefix." + number) + "   /   " + result}</span>
                    : <span className="text-white fade-in"/>
            }

        </div>
        <div className="tab-view border-all">
            <TabView children={children} tabs={tabs}/>
        </div>


    </div>
}