import _ from "lodash";
import Images from "../../../constants/lotto5Images";
import {getShortBetType} from "../../../utils/tickets/printUtils";
import {Jackpot} from "../../jackpot/JackpotValue";
import Paginate from "../../table/Paginate";
import KenoResultDetail from "../../keno/KenoResultDetail";
import React, {Component} from "react";
import {getDateDef} from "../../../utils/dateUtils";
import {isOddEven} from "../../../constants/general";

class MainResultLotto5 extends Component {

    render() {
        const {t, showResult, data, items, totals, pages, size, onChange, onToggledResult} = this.props;
        return (
            <div className="wrapper-game-content">
                <div className="table-container pt-0">
                    <table className="table-custom">
                        <thead>
                        <tr>
                            <th className="text-center">{t("labels:drawing.no")}</th>
                            <th className="text-center">{t("labels:drawing.date")}</th>
                            <th className="text-center">{t("labels:drawing.drawCode")}</th>
                            <th className="text-center">{t("labels:drawing.result")}</th>
                            <th className="text-center">{t("labels:drawing.underOver")}</th>
                            {isOddEven && <th className="text-center">{t("labels:drawing.oddEvenCode")}</th>}
                            <th className="text-center">{t("labels:drawing.range")}</th>
                            <th className="text-center">{t("labels:drawing.jackpot")}</th>
                            <th className="text-center">{t("labels:drawing.detail")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            _.isEmpty(items)
                                ? <tr>
                                    <td colSpan={10}><img className="data-not-found-img"
                                                          src={Images.dataNotFound} alt="..."/></td>
                                </tr>
                                : items.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center">{getDateDef(item.createdAt)}</td>
                                            <td className="text-center">
                                                <span className="title-ticket-id-btn">{item.drawLabel}</span>
                                            </td>
                                            <td className="text-center">{item.result}</td>
                                            <td className={"text-center left-bet-type-" + item.underOverCode}>{t("labels:betPrefix.S_" + getShortBetType(item.underOverCode))}</td>
                                            {isOddEven && <td
                                                className={"text-center left-bet-type-" + item.oddEvenCode}>{t("labels:betPrefix." + item.oddEvenCode)}</td>}
                                            <td className="text-center"><span
                                                className={"text-white left-bet-type-" + item.rangeCode}>{t("labels:betPrefix." + item.rangeCode)}</span>
                                            </td>
                                            <td className={"text-center"}>
                                                <Jackpot item={item} t={t}/>
                                            </td>
                                            <td className="text-center">{item.detail}</td>
                                        </tr>
                                    );
                                })
                        }
                        </tbody>
                    </table>
                </div>

                {
                    totals > 0
                        ? <div className="mt-2">
                            <Paginate
                                page={pages}
                                size={size}
                                total={totals}
                                onChange={page => onChange(page)}
                            />
                        </div>
                        : null
                }

                {
                    showResult
                        ? <div className="bg-overlay-keno-result">
                            <div className="modal-keno-result">
                                <div className="wrap-keno-header-row">
                                    <div className="title text-left">
                                        <p>{t("labels:common.result")}</p>
                                    </div>
                                    <div className="action-btn text-right">
                                        <button className="btn-game-close"
                                                onClick={onToggledResult(false, null)}>X
                                        </button>
                                    </div>
                                </div>
                                <KenoResultDetail data={data}/>
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}

export default MainResultLotto5;