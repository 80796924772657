import {getLocale} from "../../store/localization/locales";
import React from "react";

const Header888 = ({...props}) => {
    const {
        t,
        i18n,
        username,
        platformType,
        platform,
        isLotteryMix,
        listLocales,
        onChangeMute,
        logout,
        soundImg
    } = props;

    return <div className={"w-1200 header h-120 header-" + platform}>
        <div className="logo fade-in">
            <img src={require(`../../assets/images/logo/${platformType}.png`)} alt="..."/>
        </div>

        <div className="header-topbar">
            <div className="header-topbar-content">
                <div className="left-top-bar">
                    <label
                        className="fade-in">{t("labels:welcome")}: <strong>{username}</strong></label>
                </div>
                <div className="right-top-bar">
                    <div className="dropdown header-social fade-in">
                        <img
                            className="dropbtn  img-rounded"
                            width="40"
                            src={getLocale(i18n.language).flag}
                            alt="..."
                        />
                        <div className="dropdown-content">{listLocales}</div>
                    </div>
                    <div className="dropdown header-social fade-in">
                        <img
                            className={"dropbtn img-rounded social-top-" + platform}
                            width="28"
                            src={require("../../assets/images/logo/youtube.svg")}
                            alt="..."
                        />
                    </div>

                    <div className="dropdown header-social fade-in">
                        <img
                            className={"dropbtn img-rounded social-top-" + platform}
                            width="27"
                            src={require("../../assets/images/logo/facebook.svg")}
                            alt="..."
                        />
                    </div>
                </div>
            </div>
            <div className="marquee fade-in">
                <p className="fade-in">{t("labels:marqeeTitle")}</p>
            </div>
            <div className="header-right">
                <div className="account-language">
                    {
                        !isLotteryMix && <img
                            alt="audio"
                            onClick={onChangeMute}
                            width={40}
                            className="pr-2"
                            style={{cursor: "pointer"}}
                            src={soundImg}/>
                    }
                    <button
                        className={`btn-logout ${platform === "KPW" ? " bg-red" : ""} bg-${platform}`}
                        onClick={logout}>{t("labels:logout")}</button>
                </div>
            </div>
        </div>
    </div>
}

export default Header888;