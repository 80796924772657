import {gql} from "@apollo/client";

const posts = ["A", "B", "C", "D", "E"];


const ballNumbers = Array(99).fill(0).map((e, i) => i + 1);
const SUBSCRIPTION_LOTTO99 = gql`
        subscription Lotto99 {
            v_lotto99_drawing {
                 drawCode
                 status
            }
           }`;

export {posts, ballNumbers, SUBSCRIPTION_LOTTO99}
