import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import { Range} from "./ResultDecoration";
import {Badge} from "reactstrap";

const HistoryTableRow = ({t, index, object}) => {

    return (

        <tr className="animate-slide-up">
            <th className="text-center align-middle" scope="row">{index}</th>
            <th className="text-center align-middle" scope="row">{object.drawCode}</th>
            <td className="text-center align-middle">{moment(object.drawAt).format("YYYY-MM-DD hh:mm")}</td>

            <td className="text-center align-middle">{
                object.posts && object.posts.map((row, i) => {
                    return <span className="font-weight-bold result-number-cycle"
                                 key={i}>{row.number} {object.posts.length === (i + 1) ? "" : ""}</span>
                })
            }</td>
            <th className="text-center align-middle">
                <Badge className="f-18 text-white"
                       color="success">{object.result}</Badge>
            </th>

            <td className="text-center align-middle">
                <Badge className="f-12 text-white"
                color={object.underOverCode === "UNDER" ? "primary"  : "danger"}>{t("labels:drawing.lotto90.label." + object.underOverCode)}</Badge>
            </td>

            <td className="text-center align-middle">
                <Badge className="f-12 text-white"
                       color={object.oddEvenCode === "ODD" ? "success"  : "info"}>{t("labels:drawing.lotto90.label." + object.oddEvenCode)}</Badge>
            </td>

            <td className="text-center align-middle">
                <Badge className="f-12 text-white" color="primary">{object.smallCode}</Badge>
                &nbsp;&nbsp;/&nbsp;&nbsp;
                <Badge className="f-12 text-white" color="danger">{object.bigCode}</Badge>
            </td>

            <td className="text-center align-middle">
                <Range code={object.rangeCode}>{t("labels:betting." + object.rangeCode)}</Range>
            </td>

            {object.isJackpot ?
                <td className="text-center align-middle">
                    {t("labels:betting.jp." + object.jackpotType)} X{object.jackpot}</td>
                : <td>---</td>
            }
        </tr>
    );
};

HistoryTableRow.propTypes = {
    index: PropTypes.number.isRequired,
    object: PropTypes.object.isRequired
};

export default withTranslation()(HistoryTableRow);
